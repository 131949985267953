import {
  COLLECTION_FRAGMENT,
  COLLECTION_SUBSCRIPTION_FRAGMENT,
  FORM_FRAGMENT,
} from "@amenda-domains/fragments/forms";
import { gql, useClient, useSubscription } from "urql";
import { useCallback, useState } from "react";
import {
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useUsersStore,
  useVerifyResourceTenant,
} from "@amenda-domains/mutations";

export const GET_ALL_FORMS = gql`
  ${FORM_FRAGMENT}
  query GetAllForms(
    $name: String
    $category: String
    $order: Int
    $isDeleted: Boolean
  ) {
    getAllForms(
      name: $name
      category: $category
      order: $order
      isDeleted: $isDeleted
    ) {
      ...FormFragment
    }
  }
`;

export const useGetAllForms = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setForms = useProjectStore((state) => state.setForms);

  const getAllForms = useCallback(async () => {
    setLoading(true);
    const { data } = await client.query(GET_ALL_FORMS, {
      isDeleted: false,
    });
    if (data?.getAllForms) {
      setForms(data.getAllForms);
    }
    setLoading(false);
  }, [client, setForms]);

  return {
    loading,
    getAllForms,
  };
};

export const GET_ALL_COLLECTIONS = gql`
  query GetAllCollections(
    $id: ID
    $name: String
    $shareable: Boolean
    $isDeleted: Boolean
    $collectionType: AllowedCollectionType
  ) {
    getAllCollections(
      _id: $id
      name: $name
      shareable: $shareable
      isDeleted: $isDeleted
      collectionType: $collectionType
    ) {
      id: _id
      name
      keywords
      ownerId
      resourceIds
      collectionType
      shares {
        userIds
        userRolesIds
        expirationDate
      }
    }
  }
`;

export const useGetAllCollections = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setCollectionsByType = useFormStore(
    (state) => state.setCollectionsByType
  );

  const getAllCollections = useCallback(
    async ({ context = {}, ...variables }: Record<string, any> = {}) => {
      setLoading(true);
      const { data } = await client.query(
        GET_ALL_COLLECTIONS,
        variables,
        context
      );
      if (data?.getAllCollections) {
        setCollectionsByType(data.getAllCollections, variables.collectionType);
      }
      setLoading(false);
    },
    [client, setCollectionsByType]
  );

  return {
    loading,
    getAllCollections,
  };
};

const GET_COLLECTION = gql`
  query GetCollection($id: ID, $name: String, $shareable: Boolean) {
    getCollection(_id: $id, name: $name, shareable: $shareable) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`;

export const useGetCollection = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedCollectionByType = useFormStore(
    (state) => state.setSelectedCollectionByType
  );

  const getCollection = useCallback(
    async (variables: Record<string, any> = {}) => {
      setLoading(true);
      const { data } = await client.query(GET_COLLECTION, variables);
      if (data?.getCollection) {
        setSelectedCollectionByType(data.getCollection);
      }
      setLoading(false);
    },
    [client, setSelectedCollectionByType]
  );

  return {
    loading,
    getCollection,
  };
};

const COLLECTION_CREATED_SUBS = gql`
  ${COLLECTION_SUBSCRIPTION_FRAGMENT}
  subscription {
    collectionCreated {
      ...CollectionSubsFragment
    }
  }
`;

const COLLECTION_UPDATED_SUBS = gql`
  ${COLLECTION_SUBSCRIPTION_FRAGMENT}
  subscription {
    collectionUpdated {
      ...CollectionSubsFragment
    }
  }
`;

const COLLECTION_DELETED_SUBS = gql`
  subscription {
    collectionDeleted {
      id: _id
      isDeleted
      tenantId
      collectionType
    }
  }
`;

const isUserInCollection = ({
  collection,
  userId,
  roleId,
}: {
  collection: any;
  userId: string;
  roleId?: string;
}) => {
  const { ownerId, shares } = collection;
  if (ownerId === userId) {
    return true;
  } else if (shares?.[0]?.userIds?.includes(userId)) {
    return true;
  } else if (shares?.[0]?.userRolesIds?.includes(roleId)) {
    return true;
  }
  return false;
};

export const useCollectionCreatedSubscription = () => {
  const { isInTenant } = useVerifyResourceTenant();
  const upsertOrFilterCollection = useFormStore(
    (state) => state.upsertOrFilterCollection
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const currentSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole
  );

  useSubscription({ query: COLLECTION_CREATED_SUBS }, (messages, response) => {
    const collection = response?.collectionCreated;

    if (
      isInTenant(collection) &&
      isUserInCollection({
        collection,
        userId: currentUser?.id,
        roleId: currentSystemRole?.id,
      })
    ) {
      upsertOrFilterCollection(collection);
    }
  });
};

export const useCollectionUpdatedSubscription = () => {
  const { isInTenant } = useVerifyResourceTenant();
  const upsertOrFilterCollection = useFormStore(
    (state) => state.upsertOrFilterCollection
  );

  useSubscription({ query: COLLECTION_UPDATED_SUBS }, (messages, response) => {
    const collection = response?.collectionUpdated;

    if (isInTenant(collection)) {
      upsertOrFilterCollection(collection);
    }
  });
};

export const useCollectionDeletedSubscription = () => {
  const { isInTenant } = useVerifyResourceTenant();
  const upsertOrFilterCollection = useFormStore(
    (state) => state.upsertOrFilterCollection
  );

  useSubscription({ query: COLLECTION_DELETED_SUBS }, (messages, response) => {
    const collection = response?.collectionDeleted;

    if (isInTenant(collection)) {
      upsertOrFilterCollection(collection);
    }
  });
};
