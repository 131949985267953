import { FC, useEffect } from "react";
import {
  ProjectLayout,
  ReadOnlyProjectTabs,
  ReadonlyProjectHeader,
} from "@amenda-components/Projects";

import { FaceAPIProvider } from "@amenda-context";
import { MoveAttachmentModal } from "@amenda-components/Gallery";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router";
import { useProjectStore } from "@amenda-domains/mutations";

export const ProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { getProject } = useGetProject();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
    };
  }, [clearProjectValues]);

  return (
    <FaceAPIProvider>
      <ProjectLayout title={selectedProject?.name}>
        <MoveAttachmentModal />
        <div className="bg-white w-full">
          <ReadonlyProjectHeader />
          <div className="h-auto w-full pt-4">
            <ReadOnlyProjectTabs />
          </div>
        </div>
      </ProjectLayout>
    </FaceAPIProvider>
  );
};
