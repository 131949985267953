import * as Sentry from "@sentry/react";

import { AppRoutes } from "@amenda-types";
import { Helmet } from "react-helmet-async";
import { devConsole } from "@amenda-utils";
import { firebaseSignOut } from "@amenda-domains/mutations";
import { storage } from "@amenda-domains/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Logout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const logOut = async () => {
      await firebaseSignOut();
      navigate("/login", {
        state: { from: AppRoutes.Logout },
      });
    };

    logOut();
    Sentry.setUser(null);
    return () => {
      storage.clear().then(() => devConsole?.info("amenda:Cache cleared!"));
    };
  }, [navigate]);

  return (
    <>
      <Helmet title={t("Logout")} />
      <div />
    </>
  );
};
