import { AdminSettingsLayout, FormBuilder } from "@amenda-components/Settings";

import { FC } from "react";
import { useTranslation } from "react-i18next";

export const FormBuilderPage: FC = () => {
  const { t } = useTranslation();

  return (
    <AdminSettingsLayout className="pt-1 lg:pt-6 px-4">
      <div className="sm:flex sm:items-center px-2">
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Form Builder")}</h1>
        </div>
      </div>
      <FormBuilder />
    </AdminSettingsLayout>
  );
};
