import { CollectionMenu, DeleteModal } from "@amenda-components/Shared";
import { FC, useState } from "react";
import {
  useDeleteMaterials,
  useFormStore,
  useMaterialsStore,
  useUpdateCollection,
} from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { Button } from "@amenda-components/App";
import clsx from "clsx";
import { useGetAllMaterials } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  isCollection: boolean;
}

export const MaterialsActionBar: FC<Props> = ({ isCollection }) => {
  const { t } = useTranslation();
  const { getAllMaterials } = useGetAllMaterials();
  const [openModal, setOpenModal] = useState(false);
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const selectedCollection = useFormStore(
    (state) => state.selectedCollectionByType[AllowedCollectionType.Materials],
  );
  const selectedMaterialIds = useMaterialsStore(
    (state) => state.selectedMaterialIds,
  );
  const clearSelectedMaterialIds = useMaterialsStore(
    (state) => state.clearSelectedMaterialIds,
  );
  const { deleteMaterials, loading } = useDeleteMaterials();

  const handleCloseModal = () => setOpenModal(false);

  const handleArchiveUsers = async () => {
    await deleteMaterials({
      ids: selectedMaterialIds,
      deleteRelatedResources: false,
    });
    handleCloseModal();
    clearSelectedMaterialIds();
  };

  const handleDeleteFromCollection = async () => {
    if (selectedCollection?.id) {
      await updateCollection({
        input: {
          _id: selectedCollection.id,
          aggregation: {
            $pull: {
              resourceIds: {
                $in: selectedMaterialIds,
              },
            },
          },
        },
      });
      clearSelectedMaterialIds();
    }
  };

  const handleDeleteMaterials = async () => {
    if (isCollection) {
      await handleDeleteFromCollection();
    } else {
      setOpenModal(true);
    }
  };

  const handleClearSelectedMaterials = () => {
    clearSelectedMaterialIds();
  };

  const handleGetResourceIds = async (args: any) => {
    const materials = await getAllMaterials({
      skipStore: true,
      formValues: args,
    });
    return materials?.map((material: any) => material.id);
  };

  return (
    <>
      <DeleteModal
        title="Delete materials"
        description="Are you sure you want to delete these materials?"
        loading={loading}
        openModal={openModal}
        handleDelete={handleArchiveUsers}
        handleCloseModal={handleCloseModal}
      />
      <div className="flex items-center h-9 justify-end">
        <div
          className={clsx("flex items-center", {
            "space-x-1": selectedMaterialIds.length <= 0,
          })}
        >
          {selectedMaterialIds.length > 0 && (
            <>
              <Button
                className="text-red-600"
                size="xs"
                onClick={handleClearSelectedMaterials}
              >
                {t("Cancel")}
              </Button>
              <CollectionMenu
                isCollection={isCollection}
                isDeleting={updateCollectionLoader}
                resourceIds={selectedMaterialIds}
                collectionType={AllowedCollectionType.Materials}
                collectionRoute="/einheitspreise/sammlungen"
                getResourceIds={handleGetResourceIds}
                handleDeleteFromCollection={handleDeleteMaterials}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
