import { Chip, IconButtonBase } from "@amenda-components/App";

import { FC } from "react";
import { FormBuilderSortableItem } from "@amenda-components/Settings/FormBuilderDndComponents";
import { FormComponentTypes } from "@amenda-constants";
import { GripIcon } from "lucide-react";
import { PageComponentProps } from "@amenda-types";
import clsx from "clsx";
import { formatDescription } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface HeaderTitleAndDescriptionProps {
  title: string;
  description: string;
  titleClassName?: string;
  descriptionClassName?: string;
}

interface FormBuilderReadOnlyHeaderProps {
  component?: PageComponentProps;
}

interface HeaderSelectsProps {
  values: any[];
  component?: PageComponentProps;
}

interface HeaderTextProps {
  value: any;
  component?: PageComponentProps;
}

const HeaderTitleAndDescription: FC<HeaderTitleAndDescriptionProps> = ({
  title,
  description,
  titleClassName = "font-apercu text-sm font-medium text-gray-900",
  descriptionClassName = "font-apercu text-sm font-medium text-gray-500 mt-1",
}) => {
  return (
    <>
      <dt className={titleClassName}>{title}</dt>
      <dd className={descriptionClassName}>{description}</dd>
    </>
  );
};

export const HeaderSelects: FC<HeaderSelectsProps> = ({
  component,
  values,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      {component?.headerProperties?.properties?.withTitle && (
        <span
          className={
            component?.headerProperties?.properties.titleClassName ??
            "amenda-component-label mb-2"
          }
        >
          {t(component?.properties?.label ?? "")}
        </span>
      )}
      <div className="w-full flex-wrap hidden lg:flex">
        {values.map((v) => (
          <div className="mb-1 mr-2" key={v.value}>
            <Chip label={v.label} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const HeaderText: FC<HeaderTextProps> = ({ component, value }) => {
  const { t } = useTranslation();
  const description = formatDescription(value);

  if (component?.headerProperties?.properties?.withTitle) {
    return (
      <div className="w-full">
        <HeaderTitleAndDescription
          title={component?.properties?.label}
          description={description}
          titleClassName={
            component?.headerProperties?.properties?.titleClassName
          }
          descriptionClassName={
            component?.headerProperties?.properties?.descriptionClassName
          }
        />
      </div>
    );
  }
  return description ? (
    <div className="w-full">
      <div
        className={clsx(
          component?.headerProperties?.properties?.descriptionClassName ??
            "font-apercu text-sm font-medium text-gray-500 mb-8 max-w-2xl",
          {
            "whitespace-pre-line overflow-y-auto overscroll-contain":
              component?.component === FormComponentTypes.Textarea,
          },
        )}
      >
        {t(description)}
      </div>
    </div>
  ) : null;
};

const ReadOnlyHeaderComponent: FC<FormBuilderReadOnlyHeaderProps> = ({
  component,
}) => {
  switch (component?.component as FormComponentTypes) {
    case FormComponentTypes.Badges:
    case FormComponentTypes.Keyword:
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Checkbox:
    case FormComponentTypes.RadioButton:
      return (
        <div className="w-full flex flex-col space-y-1">
          <span className="text-sm text-base font-apercu">
            {component?.properties?.label}
          </span>
          <HeaderSelects
            values={(
              component?.properties?.options ?? [
                { value: "a", label: "A" },
                { value: "b", label: "B" },
                { value: "c", label: "C" },
              ]
            ).slice(0, 4)}
            component={component}
          />
        </div>
      );
    default:
      const value = !Boolean(component?.headerProperties?.properties?.withTitle)
        ? component?.properties?.label
        : "Placeholder";
      return <HeaderText value={value} component={component} />;
  }
};

export const FormBuilderReadOnlyHeader: FC<FormBuilderReadOnlyHeaderProps> = ({
  component,
}) => {
  return (
    <FormBuilderSortableItem
      id={component?.id ?? ""}
      data={{
        component,
      }}
    >
      {(isDragging) => (
        <div
          className={clsx(
            "flex items-baseline border border-dashed border-transparent hover:border-gray-300",
            {
              invisible: isDragging,
            },
          )}
        >
          <div>
            <IconButtonBase className="cursor-grab px-0.5 py-1 mr-1 invisible group-hover/formBuilderReadOnlyHeader:visible">
              <GripIcon className="w-4 h-4 stroke-[2]" />
            </IconButtonBase>
          </div>
          <ReadOnlyHeaderComponent component={component} />
        </div>
      )}
    </FormBuilderSortableItem>
  );
};
