import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  ChartBarIcon,
  PresentationChartLineIcon as ChartLineIcon,
} from "@heroicons/react/24/outline";

import "chart.js";
import {
  CategoryScale,
  LinearScale,
  Chart,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";

Chart.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement
);

interface MaterialPriceChartProps {
  data: { totalPrice: number; creationDate: string }[];
}

const MaterialPriceChart: React.FC<MaterialPriceChartProps> = ({ data }) => {
  const [chartType, setChartType] = useState<"bar" | "line">("bar");
  const [averagePrice, setAveragePrice] = useState<number | null>(null);

  useEffect(() => {
    const totalPrices = data.map((item) => item.totalPrice);
    const avgPrice =
      totalPrices.reduce((sum, price) => sum + price, 0) /
      (totalPrices.length > 0 ? totalPrices.length : 1);
    setAveragePrice(avgPrice);
  }, [data]);

  const labels = data.map((item) => {
    const date = new Date(item.creationDate);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getFullYear()}`;
  });

  const prices = data.map((item) => item.totalPrice);

  const trendArrows = data.map((item, index) => {
    if (index < data.length - 1) {
      const trend = data[index + 1].totalPrice - item.totalPrice;
      return {
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: index,
        borderColor: trend > 0 ? "rgba(0, 255, 0, 1)" : "rgba(255, 0, 0, 1)",
        borderWidth: 2,
        label: {
          enabled: true,
          content: `${trend > 0 ? "Up" : "Down"}`,
          position: "start",
        },
      };
    }
    return null;
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: "Material Price (€)",
        data: prices,
        borderColor: "rgba(34, 34, 34, 1)",
        fill: false,
      },
    ],
  };

  // Calculate min, max, and average values from the totalPrice property
  const totalPrices = data.map((item) => item.totalPrice);
  const minValue = Math.min(...totalPrices);
  const maxValue = Math.max(...totalPrices);
  const avgValue =
    totalPrices.reduce((sum, val) => sum + val, 0) / totalPrices.length;

  const options = {
    scales: {
      x: {
        type: "category",
        title: {
          display: false,
          text: "Months and Days",
        },
        position: "top",
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        drawBorder: false,
        drawTicks: false,
      },
      y: {
        type: "linear",
        title: {
          display: false,
          text: "Material Price (€)",
        },
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
          callback: (value: any, index: number, values: string | any[]) => {
            if (value === minValue) {
              return `${value} € (Min)`;
            } else if (value === maxValue) {
              return `${value} € (Max)`;
            } else if (value === avgValue) {
              return `${value} € (Avg)`;
            } else {
              return `${value} €`;
            }
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: {
            dataset: { label: string };
            parsed: { y: any };
          }) => {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            return `${label}: ${value} €`;
          },
        },
      },
      tooltips: {
        enabled: false,
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: averagePrice,
            borderColor: "rgba(255, 0, 0, 1)",
            borderWidth: 2,
            label: {
              enabled: true,
              content: "Average",
              position: "start",
            },
          },
          ...trendArrows.filter((arrow) => arrow !== null),
        ],
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="pt-2 w-full lg:h-[260px] h-[100px] relative border-b border-1">
      {chartType === "bar" ? (
        <Bar data={chartData} options={options} />
      ) : (
        <Line data={chartData} options={options} />
      )}
      <div className="absolute bottom-2 right-4 flex flex-col space-y-2">
        <button
          className={`px-4 py-2 bg-gray-800 text-white  ${
            chartType === "bar" ? "bg-opacity-100" : "bg-opacity-50"
          }`}
          onClick={() => setChartType("bar")}
        >
          <ChartBarIcon className="h-4 w-4" />
        </button>
        <button
          className={`px-4 py-2 bg-gray-800 text-white  ${
            chartType === "line" ? "bg-opacity-100" : "bg-opacity-50"
          }`}
          onClick={() => setChartType("line")}
        >
          <ChartLineIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default MaterialPriceChart;
