import { Control, UseFormSetValue, useController } from "react-hook-form";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  generalPermissions,
  getGeneralPermissionsName,
} from "./common";

import { Button } from "@amenda-components/App";
import { FieldPermissionsModal } from "./FieldPermissionsModal";
import { MiniSwitch } from "@amenda-components/FormComponents/MiniSwitch";
import { Option } from "@amenda-types";
import { PermissionComponentsWrapper } from "./PermissionComponentsWrapper";
import { useTranslation } from "react-i18next";

interface Props {
  selectedTab: GeneralPermissionKeys;
  control: Control<any, object>;
  globalData?: Record<string, any>;
  setValue: UseFormSetValue<any>;
}

interface PermissionSelectionProps {
  permission: Option & {
    components?: any[];
  };
  selectedTab: GeneralPermissionKeys;
  control: Control<any, object>;
}

const PermissionSelection: FC<PermissionSelectionProps> = ({
  selectedTab,
  permission,
  control,
}) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: getGeneralPermissionsName(selectedTab, permission.value),
  });

  return (
    <div className="w-full">
      <div className="flex flex-row items-center space-x-4 pb-1">
        <MiniSwitch
          label={permission.label}
          value={value}
          onChange={onChange}
        />
        <span className="text-sm font-apercu text-gray-900">
          {t(permission.label)}
        </span>
      </div>
      {permission?.components?.map?.((component) => (
        <PermissionComponentsWrapper
          key={component.id}
          show={value}
          control={control}
          component={component}
          selectedTab={selectedTab}
        />
      ))}
    </div>
  );
};

export const GeneralPermissionSettings: FC<Props> = ({
  selectedTab,
  control,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const permissions = generalPermissions[selectedTab];

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div className="mt-3">
      <FieldPermissionsModal
        isOpen={isOpen}
        control={control}
        selectedTab={selectedTab}
        onClose={handleClose}
        {...rest}
      />
      <div className="flex flex-col space-y-1" key={selectedTab}>
        {permissions.map((permission) => (
          <PermissionSelection
            key={permission.value}
            permission={permission}
            control={control}
            selectedTab={selectedTab}
          />
        ))}
      </div>
      {![GeneralPermissionKeys.Contacts, GeneralPermissionKeys.Admin].includes(
        selectedTab
      ) && (
        <div className="justify-start flex flex-row items-end w-full pt-4">
          <Button
            size="sm"
            variant="default"
            className="mt-1 border border-gray-300"
            onClick={handleOpen}
          >
            {t("Set field permissions")}
          </Button>
        </div>
      )}
    </div>
  );
};
