import { FC } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
}

export const PulseLoader: FC<Props> = ({ className = "" }) => {
  return (
    <div className="amenda-pulse-loader absolute pt-3 ml-auto text-gray-700 text-xs text-center leading-4 opacity-0 group-hover:opacity-100">
      <div className={clsx(className, "border-indigo-500")}></div>
      <div className={clsx(className, "border-indigo-500")}></div>
    </div>
  );
};
