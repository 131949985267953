import { CostGroupTypes, processCostGroupPermissions } from "./common";
import { FC, useEffect } from "react";
import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { CalculatorIcon } from "@heroicons/react/24/solid";
import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { HelperMessage } from "@amenda-components/App";
import { ReadOnlyCostGroupRowBody } from "./ReadOnlyCostGroupRowBody";
import { ReadOnlySelectCostGroupVersion } from "./ReadOnlySelectCostGroupVersion";
import isEmpty from "lodash/isEmpty";
import { keyBy } from "lodash";
import { useGetAllCostGroups } from "@amenda-domains/queries";

export const ReadOnlyProjectCostGroups: FC<{ projectId: string }> = ({
  projectId,
}) => {
  const forms = useProjectStore((state) => state.forms);
  const { getAllCostGroups } = useGetAllCostGroups();
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole
  );

  const costGroupCostForms = forms?.[AvailableForms.CostGroupsCost];
  const costGroupQuantityForms =
    forms?.[AvailableForms.CostGroupsQuantity] || [];
  const costGroupQuantityComponents = getComponentsFromForms(
    costGroupQuantityForms
  );
  const quantityComponentsById = keyBy(costGroupQuantityComponents, "id");
  const formComponents = getComponentsFromForms(costGroupCostForms);
  const permissions = currentUserSystemRole?.permissions || {};
  const formComponentTree = groupComponentsByParent(
    formComponents,
    processCostGroupPermissions(permissions)
  );

  useEffect(() => {
    if (projectId) {
      getAllCostGroups({
        isDeleted: false,
        resourceId: projectId,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getAllCostGroups]);

  if (isEmpty(formComponentTree.components)) {
    return (
      <HelperMessage
        message="Restricted Form"
        helpText="You don't have permissions to view this form"
        Icon={CalculatorIcon}
      />
    );
  }
  return (
    <ComponentsTreeView
      showTooltip={true}
      readOnly={true}
      components={formComponentTree.components as any[]}
      label="cost group"
      className="max-h-[80vh]"
      columnClassName="w-[16rem] bg-white"
      searchPlaceholder="Kostengruppe filtern..."
      columns={CostGroupTypes.map((type) => ({
        id: type,
        label: type,
      }))}
      RowBody={ReadOnlyCostGroupRowBody}
      HeaderComponent={ReadOnlySelectCostGroupVersion}
      globalData={{
        quantityComponentsById,
      }}
    />
  );
};
