import { gql } from "urql";

export const SYSTEM_ROLE_FRAGMENT = gql`
  fragment UserRolesFragment on SystemRole {
    id: _id
    name
    usersIds
    description
    isDefaultRole
  }
`;

export const WIDGET_FRAGMENT = gql`
  fragment WidgetFragment on Widget {
    id: _id
    type
    widgets {
      id
      layout
      properties
    }
  }
`;
