import { FC, ReactNode } from "react";

import clsx from "clsx";

interface Props {
  className?: string;
  wrapperClassName?: string;
  children: ReactNode;
}

export const MaxWidthLayout: FC<Props> = ({
  className,
  wrapperClassName,
  children,
}) => {
  return (
    <div className={clsx("w-full flex justify-center", wrapperClassName)}>
      <div className={clsx("w-full", className)}>{children}</div>
    </div>
  );
};
