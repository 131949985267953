import { AllowedCollectionType, AllowedContactType } from "@amenda-types";
import {
  useGetAllUsers,
  useGetUser,
  useSearchUsers,
} from "@amenda-domains/queries";

import { AppRoutes } from "@amenda-types";
import { BlockedRoutes } from "@amenda-constants";
import { ContactDirectoryWrapper } from "./ContactDirectoryWrapper";
import { useEffect } from "react";
import { useFormStore } from "@amenda-domains/mutations";

export const UsersCollectionPage = () => {
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const { getUser } = useGetUser();
  const { getAllUsers } = useGetAllUsers();
  const { searchUsers } = useSearchUsers();

  const collectionType = AllowedCollectionType.Users;
  const selectedCollection = selectedCollectionByType[collectionType];

  useEffect(() => {
    return () => {
      clearSelectedCollectionByType(collectionType);
    };
  }, [clearSelectedCollectionByType, collectionType]);

  return (
    <ContactDirectoryWrapper
      autoSelect={true}
      isCollection={true}
      collectionResourceIds={selectedCollection.resourceIds}
      singularTitle="Colleague"
      pluralTitle="Colleagues"
      collectionType={collectionType}
      rootRoute={AppRoutes.Colleagues}
      collectionRoute={BlockedRoutes.ColleaguesCollection}
      defaultContactType={AllowedContactType.office}
      getContact={getUser}
      getAllContacts={getAllUsers}
      searchContacts={searchUsers}
    />
  );
};
