import { FC } from "react";
import {
  UsersIcon,
  CalendarIcon,
  CurrencyEuroIcon,
  Squares2X2Icon,
  CreditCardIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@amenda-components/App";
import { SettingsLayout } from "@amenda-components/Settings";

export const Subscription: FC = () => {
  const { t } = useTranslation();
  return (
    <SettingsLayout>
      <div className="px-4 py-5 mb-5 border-b sm:p-6 space-y-4">
        <div>
          <h3 className="amenda-form-heading">
            {t("Your active Subscription")}
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            {t("Upgrade your Subscription to gain more functionality")}
          </p>
        </div>
        <div className="space-y-2  border-b-2 border-r-2 border-l-2 border-gray-200 lg:rounded-lg">
          <div className="flex items-center shrink-0 px-4">
            <img
              className="h-8 w-auto mt-2"
              src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
              alt="amenda Projects"
            />
          </div>
          <div className="px-4 grid grid-flow-row">
            <div>
              <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-3 truncate">
                {t("Starter Plan")} | {t("Change")}
              </p>
            </div>
            <div className="flex items-center sm:flex sm:justify-between pb-2">
              <div className="sm:flex">
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <UsersIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="mr-3">28/{t("unlimited Users")}</p>
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  <Squares2X2Icon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>13/25 {t("Projects")}</p>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  <CurrencyEuroIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>280,000 €</p>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  <CalendarIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>
                    <time dateTime="2021-04-01">1 April 2021</time>
                  </p>
                </p>
                <p className="mt-2 flex text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  <CreditCardIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="place-self-end">
                    <Link
                      to="/einstellungen"
                      className="mt-1 text-sm text-blue-600"
                    >
                      {t("Manage Payment Method")}
                    </Link>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("Transaction History")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-4">
            {t("Review all Transactions in the last")} 12 {t("months")}
          </p>
          <div className="px-4 py-4 sm:px-6  border-b-2 border-r-2 border-l-2 border-gray-200 lg:rounded-lg">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-indigo-600 truncate">
                #INV-43234234224
              </p>
              <div className="ml-2 shrink-0 flex">
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {t("Processed")}
                </p>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>
                    <time dateTime={"2021-08-07"}>7 August 2021</time>
                  </p>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  <CurrencyEuroIcon
                    className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>230,000 €</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 flex justify-end">
        <Button type="submit" variant="primary" size="md">
          {t("Save")}
        </Button>
      </div>
    </SettingsLayout>
  );
};
