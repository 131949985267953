import { gql } from "urql";

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id: _id
    contactDetails
    displayName
    docsCount
    email
    firstName
    fullName
    isDeleted
    lastName
    photoURL
    type
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id: _id
    uid
    email
    fullName
    firstName
    lastName
    isActive
    isDeleted
    emailVerified
    userDetails
    photoURL
    isTenantAdmin
    ownerId
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const USER_SUBSCRIPTION_FRAGMENT = gql`
  fragment UserSubsFragment on UserSubscription {
    id: _id
    uid
    email
    fullName
    firstName
    lastName
    isActive
    isDeleted
    emailVerified
    userDetails
    photoURL
    ownerId
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const CONTACT_SUBSCRIPTION_FRAGMENT = gql`
  fragment ContactSubsFragment on ContactSubscription {
    id: _id
    email
    fullName
    firstName
    lastName
    isDeleted
    contactDetails
    photoURL
    type
    share {
      type
      users {
        id
        role
      }
    }
  }
`;
