import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { CostGroupColumns } from "./common";
import { CostGroupInputField } from "./CostGroupInputField";
import { FC } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { getCostGroupId } from "@amenda-constants";
import { isEmpty } from "lodash";
import { useController } from "react-hook-form";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

type CostParemeterBodyComponentsProps = ComponentsTreeViewRowProps & {
  id: string;
  value: any;
  error?: string;
  endAddOnText?: string;
  tooltipMessage?: string;
  onChange: (value: number) => void;
  onBlur?: () => void;
  onClear?: () => void;
};

type CostParameterBodyCostComponentsWrapperProps =
  ComponentsTreeViewRowProps & {
    id: string;
  };

const CostParemeterBodyComponents: FC<CostParemeterBodyComponentsProps> = ({
  column,
  readOnly,
  onChange,
  onClear,
  ...rest
}) => {
  const { t } = useTranslation();

  if (column.id === CostGroupColumns.RowClear) {
    return (
      <div className="flex justify-end items-center h-full px-2 invisible group-hover/treeRow:visible">
        {!readOnly && (
          <button
            type="button"
            className="flex items-center justify-center text-gray-800 group/clearBtn hover:text-gray-900 rounded-full h-8 w-8"
            onClick={onClear}
          >
            <XCircleIcon
              className="h-5 w-5 group-hover/clearBtn:h-7 group-hover/clearBtn:w-7"
              aria-hidden="true"
            />
            <span className="sr-only">{t("Clear values for this row")}</span>
          </button>
        )}
      </div>
    );
  }
  return (
    <CostGroupInputField readOnly={readOnly} onChange={onChange} {...rest} />
  );
};

const CostParameterBodyCostComponentsWrapper: FC<
  CostParameterBodyCostComponentsWrapperProps
> = ({ id, component, control, ...rest }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });
  const updateCostGroupCounterCosts = useProjectStore(
    (state) => state.updateCostGroupCounterCosts
  );

  const onChangeHandler = (value: number) => {
    onChange(value);
    updateCostGroupCounterCosts(component, value);
  };

  return (
    <CostParemeterBodyComponents
      id={id}
      value={value}
      error={error?.message}
      component={component}
      endAddOnText="€"
      onChange={onChangeHandler}
      onClear={() => onChangeHandler(NaN)}
      {...rest}
    />
  );
};

export const CostParameterBodyCostComponents: FC<
  ComponentsTreeViewRowProps
> = ({ component, ...rest }) => {
  const id = getCostGroupId("cost", component);

  return (
    <CostParameterBodyCostComponentsWrapper
      id={id}
      key={id}
      component={component}
      {...rest}
    />
  );
};

const CostParameterBodyQuantityComponentsWrapper: FC<
  CostParameterBodyCostComponentsWrapperProps
> = ({ id, component, control, ...rest }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });
  const updateCostGroupCounterQuantity = useProjectStore(
    (state) => state.updateCostGroupCounterQuantity
  );
  const quantityByCosts = useProjectStore(
    (state) => state.costGroupCounter.quantityByCosts
  );

  const message =
    !isEmpty(quantityByCosts[component?.id]) && !Number.isFinite(value)
      ? "This value is being referenced in costs, enter quantity to enable calculations"
      : undefined;

  const onChangeHandler = (value: number) => {
    onChange(value);
  };

  const onClear = () => {
    onChange(NaN);
    updateCostGroupCounterQuantity(component, NaN);
  };

  const onBlur = () => {
    updateCostGroupCounterQuantity(component, value);
  };

  return (
    <CostParemeterBodyComponents
      id={id}
      value={value}
      error={error?.message}
      component={component}
      onChange={onChangeHandler}
      endAddOnText={component?.properties?.endAddOnText}
      tooltipMessage={message}
      onClear={onClear}
      onBlur={onBlur}
      {...rest}
    />
  );
};

export const CostParameterBodyQuantityComponents: FC<
  ComponentsTreeViewRowProps
> = ({ component, ...rest }) => {
  const id = getCostGroupId("quantities", component);

  return (
    <CostParameterBodyQuantityComponentsWrapper
      id={id}
      key={id}
      component={component}
      {...rest}
    />
  );
};
