import { AllowedContactType, Option } from "@amenda-types";
import { CheckIcon, ChevronRightIcon } from "lucide-react";
import {
  ErrorMessage,
  ReactSelectCreatable,
  createOption,
} from "@amenda-components/FormComponents";
import { FC, useEffect, useRef, useState } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";
import { getContactRequestArgs, getUserName } from "./common";
import {
  getErrorFromFieldError,
  sharePermissionsSecondaryOptions,
} from "@amenda-components/Shared/common";
import { useFieldArray, useFormState } from "react-hook-form";
import {
  useGetKeywords,
  useSearchContacts,
  useSearchUsers,
} from "@amenda-domains/queries";

import AsyncSelect from "react-select/async";
import { ContactCardWrapper } from "./ContactCardWrapper";
import { ContactPermissionBaseCard } from "@amenda-components/Shared";
import { DebounceTimes } from "@amenda-constants";
import { IconButton } from "@amenda-components/App";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { ProjectContactCard } from "./ProjectContactCard";
import { components } from "react-select";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useUpsertKeywords } from "@amenda-domains/mutations";

interface Props {
  canCreateResourceSpecificKeywords?: boolean;
  control: any;
  contactType: AllowedContactType;
  id: string;
  disabled?: boolean;
  keywordResourceLabel?: string;
  readOnly?: boolean;
  resourceId?: string;
  roles?: Option[];
  values?: any[];
}

type ReadOnlyInputProps = Pick<
  Props,
  "id" | "roles" | "values" | "contactType"
>;

interface LabelledSearchInputProps {
  contactType: AllowedContactType;
  prepend: (value: any) => void;
  roles?: Option[];
}

interface KeywordsInputProps {
  canCreateResourceSpecificKeywords?: boolean;
  componentId: string;
  keywordResourceLabel: string;
  resourceId?: string;
  keywords: any[];
  isLoading: boolean;
  handleCreate: (input: Record<string, any>) => void;
}

interface ContactCardProps {
  selectedRoles?: string[];
  contactId?: string;
  roles?: Option[];
  contactType: AllowedContactType;
  remove: () => void;
  update: (role: string[]) => void;
}

const DropdownIndicator = () => {
  return null;
};

const ClearIndicator = () => {
  return null;
};

const IndicatorSeparator = () => {
  return null;
};

const ReactCreatableDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronRightIcon className="h-4 w-4" />
    </components.DropdownIndicator>
  );
};

const createableOptionClassName =
  "w-full py-2 px-3 text-gray-800 bg-white hover:text-white hover:bg-gray-900";

const KeywordsInput: FC<KeywordsInputProps> = ({
  canCreateResourceSpecificKeywords,
  componentId,
  keywordResourceLabel,
  resourceId,
  keywords,
  isLoading,
  handleCreate,
}) => {
  const { t } = useTranslation();

  const groupedKeywords = groupBy(keywords, (keyword: any) => {
    return Boolean(keyword?.resourceId) ? keywordResourceLabel : "General";
  });
  const groupedOptions = Object.keys(groupedKeywords).reduce<any[]>(
    (acc, key) => {
      const options = groupedKeywords[key].map((keyword) =>
        createOption(keyword.name, keyword.id),
      );
      return [
        ...acc,
        {
          label: key,
          options,
        },
      ];
    },
    [],
  );

  const onCreate = async (inputValue: string, isResourceSpecific = false) => {
    let input: any = {
      name: inputValue,
      componentId,
    };

    if (isResourceSpecific) {
      input = {
        ...input,
        resourceId,
      };
    }
    await handleCreate(input);
  };

  return (
    <div className="max-w-64">
      <ReactSelectCreatable
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        classNames={{
          control: (state: any) => {
            const { isFocused } = state;
            if (isFocused) {
              return "!border-0 !border-b max-w-96";
            }
            return "!border-0 !w-fit";
          },
          option: (state: any) => {
            const { data } = state;

            return data?.__isNew__ ? "!bg-white !p-0" : "";
          },
          valueContainer: () => {
            return "!p-0";
          },
        }}
        components={{
          ClearIndicator,
          DropdownIndicator: ReactCreatableDropdownIndicator,
          IndicatorSeparator,
        }}
        hideErrorMessage={true}
        isMulti={true}
        isClearable={false}
        placeholder="Add label"
        isLoading={isLoading}
        disabled={isLoading}
        formatCreateLabel={(inputValue) => (
          <div className="flex flex-col">
            <span
              className={createableOptionClassName}
              onClick={() => onCreate(inputValue)}
            >
              {t("Create new keyword")}: <strong>{inputValue}</strong>
            </span>
            {canCreateResourceSpecificKeywords && resourceId && (
              <span
                className={createableOptionClassName}
                onClick={() => onCreate(inputValue, true)}
              >
                {t("Create new keyword")}: <strong>{inputValue}</strong> fur{" "}
                {keywordResourceLabel}
              </span>
            )}
          </div>
        )}
        formatGroupLabel={(group) => {
          return <span>{t(group.label)}</span>;
        }}
        options={groupedOptions}
        onCreate={() => {}}
        onChange={() => {}}
        value={keywords.map((k) => createOption(k.name, k.id))}
      />
    </div>
  );
};

const LabelledSearchInput: FC<LabelledSearchInputProps> = ({
  contactType,
  prepend,
  roles,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const { searchUsers, loading: loadingSearchUsers } = useSearchUsers();
  const { searchContacts, loading: loadingSearchContacts } =
    useSearchContacts();
  const [selectedRoles, setSelectedRoles] = useState<string[]>();

  const isSearching = loadingSearchContacts || loadingSearchUsers;

  const debouncedSearch = useRef(
    debounce(
      async (
        args: { searchTerm: string } & any,
        resolve: (users: any[]) => void,
      ) => {
        const cb =
          args.type === AllowedContactType.office
            ? searchUsers
            : searchContacts;

        await cb({
          ...args,
          callback: resolve,
        });
      },
      DebounceTimes.Search,
    ),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        const args = getContactRequestArgs({
          contactType,
          autoSelect: false,
          isCollection: false,
          searchTerm: inputValue,
        });

        await debouncedSearch(args, resolve);
      }
    });

  const handleSelectContacts = (value: any[] | null) => {
    setContacts(value ?? []);
  };

  const handleRoles = ({ value }: Option) => {
    setSelectedRoles((prev) => {
      const values = prev?.includes(value)
        ? prev?.filter((v) => v !== value)
        : [...(prev ?? []), value];

      return values;
    });
  };

  const handleSave = () => {
    contacts?.forEach((contact) => {
      prepend({
        contactId: contact.id,
        roles: selectedRoles,
      });
    });
    setContacts([]);
    setSelectedRoles([]);
  };

  return (
    <div className="col-span-2 bg-white border border-gray-300 px-3 py-2 hover:border-gray-500">
      <div className="w-full grid grid-cols-4 gap-x-2 items-center pb-2">
        <div className="w-full col-span-3" ref={ref}>
          <AsyncSelect
            cacheOptions
            contactType={contactType}
            classNames={{
              control: () => {
                return "!border-0 !border-b";
              },
            }}
            isMulti={true}
            value={contacts}
            onChange={handleSelectContacts}
            menuPlacement="auto"
            isClearable={true}
            placeholder={t("Search contacts")}
            className={customStyling.select.containerClass}
            isLoading={isSearching}
            loadingMessage={() => t("Loading") + "..."}
            loadOptions={loadOptions}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? t("No results found") : t("Start typing")
            }
            getOptionValue={(option: any) => option.id}
            getOptionLabel={getUserName}
            components={{
              ClearIndicator,
              DropdownIndicator,
              IndicatorSeparator,
            }}
            styles={{
              ...customStyling.select.styleOverride,
              ...selectMenuOverflow(true, ref),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            {...selectMenuOverflowProps(true)}
          />
        </div>
        <div className="w-full flex flex-col items-end">
          {!isEmpty(roles) && (
            <OperationsMenu
              label="Select role"
              menuItemsClassName="w-58 origin-top-right right-0"
              options={roles ?? []}
              selectedOption={selectedRoles}
              indicatorIconClassName="h-3 w-3"
              labelClassName="text-xs font-medium text-gray-500"
              onChange={handleRoles}
            />
          )}
          {!isEmpty(contacts) && (
            <IconButton
              label="Add item"
              Icon={CheckIcon}
              className="text-green-500"
              onClick={handleSave}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ContactCard: FC<ContactCardProps> = ({
  selectedRoles,
  remove,
  roles,
  update,
  contactId,
  contactType,
}) => {
  if (!contactId) {
    return null;
  }
  return (
    <ContactCardWrapper userId={contactId} contactType={contactType}>
      {(contact: any) => (
        <ContactPermissionBaseCard inlineChildren={true} contact={contact}>
          <OperationsMenu
            hasMenuOverflow={true}
            menuItemsClassName="z-[80]"
            indicatorIconClassName="h-3 w-3"
            labelClassName="text-xs font-medium text-gray-500"
            options={roles ?? []}
            secondaryOptions={sharePermissionsSecondaryOptions}
            selectedOption={selectedRoles}
            onChange={({ value }: any) => {
              if (value === sharePermissionsSecondaryOptions[0].value) {
                remove();
              } else {
                const roles = selectedRoles?.includes(value)
                  ? selectedRoles.filter((r) => r !== value)
                  : [...(selectedRoles ?? []), value];

                update(roles);
              }
            }}
          />
        </ContactPermissionBaseCard>
      )}
    </ContactCardWrapper>
  );
};

const LabelledInput: FC<Props> = ({
  control,
  contactType,
  canCreateResourceSpecificKeywords,
  id,
  keywordResourceLabel = "Project",
  resourceId,
  roles,
}) => {
  const { fields, prepend, remove, update } = useFieldArray({
    control,
    name: id,
    keyName: "arrId",
  });
  const { errors } = useFormState({
    control,
    name: id,
    exact: true,
  });
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();
  const { upsertKeywords, loading: isUpsertingKeywords } = useUpsertKeywords();

  const fieldError = errors[id];
  const error = Array.isArray(fieldError)
    ? getErrorFromFieldError(fieldError)
    : (fieldError?.message as string);
  const groupedFields = groupBy(fields, "keywordId");
  const getIndex = (fieldId: string) => {
    return fields.findIndex((field) => field.arrId === fieldId);
  };

  const handleCreate = async (values: any) => {
    await upsertKeywords({
      ...values,
      callback: (keyword) => setKeywords((prev) => [...prev, keyword]),
    });
  };

  useEffect(() => {
    if (id) {
      getKeywords({
        componentIds: [id],
        setKeywords,
      });
    }
  }, [getKeywords, id]);

  return (
    <div className="w-full">
      <KeywordsInput
        canCreateResourceSpecificKeywords={canCreateResourceSpecificKeywords}
        componentId={id}
        keywordResourceLabel={keywordResourceLabel}
        resourceId={resourceId}
        isLoading={loading || isUpsertingKeywords}
        keywords={keywords}
        handleCreate={handleCreate}
      />
      {keywords.map((keyword) => {
        const fields = groupedFields[keyword?.id] ?? [];

        return (
          <div className="w-full py-2" key={keyword?.id}>
            <div className="capitalize pb-2 font-apercu text-sm text-gray-800">
              {keyword?.name}
            </div>
            <div className="grid gap-1 md:grid-cols-2 2xl:grid-cols-4 pb-4">
              {fields.map(({ arrId, ...rest }: any) => (
                <ContactCard
                  key={arrId}
                  selectedRoles={rest.roles}
                  contactId={rest.contactId}
                  contactType={contactType}
                  roles={roles}
                  remove={() => remove(getIndex(arrId))}
                  update={(roles) =>
                    update(getIndex(arrId), {
                      ...rest,
                      roles,
                    })
                  }
                />
              ))}
            </div>
            <div className="grid gap-1 md:grid-cols-2 2xl:grid-cols-4">
              <LabelledSearchInput
                contactType={contactType}
                prepend={(value) =>
                  prepend({
                    ...value,
                    keywordId: keyword?.id,
                  })
                }
                roles={roles}
              />
            </div>
          </div>
        );
      })}
      <ErrorMessage id={id} error={error} className="text-xs" />
    </div>
  );
};

const ReadOnlyInput: FC<ReadOnlyInputProps> = ({
  id,
  roles,
  values,
  contactType,
}) => {
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  const getContactsByKeyword = (keywordId: string) => {
    return (values ?? []).filter((value) => value.keywordId === keywordId);
  };

  const getRoleLabels = (selectedRoles?: string[]) => {
    if (roles) {
      return roles
        .filter((role) => selectedRoles?.includes(role.value))
        .map((role) => role.label);
    }
  };

  useEffect(() => {
    if (id) {
      getKeywords({
        componentIds: [id],
        setKeywords,
      });
    }
  }, [getKeywords, id]);

  if (loading) {
    return null;
  }
  return (
    <div className="w-full space-y-2 font-apercu">
      {keywords.map((keyword) => (
        <div className="w-full pb-4" key={keyword?.id}>
          {!isEmpty(getContactsByKeyword(keyword?.id)) && (
            <div className="w-full amenda-component-label capitalize pb-4">
              {keyword?.name}
            </div>
          )}
          <div className="grid gap-1 md:grid-cols-2 2xl:grid-cols-4">
            {getContactsByKeyword(keyword?.id).map((value) => (
              <ContactCardWrapper
                key={value.contactId}
                userId={value.contactId}
                contactType={contactType}
              >
                {(contact: any) => (
                  <ProjectContactCard
                    contact={contact}
                    roles={getRoleLabels(value.roles)}
                  />
                )}
              </ContactCardWrapper>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const LabelledContactsField: FC<Props> = ({
  control,
  readOnly = false,
  ...props
}) => {
  if (!readOnly && control) {
    return <LabelledInput control={control} {...props} />;
  }
  return <ReadOnlyInput {...props} />;
};
