import {
  ArrowRightIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { FC } from "react";
import { Image } from "@amenda-components/App";
import { RoundedVariants } from "@amenda-types";

interface Props {
  cardRemove?: boolean;
  disabled?: boolean;
  image: string;
  projectName: string;
  projectDescription: string;
  enableHighlighting?: boolean;
  onClick?: () => void;
}

export const MiniCard: FC<Props> = ({
  cardRemove,
  disabled,
  image,
  projectName,
  projectDescription,
  enableHighlighting = false,
  onClick,
}) => {
  const Icon = cardRemove ? XMarkIcon : ArrowRightIcon;
  return (
    <div className="flex border border-gray-300 hover:bg-gray-50">
      <div className="w-24">
        <Image
          url={image}
          size="pb-5/6"
          magicSize="default"
          rounded={RoundedVariants.none}
          enableHighlighting={enableHighlighting}
        />
      </div>
      <div className="flex-1 overflow-hidden flex items-center justify-between">
        <div className="flex-1 px-4 py-2 text-sm truncate">
          <p className="text-gray-900 font-medium hover:text-gray-600 truncate font-apercu">
            {projectName}
          </p>
          <p className="text-gray-500">{projectDescription}</p>
        </div>
        {onClick && (
          <div className="shrink-0 pr-2">
            {!disabled ? (
              <button
                type="button"
                className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none ring-2 ring-offset-2 ring-indigo-500"
                disabled={disabled}
                onClick={onClick}
              >
                <span className="sr-only">
                  {cardRemove ? "Remove from list" : "Add to list"}
                </span>
                <Icon className="w-5 h-5" aria-hidden="true" />
              </button>
            ) : (
              <CheckIcon className="w-5 h-5 text-indigo-500" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
