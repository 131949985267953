import { AllowedCollectionType, AllowedContactType } from "@amenda-types";
import { Controller, UseFormReset, useForm } from "react-hook-form";
import { FC, ReactNode, useEffect, useState } from "react";
import { FormComponentTypes, collectionSchema } from "@amenda-constants";
import { FullColumn, GridWrapper } from "@amenda-components/PageLayouts";
import { TextArea, TextField } from "@amenda-components/FormComponents";

import { CollectionContacts } from "./CollectionContacts";
import { CollectionRoles } from "./CollectionRoles";
import { CollectionTags } from "./CollectionTags";
import { CollectionUserTags } from "./CollectionUserTags";
import { Modal } from "@amenda-components/App";
import { getComponentsFromForms } from "@amenda-utils";
import { getFormsByCollectionType } from "./common";
import { useGetUsersById } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  title: string;
  values?: any;
  isOpen: boolean;
  isLoading?: boolean;
  collectionType: AllowedCollectionType;
  handleClose: () => void;
  handleSubmit: (data: any, reset: UseFormReset<any>) => void;
  footerChildren: (formId: string, loading?: boolean) => ReactNode;
}

const formId = "upsertCollectionForm";

export const CollectionsModalWrapper: FC<Props> = ({
  title,
  isOpen,
  collectionType,
  values = {},
  isLoading = false,
  handleClose,
  footerChildren,
  handleSubmit: submitFn,
}) => {
  const [contacts, setContacts] = useState<any[]>([]);
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    values: {
      ...values,
      shareWithContacts: contacts,
    },
    resolver: yupResolver(collectionSchema),
  });
  const forms = useProjectStore((state) => state.forms);
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType
  );
  const { getUsersById } = useGetUsersById();

  const officeForms = formsByContactType[AllowedContactType.office];
  const officeComponents = getComponentsFromForms(officeForms);
  const collectionForms = getFormsByCollectionType(collectionType, forms);
  const collectionComponents = getComponentsFromForms(collectionForms);
  const collectionComponentIds = collectionComponents
    .filter((c) => c.component === FormComponentTypes.Keyword)
    .map((c) => c.id);
  const officeComponentIds = officeComponents
    .filter((c) => c.component === FormComponentTypes.Keyword)
    .map((c) => c.id);

  const handleResetOnClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (isOpen && values?.shareWithContacts?.length > 0) {
      getUsersById(values.shareWithContacts, setContacts);
    }
  }, [values?.shareWithContacts, isOpen, getUsersById]);

  const onSubmit = (data: any) => {
    submitFn(data, reset);
  };

  return (
    <Modal
      title={title}
      size="md"
      successLabel="Submit"
      className="lg:w-4/12 md:w-1/2 w-11/12"
      isOpen={isOpen}
      loading={isLoading}
      closeModalFromTitle={true}
      onClose={handleResetOnClose}
      footerChildren={({ loading }) => {
        return footerChildren(formId, loading);
      }}
    >
      <form
        id={formId}
        className="overflow-y-auto max-h-[70vh] px-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <GridWrapper className="gap-y-2">
          <FullColumn>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="name"
                    label="Collection name"
                    maxLength={25}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </FullColumn>
          <FullColumn>
            <Controller
              name="tags"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <CollectionTags
                    id="tags"
                    values={value}
                    error={error?.message}
                    collectionType={collectionType}
                    componentIds={collectionComponentIds}
                    onChange={onChange}
                  />
                );
              }}
            />
          </FullColumn>
          <FullColumn>
            <Controller
              name="description"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    id="description"
                    label="Description"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </FullColumn>
          <FullColumn>
            <CollectionUserTags
              id="shareWithUserTags"
              control={control}
              componentIds={officeComponentIds}
            />
          </FullColumn>
          <FullColumn>
            <CollectionContacts
              id="shareWithContacts"
              control={control}
              setValue={setValue}
            />
          </FullColumn>
          <FullColumn>
            <CollectionRoles id="shareWithRoles" control={control} />
          </FullColumn>
        </GridWrapper>
      </form>
    </Modal>
  );
};
