import {
  BuildingOfficeIcon,
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SingleCheckbox, TextField } from "@amenda-components/FormComponents";

import { FC } from "react";
import { RegistrationSchema } from "@amenda-constants";
import { Spinner } from "@amenda-components/App";
import { useCreateTenant } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface RegistrationInput {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
}

const showFormNeedsConsent = (errorKeys: string[]) => {
  return errorKeys.length === 1 && errorKeys[0] === "consent";
};

export const RegistrationForm: FC = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RegistrationInput>({
    resolver: yupResolver(RegistrationSchema),
  });
  const { createTenant, loading } = useCreateTenant();

  const onSubmit: SubmitHandler<RegistrationInput> = async ({
    displayName,
    consent,
    confirmPassword,
    ...rest
  }) => {
    await createTenant({
      input: {
        tenant: {
          displayName,
          emailSignInConfig: {
            enabled: true,
            passwordRequired: true,
          },
        },
        admin: {
          ...rest,
        },
      },
    });
  };

  return (
    <>
      <div className="font-apercu mb-8">
        Wir freuen uns, dass Sie sich für amenda registrieren. amenda ist eine
        büroweite Projekt- und Medien-Datenbank! Die von Ihnen angegebenen Daten
        sind streng vertraulich und für Dritte nicht einsehbar.
      </div>
      <form
        className="space-y-1 grid grid-flow-row"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            name="displayName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="displayName"
                  placeholder="Organization Name"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  StartIcon={BuildingOfficeIcon}
                />
              );
            }}
          />
        </div>
        <div className="grid grid-flow-col gap-2">
          <div>
            <Controller
              name="firstName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="firstName"
                    placeholder="First Name"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                    StartIcon={UserIcon}
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              name="lastName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="lastName"
                    placeholder="Last Name"
                    error={error?.message}
                    autoComplete="family-name"
                    value={value}
                    onChange={onChange}
                    StartIcon={UserIcon}
                  />
                );
              }}
            />
          </div>
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  placeholder="E-Mail"
                  error={error?.message}
                  value={value}
                  type="email"
                  onChange={onChange}
                  StartIcon={EnvelopeIcon}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  error={error?.message}
                  value={value}
                  type="password"
                  onChange={onChange}
                  StartIcon={LockClosedIcon}
                />
              );
            }}
          />
        </div>
        <div>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="confirm-password"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  error={error?.message}
                  value={value}
                  type="password"
                  onChange={onChange}
                  StartIcon={LockClosedIcon}
                />
              );
            }}
          />
        </div>
        <div className="py-3">
          <Controller
            name="consent"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SingleCheckbox
                  id="consent"
                  label={
                    <span>
                      Ich stimme den{" "}
                      <a
                        href="https://www.amenda.app/nutzungsbedingungen"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>Nutzungsbedindungen</u>
                      </a>{" "}
                      zu.
                    </span>
                  }
                  checked={value}
                  required={showFormNeedsConsent(Object.keys(errors))}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
        <div className="pt-3">
          <button type="submit" className="amenda-button">
            <div className="flex align-middle">
              {loading && <Spinner variant="primary" spinnerSize="xs" />}
              {t("Start Now")}
            </div>
          </button>
        </div>
      </form>
    </>
  );
};
