import {
  AddressSearch,
  CheckBox,
  DatePickerRange,
  InputRangePicker,
  TextField,
} from "@amenda-components/FormComponents";
import { FC, useEffect, useState } from "react";
import { IconButton, Skeleton } from "@amenda-components/App";
import { getCheckboxCount, getKeywordsCount } from "@amenda-utils";

import { ComponentValidationType } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import Fuse from "fuse.js";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { RegionalSelect } from "./RegionalSelect";
import { SearchAndSelectProjects } from "@amenda-components/Projects";
import { SidebarSearchContactByType } from "@amenda-components/Contacts";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useGetKeywords } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  component: any;
  values?: any;
  formValues?: any[];
  onChange: (values: any) => void;
}

export const KeywordCheckboxWrapper: FC<Props> = ({
  component,
  values,
  formValues = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const keywords = useAppStore(
    (state) => state.sidebarKeywords[component?.id] ?? [],
  );
  const setSidebarKeywords = useAppStore((state) => state.setSidebarKeywords);
  const [availableKeywords, setAvailableKeywords] = useState<any[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { getKeywords, loading } = useGetKeywords();

  const handleClick = () => {
    setSearchTerm("");
    setShowSearch(false);
    setAvailableKeywords(keywords);
  };

  const handleSearch = (searchTerm: string) => {
    let availableKeywords = [...keywords];
    if (searchTerm) {
      const fuse = new Fuse(keywords, {
        includeScore: true,
        keys: ["name"],
      });
      const results = fuse.search(searchTerm);
      availableKeywords = results.map((res) => res.item);
    }

    setSearchTerm(searchTerm);
    setAvailableKeywords(availableKeywords);
  };

  useEffect(() => {
    const getkeywordsAsync = async () => {
      if (component?.id) {
        const keywords = await getKeywords({
          componentIds: [component.id],
          setKeywords: (keywords) => setSidebarKeywords(component.id, keywords),
        });
        setAvailableKeywords(keywords);
      }
    };

    getkeywordsAsync();
  }, [component?.id, getKeywords, setSidebarKeywords]);

  useEffect(() => {
    return () => {
      setSearchTerm("");
      setAvailableKeywords([]);
    };
  }, []);

  if (loading) {
    return (
      <div className="mb-3 mt-1">
        <Skeleton height={5} />
      </div>
    );
  } else if (isEmpty(availableKeywords) && !searchTerm) {
    return (
      <div className="text-sm font-apercu mt-1 pb-2 text-gray-400">
        {t("No keywords found")}
      </div>
    );
  }
  return (
    <div className="grid">
      <div className="flex items-center">
        {showSearch ? (
          <div className="pl-0 w-full pb-3">
            <MiniSearchField
              className="h-8 border boder-gray-900"
              placeholder="Liste durchsuchen ..."
              value={searchTerm}
              onChange={handleSearch}
              onClear={handleClick}
            />
          </div>
        ) : (
          <div className="flex items-center h-12">
            <IconButton
              className="ml-[7px]"
              iconSize={1}
              Icon={MagnifyingGlassIcon}
              label="Open Search"
              onClick={() => setShowSearch(true)}
            />
          </div>
        )}
      </div>
      <CheckBox
        className="mb-3"
        id={component?.id}
        options={availableKeywords.map((keyword) => ({
          label: keyword.name,
          value: keyword?.id,
          count: getKeywordsCount({
            componentId: component?.id,
            formValues,
            keywordId: keyword?.id,
          }),
        }))}
        onChange={onChange}
        selectedOptions={values || []}
      />
    </div>
  );
};

const FilterCheckBox: FC<Props> = ({
  component,
  values,
  formValues = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>();
  const [availableOptions, setAvailableOptions] = useState<any[]>([]);

  const handleClick = () => {
    const options = component?.properties?.options ?? [];

    setSearchTerm("");
    setShowSearch(false);
    setAvailableOptions(options);
  };

  const handleSearch = (searchTerm: string) => {
    const options = component?.properties?.options || [];
    let availableOptions: any[] = [...options];
    if (
      [FormComponentTypes.MultiSelect, FormComponentTypes.Select].includes(
        component.component,
      ) &&
      searchTerm
    ) {
      const fuse = new Fuse(options, {
        includeScore: true,
        keys: ["label", "value"],
      });
      const results = fuse.search(searchTerm);
      availableOptions = results.map((res) => res.item);
    }

    setSearchTerm(searchTerm);
    setAvailableOptions(availableOptions);
  };

  useEffect(() => {
    if (component?.properties?.options) {
      setAvailableOptions(component?.properties?.options);
    }

    return () => {
      setSearchTerm("");
      setAvailableOptions([]);
    };
  }, [component?.properties?.options]);

  return (
    <div className="grid">
      {isEmpty(availableOptions) ? (
        <>
          <div className="text-sm font-apercu mt-1 pb-2 text-gray-400">
            {t("No filters found")}
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center">
            {showSearch ? (
              <div className="pl-0 w-full pb-3">
                <MiniSearchField
                  className="h-8 border boder-gray-900"
                  placeholder="Liste durchsuchen ..."
                  value={searchTerm}
                  onChange={handleSearch}
                  onClear={handleClick}
                />
              </div>
            ) : (
              <div className="flex items-center h-12">
                <IconButton
                  className="ml-[7px]"
                  iconSize={1}
                  Icon={MagnifyingGlassIcon}
                  label="Open Search"
                  onClick={() => setShowSearch(true)}
                />
              </div>
            )}
          </div>
          <CheckBox
            className="mb-3"
            id={component.id}
            options={availableOptions.map((availableOption: any) => ({
              ...availableOption,
              count: getCheckboxCount({
                componentId: component.id,
                formValues,
                value: availableOption.value,
              }),
            }))}
            selectedOptions={values || []}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

export const ComponentsCheckboxWrapper: FC<Props> = (props) => {
  const { component, values, onChange } = props;

  if (
    component.validationType === ComponentValidationType.Number ||
    (component?.component === FormComponentTypes.Hidden &&
      (component.properties?.startAddonText ||
        component.properties?.endAddOnText))
  ) {
    const addOnText =
      component.properties?.endAddOnText ||
      component.properties?.startAddonText;

    return (
      <InputRangePicker
        id={component.id}
        fromAddonText={component.properties?.startAddOnText || addOnText}
        fromPlaceholder="from"
        value={values || {}}
        onChange={onChange}
        type="number"
        toAddonText={component.properties?.endAddOnText || addOnText}
        toPlaceholder="to"
      />
    );
  }
  switch (component?.component) {
    case FormComponentTypes.DatePickerRange:
      return (
        <DatePickerRange
          id={component.id}
          value={values || {}}
          onChange={onChange}
          size="sm"
          withPortal={true}
        />
      );
    case FormComponentTypes.AddressSearch:
      return (
        <div className="pb-3">
          <AddressSearch
            id={component.id}
            value={values}
            StartIcon={MapPinIcon}
            onChange={onChange}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    case FormComponentTypes.Input:
      return (
        <div className="pb-2">
          <TextField
            id="fullTextSearch"
            value={values || ""}
            onChange={onChange}
          />
        </div>
      );
    case FormComponentTypes.RadioButton:
    case FormComponentTypes.Hidden:
      return (
        <CheckBox
          id={component?.id}
          options={component?.properties?.options}
          onChange={onChange}
          selectedOptions={values || []}
        />
      );
    case FormComponentTypes.SearchAndSelectProjects:
      return (
        <div className="pb-3">
          <SearchAndSelectProjects
            id={component.id}
            projectIds={values}
            onChange={onChange}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    case FormComponentTypes.SearchAndSelect:
    case FormComponentTypes.LabelledContactInputs:
      if (!component?.properties?.contactType) {
        return null;
      }
      return (
        <div className="pb-3">
          <SidebarSearchContactByType
            id={component.id}
            contactId={values}
            contactType={component.properties.contactType}
            onChange={onChange}
            shouldReturnId={true}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    case FormComponentTypes.RegionalSelect:
      return (
        <div className="pb-3">
          <RegionalSelect
            id={component.id}
            regionId={values}
            onChange={onChange}
            shouldReturnId={true}
            hideErrorMessage={true}
            hasMenuOverflow={true}
          />
        </div>
      );
    default:
      return <FilterCheckBox {...props} />;
  }
};
