import { AllowedCollectionType, Option } from "@amenda-types";
import { FC, useEffect, useState } from "react";
import { MiniSwitch, ReactSelect } from "@amenda-components/FormComponents";
import { isEmpty, isString } from "lodash";

import { useGetKeywords } from "@amenda-domains/queries";

interface Props {
  id: string;
  error?: string;
  placeholder?: string;
  values: Option[];
  disabled?: boolean;
  hasMenuOverflow?: boolean;
  collectionType: AllowedCollectionType;
  componentIds?: string[];
  onChange: (values: Option[]) => void;
}

export const CollectionTags: FC<Props> = ({
  values,
  collectionType,
  componentIds = [],
  onChange,
  ...rest
}) => {
  const [autoCreate, setAutoCreate] = useState(true);
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  const options = keywords.map((k) => ({
    value: k.id,
    label: k.name,
    componentId: k.componentId,
  }));
  const selectedValues = values?.map((value) => {
    if (isString(value)) {
      return options.find((o) => o.value === value);
    }
    return value;
  }) as Option[];

  const handleChange = (option: Option[]) => {
    onChange(option);
  };

  useEffect(() => {
    if (!isEmpty(componentIds)) {
      getKeywords({
        componentIds,
        setKeywords,
      });
    }
  }, [getKeywords, componentIds]);

  if (isEmpty(componentIds)) {
    return null;
  }
  return (
    <ReactSelect
      isMulti
      placeholder="Add a tag"
      label="Auto create from tags"
      optional={
        <MiniSwitch
          label="Auto create tags"
          value={autoCreate}
          onChange={setAutoCreate}
        />
      }
      isLoading={loading}
      options={options}
      hasMenuOverflow={true}
      value={selectedValues}
      onChange={handleChange}
      {...rest}
    />
  );
};
