import "keen-slider/keen-slider.min.css";

import { AvailableImageVariants, RoundedVariants } from "@amenda-types";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { FC, MouseEvent, useState } from "react";
import { IconButtonBase, Image } from "@amenda-components/App";

import clsx from "clsx";
import { useKeenSlider } from "keen-slider/react";

interface Props {
  vertical?: boolean;
  files: any[];
  thumbnailClassName?: string;
  lazyLoad?: boolean;
  perView?: number;
  selectedFileId?: string;
  onClick?: (file?: any) => void;
}

interface ThumbnailProps
  extends Pick<Props, "lazyLoad" | "thumbnailClassName" | "onClick"> {
  file: any;
}

const getUrl = (file: any) => {
  return file.url;
};

const showArrowDown = ({
  currentSlide,
  perView,
  totalSlides,
}: {
  currentSlide: number;
  perView: number;
  totalSlides: number;
}) => {
  return currentSlide + perView < totalSlides;
};

const Thumbnail: FC<ThumbnailProps> = ({
  file,
  thumbnailClassName,
  lazyLoad = true,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(file);
  };

  return (
    <button className="h-full w-full focus:outline-none" onClick={handleClick}>
      <Image
        url={getUrl(file)}
        size={thumbnailClassName}
        magicSize="galleryCropped"
        variant={AvailableImageVariants.contain}
        rounded={RoundedVariants?.none}
        lazyLoad={lazyLoad}
      />
    </button>
  );
};

export const ThumbnailSliderView: FC<Props> = ({
  files,
  selectedFileId,
  perView = 6,
  thumbnailClassName = "pb-16/17",
  vertical = false,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const totalSlides = files.length;
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    vertical,
    mode: "free-snap",
    rubberband: true,
    initial: 0,
    slides: {
      perView,
      spacing: 1,
      number: totalSlides,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const handleClick =
    (instanceRefFunc?: () => void) => (e?: MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      instanceRefFunc?.();
    };

  return (
    <div
      className={clsx("w-full", {
        "h-full": vertical,
        "h-auto": !vertical,
      })}
    >
      <div
        ref={sliderRef}
        className={clsx("keen-slider h-full", {
          "max-h-[calc(100vh-5rem)]": vertical,
        })}
      >
        {loaded && instanceRef.current && currentSlide !== 0 && (
          <div
            className={clsx("flex justify-center z-20", {
              "absolute top-0 w-full": vertical,
              "absolute left-0 h-full items-center": !vertical,
            })}
          >
            <IconButtonBase onClick={handleClick(instanceRef.current?.prev)}>
              <ChevronLeft
                className={clsx("h-10 w-10", {
                  "rotate-90": vertical,
                })}
              />
            </IconButtonBase>
          </div>
        )}
        {files?.map((file) => (
          <div
            key={file.id}
            className={clsx("keen-slider__slide cursor-pointer h-fit border", {
              "mr-1": !vertical,
              "border-gray-900": file.id === selectedFileId,
              "border-transparent": file.id !== selectedFileId,
            })}
          >
            <Thumbnail
              file={file}
              thumbnailClassName={thumbnailClassName}
              {...rest}
            />
          </div>
        ))}
        {loaded &&
          instanceRef.current &&
          showArrowDown({
            perView,
            currentSlide,
            totalSlides,
          }) && (
            <div
              className={clsx("flex justify-center z-20", {
                "absolute bottom-0 w-full": vertical,
                "absolute right-0 h-full items-center": !vertical,
              })}
            >
              <IconButtonBase onClick={handleClick(instanceRef.current?.next)}>
                <ChevronRight
                  className={clsx("h-10 w-10", {
                    "rotate-90": vertical,
                  })}
                />
              </IconButtonBase>
            </div>
          )}
      </div>
    </div>
  );
};
