import { FC, ReactNode } from "react";

import { Spinner } from "@amenda-components/App";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface LoaderWrapperProps {
  className?: string;
  size?: number;
  borderWidth?: number;
  children?: ReactNode;
  spinnerSize?: "sm" | "md" | "lg" | "xs";
  variant?:
    | "primary"
    | "secondary"
    | "default"
    | "danger"
    | "warning"
    | "outline"
    | "dangerAlt";
}
export const LoaderWrapper: FC<LoaderWrapperProps> = ({
  className = "h-96",
  children,
  borderWidth,
  variant = "secondary",
  spinnerSize = "md",
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("flex flex-col justify-center items-center", className)}
    >
      <Spinner
        spinnerSize={spinnerSize}
        variant={variant}
        borderWidth={borderWidth}
      />
      <span className="pt-2 font-apercu text-sm">{t("Loading")}...</span>
      {children}
    </div>
  );
};
