import { Cell, Column, Header, Row, Table } from "@tanstack/react-table";
import { ChangeEvent, useCallback } from "react";

import Papa from "papaparse";
import { formatDate } from "@amenda-utils";
import { isObject } from "lodash";
import xlsx from "json-as-xlsx";

export enum SpecialColumns {
  SELECT = "select",
  ACTIONS = "userActions",
  GROUPING = "grouping",
}

export const tableDownloadOptions = [
  {
    value: "excel",
    label: "Excel",
  },
  {
    value: "csv",
    label: "CSV",
  },
];

export const isLastHeader = (index: number, headers: any[]) => {
  return index + 1 === headers.length;
};

const getStickyPosition = (
  items: Header<any, unknown>[] | Cell<any, unknown>[],
  i: number,
) => {
  const prevItems = items.slice(0, i);
  const currItem = items[i];
  let left = 0;
  prevItems.forEach((item) => {
    if (item.column.getIsPinned()) {
      left += item.column.getSize();
    }
  });

  if (currItem.column.id === SpecialColumns.ACTIONS) {
    return {
      right: 0,
    };
  }
  return {
    left,
  };
};

export const getHeaderStickyPosition = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  const currHeader = headers[index];
  if (currHeader.column.getIsPinned()) {
    return getStickyPosition(headers, index);
  }
  return {};
};

export const getCellStickyPosition = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  const currCell = cells[index];
  if (currCell.column.getIsPinned()) {
    return getStickyPosition(cells, index);
  }
  return {};
};

const getPinnedClassName = (
  items: Cell<any, unknown>[] | Header<any, unknown>[],
  i: number,
) => {
  let pinnedItem: Cell<any, unknown> | Header<any, unknown> | undefined;

  items.forEach((item) => {
    if (
      item.column.getIsPinned() &&
      item.column.id !== SpecialColumns.ACTIONS
    ) {
      pinnedItem = item;
    }
  });

  const currItem = items[i];
  if (pinnedItem && pinnedItem.column.id === currItem.column.id) {
    return "border-r";
  } else if (currItem.column.id === SpecialColumns.ACTIONS) {
    return "border-l";
  }
  return "";
};

export const getPinnedCellClassName = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(cells, index);
};

export const getPinnedHeaderClassName = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(headers, index);
};

export const isSelectColumn = (id: string) =>
  [SpecialColumns.SELECT, SpecialColumns.ACTIONS].includes(
    id as SpecialColumns,
  );

export const getPadding = (virtualRows: any[], totalSize: number) => {
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return {
    paddingBottom,
    paddingTop: virtualRows?.[0]?.start || 0,
  };
};

export const getTableSize = (table: Table<any>, isFullWidth: boolean) => {
  const width = isFullWidth ? "100%" : table.getCenterTotalSize();

  return {
    width,
  };
};

export const getDefaultPinnedColumns = ({
  showSelectorColumn,
  showUserActionColumn,
  defaultPinnedColumns,
  showGroupingColumn,
}: {
  showGroupingColumn: boolean;
  showSelectorColumn: boolean;
  showUserActionColumn: boolean;
  defaultPinnedColumns?: { left?: string[]; right?: string[] };
}) => {
  let left = showGroupingColumn
    ? [SpecialColumns.GROUPING, ...(defaultPinnedColumns?.left || [])]
    : [...(defaultPinnedColumns?.left || [])];
  const right = showUserActionColumn
    ? [SpecialColumns.ACTIONS, ...(defaultPinnedColumns?.right || [])]
    : [...(defaultPinnedColumns?.right || [])];

  if (showSelectorColumn) {
    left = [SpecialColumns.SELECT, ...left];
  }

  return {
    left,
    right,
  };
};

export const getDefaultVisibleColumns = (columns: Column<any, any>[]) => {
  const defaultVisibleColumns: Record<string, boolean> = {};
  columns.forEach((column) => {
    defaultVisibleColumns[column.id] = true;
  });

  return { defaultVisibleColumns };
};

export const getDefaultSelection = (items: any[], selectedItems: string[]) => {
  const defaultSelection: Record<number, boolean> = {};

  items.forEach((item, i) => {
    if (selectedItems.includes(item.id)) {
      defaultSelection[i] = true;
    }
  });

  return { defaultSelection };
};

export const useTableRowsSelection = <T>({
  addSelectedRows,
  toggleSelectedRow,
}: {
  toggleSelectedRow: (id: string) => void;
  addSelectedRows: (ids: string[]) => void;
}) => {
  const onRowSelectionChange = useCallback(
    (row: Row<T>) => (event: ChangeEvent<HTMLInputElement>) => {
      row.getToggleSelectedHandler()(event);
      const rowId = (row.original as any).id;

      if (!row.getIsSelected()) {
        toggleSelectedRow(rowId);
      } else {
        toggleSelectedRow(rowId);
      }
    },
    [toggleSelectedRow],
  );

  const onAllRowsSelectionChange = useCallback(
    (table: Table<T>) => (event: ChangeEvent<HTMLInputElement>) => {
      table.getToggleAllRowsSelectedHandler()(event);
      if (table.getIsAllRowsSelected()) {
        addSelectedRows([]);
      } else {
        const rows = table
          .getPreSelectedRowModel()
          .rows.map((row) => (row.original as any).id);

        addSelectedRows(rows);
      }
    },
    [addSelectedRows],
  );

  return {
    onRowSelectionChange,
    onAllRowsSelectionChange,
  };
};

const fileDownloader = (fileName: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  a.click();
};

const convertToCSV = (
  columnHeaders: { value: string; label: string }[],
  csvData: any[][],
) => {
  const csv = Papa.unparse({
    fields: columnHeaders.map((header) => header.label),
    data: csvData,
  });

  const blob = new Blob([csv], { type: "text/csv" });
  const date = new Date().toISOString();
  fileDownloader(`amenda-table-${formatDate(date)}.csv`, blob);
};

const convertToExcel = (
  columnHeaders: { value: string; label: string }[],
  content: any[],
) => {
  const date = new Date().toISOString();
  const settings = {
    fileName: `Amenda Table ${formatDate(date)}`,
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false,
  };
  const data = [
    {
      content,
      columns: columnHeaders,
    },
  ];

  xlsx(data, settings);
};

const getColumnHeaders = (
  columns: Column<any, any>[],
  visibleColumns: Record<string, boolean>,
) => {
  return Object.keys(visibleColumns)
    .filter((key) => visibleColumns[key])
    .map((key) => {
      const column = columns.find((column) => column.id === key);
      if (!column) {
        return { label: key, value: key };
      }
      return {
        value: key,
        label: (column.columnDef.meta as any)?.label || key,
      };
    });
};

const getCsvData = (data: any[], visibleColumns: Record<string, boolean>) => {
  const csvData: any[][] = [];

  data.forEach((item) => {
    const row: any[] = [];
    Object.keys(visibleColumns)
      .filter((key) => visibleColumns[key])
      .forEach((key) => {
        const column = item[key];
        row.push(column);
      });
    csvData.push(row);
  });

  return csvData;
};

const processData = (input: Record<string, Record<string, any>>[]) => {
  const output: any[] = [];

  input.forEach((data) => {
    const item: any = {};
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        const values = data[key].map((item: any) => item.value).filter(Boolean);
        item[key] = values.join(",");
      } else if (isObject(data[key])) {
        item[key] = data[key]["name"] || data[key]["label"];
      } else {
        item[key] = data[key];
      }
    });

    output.push(item);
  });

  return output;
};

export const downloadCsvOrExcel = async ({
  data,
  value,
  visibleColumns,
  availableColumns,
}: {
  value: "csv" | "excel";
  visibleColumns: Record<string, boolean>;
  availableColumns: Column<any, any>[];
  data: any[];
}) => {
  const processedData = processData(data);
  const columnHeaders = getColumnHeaders(availableColumns, visibleColumns);
  const csvData = getCsvData(processedData, visibleColumns);

  if (value === "csv") {
    convertToCSV(columnHeaders, csvData);
  } else {
    convertToExcel(columnHeaders, processedData);
  }
};
