import { FC, useState } from "react";

import { Button } from "@amenda-components/App";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { SearchIcon } from "lucide-react";
import { inputFieldTheme } from "@amenda-styles/theme";
import { useTranslation } from "react-i18next";

interface Props {
  searchTerm: string;
  className?: string;
  handleSearch?: (searchText: string) => void;
}

export const ExpandableSearch: FC<Props> = ({
  searchTerm,
  className,
  handleSearch,
}) => {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(!!searchTerm);
  const { searchInputCss } = inputFieldTheme();

  const handleOpenSearch = () => setShowSearch(true);

  const onSearch = (searchTerm: string) => {
    handleSearch?.(searchTerm);
  };

  const handleCloseSearch = async () => {
    setShowSearch(false);
    handleSearch?.("");
  };

  return (
    <div className={className}>
      {showSearch ? (
        <MiniSearchField
          className={searchInputCss({ size: "sm" })}
          placeholder="Search"
          value={searchTerm}
          onChange={onSearch}
          onClear={handleCloseSearch}
        />
      ) : (
        <Button size="xs" className="focus:ring-0" onClick={handleOpenSearch}>
          <span className="sr-only">{t("Open Search")}</span>
          <SearchIcon className="h-5 w-5" />
        </Button>
      )}
    </div>
  );
};
