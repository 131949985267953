import { tv } from "tailwind-variants";

export interface Sizes {
  xs?: string;
  sm: string;
  md: string;
  lg: string;
  xl?: string;
}

export interface Colors {
  default: any;
  danger: any;
  primary: any;
  secondary: any;
  warning: any;
  dangerAlt?: any;
  outline?: any;
  success?: any;
}

interface Theme {
  menus: {
    panel: {
      sm: string;
      lg: string;
    };
    panelItem: {
      sm: string;
      lg: string;
    };
    panelItemActive: {
      sm: string;
      lg: string;
    };
  };
}

export const layoutTheme = tv({
  slots: {
    gridCss:
      "grid grid-cols gap-2 xxl:gap-8 transition-all duration-500 grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5",
  },
  variants: {
    sidebarOpen: {
      true: {
        gridCss: "grid-cols-2 xl:grid-cols-3 xxl:grid-cols-5",
      },
    },
  },
});

export const coloredSelectTheme = tv({
  slots: {
    badgeCss: "rounded-full",
  },
  variants: {
    size: {
      sm: {
        badgeCss: "w-4 h-4",
      },
      md: {
        badgeCss: "w-5 h-5",
      },
      lg: {
        badgeCss: "w-8 h-8",
      },
    },
    value: {
      good_for_use: {
        badgeCss: "bg-green-500",
      },
      not_recommended: {
        badgeCss: "bg-red-500",
      },
      good_reference: {
        badgeCss: "bg-yellow-500",
      },
    },
  },
});

export const cardTheme = tv({
  slots: {
    cardCss: "cursor-pointer",
    titleCss: "text-gray-900 text-md xxl:text-lg text-left truncate mt-2 mb-1",
    descriptionCss: "font-apercu text-sm text-gray-500 mb-2",
    imageCss: "",
  },
  variants: {
    variant: {
      primary: {},
      secondary: {
        cardCss:
          "p-3 bg-gray-100 border-2 border-transparent hover:bg-white hover:border-gray-800",
        imageCss: "border-2 border-gray-200",
      },
    },
    isSelected: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      variant: "secondary",
      className: {
        cardCss: "bg-white",
      },
    },
  ],
});

export const datePickerTheme = tv({
  slots: {
    headerMonthYearBtnCss:
      "py-2 px-3 font-apercu text-sm uppercase text-gray-600 hover:bg-gray-100",
    headerBtnIconCss: "h-5 w-5",
    headerBtnCss:
      "relative inline-flex items-center p-1 bg-white text-sm font-medium text-gray-600 rounded-full hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0 focus:ring-indigo-500",
    calendarDayCss:
      "!font-normal !rounded-sm hover:!bg-gray-200 hover:!text-gray-800",
    yearBtnCss:
      "px-3 py-2 !font-normal !rounded-sm !bg-white !text-gray-800 hover:!bg-gray-200 hover:!text-gray-800",
  },
  variants: {
    isToday: {
      true: {
        calendarDayCss:
          "underline underline-offset-4 decoration-2 decoration-blue-600",
        yearBtnCss:
          "underline underline-offset-4 decoration-2 decoration-blue-600",
      },
    },
    disabled: {
      true: {
        headerBtnCss: "hidden",
        headerMonthYearBtnCss: "hidden",
        yearBtnCss: "cursor-not-allowed",
        calendarDayCss: "!cursor-not-allowed",
      },
    },
    isSelected: {
      true: {
        yearBtnCss:
          "!bg-gray-800 !text-white hover:!bg-gray-500 hover:!text-white",
        calendarDayCss:
          "!bg-gray-800 !text-white hover:!bg-gray-500 hover:!text-white",
      },
    },
  },
});

export const buttonTheme = tv({
  base: "font-apercu inline-flex items-center text-sm",
  variants: {
    variant: {
      outline:
        "text-gray-900 bg-white border border-gray-200 hover:bg-gray-900 hover:text-white",
      dangerAlt:
        "text-red-600 hover:text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 group/dangerAlt",
      default:
        "text-gray-900 group/btn bg-white hover:bg-gray-900 hover:text-white",
      danger:
        "text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
      primary:
        "text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-0",
      secondary: "text-white bg-gray-900 hover:bg-gray-800",
      warning:
        "text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500",
    },
    size: {
      xs: "px-2.5 py-1.5 text-xs h-9",
      sm: "px-3 py-2 leading-4",
      md: "md:px-4 px-2.5 h-8 md:h-10",
      lg: "px-4 py-2.5 text-base",
      xl: "px-6 py-3 text-base",
    },
    disabled: {
      true: "cursor-not-allowed",
    },
    withGroup: {
      true: "mr-2",
    },
  },
});

export const tagTheme = tv({
  base: "inline-flex items-center text-sm font-apercu leading-none px-3 py-1",
  variants: {
    variant: {
      default: "bg-gray-100 text-gray-800",
      primary: "bg-white text-gray-700 border border-gray-200",
      secondary: "bg-gray-700/60 text-white",
      secondaryAlt: "bg-black text-white",
      success: "bg-green-100 text-green-800",
      danger: "bg-red-100 text-red-800",
      warning: "bg-yellow-100 text-yellow-800",
    },
    clickable: {
      true: "cursor-pointer hover:bg-opacity-80",
    },
    isSelected: {
      true: "",
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      variant: "primary",
      className: "bg-gray-800 text-white",
    },
    {
      isSelected: true,
      variant: "default",
      className: "bg-gray-700/60 text-white",
    },
    {
      isSelected: true,
      variant: "secondary",
      className: "bg-white/60 text-gray-800",
    },
  ],
  defaultVariants: {
    variant: "default",
  },
});

export const inputFieldTheme = tv({
  slots: {
    inputLabelCss: "text-sm text-gray-500 font-apercu",
    inputCss:
      "font-apercu block w-full text-sm border-gray-300 focus:outline-none focus:ring-0 focus:ring-gray-900 focus:border-gray-500",
    searchInputCss: "w-60 bg-white border border-gray-900",
  },
  variants: {
    size: {
      sm: {
        inputCss: "h-8",
        searchInputCss: "h-9",
      },
      md: {
        inputCss: "h-11",
        searchInputCss: "h-11",
      },
    },
  },
});

export const iconButtonTheme = tv({
  extend: buttonTheme,
  base: "flex items-center focus:outline-none",
  variants: {
    variant: {
      primary:
        "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500",
      secondary:
        "text-indigo-400 bg-white hover:bg-indigo-100 hover:text-indigo-500 focus:ring-2 focus:ring-indigo-500",
      default: "text-gray-900 bg-white hover:bg-gray-900 hover:text-white",
      danger: "text-red-500 bg-white hover:bg-red-200 hover:text-red-600",
      dangerAlt:
        "inline-flex items-center justify-center rounded-full bg-red-500 hover:bg-red-600 text-white font-medium",
      base: "text-gray-900 bg-white hover:bg-gray-200 hover:text-gray-900",
      round:
        "text-gray-400 bg-white hover:bg-gray-900 hover:text-gray-700 rounded-full",
      clean: "text-gray-900 hover:bg-gray-900 hover:text-white",
      inverted: "text-white bg-gray-900 hover:bg-gray-700",
      primaryAlt: "text-blue-500 bg-white hover:text-blue-600",
    },
    size: {
      xs: "p-0.5 w-9",
      sm: "p-2",
      md: "p-2 md:p-2 w-8 md:w-10",
    },
  },
});

export const alertTheme = tv({
  base: "text-sm",
  variants: {
    variant: {
      default: "border border-gray-300 text-gray-700 bg-white",
      danger: "bg-red-600 text-white",
      primary: "bg-indigo-600 text-white",
      secondary: "border bg-white text-indigo-700",
      warning: "border border-yellow-500 text-yellow-700 bg-yellow-100",
    },
    size: {
      sm: "leading-4",
      md: "",
      lg: "text-base",
    },
  },
});

export const spinnerTheme = tv({
  base: "border-white",
  variants: {
    variant: {
      danger: "",
      primary: "",
      outline: "",
      default: "border-gray-900 group-hover/btn:border-white",
      secondary: "border-gray-600",
      warning: "border-yellow-700",
      dangerAlt: "border-red-600 group-hover/dangerAlt:border-white",
    },
    size: {
      xs: "h-4 w-4",
      sm: "h-6 w-6",
      md: "h-12 w-12",
      lg: "h-24 w-24",
    },
  },
});

export const transitionsTheme = tv({
  base: "ease-in-out delay-150",
  variants: {
    variant: {
      default: "transition",
      transform: "transition-transform",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const allBlue: Theme = {
  menus: {
    panel: {
      sm: "block py-3 text-base font-medium text-gray-500",
      lg: "origin-top-right absolute right-0 w-48 mt-2 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50",
    },
    panelItemActive: {
      sm: "bg-indigo-50 border-indigo-500 text-indigo-700",
      lg: "bg-gray-100",
    },
    panelItem: {
      sm: "block px-4 py-3 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 no-underline",
      lg: "block px-4 py-2 text-sm text-gray-700 no-underline hover:no-underline w-full text-left",
    },
  },
};
