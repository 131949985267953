import { FC } from "react";
import { MaterialListHeader } from "./MaterialListHeader";
import { MaterialListView } from "./MaterialListView";
import { SlideOver } from "@amenda-components/App";
import { useMaterialsStore } from "@amenda-domains/mutations";

interface Props {
  isCollection?: boolean;
}

export const MaterialList: FC<Props> = ({ isCollection = false }) => {
  return (
    <aside className="hidden md:w-72 lg:w-96 flex-shrink-0 h-full border-r border-gray-200 md:order-first md:flex md:flex-col">
      <MaterialListHeader isCollection={isCollection} />
      <MaterialListView isCollection={isCollection} />
    </aside>
  );
};

export const MaterialListMobile: FC<Props> = ({ isCollection = false }) => {
  const openMaterialListSlideOver = useMaterialsStore(
    (state) => state.openMaterialListSlideOver,
  );
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const handleClose = () => {
    setOpenMaterialListSlideOver(false);
  };

  return (
    <SlideOver show={openMaterialListSlideOver} onClose={handleClose}>
      <div className="w-full h-screen overflow-y-auto relative">
        <div className="w-full sticky top-0 z-20 bg-white">
          <MaterialListHeader isCollection={isCollection} />
        </div>
        <MaterialListView isCollection={isCollection} />
      </div>
    </SlideOver>
  );
};
