import { FilterIcon, XIcon } from "lucide-react";
import {
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { ContactCollectionDropdown } from "./ContactCollectionDropdown";
import { ContactDirectoryProps } from "./types";
import { ContactSearchProps } from "./common";
import { ContactsActionBar } from "./ContactsActionBar";
import { ContactsFilterMenu } from "./ContactsFilterMenu";
import { FC } from "react";
import { IconButtonBase } from "@amenda-components/App";
import { SearchField } from "@amenda-components/SearchComponents";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props extends ContactDirectoryProps {
  isCollection: boolean;
  handleSearch: (args: ContactSearchProps) => Promise<void>;
}

export const ContactListHeader: FC<Props> = ({ handleSearch, ...props }) => {
  const { t } = useTranslation();
  const setOpenUsersFilterMenu = useUsersStore(
    (state) => state.setOpenUsersFilterMenu,
  );
  const openUsersFilterMenu = useUsersStore(
    (state) => state.openUsersFilterMenu,
  );
  const { searchFilters, setSearchFilters } = useUserSearchFiltersWithPath();

  const onSearch = async (searchTerm: string) => {
    setSearchFilters("searchTerm", searchTerm);
    await handleSearch({ ...searchFilters, searchTerm });
  };

  const handleFilterMenu = async () => {
    setOpenUsersFilterMenu(!openUsersFilterMenu);
  };

  return (
    <div
      className={clsx("px-4 py-4 w-full", {
        "border-b border-gray-200": openUsersFilterMenu,
      })}
    >
      <ContactCollectionDropdown {...props} />
      <div className="flex flex-col space-y-2">
        <div className="mt-4 flex items-center space-x-2">
          <div className="min-w-0 flex-1">
            <label htmlFor="search" className="sr-only">
              {t("Search")}
            </label>
            <div className="relative rounded-md">
              <SearchField
                variant="slim"
                isClearable={true}
                defaultValue={searchFilters?.searchTerm}
                placeHolder={t(`Search ${props.pluralTitle}`)}
                onSearch={onSearch}
              />
            </div>
          </div>
          <IconButtonBase
            variant="inverted"
            label={openUsersFilterMenu ? t("Close filters") : t("Open filters")}
            onClick={handleFilterMenu}
          >
            {openUsersFilterMenu ? (
              <XIcon className="w-5 h-5 lg:w-6 lg:h-6" />
            ) : (
              <FilterIcon className="w-5 h-5 lg:w-6 lg:h-6" />
            )}
          </IconButtonBase>
        </div>
        <ContactsFilterMenu
          isOpen={openUsersFilterMenu}
          handleSearch={handleSearch}
          {...props}
        />
        <ContactsActionBar {...props} />
      </div>
    </div>
  );
};
