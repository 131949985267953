import { NestedPageComponentProps, PageComponentProps } from "@amenda-types";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { ObjectSchema } from "yup";
import { SimilaritySearchDropdown } from "./SimilaritySearchDropdown";
import { devConsole } from "@amenda-utils";
import { forwardRef } from "react";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  values: Record<string, any>;
  inputSchema: ObjectSchema<any>;
  components: PageComponentProps[];
  formComponentTree: NestedPageComponentProps;
  handleSubmit: (values: any) => Promise<void>;
}

export const SimilaritySearchForm = forwardRef<HTMLButtonElement, Props>(
  (
    {
      values,
      components,
      inputSchema,
      formComponentTree,
      handleSubmit: onSubmitCallback,
    },
    ref,
  ) => {
    const { control, reset, resetField, handleSubmit } = useForm<any>({
      values,
      resolver: yupResolver(inputSchema),
    });

    const onError = (errors: any, e: any) => {
      devConsole?.warn("amenda:search form error gat", errors);
    };

    const onSubmit = handleSubmit(onSubmitCallback, onError);

    return (
      <div className="w-full h-full lg:px-8 md:px-4 px-2 overflow-y-auto pb-24">
        <form onSubmit={onSubmit}>
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
              return e;
            }}
          >
            {!isEmpty(formComponentTree.components) && (
              <ComponentTreeRenderer
                config={formComponentTree}
                readOnly={false}
                isSimilaritySearch={true}
                globalProps={{
                  control,
                  canCreateKeywords: false,
                  hasMenuOverflow: true,
                  reset,
                }}
              />
            )}
            <div className="w-full flex justify-center">
              <SimilaritySearchDropdown
                resetField={resetField}
                components={components}
              />
            </div>
          </div>
          <button ref={ref} type="submit" className="hidden" />
        </form>
      </div>
    );
  },
);
