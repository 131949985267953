import { FC, useEffect, useState } from "react";
import { useAppStore, useUsersStore } from "@amenda-domains/mutations";

import { AppRoutes } from "@amenda-types";
import { ContactProfileCardBase } from "@amenda-components/Contacts";
import { Link } from "@amenda-components/App";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  contacts: any[];
}

export const ContactResults: FC<Props> = ({ contacts }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [results, setResults] = useState<any[]>([]);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);

  const handleClick = (contact: any) => {
    setSelectedUserId(contact);
    toggleSearchModal(false);
    navigate(AppRoutes.Contacts);
  };

  useEffect(() => {
    setResults(contacts.slice(0, 4));
  }, [contacts]);

  if (isEmpty(contacts)) {
    return null;
  }
  return (
    <div className="mt-2">
      <div>
        <p className="text-gray-900 text-sm font-apercu mb-1 mt-2">
          {t("Found Contacts")}
        </p>
      </div>
      <div className="grid grid-cols-1">
        {results?.map((c) => (
          <div key={c.id} className="px-2 py-2 truncate">
            <div className="relative w-full py-3 flex items-center space-x-3">
              <Link
                className="flex items-center hover:no-underline"
                onClick={() => handleClick(c)}
              >
                <ContactProfileCardBase contact={c} />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
