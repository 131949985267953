import { FC, ReactNode, useRef, useState } from "react";
import {
  FileLimits,
  isValidFileSize,
  isValidFileType,
} from "@amenda-components/FileUploads/common";

import { AvailableNotificationTypes } from "@amenda-types";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface SingleFileUploaderProps {
  children?: (props: {
    file: any;
    getFileSrc: (defaultUrl?: string) => string;
    openFileUploader: () => void;
  }) => ReactNode;
  fileLimit?: number;
  title?: string;
  validTypes?: any[];
  isDisabled?: boolean;
  onFileChange: (file: any) => void;
}

export const SingleFileUploader: FC<SingleFileUploaderProps> = ({
  title,
  children,
  isDisabled,
  fileLimit = FileLimits["5MB"],
  validTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"],
  onFileChange,
}) => {
  const { t } = useTranslation();
  const fileEl = useRef<any>(null);
  const [file, setFile] = useState<any>();
  const showNotification = useAppStore((state) => state.showNotification);

  const handleClick = () => {
    fileEl.current.click();
  };

  const notifyError = (message: string) =>
    showNotification(AvailableNotificationTypes.Error, message);

  const validateAndUpdateFile = (file: any) => {
    if (!isValidFileType(file, validTypes)) {
      notifyError("Invalid File Type");
    } else if (!isValidFileSize(file, fileLimit)) {
      notifyError("File is Too Big");
    } else {
      setFile(file);
      onFileChange(file);
    }
  };

  const handleFileChange = () => {
    const files = fileEl.current.files;
    if (files.length) {
      const file = files.item(0);
      validateAndUpdateFile(file);
    }
  };

  const getFileSrc = (defaultUrl = "") => {
    return file ? URL.createObjectURL(file) : defaultUrl;
  };

  return (
    <div>
      {title && (
        <label htmlFor="photo" className="amenda-component-label sm:mt-px">
          {t(title)}
        </label>
      )}
      <div className="mt-1 flex items-center space-x-3">
        {children?.({
          file,
          getFileSrc,
          openFileUploader: handleClick,
        })}
        <div
          className={clsx("flex items-center justify-center", {
            hidden: Boolean(isDisabled),
          })}
        >
          <button
            type="button"
            className="amenda-button-light"
            onClick={handleClick}
          >
            {t("change")}
          </button>
          <input
            ref={fileEl}
            type="file"
            className="mb-4"
            hidden
            multiple={false}
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  );
};
