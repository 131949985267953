import { Control, Controller, useController } from "react-hook-form";
import { FC, ReactNode } from "react";
import { MultiSelect, Switch } from "@amenda-components/FormComponents";

import { getComponentOptions } from "./common";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  isCreating?: boolean;
  component?: any;
}

interface ComponentWrapperProps {
  children: ReactNode;
  showMessage?: boolean;
  message?: string;
}

const ComponentWrapper: FC<ComponentWrapperProps> = ({
  children,
  message,
  showMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col space-y-1">
      {children}
      {showMessage && (
        <div className="text-sm text-gray-800">{t(message ?? "")}</div>
      )}
    </div>
  );
};

const EditComponentLayout: FC<Props> = ({ control, component }) => {
  const {
    field: { value: deleteLayout, onChange: onDeleteLayoutChange },
  } = useController({
    name: "deleteLayout",
    control,
    defaultValue: false,
  });
  const {
    field: { value: cloneLayout, onChange: onCloneLayoutChange },
  } = useController({
    name: "cloneLayout",
    control,
    defaultValue: false,
  });

  const isAppSpecific =
    component?.isAppSpecific ||
    (component?.components || []).some((c: any) => Boolean(c.isAppSpecific));

  const handleCloneLayoutChange = (value: boolean) => {
    onCloneLayoutChange(value);
    if (value) {
      onDeleteLayoutChange(false);
    }
  };

  const handleDeleteLayoutChange = (value: boolean) => {
    onDeleteLayoutChange(value);
    if (value) {
      onCloneLayoutChange(false);
    }
  };

  return (
    <div className="w-full flex flex-col space-y-1">
      <ComponentWrapper
        showMessage={cloneLayout}
        message="This will create another component with the same nested components"
      >
        <Switch
          label="Clone component?"
          checked={cloneLayout}
          onChange={handleCloneLayoutChange}
          showErrorMessage={false}
        />
      </ComponentWrapper>
      {!isAppSpecific && (
        <ComponentWrapper
          showMessage={deleteLayout}
          message="This will delete this component and all its nested components"
        >
          <Switch
            label="Delete component?"
            checked={deleteLayout}
            onChange={handleDeleteLayoutChange}
            showErrorMessage={false}
          />
        </ComponentWrapper>
      )}
    </div>
  );
};

const CreateComponentLayout: FC<Props> = ({ control }) => {
  return (
    <div className="w-full">
      <Controller
        control={control}
        name="layoutComponents"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <MultiSelect
              id="layoutComponents"
              label="Select components to add"
              error={error?.message}
              values={value}
              hasMenuOverflow={true}
              onChange={onChange}
              options={getComponentOptions()}
            />
          );
        }}
      />
    </div>
  );
};

export const FormBuilderLayoutComponentSettings: FC<Props> = ({
  control,
  isCreating,
  component,
}) => {
  if (Boolean(isCreating)) {
    return <CreateComponentLayout control={control} />;
  }
  return <EditComponentLayout control={control} component={component} />;
};
