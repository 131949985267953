import { Box, Column, Fragment, Image } from ".";
import { FormComponentProps, NestedPageComponentProps } from "@amenda-types";
import {
  templateComponents,
  templateLayoutComponents,
} from "@amenda-components/Templates";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { groupComponentsByParent } from "@amenda-utils";

interface PdfGeneratorProps {
  components: NestedPageComponentProps[];
  globalProps?: any;
}

export const PdfGenerator: FC<PdfGeneratorProps> = ({
  components,
  globalProps = {},
}) => {
  const pdfComponentTree = groupComponentsByParent(components);

  return (
    <ComponentTreeRenderer
      config={pdfComponentTree}
      globalProps={globalProps}
      customComponents={{
        ...templateComponents,
        Image: ({ config, global }: FormComponentProps) => {
          return (
            <Image {...config.properties} global={global} id={config.id} />
          );
        },
      }}
      customLayoutComponents={{
        ...templateLayoutComponents,
        Box,
        Column,
        Fragment,
      }}
    />
  );
};
