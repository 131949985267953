import { FC, useEffect, useState } from "react";
import { PencilIcon, SaveIcon, TrashIcon } from "lucide-react";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { IconButtonBase } from "@amenda-components/App";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  onTitleUpdate: (text?: string) => Promise<void>;
}

const FormTitle: FC<Props> = ({ title, onTitleUpdate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleBlur = () => {
    if (value && value !== title) {
      onTitleUpdate(value);
    }
  };

  useEffect(() => {
    setValue(title ?? "");

    return () => setValue("");
  }, [title]);

  return (
    <div className="w-64 md:w-80 font-apercu text-sm">
      <input
        type="text"
        className="py-0.5 px-2 w-full focus:outline-none focus:ring-0 border-transparent hover:border-gray-800 focus:border-gray-800"
        placeholder={t("Title")}
        value={value}
        onChange={(e) => {
          setValue(e.target?.value);
        }}
        onBlur={handleBlur}
      />
    </div>
  );
};

export const FormBuilderActionBar: FC = () => {
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const { updateForm, loading } = useUpdateForm();
  const originalForms = useProjectStore((state) => state.originalForms);

  const isAppSpecificForm =
    selectedForm?.isAppSpecific ||
    (selectedForm?.components ?? []).some((c) => Boolean(c?.isAppSpecific));

  const handleDelete = () => {
    updateFormBuilderState("deleteForm", true);
  };

  const handleEdit = () => {
    updateFormBuilderState("openEditFormModal", true);
  };

  const onTitleUpdate = async (text?: string) => {
    const foundForm =
      selectedForm && originalForms.find((form) => form.id === selectedForm.id);
    if (!foundForm) return;

    const foundComponent = foundForm.components.find(
      (c) => c.parentId === null,
    );
    if (!foundComponent) return;

    await updateForm({
      id: selectedForm.id,
      name: text,
      components: [
        {
          ...foundComponent,
          properties: {
            ...(foundComponent?.properties ?? {}),
            label: text,
          },
        },
      ],
    });
  };

  return (
    <div className="w-full flex justify-between items-center pt-1 pb-4 px-1 bg-white sticky top-0 z-20">
      <FormTitle title={selectedForm?.name} onTitleUpdate={onTitleUpdate} />
      <div className="flex space-x-2 items-center">
        <IconButtonBase
          className={clsx("cursor-default", {
            "animate-pulse text-green-600": loading,
          })}
        >
          <SaveIcon className="w-5 h-5" />
        </IconButtonBase>
        <IconButtonBase onClick={handleEdit}>
          <PencilIcon className="w-5 h-5" />
        </IconButtonBase>
        {!isAppSpecificForm && (
          <IconButtonBase variant="danger" onClick={handleDelete}>
            <TrashIcon className="w-5 h-5" />
          </IconButtonBase>
        )}
      </div>
    </div>
  );
};
