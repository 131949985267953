import { FC } from "react";
import { FileImage } from "lucide-react";
import clsx from "clsx";

interface SkeletonProps {
  width?: number;
  height?: number;
}

export const Skeleton: FC<SkeletonProps> = ({ width = 24, height = 4 }) => {
  return (
    <div className="flex animate-pulse flex-row items-center">
      <div className="flex flex-col space-y-2">
        <div
          className={clsx(
            "bg-gray-300 rounded-sm",
            `w-${width}`,
            `h-${height}`,
          )}
        ></div>
      </div>
    </div>
  );
};

interface SkeletonImageProps {
  className?: string;
}

export const SkeletonImage: FC<SkeletonImageProps> = ({
  className = "h-48 w-full",
}) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center bg-gray-200 rounded animate-pulse",
        className,
      )}
    >
      <FileImage className="w-10 h-10 text-gray-500" />
    </div>
  );
};
