import { FC, Fragment, Profiler, ReactNode } from "react";
import { devConsole, useRouteQuery } from "@amenda-utils";

interface Props {
  id: string;
  children: ReactNode;
}

export const ProfilerWrapper: FC<Props> = ({ id, children }) => {
  const query = useRouteQuery();
  const enableProfiler = query.get("profilerEnabled");

  const onRender = () => {
    devConsole?.info(`amenda:profiler: updating component ID: ${id}`);
  };

  if (Boolean(enableProfiler)) {
    return (
      <Profiler id={id} onRender={onRender}>
        {children}
      </Profiler>
    );
  }
  return <Fragment>{children}</Fragment>;
};
