import { pipe, tap } from "wonka";

import { Exchange } from "urql";

const abortControllers = new Map();

export const abortExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      ops$,
      tap((operation) => {
        if (operation.kind === "mutation" || operation.kind === "query") {
          // Create an AbortController for this operation
          const controller = new AbortController();
          const signal = controller.signal;
          const operationKey = operation.context?.customKey || operation.key;

          // Store the controller so it can be aborted later
          abortControllers.set(operationKey, controller);

          // Attach the signal to the operation context
          operation.context.fetchOptions = {
            ...operation.context.fetchOptions,
            signal,
          };
        }
      }),
      forward,
      tap((result) => {
        if (
          result.operation.kind === "mutation" ||
          result.operation.kind === "query"
        ) {
          // Remove the AbortController when the operation completes
          abortControllers.delete(result.operation.key);
        }
      }),
    );
  };

// A function to abort an ongoing request
export const abortRequest = (key: string) => {
  const controller = abortControllers.get(key);
  if (controller) {
    controller.abort();
    abortControllers.delete(key);
  }
};
