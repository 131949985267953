import React from "react";
import clsx from "clsx";
import { tagTheme } from "../../styles/theme";

interface TagProps {
  children: React.ReactNode;
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "secondaryAlt"
    | "success"
    | "danger"
    | "warning";
  className?: string;
  isSelected?: boolean;
}

export const Tag: React.FC<TagProps> = ({ children, ...rest }) => {
  return <span className={tagTheme(rest)}>{children}</span>;
};

interface TagsProps {
  children: React.ReactNode;
  className?: string;
}

export const Tags: React.FC<TagsProps> = ({ children, className }) => {
  return (
    <div className={clsx("flex flex-wrap gap-2", className)}>{children}</div>
  );
};
