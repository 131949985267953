import {
  ComponentTreeRenderer,
  useFormAutoSave,
} from "@amenda-components/PageBuilder";
import { FC, memo } from "react";
import { FormComponentProps, PageComponentProps } from "@amenda-types";

import { groupComponentsByParent } from "@amenda-utils";
import { processFormPermissions } from "@amenda-components/Shared/common";
import { useSettingsStore } from "@amenda-domains/mutations";

interface Props {
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  components: PageComponentProps[];
}

export const ProjectForm: FC<Props> = memo(
  ({ components, customFormComponents = {} }) => {
    const { reset, control, submitButtonRef, handleFormSubmit } =
      useFormAutoSave();
    const permissions = useSettingsStore(
      (state) => state.currentUserSystemRole?.permissions || {}
    );
    const formComponentTree = groupComponentsByParent(
      components,
      processFormPermissions(permissions)
    );

    return (
      <form onSubmit={handleFormSubmit}>
        <ComponentTreeRenderer
          config={formComponentTree}
          customComponents={customFormComponents}
          globalProps={{
            reset,
            control,
          }}
          readOnly={false}
        />
        <button type="submit" hidden ref={submitButtonRef} />
      </form>
    );
  }
);
