import { FC } from "react";
import clsx from "clsx";
import { isEmpty } from "lodash";

interface Props {
  className?: string;
}

export const Divider: FC<Props> = ({ className }) => {
  return (
    <div
      className={clsx("border-b-2", className, {
        "border-gray-500": isEmpty(className),
      })}
    />
  );
};
