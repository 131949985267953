import { Controller, useForm } from "react-hook-form";
import { decodeToken, getQueryParams } from "@amenda-utils";
import { useAppStore, useResetPassword } from "@amenda-domains/mutations";
import { useLocation, useNavigate } from "react-router-dom";

import { AvailableNotificationTypes } from "@amenda-types";
import { FC } from "react";
import { Spinner } from "@amenda-components/App";
import { TextField } from "@amenda-components/FormComponents";
import { resetPasswordSchema } from "@amenda-constants";
import { useGetTenantId } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface ResetPasswordInput {
  password: string;
  confirmPassword: string;
}

export const ResetPasswordForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { getTenantId, loading } = useGetTenantId();
  const { handleSubmit, control } = useForm<ResetPasswordInput>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const showNotification = useAppStore((state) => state.showNotification);
  const { resetPassword } = useResetPassword();

  const query = getQueryParams(location.search);
  const token: string = query.token;
  const decoded = decodeToken(token || "");

  const onSubmit = async ({ password }: ResetPasswordInput) => {
    try {
      const email = decoded?.email;
      const uid = decoded?.uid;

      await getTenantId({
        tenantUserEmail: email,
        callback: (tenantId) =>
          resetPassword({
            uid,
            email,
            password,
            tenantId,
          }),
      });
      navigate("/login");
    } catch (error: any) {
      showNotification(
        AvailableNotificationTypes.Error,
        error.message || "Something went wrong",
      );
    }
  };

  return (
    <div className="px-4">
      <div className="font-apercu mb-6">
        {t(
          "Reset your password. Please enter your new password twice to confirm",
        )}
      </div>
      <form
        className="flex flex-col space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="new-password"
                type="password"
                autoComplete="new-password"
                onChange={onChange}
                label="New Password"
                error={error?.message}
                value={value}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="confirm-password"
                type="password"
                autoComplete="new-password"
                onChange={onChange}
                label="Repeat New Password"
                error={error?.message}
                value={value}
              />
            );
          }}
        />
        <div>
          <button type="submit" className="amenda-button">
            {loading && <Spinner variant="primary" spinnerSize="xs" />}
            {t("Reset password")}
          </button>
        </div>
      </form>
    </div>
  );
};
