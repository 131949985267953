import { FC, useEffect } from "react";

import { AllowedAttachmentType } from "@amenda-types";
import { PdfListView } from "@amenda-components/FileUploads";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useSearchAttachments } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  projectId?: string;
}

export const ReadOnlyProjectTemplateDesigner: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { searchAttachments, loading } = useSearchAttachments();
  const attachments = useAttachmentStore((state) => state.attachments);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      setAttachments({
        attachments: [],
      });

      if (projectId) {
        await searchAttachments({
          filters: {
            type: AllowedAttachmentType.pdf,
            "formValues.project": projectId,
          },
        });
      }
    };

    searchAttachmentAsync();
  }, [projectId, setAttachments, searchAttachments]);

  return (
    <div className="py-5">
      <h3 className="amenda-form-heading">{t("Project Designs")}</h3>
      <PdfListView loading={loading} uploadedFiles={attachments} />
    </div>
  );
};
