import { FC, KeyboardEvent, forwardRef, useRef, useState } from "react";
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";

import { ChevronRightIcon } from "lucide-react";
import { CountryCode } from "libphonenumber-js";
import { IconButtonBase } from "@amenda-components/App";
import PhoneInput from "react-phone-number-input/input";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import labels from "react-phone-number-input/locale/de.json";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  className?: string;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return <input ref={ref} {...props} />;
});

const countriesInfo = getCountries().map((c) => {
  const code = getCountryCallingCode(c);

  return {
    code,
    country: c,
    formattedCode: `+${code}`,
  };
});

export const PhoneInputBase: FC<Props> = ({
  id,
  value,
  className = "w-full",
  autoFocus,
  placeholder = "",
  onChange,
  onKeyPress,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [country, setCountry] = useState<CountryCode | undefined>("DE");
  const [showMenu, setShowMenu] = useState(false);
  const [countryCodes, setCountryCodes] = useState(countriesInfo);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "+") {
      setShowMenu(true);
    }
    onKeyPress(e);
  };

  const handleMenu = (value: string) => {
    const filteredCountries = countriesInfo.filter(({ formattedCode }) =>
      formattedCode.startsWith(value),
    );

    if (value.startsWith("+") && value.length <= 3) {
      setCountryCodes(filteredCountries);
      setShowMenu(true);
    } else {
      setCountryCodes(countriesInfo);
      setShowMenu(false);
    }
  };

  const handleSetCountry = (value: string) => {
    if (value.length <= 0) {
      setCountry(undefined);
    } else if (!country && value.length >= 3) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber?.country) {
        setCountry(phoneNumber.country);
      }
    }
  };

  const handleChange = (value = "") => {
    onChange(value);
    handleSetCountry(value);
    handleMenu(value);
  };

  const handleSelect = (country: CountryCode, formattedCode: string) => () => {
    onChange(formattedCode);
    setCountry(country);
    setCountryCodes(countriesInfo);
    setShowMenu(false);
  };

  const getMenuStyle = (divEl: HTMLDivElement | null) => {
    if (!divEl) return;

    const boundingRect = divEl.getBoundingClientRect();
    const top = boundingRect.top + boundingRect.height;
    const bottom = window.innerHeight - top;
    const totalHeight = boundingRect.height + boundingRect.y + 192;

    if (totalHeight < window.innerHeight) {
      return {
        top,
      };
    }
    return {
      bottom,
    };
  };

  return (
    <div ref={wrapperRef} className={clsx("relative", className)}>
      <div className="w-full flex border-gray-200 border-0 border-b-[1px] focus-within:border-gray-900">
        <PhoneInput
          id={id}
          autoFocus={autoFocus}
          className="w-full p-0 border-0 focus:outline-none focus:ring-0"
          country={country}
          value={value}
          international={true}
          withCountryCallingCode={true}
          inputComponent={CustomInput}
          placeholder={t(placeholder)}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <IconButtonBase onClick={() => setShowMenu(!showMenu)}>
          <ChevronRightIcon
            className={clsx("h-4 w-4 text-gray-400", {
              "rotate-90 transform": !showMenu,
            })}
          />
        </IconButtonBase>
      </div>
      <Transition
        show={showMenu}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="fixed z-50 w-48 bg-white mt-1 mb-6 max-h-48 font-apercu shadow-lg overflow-x-hidden overflow-y-auto overscroll-contain ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={getMenuStyle(wrapperRef?.current)}
          onMouseLeave={() => setShowMenu(false)}
        >
          {countryCodes.map(({ formattedCode, country }) => (
            <div
              key={country}
              className="p-2 hover:bg-gray-200 cursor-pointer text-sm truncate"
              onClick={handleSelect(country, formattedCode)}
            >
              {formattedCode} {labels[country]}
            </div>
          ))}
        </div>
      </Transition>
    </div>
  );
};
