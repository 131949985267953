import {
  CreateOrEditProject,
  ProjectLayout,
} from "@amenda-components/Projects";
import { FC, useEffect } from "react";

import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";

export const CreateOrEditProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const { getProject } = useGetProject();
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues
  );
  const clearSelectedProjectCostGroup = useProjectStore(
    (state) => state.clearSelectedProjectCostGroup
  );

  const title = projectId ? selectedProject?.name : "New Project";

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
      clearSelectedProjectCostGroup();
    };
  }, [clearProjectValues, clearSelectedProjectCostGroup]);

  return (
    <ProjectLayout title={title}>
      <CreateOrEditProject projectId={projectId} />
    </ProjectLayout>
  );
};
