import { AllowedContactType, ReactTableKeys } from "@amenda-types";
import {
  Avatar,
  Button,
  IconButton,
  Spinner,
  Tooltip,
} from "@amenda-components/App";
import { DebounceTimes, PaginationLimit } from "@amenda-constants";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getUserSystemRole,
  useReInviteUsers,
  useUsersStore,
} from "@amenda-domains/mutations";
import {
  useGetAllSystemRoles,
  useGetAllUsers,
  useSearchUsers,
} from "@amenda-domains/queries";

import { ColumnDef } from "@tanstack/react-table";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ReactTable } from "@amenda-components/Shared";
import { TFunction } from "i18next";
import clsx from "clsx";
import { debounce } from "lodash";
import { getUserName } from "@amenda-components/Contacts/common";
import { useTranslation } from "react-i18next";

interface ShowStatusProps {
  isActive?: boolean;
  emailVerified?: boolean;
}

const ShowStatus: FC<ShowStatusProps> = ({ isActive, emailVerified }) => {
  const { t } = useTranslation();

  if (!emailVerified && isActive) {
    return (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {t("Pending Verification")}
      </span>
    );
  }
  return isActive ? (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      {t("Active")}
    </span>
  ) : (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
      {t("Disabled")}
    </span>
  );
};

interface GetColumnsArgs {
  t: TFunction<"translation", undefined>;
  loading?: boolean;
  userId?: string;
  systemRoles: any[];
  currentUserId?: string;
  setUserId: (id?: string) => void;
  setSelectedUser: (user: any) => void;
  setOfficeUserModal: (isOpen: boolean) => void;
  reInviteUsers: (args: any) => Promise<void>;
}

const getColumns = ({
  loading,
  userId,
  systemRoles,
  currentUserId,
  t,
  setUserId,
  setSelectedUser,
  setOfficeUserModal,
  reInviteUsers,
}: GetColumnsArgs): ColumnDef<any>[] => {
  const handleEdit = (user: any) => {
    setSelectedUser(user);
    setOfficeUserModal(true);
  };
  const handleSendInviteEmail = (user: any) => async () => {
    if (user?.email) {
      setUserId(user?.id);
      await reInviteUsers({
        input: {
          emails: [user.email],
        },
      });
    }
  };

  return [
    {
      id: "user_name",
      header: t("Name"),
      minSize: 250,
      accessorKey: "firstName",
      enablePinning: false,
      cell: ({ row }) => {
        const user = row.original;

        return (
          <div className="flex items-center">
            <Avatar
              src={user.photoURL}
              name={getUserName(user)}
              iconClassName="h-9 w-9"
              className={clsx("h-10 w-10 min-h-10 min-w-10 text-base", {
                "ring-offset-2 ring-2 ring-gray-800": user.id === currentUserId,
              })}
            />
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {user?.firstName && getUserName(user)}
              </div>
              <div className="font-apercu text-sm text-gray-500 max-w-[15rem] truncate">
                {user?.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: "status",
      header: t("Status"),
      enablePinning: false,
      accessorFn: (row) => row.isActive && row.emailVerified,
      minSize: 200,
      cell: ({ row }) => {
        return (
          <div className="flex items-center">
            <ShowStatus {...row.original} />
          </div>
        );
      },
    },
    {
      id: "role",
      header: t("Role"),
      enablePinning: false,
      accessorFn: (row) => getUserSystemRole(systemRoles, row?.id)?.name,
      cell: ({ row }) => {
        return (
          <div className="flex items-center">
            <span className="font-apercu whitespace-nowrap text-sm text-gray-500">
              {getUserSystemRole(systemRoles, row.original?.id)?.name}
            </span>
          </div>
        );
      },
    },
    {
      id: "edit",
      enablePinning: false,
      header: () => <span className="sr-only">{t("Edit")}</span>,
      cell: ({ row }) => {
        const user = row.original;
        const showSendInvite = !user?.isActive || !user?.emailVerified;

        return (
          <div className="flex space-x-8 items-center">
            <div>
              <Button
                size="xs"
                variant="default"
                className="border border-1"
                onClick={() => handleEdit(row.original)}
              >
                {t("Edit")}
              </Button>
            </div>
            {showSendInvite && (
              <Tooltip
                id={`send-invite-${row.id}`}
                positionStrategy="fixed"
                position="left"
                message="Resend Invite"
              >
                {loading && userId === row.original?.id ? (
                  <Spinner spinnerSize="xs" variant="default" />
                ) : (
                  <IconButton
                    variant="default"
                    label="Resend Invite"
                    Icon={EnvelopeIcon}
                    onClick={handleSendInviteEmail(row.original)}
                  />
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};

export const UsersTable: FC = () => {
  const { t } = useTranslation();
  const users = useUsersStore((state) => state.users);
  const setSelectedUser = useUsersStore((state) => state.setSelectedUser);
  const setOfficeUserModal = useUsersStore((state) => state.setOfficeUserModal);
  const { getAllUsers } = useGetAllUsers();
  const { reInviteUsers, loading } = useReInviteUsers();
  const [userId, setUserId] = useState<string>();
  const currentUser = useUsersStore((state) => state.currentUser);
  const pagination = useUsersStore((state) => state.pagination);
  const { searchUsers, loading: searchUsersLoader } = useSearchUsers();
  const { getAllSystemRoles } = useGetAllSystemRoles();
  const [systemRoles, setSystemRoles] = useState<any[]>([]);

  const columns = useMemo(
    () =>
      getColumns({
        userId,
        systemRoles,
        loading,
        currentUserId: currentUser?.id,
        t,
        setUserId,
        setSelectedUser,
        setOfficeUserModal,
        reInviteUsers,
      }),
    [
      userId,
      systemRoles,
      currentUser?.id,
      loading,
      t,
      setUserId,
      setSelectedUser,
      setOfficeUserModal,
      reInviteUsers,
    ],
  );

  const fetchNextPage = useCallback(async () => {
    if (pagination?.hasNext) {
      await getAllUsers({
        isDeleted: false,
        next: pagination?.next,
        limit: PaginationLimit.contacts,
      });
    }
  }, [pagination?.next, pagination?.hasNext, getAllUsers]);

  const handleResetSearch = () => {
    getAllUsers({
      isDeleted: false,
      autoSelect: true,
    });
  };

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length > 0) {
        await searchUsers({
          searchTerm,
          type: AllowedContactType.office,
          autoSelect: true,
        });
      } else {
        await handleResetSearch();
      }
    }, DebounceTimes.Search),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    return debouncedSearch(searchTerm);
  };

  useEffect(() => {
    const usersIds = users.map((u) => u.id).filter(Boolean);

    if (usersIds.length > 0) {
      getAllSystemRoles({
        usersIds,
        context: {
          requestPolicy: "cache-and-network",
        },
        callback: setSystemRoles,
      });
    }
  }, [getAllSystemRoles, users]);

  return (
    <div className="flex flex-col px-4 sm:px-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block w-full">
          <div className="overflow-hidden px-2">
            <ReactTable
              isFullWidth
              tableId={ReactTableKeys.Users}
              maxEstimatedRowHeight={65}
              containerClass="h-[calc(100vh-18.5rem)] w-full"
              isLoading={searchUsersLoader}
              data={users}
              columns={columns}
              pagination={pagination}
              handleSearch={handleSearch}
              fetchNextPage={fetchNextPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
