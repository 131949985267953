import { FC, ReactNode, useEffect, useState } from "react";
import { ReactSelectCreatable, createOption } from "./ReactSelectCreatable";

import { Option } from "@amenda-types";
import { ReactSelect } from "./ReactSelect";
import { groupBy } from "lodash";
import { useGetKeywords } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { useUpsertKeywords } from "@amenda-domains/mutations";

interface Props {
  canCreateResourceSpecificKeywords?: boolean;
  error?: string;
  id: string;
  isClearable?: boolean;
  placeholder?: string;
  values: Option[];
  label?: string;
  optional?: ReactNode;
  disabled?: boolean;
  isCreatable?: boolean;
  hasMenuOverflow?: boolean;
  onChange: (values: string[]) => void;
  resourceId?: string;
  resourceLabel?: string;
}

const createableOptionClassName =
  "w-full py-2 px-3 text-gray-800 bg-white hover:text-white hover:bg-gray-900";

export const KeywordCollection: FC<Props> = ({
  canCreateResourceSpecificKeywords = false,
  id,
  values,
  disabled,
  isCreatable = true,
  optional,
  onChange,
  placeholder = "Suchen...",
  resourceId,
  resourceLabel = "Project",
  ...rest
}) => {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState<any[]>([]);
  const { upsertKeywords, loading } = useUpsertKeywords();
  const { getKeywords, loading: loadingGetKeywords } = useGetKeywords();

  const isDisabled = loading || loadingGetKeywords || disabled;
  const groupedKeywords = groupBy(keywords, (keyword: any) => {
    return Boolean(keyword?.resourceId) ? resourceLabel : "General";
  });
  const groupedOptions = Object.keys(groupedKeywords).reduce<any[]>(
    (acc, key) => {
      const options = groupedKeywords[key].map((keyword) =>
        createOption(keyword.name, keyword.id)
      );
      return [
        ...acc,
        {
          label: key,
          options,
        },
      ];
    },
    []
  );

  const handleChange = (options: Option[]) => {
    onChange(options.map(({ value }) => value));
  };

  const processValues = (values?: any[]) => {
    if (values) {
      return values
        .map((value) => {
          const keyword = keywords.find((keyword) => keyword.id === value);
          return keyword ? createOption(keyword.name, keyword.id) : value;
        })
        .filter((keyword) => Boolean(keyword?.value));
    }
    return values;
  };

  const onCreate = async (inputValue: string, isResourceSpecific = false) => {
    let value: any = {
      name: inputValue,
      componentId: id,
    };

    if (isResourceSpecific) {
      value = {
        ...value,
        resourceId,
      };
    }

    await upsertKeywords({
      ...value,
      callback: (keyword) => {
        const selectedOption = [...(values ?? []), keyword.id];

        setKeywords((prev) => [...prev, keyword]);
        onChange(selectedOption);
      },
    });
  };

  useEffect(() => {
    if (id) {
      getKeywords({
        componentIds: [id],
        setKeywords,
      });
    }

    return () => {
      setKeywords([]);
    };
  }, [getKeywords, id]);

  if (isCreatable) {
    return (
      <ReactSelectCreatable
        classNames={{
          option: (state: any) => {
            const { data } = state;

            return data?.__isNew__ ? "!bg-white !p-0" : "";
          },
        }}
        id={id}
        isMulti={true}
        isClearable={true}
        optional={optional}
        placeholder={placeholder}
        isLoading={loading || loadingGetKeywords}
        disabled={isDisabled}
        formatCreateLabel={(inputValue) => (
          <div className="flex flex-col">
            <span
              className={createableOptionClassName}
              onClick={() => onCreate(inputValue)}
            >
              {t("Create new keyword")}: <strong>{inputValue}</strong>
            </span>
            {canCreateResourceSpecificKeywords && resourceId && (
              <span
                className={createableOptionClassName}
                onClick={() => onCreate(inputValue, true)}
              >
                {t("Create new keyword")}: <strong>{inputValue}</strong> fur{" "}
                {resourceLabel}
              </span>
            )}
          </div>
        )}
        formatGroupLabel={(group) => {
          return <span>{t(group.label)}</span>;
        }}
        options={groupedOptions}
        onCreate={() => {}}
        onChange={(options, actionMeta) => handleChange(options)}
        value={processValues(values)}
        {...rest}
      />
    );
  }
  return (
    <ReactSelect
      disabled={isDisabled}
      formatGroupLabel={(group) => {
        if (group.label === "All") return null;
        return <span>{t(group.label)}</span>;
      }}
      id={id}
      isMulti={true}
      isClearable={true}
      optional={optional}
      isLoading={loading || loadingGetKeywords}
      options={groupedOptions}
      onChange={(options: Option[]) => handleChange(options)}
      placeholder={placeholder}
      value={processValues(values)}
      {...rest}
    />
  );
};
