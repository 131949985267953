import { FC, ReactNode } from "react";
import {
  PlacesType,
  PositionStrategy,
  Tooltip as ReactTooltip,
} from "react-tooltip";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  hidden?: boolean;
  openOnClick?: boolean;
  message?: string;
  id?: string;
  className?: string;
  position?: PlacesType;
  anchorSelect?: string;
  positionStrategy?: PositionStrategy;
  children: ReactNode;
}

export const Tooltip: FC<Props> = ({
  id,
  children,
  anchorSelect,
  message,
  className,
  position = "bottom",
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div data-tooltip-id={id} className={clsx(className, "text-sm")}>
      {children}
      {message && (
        <ReactTooltip
          id={id}
          place={position}
          content={t(message)}
          className="amenda-tooltip"
          anchorSelect={anchorSelect}
          {...props}
        />
      )}
    </div>
  );
};
