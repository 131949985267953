import { FC, useEffect } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  useAppStore,
  useAttachmentStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { GalleryCard } from "@amenda-components/Gallery";
import { GeneralPermissionTypes } from "@amenda-types";
import { LoaderWrapper } from "@amenda-components/App";
import { MediaGalleryProps } from "./common";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

type Props = Pick<
  MediaGalleryProps,
  "canUpload" | "attachments" | "fetchPaginatedAttachments"
>;

const GridView: FC<Props> = ({ attachments, fetchPaginatedAttachments }) => {
  const { ref, inView } = useInView();
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);

  useEffect(() => {
    if (inView && fetchPaginatedAttachments) {
      fetchPaginatedAttachments();
    }
  }, [inView, fetchPaginatedAttachments]);

  return (
    <div className="w-full">
      {isSearching ? (
        <LoaderWrapper />
      ) : (
        <div
          className={clsx(
            "grid grid-cols gap-2 md:gap-2 lg:gap-2 xxl:gap-8 transition-all duration-500",
            {
              "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6":
                !sidebarOpen,
              "grid-cols-2 md:grid-cols-2 lg:grid-cols-4": sidebarOpen,
            },
          )}
        >
          {attachments.map((attachment) => (
            <GalleryCard key={attachment.id} attachment={attachment} />
          ))}
        </div>
      )}
      {isFetching && <LoaderWrapper className="h-44" />}
      <div ref={ref} />
    </div>
  );
};

export const GalleryGridView: FC<Props> = (props) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const showMediaGeneralPermissions =
    mediaGeneralPermissions[GeneralPermissionTypes.Create];

  if (showMediaGeneralPermissions && Boolean(props?.canUpload)) {
    return <GridView {...props} />;
  }
  return <GridView {...props} />;
};
