import { FC, ReactNode, useRef } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";

import { CheckIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ErrorMessage } from "./ErrorMessage";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  error?: string;
  id?: string;
  label?: string;
  options: Option[];
  optional?: ReactNode;
  selectedOption?: string;
  disabled?: boolean;
  hideDefault?: boolean;
  className?: string;
  hasMenuOverflow?: boolean;
  showErrorMessage?: boolean;
  optionChildren?: (option: Option, isSelected: boolean) => ReactNode;
  onChange: (option: string) => void;
  onBlur?: () => void;
}

const defaultOption: Option = {
  value: "no_selection",
  label: "No selection",
  unavailable: true,
};

export const Select: FC<Props> = ({
  error,
  id,
  label,
  optional,
  selectedOption,
  disabled,
  hideDefault,
  className = "h-11 py-2",
  hasMenuOverflow = false,
  showErrorMessage = true,
  options: selectableOptions,
  optionChildren,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const getCurrentOption = () => {
    if (!!selectedOption) {
      return selectableOptions.find(
        (option) => option.value === selectedOption,
      );
    }
    return hideDefault ? selectableOptions[0] : defaultOption;
  };
  const handleChange = (option: Option) => {
    onChange(option.value);
  };

  const currentOption = getCurrentOption();
  const options = hideDefault
    ? selectableOptions
    : [defaultOption, ...selectableOptions];

  return (
    <div ref={ref}>
      <Listbox
        value={currentOption}
        disabled={disabled}
        onChange={handleChange}
      >
        {({ open }) => (
          <>
            {label && (
              <Label className="amenda-component-label">
                <div className="flex flex-row justify-between items-center">
                  <span>{t(label)}</span>
                  {optional}
                </div>
              </Label>
            )}
            <div className="relative">
              <ListboxButton
                className={clsx(
                  "amenda-component relative w-full pl-3 pr-10 text-left border sm:text-sm",
                  className,
                  {
                    "border-gray-700": open,
                    "cursor-default bg-white ": !disabled,
                    "cursor-not-allowed bg-gray-100": disabled,
                  },
                )}
                onBlur={onBlur}
              >
                <span className="font-apercu block truncate">
                  {currentOption?.label && t(currentOption.label)}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className={clsx("h-5 w-5 text-gray-400", {
                      "rotate-180": open,
                    })}
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  static
                  className={clsx(
                    "amenda-component focus:ring-gray-200 z-50 mt-1 bg-white shadow-lg max-h-60 py-1 text-base overflow-auto focus:outline-none sm:text-sm",
                    {
                      fixed: hasMenuOverflow,
                      absolute: !hasMenuOverflow,
                    },
                  )}
                  style={{
                    width: `${
                      hasMenuOverflow ? `${ref.current?.clientWidth}px` : "100%"
                    }`,
                  }}
                >
                  {options.map((option) => {
                    const selected = option.value === selectedOption;
                    return (
                      <ListboxOption
                        key={option.value}
                        value={option}
                        disabled={!!option.unavailable}
                        className={({ focus }) =>
                          clsx(
                            "cursor-default select-none relative flex items-center py-2 pl-3 pr-9",
                            {
                              "text-white bg-gray-900": !!focus,
                              "text-gray-900": !focus,
                            },
                          )
                        }
                      >
                        {({ focus }) => (
                          <>
                            {optionChildren?.(option, selected)}
                            {!optionChildren && (
                              <span
                                className={clsx("font-apercu block truncate", {
                                  "font-semibold": !!selected,
                                  "font-normal": !selected,
                                })}
                              >
                                {option.label && t(option.label)}
                              </span>
                            )}
                            {selected && (
                              <span
                                className={clsx(
                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                  {
                                    "text-white": !!focus,
                                    "text-gray-900": !focus,
                                  },
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </ListboxOption>
                    );
                  })}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {showErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
