import { FC, ReactNode } from "react";

import { Layout } from "@amenda-components/App";

interface Props {
  children: ReactNode;
}

export const PrivateRouteWrapper: FC<Props> = ({ children }) => {
  return <Layout>{children}</Layout>;
};
