import {
  AvailableNotificationTypes,
  Pagination,
  SystemRole,
} from "@amenda-types";
import { gql, useMutation } from "urql";

import { SYSTEM_ROLE_FRAGMENT } from "../fragments/settings";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useAppStore } from "./app";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "./users";

type State = {
  searchTerm: string;
  currentUserSystemRole?: SystemRole;
  pagination: Pagination;
  systemRoles: SystemRole[];
  systemRoleForm?: Omit<SystemRole, "id" | "usersIds" | "isDefaultRole">;
  selectedSystemRole?: SystemRole;
};

type Actions = {
  assigningSystemRole: (props: {
    userId: string;
    currentUserId: string;
    systemRole: SystemRole;
  }) => void;
  setCurrentUserSystemRole: (role: SystemRole) => void;
  setSelectedSystemRole: (role: SystemRole) => void;
  updateCurrentSystemRole: (role: SystemRole) => void;
  clearSelectedSystemRole: () => void;
  setSystemRoles: (
    props: Pagination & {
      roles: SystemRole[];
    },
  ) => void;
  setSearchTerm: (searchTerm: string) => void;
};

export const useSettingsStore = create(
  immer<State & Actions>((set, get) => ({
    systemRoles: [],
    pagination: {},
    searchTerm: "",
    setSearchTerm: (searchTerm) =>
      set((state) => {
        state.searchTerm = searchTerm;
      }),
    assigningSystemRole: ({ userId, currentUserId, systemRole }) =>
      set((state) => {
        const systemRoles = get().systemRoles;
        const currentUserSystemRole = get().currentUserSystemRole;
        const unassignUserFromRoles = systemRoles.map((r) => {
          if (r.usersIds?.includes(userId)) {
            return {
              ...r,
              usersIds: r.usersIds.filter((u) => u !== userId),
            };
          }
          return r;
        });
        const updatedSystemRoles = unassignUserFromRoles.map((r) => {
          return r?.id === systemRole?.id ? systemRole : r;
        });

        state.systemRoles = updatedSystemRoles;
        state.currentUserSystemRole = systemRole.usersIds?.includes(
          currentUserId,
        )
          ? systemRole
          : currentUserSystemRole;
      }),
    setCurrentUserSystemRole: (role) =>
      set((state) => {
        state.currentUserSystemRole = role;
      }),
    setSelectedSystemRole: (role) =>
      set((state) => {
        const { name, description, permissions } = role;

        state.selectedSystemRole = role;
        state.systemRoleForm = {
          name,
          description,
          permissions,
        };
      }),
    clearSelectedSystemRole: () =>
      set((state) => {
        state.systemRoleForm = undefined;
        state.selectedSystemRole = undefined;
      }),
    setSystemRoles: ({ roles, ...pagination }) =>
      set((state) => {
        state.systemRoles = roles;
        state.pagination = pagination;
      }),
    updateCurrentSystemRole: (role) =>
      set((state) => {
        if (role.id === state.currentUserSystemRole?.id) {
          state.currentUserSystemRole = role;
        }
      }),
  })),
);

export const getUserSystemRole = (
  systemRoles: SystemRole[],
  userId: string,
) => {
  const selectedSystemRole = systemRoles.find((role) => {
    return role.usersIds?.includes(userId);
  });

  return selectedSystemRole;
};

const UPSERT_SYSTEM_ROLE = gql`
  ${SYSTEM_ROLE_FRAGMENT}
  mutation UpsertSystemRole($input: SystemRoleInput!) {
    upsertSystemRole(input: $input) {
      ...UserRolesFragment
      permissions
    }
  }
`;

const ASSIGN_SYSTEM_ROLE_TO_USER = gql`
  ${SYSTEM_ROLE_FRAGMENT}
  mutation AssignSystemRoleToUser($input: UserSystemRoleInput!) {
    assignSystemRoleToUser(input: $input) {
      ...UserRolesFragment
      permissions
    }
  }
`;

const DELETE_SYSTEM_ROLE = gql`
  mutation DeleteSystemRole($id: String!) {
    deleteSystemRole(id: $id)
  }
`;

export const useUpsertSystemRole = () => {
  const navigate = useNavigate();
  const showNotification = useAppStore((state) => state.showNotification);
  const updateCurrentSystemRole = useSettingsStore(
    (state) => state.updateCurrentSystemRole,
  );
  const [result, callUpsertSystemRole] = useMutation(UPSERT_SYSTEM_ROLE);

  const upsertSystemRole = ({
    skipCallback = false,
    ...variables
  }: Record<string, any>) =>
    callUpsertSystemRole(variables).then(({ data }) => {
      if (data?.upsertSystemRole) {
        updateCurrentSystemRole(data.upsertSystemRole);

        if (!skipCallback) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Role created successfully",
          );
          navigate("/admin/rollen");
        }
      }
    });

  return {
    upsertSystemRole,
    loading: result.fetching,
  };
};

export const useAssignSystemRole = () => {
  const [result, callAssignSystemRoleToUser] = useMutation(
    ASSIGN_SYSTEM_ROLE_TO_USER,
  );
  const updateAssignedSystemRole = useSettingsStore(
    (state) => state.assigningSystemRole,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const currentUser = useUsersStore((state) => state.currentUser);

  const assignSystemRole = (variables: Record<string, any>) => {
    return callAssignSystemRoleToUser(variables).then(({ data }) => {
      if (data?.assignSystemRoleToUser) {
        updateAssignedSystemRole({
          userId: variables.input.userId,
          currentUserId: currentUser?.id,
          systemRole: data.assignSystemRoleToUser,
        });
        showNotification(
          AvailableNotificationTypes.Success,
          "New role assigned!",
        );
      }
    });
  };
  return {
    loading: result.fetching,
    assignSystemRole,
  };
};

export const useDeleteSystemRole = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callDeleteSystemRole] = useMutation(DELETE_SYSTEM_ROLE);

  const deleteSystemRole = (id: string) =>
    callDeleteSystemRole({ id }).then(() => {
      showNotification(
        AvailableNotificationTypes.Success,
        "Role deleted successfully",
      );
    });

  return {
    deleteSystemRole,
    loading: result.fetching,
  };
};
