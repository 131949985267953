import { gql } from "urql";

export const SEARCH_PROJECT_FRAGMENT = gql`
  fragment SearchProjectFragment on Project {
    id: _id
    name
    number
    updatedAt
    createdAt
    galleryUrl
    isDeleted
    settings
    ownerId
    attachmentIds
    address {
      coordinates
      name
    }
    formValues
    excludeFromCostEstimation
  }
`;

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on Project {
    id: _id
    name
    number
    updatedAt
    createdAt
    galleryUrl
    isDeleted
    regionId
    ownerId
    attachmentIds
    formValues
    excludeFromCostEstimation
    address {
      coordinates
      name
    }
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const PROJECT_SUBSCRIPTION_FRAGMENT = gql`
  fragment ProjectSubsFragment on ProjectSubscription {
    id: _id
    name
    number
    updatedAt
    createdAt
    galleryUrl
    attachmentIds
    isDeleted
    regionId
    ownerId
    formValues
    excludeFromCostEstimation
    address {
      coordinates
      name
    }
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const COST_GROUP_FRAGMENT = gql`
  fragment CostGroupFragment on CostGroup {
    id: _id
    type
    bkiDate
    note
    resourceId
    versionDate
    source
    ownerId
    servicePhase
    generalContractorFactor
    quantities
    values {
      key
      totalCost
      componentId
    }
  }
`;
