import {
  DateQuarterPickerBase,
  DateQuarterPickerProps,
} from "@amenda-components/FormComponents";
import { FC, ReactNode } from "react";

import { ErrorMessage } from "./ErrorMessage";
import { useTranslation } from "react-i18next";

export const DateQuarterPicker: FC<
  DateQuarterPickerProps & {
    error?: string;
    optionalLabel?: string | ReactNode;
  }
> = ({ id, error, label, optionalLabel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <label htmlFor={id} className="amenda-component-label">
          {t(label)}
        </label>
        {optionalLabel && <div>{optionalLabel}</div>}
      </div>
      <DateQuarterPickerBase
        id={id}
        label={label}
        className="amenda-component h-11"
        {...rest}
      />
      <ErrorMessage id={id} error={error} />
    </div>
  );
};
