import { FC, useEffect } from "react";
import { GeneralAccessTypes, PermissionComponentKey } from "@amenda-constants";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  MediaDropZone,
  getUploadDataFromFile,
  getUploadDataFromResponse,
  useMediaUploader,
} from "@niklasenb/azimio-media-client";
import {
  useAttachmentStore,
  useAuthStore,
  useCreateAttachment,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { FileLimits } from "./common";
import { IconButtonBase } from "@amenda-components/App";
import { VALID_IMAGE_TYPES } from "@amenda-types";
import { XIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MediaUploaderDropZone: FC = () => {
  const { t } = useTranslation();
  const user = useAuthStore((state) => state.loggedInUser);
  const { projectId } = useParams<{ projectId?: string }>();
  const { createAttachment } = useCreateAttachment();
  const addAttachmentToBulkEditor = useAttachmentStore(
    (state) => state.addAttachmentToBulkEditor,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal,
  );
  const setBulkEditorState = useAttachmentStore(
    (state) => state.setBulkEditorState,
  );

  const uppy = useMediaUploader({
    tenantId: user.tenantId,
    path: "projects",
    autoProceed: true,
    allowedFileTypes: VALID_IMAGE_TYPES,
    maxFileSize: FileLimits["50MB"],
    url: `${process.env.REACT_APP_BE_REST_URL}/files/`,
  });

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const defaultShareType =
    mediaGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];

  const doneButtonHandler = () => {
    uppy.clear();
    setBulkEditorState("isEditing", true);
  };

  const handleClose = () => {
    toggleBulkEditorModal(false);
  };

  useEffect(() => {
    const handleUploadSuccess = async (file: any, response: any) => {
      const { bucketName, uploadLocation, type } = await getUploadDataFromFile(
        user.tenantId,
        file,
      );
      const { filename } = getUploadDataFromResponse(response);

      await createAttachment({
        input: {
          type,
          filename,
          uploadLocation,
          bucketname: bucketName,
          originalFilename: file.name,
          formValues: {
            project: projectId,
          },
          metadata: {
            ...(file.meta || {}),
            size: file.size,
          },
          share: {
            type: defaultShareType ?? GeneralAccessTypes.Everyone,
          },
        },
        callback: addAttachmentToBulkEditor,
      });
    };

    uppy.on("upload-success", handleUploadSuccess);

    return () => {
      uppy.off("upload-success", handleUploadSuccess);
    };
  }, [
    uppy,
    projectId,
    user.tenantId,
    defaultShareType,
    createAttachment,
    addAttachmentToBulkEditor,
  ]);

  return (
    <div className="relative flex flex-col w-full h-full p-2">
      <div className="w-full flex justify-end">
        <IconButtonBase variant="base">
          <XIcon className="w-6 h-6" onClick={handleClose} />
        </IconButtonBase>
      </div>
      <h3 className="text-center text-xl py-4 font-sans">
        {t("Upload new Images")}
      </h3>
      <div className="h-full w-full lg:px-8 md:px-6 px-4">
        <MediaDropZone
          uppy={uppy}
          className="amenda-dropzone font-apercu text-sm w-full h-[80vh]"
          doneButtonHandler={doneButtonHandler}
        />
      </div>
    </div>
  );
};
