import { CheckCircle2 } from "lucide-react";
import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  isChecked: boolean;
  onChange?: () => void;
}

export const CheckMarkRadio: React.FC<Props> = ({
  isChecked,
  className,
  onChange,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        "flex items-center justify-center rounded-full h-6 w-6 border text-gray-900 bg-white",
        className,
        {
          "border-gray-900 bg-[#2286E0]": isChecked,
          "border-gray-400 hover:border-gray-900": !isChecked,
        },
      )}
      onClick={(e) => {
        e.stopPropagation();
        onChange?.();
      }}
    >
      {isChecked && (
        <div className="w-full h-full absolute inset-0 flex items-center justify-center">
          <div
            className={`text-white rounded-full ${
              isChecked ? "bg-[#2286E0]" : "hover:bg-[#2286E0]"
            }`}
          >
            {isChecked ? (
              <CheckIcon className="h-5 w-5  stroke-[3]" />
            ) : (
              <CheckCircle2 className="h-5 w-5 stroke-[2]" />
            )}
          </div>
        </div>
      )}
    </button>
  );
};
