import {
  AllowedAttachmentType,
  AvailableImageVariants,
  RoundedVariants,
} from "@amenda-types";
import {
  Button,
  IconButton,
  IconButtonBase,
  Image,
  LoaderWrapper,
} from "@amenda-components/App";
import {
  DetectedFaces,
  MainContainer,
  SidebarContainer,
} from "@amenda-components/Shared";
import { FC, useEffect, useState } from "react";
import { MapPinnedIcon, ScanFace, Trash2Icon, XIcon } from "lucide-react";
import {
  generateBoundsCoordinates,
  isCoordinateValid,
} from "@amenda-components/Maps/common";
import {
  useAttachmentStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { AddressSearch } from "@amenda-components/FormComponents";
import { BulkEditorAttachmentForm } from "@amenda-components/Gallery";
import { Map } from "@amenda-components/Maps";
import { PinnedLocationMarker } from "@amenda-components/Maps/MapComponents";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TitleAndSubtitle } from "@amenda-components/PdfBuilder";
import { formatFileSizeResult } from "./common";
import { useFaceAPI } from "@amenda-context";
import { useGetAttachment } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

type ImagePreviewProps = {
  loading: boolean;
  attachment: any;
  totalAttachments: number;
};

type AttachmentFormWrapperProps = {
  loading: boolean;
};

interface AddLocationProps {
  id: string;
  attachment: any;
}

const AddLocation: FC<AddLocationProps> = ({ id, attachment }) => {
  const { t } = useTranslation();
  const { updateAttachment } = useUpdateAttachment();

  const [address, setAddress] = useState<{
    name?: string;
    coordinates: number[];
  }>({
    coordinates: attachment?.metadata?.location || [],
  });

  const updateMetadata = async (location: any) => {
    setAddress(location);
    await updateAttachment({
      input: {
        _id: attachment.id,
        metadata: {
          ...attachment.metadata,
          location: location.coordinates,
        },
      },
    });
  };

  return (
    <div>
      <button
        data-tooltip-id={id}
        className="flex items-center space-x-1 text-blue-500"
      >
        <MapPinnedIcon className="h-5 w-5" />
        <span>
          {t(
            isCoordinateValid(address.coordinates)
              ? "Show location"
              : "Add a location"
          )}
        </span>
      </button>
      <ReactTooltip
        id={id}
        clickable={true}
        positionStrategy="fixed"
        place="top-start"
        className="amenda-tooltip !p-0 !bg-white !opacity-100 !text-gray-800 !rounded-none z-[60] shadow-xl"
      >
        <div className="relative">
          <div className="w-96 h-80 p-1 bg-white relative">
            {!isCoordinateValid(address.coordinates) && (
              <div className="absolute bottom-0 right-0 h-auto w-64 z-20 pr-2 pb-2">
                <AddressSearch
                  id="location"
                  placeholder="Location"
                  hideErrorMessage={true}
                  value={address}
                  onChange={(value) => {
                    updateMetadata(value);
                  }}
                />
              </div>
            )}
            <Map
              centerCoordinate={
                isCoordinateValid(address.coordinates)
                  ? generateBoundsCoordinates(address.coordinates)
                  : undefined
              }
              doubleClickZoom={false}
            >
              <PinnedLocationMarker centerCoordinate={address.coordinates} />
            </Map>
          </div>
        </div>
      </ReactTooltip>
    </div>
  );
};

const ImagePreview: FC<ImagePreviewProps> = ({
  loading,
  attachment,
  totalAttachments,
}) => {
  const { t } = useTranslation();
  const {
    processing,
    detectedFaces,
    isModelLoaded,
    processImageForFaceRecognition,
  } = useFaceAPI();

  if (!attachment) return null;
  return (
    <div className="w-full h-full">
      <div className="w-full p-2 h-full relative">
        <div className="w-full flex items-center justify-between pb-1">
          <div className="w-full flex flex-col space-y-0.5">
            <span className="amenda-component-label">
              {attachment?.originalFilename || attachment?.metadata?.name}
            </span>
            <span className="text-sm">
              {t("BulkEditCounter", {
                totalAttachments,
              })}
            </span>
          </div>
          <IconButton
            Icon={ScanFace}
            label="Detected faces"
            variant="base"
            onClick={() => {
              processImageForFaceRecognition(attachment?.originalFilename);
            }}
            disabled={!isModelLoaded}
          />
        </div>

        <div className="w-full h-[80%] relative">
          {loading ? (
            <LoaderWrapper variant="default" className="h-full" />
          ) : (
            <Image
              id={attachment?.originalFilename}
              url={attachment?.url}
              size="h-full"
              magicSize="fullscreen"
              variant={AvailableImageVariants.scaleDown}
              rounded={RoundedVariants.none}
              enableHighlighting={false}
            >
              {(imageDimensions) => (
                <DetectedFaces
                  isLoading={processing}
                  detectedFaces={detectedFaces}
                  imageDimensions={imageDimensions}
                />
              )}
            </Image>
          )}
        </div>
        <div className="absolute bottom-10 left-0 w-full flex justify-center items-baseline lg:py-6 py-4 lg:px-8 px-4">
          <TitleAndSubtitle title="Location">
            <AddLocation id="bulkEditAddLocation" attachment={attachment} />
          </TitleAndSubtitle>
          <TitleAndSubtitle title="Dimensions">
            {attachment?.metadata?.height && attachment?.metadata?.width
              ? `${attachment?.metadata?.height}px mal ${attachment?.metadata?.width}px`
              : "-"}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="Size">
            {`${formatFileSizeResult(attachment?.metadata?.size)}`}
          </TitleAndSubtitle>
          <TitleAndSubtitle title="File">
            {attachment?.metadata?.type}
          </TitleAndSubtitle>
        </div>
      </div>
    </div>
  );
};

const AttachmentFormWrapper: FC<AttachmentFormWrapperProps> = ({ loading }) => {
  const { t } = useTranslation();
  const { updateAttachment, loading: isDeleting } = useUpdateAttachment();
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal
  );
  const activeAttachment = useAttachmentStore(
    (state) => state.bulkEditorState.activeAttachment
  );

  const handleClose = () => {
    toggleBulkEditorModal(false);
  };

  const deleteAttachment = async () => {
    if (activeAttachment?.id) {
      await updateAttachment({
        input: {
          isDeleted: true,
          _id: activeAttachment.id,
          type: activeAttachment.type,
        },
      });
    }
  };

  return (
    <div className="relative h-full w-full flex flex-col space-y-1 overflow-y-auto overscroll-y-contain px-4 pb-3 pt-4">
      <div className="w-full flex justify-end pb-8 pt-1">
        <IconButtonBase variant="base">
          <XIcon className="w-6 h-6" onClick={handleClose} />
        </IconButtonBase>
      </div>
      {loading ? <LoaderWrapper /> : <BulkEditorAttachmentForm />}
      <div className="w-full flex justify-center">
        <Button
          onClick={deleteAttachment}
          variant="dangerAlt"
          loading={isDeleting}
        >
          <div className="flex items-center space-x-4">
            <span>{t("Delete")}</span>
            <Trash2Icon className="h-5 w-5" />
          </div>
        </Button>
      </div>
    </div>
  );
};

export const MediaBulkEditorAttachmentView: FC = (props) => {
  const activeAttachmentIndex = useAttachmentStore(
    (state) => state.bulkEditorState.activeAttachmentIndex
  );
  const attachments = useAttachmentStore(
    (state) => state.bulkEditorState.attachments
  );
  const activeAttachment = useAttachmentStore(
    (state) => state.bulkEditorState.activeAttachment
  );
  const setBulkEditorActiveAttachment = useAttachmentStore(
    (state) => state.setBulkEditorActiveAttachment
  );
  const { getAttachment, loading } = useGetAttachment();

  const selectedAttachmentId = attachments[activeAttachmentIndex]?.id;

  useEffect(() => {
    if (selectedAttachmentId) {
      getAttachment({
        id: selectedAttachmentId,
        type: AllowedAttachmentType.image,
        attachmentFields: { metadata: 1, url: 1, name: 1 },
        context: {
          requestPolicy: "network-only",
        },
        callback: setBulkEditorActiveAttachment,
      });
    }

    return () => {
      setBulkEditorActiveAttachment({});
    };
  }, [selectedAttachmentId, getAttachment, setBulkEditorActiveAttachment]);

  return (
    <>
      <MainContainer className="lg:px-5 lg:py-4 px-2">
        <ImagePreview
          attachment={activeAttachment}
          totalAttachments={attachments.length}
          loading={loading}
        />
      </MainContainer>
      <SidebarContainer>
        <AttachmentFormWrapper loading={loading} />
      </SidebarContainer>
    </>
  );
};
