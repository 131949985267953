import { FC } from "react";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface ChipProps {
  label: string;
  className?: string;
  onDelete?: () => void;
}

export const Chip: FC<ChipProps> = ({
  label,
  className = "text-sm max-h-[30px] py-[7px] px-3 flex justify-center items-center border-1 border rounded-none border-gray-500 text-gray-900 bg-white",
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <span
      className={clsx(
        "font-apercu inline-flex items-center pl-3 py-1",
        className,
        {
          "pr-1.5": !!onDelete,
          "pr-3": !onDelete,
        },
      )}
    >
      {t(label)}
      {onDelete && (
        <button type="button" onClick={onDelete}>
          <XIcon className="ml-2 w-3 h-3" />
        </button>
      )}
    </span>
  );
};
