import {
  BuildingOffice2Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { ControlProps, components } from "react-select";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";
import { debounce, isEmpty } from "lodash";
import {
  useGetAllProjectsOptional,
  useSearchProjects,
} from "@amenda-domains/queries";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import AsyncSelect from "react-select/async";
import { DebounceTimes } from "@amenda-constants";
import { EmptyIndicator } from "@amenda-components/Shared";
import { ErrorMessage } from "@amenda-components/FormComponents";
import { ProjectMiniCard } from "./ProjectMiniCard";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import { abortRequest } from "@amenda-domains/abortExchange";
import clsx from "clsx";
import { getComponentsById } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  projectIds?: string[] | string;
  label?: string;
  error?: string;
  isMulti?: boolean;
  optional?: ReactNode;
  isClearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  StartIcon?: FC<{ className: string }>;
  children?: ReactNode;
  onBlur?: () => void;
  onChange: (values: any) => void;
}

interface BaseProps {
  projectIds?: string[] | string;
  children: (projects: any[]) => ReactNode;
}

export const ReadOnlySearchAndSelectProjectsBase: FC<BaseProps> = ({
  projectIds,
  children,
}) => {
  const [projects, setProjects] = useState<any[]>([]);
  const { getAllProjects } = useGetAllProjectsOptional();

  useEffect(() => {
    const getAllProjectsAsync = async () => {
      if (projectIds) {
        const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
        getAllProjects({
          ids,
          limit: ids.length,
          callback: (projects) => {
            setProjects(projects);
          },
        });
      } else {
        setProjects([]);
      }
    };

    getAllProjectsAsync();
    return () => {
      setProjects([]);
    };
  }, [projectIds, getAllProjects]);

  return <>{children(projects)}</>;
};

interface ReadOnlyProps {
  label: string;
  projectIds?: string[] | string;
}

export const ReadOnlySearchAndSelectProject: FC<ReadOnlyProps> = ({
  label,
  ...props
}) => {
  const { t } = useTranslation();
  const components = useProjectStore((state) => state.projectFormComponents);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const componentsById = getComponentsById(components);

  return (
    <ReadOnlySearchAndSelectProjectsBase {...props}>
      {(projects) => (
        <>
          {projects.length > 0 ? (
            <div>
              <p className="text-sm font-medium text-gray-900 mb-3">
                {t(label)}
              </p>
              <div className="grid grid-cols-1 mb-2">
                {projects.map((project) => (
                  <ProjectMiniCard
                    key={project?.id}
                    componentsById={componentsById}
                    permissions={permissions}
                    project={project}
                  />
                ))}
              </div>
            </div>
          ) : (
            <TitleAndDescription
              container="column"
              title={label}
              description={<EmptyIndicator />}
            />
          )}
        </>
      )}
    </ReadOnlySearchAndSelectProjectsBase>
  );
};

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <BuildingOffice2Icon
        className="h-5 w-5 text-gray-400 ml-2"
        aria-hidden="true"
      />
      {children}
    </components.Control>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.ClearIndicator>
  );
};

export const SearchAndSelectProjects: FC<Props> = ({
  error,
  id,
  label,
  projectIds,
  disabled = false,
  isClearable = true,
  isMulti = false,
  optional,
  hasMenuOverflow = false,
  hideErrorMessage = false,
  placeholder = "Search projects",
  onBlur,
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { searchProjects, loading } = useSearchProjects();
  const { getAllProjects, loading: getAllProjectsLoading } =
    useGetAllProjectsOptional();
  const [selectedProjects, setSelectedProjects] = useState<any>();

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string, resolve: (projects: any[]) => void) => {
      if (!isEmpty(searchTerm)) {
        await searchProjects({
          searchTerm,
          callback: (projects) => resolve(projects ?? []),
        });
      }
    }, DebounceTimes.Search),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        await debouncedSearch(inputValue, resolve);
      }
    });

  const handleChange = (projects: any) => {
    const value = Array.isArray(projects)
      ? projects.map((p) => p.id)
      : projects?.id;
    onChange(value);
  };

  useEffect(() => {
    if (projectIds) {
      abortRequest("searchProjects");
      const ids = Array.isArray(projectIds) ? projectIds : [projectIds];
      getAllProjects({
        ids,
        limit: ids.length,
        callback: (projects) => {
          setSelectedProjects(isMulti ? projects : projects[0]);
        },
      });
    }
  }, [projectIds, isMulti, getAllProjects]);

  return (
    <div className="group/searchProjects" ref={ref}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <div>
        <div className="w-full">
          <AsyncSelect
            inputId={id}
            cacheOptions
            isMulti={isMulti}
            isDisabled={disabled}
            value={selectedProjects}
            placeholder={t(placeholder)}
            loadingMessage={() => t("Loading") + "..."}
            className={customStyling.select.containerClass}
            loadOptions={loadOptions}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? t("No results found") : t("Start typing")
            }
            isLoading={loading || getAllProjectsLoading}
            isClearable={isClearable}
            onBlur={onBlur}
            onChange={handleChange}
            styles={{
              ...customStyling.select.styleOverride,
              ...selectMenuOverflow(hasMenuOverflow, ref),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            components={{
              Control,
              ClearIndicator,
              DropdownIndicator,
            }}
            {...selectMenuOverflowProps(hasMenuOverflow)}
          />
        </div>
      </div>
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
