import { FC } from "react";
import { FullScreenModal } from "@amenda-components/App";
import { MediaBulkEditorAttachmentView } from "./MediaBulkEditorAttachmentView";
import { MediaBulkEditorAttachmentsView } from "./MediaBulkEditorAttachmentsView";
import { MediaUploaderDropZone } from "./MediaBulkEditorDropzone";
import { useAttachmentStore } from "@amenda-domains/mutations";

export const MediaBulkEditorModal: FC = () => {
  const isEditing = useAttachmentStore(
    (state) => state.bulkEditorState.isEditing
  );
  const isOpen = useAttachmentStore(
    (state) => state.bulkEditorState.isModalOpen
  );
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal
  );
  const isAttachmentView = useAttachmentStore(
    (state) => state.bulkEditorState.isAttachmentView
  );

  const handleClose = () => {
    toggleBulkEditorModal(false);
  };

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      {!isEditing ? (
        <MediaUploaderDropZone />
      ) : isAttachmentView ? (
        <MediaBulkEditorAttachmentView />
      ) : (
        <MediaBulkEditorAttachmentsView />
      )}
    </FullScreenModal>
  );
};
