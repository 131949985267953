import { CreateOrEditMaterialUnitPrices } from "./CreateOrEditMaterialUnitPrice";
import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { MaterialReadOnlyUnitPrices } from "./MaterialReadOnlyUnitPrices";

export const MaterialCostInput: FC<FormComponentProps> = ({
  config,
  global,
  readOnly,
}) => {
  const { id, properties } = config;
  const { control, data } = global || {};
  const isReadOnly = readOnly || properties?.readOnly;

  if (control && !isReadOnly) {
    return <CreateOrEditMaterialUnitPrices id={id} control={control} />;
  }
  return <MaterialReadOnlyUnitPrices values={data?.[id]} />;
};
