import { PageComponentProps, availableLayouts } from "@amenda-types";
import {
  getPrintSectionComponents,
  groupComponentsByParent,
} from "@amenda-utils";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { LoaderWrapper } from "@amenda-components/App";
import { ReadOnlyTwoColumnsFullWidth } from "@amenda-components/PageLayouts";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  components: PageComponentProps[];
  loading?: boolean;
}

export const ProjectFormPrintView: FC<Props> = ({
  components,
  loading = false,
}) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);

  const pageComponents = getPrintSectionComponents(components);
  const componentTree = groupComponentsByParent(pageComponents);
  const formValues = selectedProject.formValues || {};

  if (loading) {
    return <LoaderWrapper />;
  }

  return (
    <div>
      <ComponentTreeRenderer
        config={componentTree}
        customLayoutComponents={{
          [availableLayouts.twoColumnsFullWidth]: ReadOnlyTwoColumnsFullWidth,
        }}
        globalProps={{
          data: formValues,
        }}
      />
    </div>
  );
};
