import { FC, useRef } from "react";
import {
  FormAutoSaveSubmitProps,
  FormWrapper,
} from "@amenda-components/PageBuilder";
import {
  getComponentsFromForms,
  getValidationWithPermissions,
  groupComponentsByParent,
} from "@amenda-utils";
import {
  transformAttachmentToForm,
  transformFormToAttachmentBase,
} from "./common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { Button } from "@amenda-components/App";
import isEmpty from "lodash/isEmpty";
import { processFormPermissions } from "@amenda-components/Shared/common";
import { useTranslation } from "react-i18next";

export const BulkEditorAttachmentForm: FC = () => {
  const { t } = useTranslation();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const forms = useProjectStore((state) => state.forms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {}
  );
  const cleanUpBulkEditorState = useAttachmentStore(
    (state) => state.cleanUpBulkEditorState
  );
  const nextBulkEditorActiveAttachment = useAttachmentStore(
    (state) => state.nextBulkEditorActiveAttachment
  );
  const attachment = useAttachmentStore(
    (state) => state.bulkEditorState.activeAttachment
  );
  const { updateAttachment, loading } = useUpdateAttachment();
  const activeAttachmentIndex = useAttachmentStore(
    (state) => state.bulkEditorState.activeAttachmentIndex
  );
  const attachments = useAttachmentStore(
    (state) => state.bulkEditorState.attachments
  );

  const values = transformAttachmentToForm(attachment?.formValues || {});
  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const formComponentTree = groupComponentsByParent(
    components,
    processFormPermissions(permissions)
  );
  const inputSchema = getValidationWithPermissions(
    components,
    processFormPermissions(permissions)
  );

  const handleSave = () => {
    if (submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };

  const onSubmit = async ({ data, resourceId }: FormAutoSaveSubmitProps) => {
    if (!isEmpty(data) && resourceId) {
      await updateAttachment({
        input: {
          ...transformFormToAttachmentBase(data),
          _id: resourceId,
        },
      });
    }
    nextBulkEditorActiveAttachment();
    cleanUpBulkEditorState();
  };

  return (
    <>
      <FormWrapper
        className="py-1"
        resourceId={attachment?.id}
        inputSchema={inputSchema}
        formComponentTree={formComponentTree}
        values={values}
        onSubmit={onSubmit}
      >
        {() => {
          return <button ref={submitBtnRef} type="submit" className="hidden" />;
        }}
      </FormWrapper>
      <div className="w-full bg-white z-10 py-2 px-2 border-t border-gray-200 absolute bottom-0 left-0 flex justify-end">
        <Button
          size="lg"
          variant="primary"
          loading={loading}
          onClick={handleSave}
        >
          {t(
            activeAttachmentIndex + 1 >= attachments.length
              ? "Save"
              : "Save + Continue"
          )}
        </Button>
      </div>
    </>
  );
};
