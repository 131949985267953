import { CANVAS_ID } from "./constants";
import { devConsole } from "@amenda-utils";

export const injectHtMLElements = (inputImgEl: {
  closest: (arg0: string) => any;
}) => {
  // Find the nearest ancestor div
  let parentDiv = inputImgEl.closest("div");

  // If there is no ancestor div, create one and append it to the body
  if (!parentDiv) {
    parentDiv = document.createElement("div");
    document.body.appendChild(parentDiv);
  }

  // Create a new canvas element
  const canvas = document.createElement("canvas");
  canvas.getContext("2d", { willReadFrequently: true });
  canvas.id = CANVAS_ID;

  // Apply inline styles to ensure it appears on top of the image
  canvas.style.position = "absolute";
  canvas.style.top = "50%";
  canvas.style.left = "50%";
  canvas.style.transform = "translate(-50%, -50%)";

  canvas.style.pointerEvents = "none";

  parentDiv.appendChild(canvas);
};

const isBase64 = (str: string) => {
  // Regular expression to match the structure of a Base64 encoded image URI
  return /^data:image\/([a-zA-Z]*);base64,/.test(str);
};

export const convertImgElementSrcToBase64 = (
  imgElementId: string,
  callback: (inputImgEl: any) => Promise<void>
) => {
  const inputImgEl: any = document.getElementById(imgElementId);
  const inputImgSrc = inputImgEl.src;

  if (!inputImgSrc) {
    devConsole?.error("Invalid image URL");
    return;
  }
  if (isBase64(inputImgSrc)) {
    devConsole?.log("Image source is already Base64 encoded.");
    callback(inputImgEl);
  } else {
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d", { willReadFrequently: true });
    const img = new Image();

    img.crossOrigin = "Anonymous";

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convert the canvas content to base64
      const base64Img = canvas.toDataURL("image/png");

      inputImgEl.src = base64Img;
      callback(inputImgEl);
    };

    img.src = inputImgSrc;
  }
};

export const convertImageToBase64 = (
  image: HTMLImageElement | HTMLCanvasElement
): string => {
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d", { willReadFrequently: true });

  // Set the canvas dimensions to match the image
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // Convert the canvas content to base64
  const base64String = canvas.toDataURL("image/jpeg");
  return base64String;
};
