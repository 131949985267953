import {
  ComponentTreeRenderer,
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperWithConditions,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  getValidationWithPermissions,
  groupComponentsByParent,
  processDisplayComponents,
} from "@amenda-utils";
import {
  isAddingNewMaterial,
  transformFormToMaterial,
  transformMaterialToForm,
} from "./common";
import {
  useCreateMaterial,
  useMaterialsStore,
  useSettingsStore,
  useUpdateMaterial,
} from "@amenda-domains/mutations";
import { useLocation, useParams } from "react-router-dom";

import { CubeIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { HelperMessage } from "@amenda-components/App";
import { PermissionComponentKey } from "@amenda-constants";
import { isEmpty } from "lodash";
import { processFormPermissions } from "@amenda-components/Shared/common";

interface Props {
  components: any[];
}

const MaterialsEmptyScreen: FC = () => {
  return (
    <HelperMessage
      Icon={CubeIcon}
      message="Restricted Form"
      helpText="You don't have permissions to view this form"
    />
  );
};

const UpsertMaterialsForm: FC<Props> = ({ components }) => {
  const { createMaterial } = useCreateMaterial();
  const { updateMaterial } = useUpdateMaterial();
  const setIsUpsertingMaterial = useMaterialsStore(
    (state) => state.setIsUpsertingMaterial,
  );
  const selectedMaterial = useMaterialsStore((state) => state.selectedMaterial);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const values = transformMaterialToForm(selectedMaterial);
  const validationSchema = getValidationWithPermissions(
    components,
    processFormPermissions(permissions),
  );
  const visibleComponents = processFormPermissions(permissions)(components);
  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );
  const defaultShareType =
    unitPriceGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];

  const onSubmit = async ({
    dirtyData,
    resourceId,
  }: FormAutoSaveSubmitProps) => {
    const input = transformFormToMaterial({
      defaultShareType,
      form: dirtyData,
      resourceId,
    });
    const handleMaterial = resourceId ? updateMaterial : createMaterial;
    if (isEmpty(dirtyData)) {
      return;
    }

    setIsUpsertingMaterial(true);
    await handleMaterial({
      input,
    });
    setIsUpsertingMaterial(false);
  };

  if (isEmpty(visibleComponents)) {
    return <MaterialsEmptyScreen />;
  }
  return (
    <FormAutoSaveWrapperWithConditions
      key={selectedMaterial?.id || "new_material"}
      className="py-0"
      inputSchema={validationSchema}
      resourceId={selectedMaterial?.id}
      components={components}
      values={values}
      globalProps={{
        hasMenuOverflow: true,
      }}
      onSubmit={onSubmit}
      processFormPermissions={processFormPermissions(permissions)}
    />
  );
};

const ReadOnlyMaterialsForm: FC<Props> = ({ components }) => {
  const selectedMaterial = useMaterialsStore((state) => state.selectedMaterial);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const data = transformMaterialToForm(selectedMaterial);
  const availableComponents = processDisplayComponents(data, components);
  const formComponentTree = groupComponentsByParent(
    availableComponents,
    processFormPermissions(permissions),
  );

  if (isEmpty(formComponentTree?.components)) {
    return <MaterialsEmptyScreen />;
  }
  return (
    <ComponentTreeRenderer
      config={formComponentTree}
      readOnly={true}
      globalProps={{
        data,
      }}
    />
  );
};

export const MaterialsForm: FC<Props> = ({ components }) => {
  const { pathname } = useLocation();
  const { materialId } = useParams<{ materialId: string }>();

  if (materialId || isAddingNewMaterial(pathname)) {
    return <UpsertMaterialsForm components={components} />;
  }
  return <ReadOnlyMaterialsForm components={components} />;
};
