import {
  Avatar,
  AvatarGroupWrapper,
  Chip,
  Skeleton,
} from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import { useGetKeywords, useGetUsersById } from "@amenda-domains/queries";

import { SingleCheckbox } from "@amenda-components/FormComponents";
import { getUserName } from "@amenda-components/Contacts/common";
import isEmpty from "lodash/isEmpty";

interface Props {
  collection: any;
  isSelected: boolean;
  handleSelect: () => void;
}

export const CollectionCard: FC<Props> = ({
  isSelected,
  collection,
  handleSelect,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getUsersById } = useGetUsersById();
  const { getKeywords, loading } = useGetKeywords();

  const slicedUsers = users.slice(0, 3);

  useEffect(() => {
    if (!isEmpty(collection?.keywords)) {
      getKeywords({
        ids: collection.keywords,
        setKeywords,
      });
    }
  }, [collection?.keywords, getKeywords]);

  useEffect(() => {
    const userIds = collection.shares?.[0]?.userIds || [];

    if (userIds) {
      getUsersById(userIds, setUsers);
    }
  }, [getUsersById, collection.shares]);

  return (
    <div className="flex flex-col w-full">
      <div className="border bg-white flex flex-col justify-center p-2">
        <div className="flex flex-row justify-between items-start">
          <div className="flex flex-row mt-px gap-2 shrink-0 items-start">
            <SingleCheckbox
              id="selectCollection"
              checked={isSelected}
              onChange={handleSelect}
            />
            <div className="text-sm font-apercu capitalize text-gray-800">
              {collection.name}
            </div>
          </div>
          <AvatarGroupWrapper>
            {slicedUsers.map((contact: any) => {
              return (
                <Avatar
                  key={contact.id}
                  src={contact?.photoURL}
                  name={getUserName(contact)}
                  className="border-2 border-white h-10 w-10 min-w-10 min-h-10"
                  iconClassName="h-9 w-9"
                />
              );
            })}
            {users.length > slicedUsers.length && (
              <Avatar
                placeHolder={`+${users.length - slicedUsers.length}`}
                className="border-2 border-white h-10 w-10 min-w-10 min-h-10"
                iconClassName="h-9 w-9"
              />
            )}
          </AvatarGroupWrapper>
        </div>
        <div className="w-full flex flex-wrap items-center mt-3">
          {loading ? (
            <Skeleton height={5} />
          ) : (
            <>
              {keywords.map((keyword) => (
                <Chip
                  key={keyword.id}
                  className="bg-gray-200 text-gray-600 lg:text-xs mr-1 mb-1"
                  label={keyword.name}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
