import { FC, useEffect } from "react";
import { useAttachmentStore, useFormStore } from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { AttachmentsPageWrapper } from "./AttachmentsPageWrapper";

export const AttachmentsCollectionPage: FC = () => {
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );

  useEffect(() => {
    return () => {
      clearSelectedAttachments();
      clearSelectedCollectionByType(AllowedCollectionType.Attachments);
    };
  }, [clearSelectedAttachments, clearSelectedCollectionByType]);

  return <AttachmentsPageWrapper isCollection={true} />;
};
