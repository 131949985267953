import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { FC, ReactNode, useRef } from "react";
import Select, {
  FormatOptionLabelMeta,
  StylesConfig,
  components,
} from "react-select";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";

import { ErrorMessage } from "./ErrorMessage";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export interface ReactSelectProps {
  options: any[];
  label?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  getOptionValue?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  formatGroupLabel?: (group: any) => any;
  formatOptionLabel?: (option: any, meta: FormatOptionLabelMeta<Option>) => any;
  styles?: Partial<StylesConfig<any, boolean, any>>;
  renderValueLabel?: (props: any) => ReactNode;
}

interface Props extends ReactSelectProps {
  id?: string;
  optional?: ReactNode;
  value?: Option[] | Option;
  isMulti?: boolean;
  className?: string;
  onChange: (value: any) => void;
  onBlur?: () => void;
}

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

interface MultiValueLabelProps {
  children: ReactNode;
  selectProps: any;
}

const MultiValueLabel: FC<MultiValueLabelProps> = (props: any) => {
  const {
    children,
    selectProps: { renderValueLabel },
  } = props;
  if (!renderValueLabel) {
    return <components.MultiValueLabel {...props} />;
  }
  return (
    <components.MultiValueLabel {...props}>
      <div className="flex items-center">
        {renderValueLabel(props)}
        <div>{children}</div>
      </div>
    </components.MultiValueLabel>
  );
};

export const ReactSelect: FC<Props> = ({
  label,
  error,
  optional,
  disabled,
  id = "",
  isMulti = false,
  isClearable = true,
  styles = {},
  className = "mb-2",
  placeholder = "Select",
  hasMenuOverflow = false,
  hideErrorMessage = false,
  renderValueLabel,
  ...rest
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className={clsx(className, "group/reactSelect")}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <Select
        renderValueLabel={renderValueLabel}
        className={customStyling.select.containerClass}
        placeholder={placeholder && t(placeholder)}
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable={isClearable}
        noOptionsMessage={() => t("No results found")}
        styles={{
          ...customStyling.select.styleOverride,
          ...selectMenuOverflow(hasMenuOverflow, ref),
          ...styles,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValueLabel,
        }}
        {...selectMenuOverflowProps(hasMenuOverflow)}
        {...rest}
      />
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
