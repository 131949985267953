import {
  ComponentTreeRenderer,
  FormAutoSaveResourceProps,
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperBase,
} from "@amenda-components/PageBuilder";
import { FC, memo } from "react";
import { FormComponentProps, NestedPageComponentProps } from "@amenda-types";

import { ObjectSchema } from "yup";

interface Props extends FormAutoSaveResourceProps {
  className?: string;
  inputSchema: ObjectSchema<any>;
  customFormComponents?: Record<string, FC<FormComponentProps>>;
  values: Record<string, any>;
  globalProps?: Record<string, any>;
  formComponentTree: NestedPageComponentProps;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
}

export const FormAutoSaveWrapper: FC<Props> = memo(
  ({
    formComponentTree,
    globalProps = {},
    customFormComponents = {},
    ...rest
  }) => {
    return (
      <FormAutoSaveWrapperBase {...rest}>
        {({ control, reset }) => (
          <ComponentTreeRenderer
            config={formComponentTree}
            customComponents={customFormComponents}
            globalProps={{
              ...globalProps,
              reset,
              control,
            }}
            readOnly={false}
          />
        )}
      </FormAutoSaveWrapperBase>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.formComponentTree) !==
      JSON.stringify(nextProps.formComponentTree)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.globalProps) !==
      JSON.stringify(nextProps.globalProps)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.values) !== JSON.stringify(nextProps.values)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.resourceIds) !==
      JSON.stringify(nextProps.resourceIds)
    ) {
      return false;
    } else if (prevProps.resourceId !== nextProps.resourceId) {
      return false;
    } else {
      return true;
    }
  },
);
