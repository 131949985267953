import { HoverSelector, Image, Tag, Tags } from "@amenda-components/App";
import {
  getGalleryCardKeywordIds,
  getKeywordsFromSidebarFilters,
} from "./common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { FC } from "react";
import { HoverVariants } from "@amenda-types";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import clsx from "clsx";
import { getComponentsFromForms } from "@amenda-utils";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useInView } from "react-intersection-observer";

interface Props {
  attachment: any;
}

const TagsAndName: FC<Props> = ({ attachment }) => {
  const { ref, inView } = useInView();
  const forms = useProjectStore((state) => state.forms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { sidebarFilters } = useSidebarFiltersWithPath();

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const { keywordIds, keywordComponentIds } = getGalleryCardKeywordIds({
    permissions,
    components,
    attachment,
  });
  const filterKeywords = getKeywordsFromSidebarFilters(
    sidebarFilters,
    keywordComponentIds,
  );

  return (
    <>
      <div ref={ref} />
      <div
        className={clsx("absolute inset-0 p-2 bg-black/15 font-apercu", {
          "hidden group-hover:block": isSearchFilterEmpty(sidebarFilters),
        })}
      >
        <div className="h-full w-full relative">
          {inView && (
            <ReadOnlyKeywordsBase keywords={keywordIds}>
              {(loading, selectedKeywords) => (
                <div className="w-full absolute bottom-0 left-0 overflow-hidden max-h-[50%]">
                  <div className="text-sm text-white truncate pb-2">
                    {attachment?.originalFilename ?? attachment?.filename}
                  </div>
                  <Tags className="gap-x-1 gap-y-0.5">
                    {selectedKeywords?.map((keyword) => {
                      const isSelected = filterKeywords.includes(keyword.id);

                      return (
                        <Tag
                          key={keyword?.id}
                          variant="secondary"
                          className="py-0.5 px-2"
                          isSelected={isSelected}
                        >
                          {keyword?.name}
                        </Tag>
                      );
                    })}
                  </Tags>
                </div>
              )}
            </ReadOnlyKeywordsBase>
          )}
        </div>
      </div>
    </>
  );
};

export const GalleryCard: FC<Props> = ({ attachment }) => {
  const selectedImageVariant = useAttachmentStore(
    (state) => state.selectedImageVariant,
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const setIsGalleryModalOpen = useAttachmentStore(
    (state) => state.setIsGalleryModalOpen,
  );
  const setShiftSelectedAttachment = useAttachmentStore(
    (state) => state.setShiftSelectedAttachment,
  );

  const handleClick = () => {
    setSelectedAttachment(attachment);
    setIsGalleryModalOpen(true);
  };

  const handleSelect = () => {
    toggleSelectedAttachment(attachment.id);
  };

  const handleShiftSelect = () => {
    setShiftSelectedAttachment(attachment);
  };

  return (
    <HoverSelector
      canSelect
      className="cursor-pointer"
      variant={HoverVariants.Checkbox}
      id={attachment?.id}
      isSelected={selectedAttachments.includes(attachment?.id)}
      handleSelect={handleSelect}
      handleClick={handleClick}
      handleShiftSelect={handleShiftSelect}
    >
      <div>
        <Image
          url={attachment.url}
          variant={selectedImageVariant}
          magicSize="gallery"
          background="bg-gray-100"
        >
          {() => <TagsAndName attachment={attachment} />}
        </Image>
      </div>
    </HoverSelector>
  );
};
