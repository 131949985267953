import {
  AvailableNotificationTypes,
  PaginationQueryProps,
} from "@amenda-types";
import {
  COST_GROUP_FRAGMENT,
  PROJECT_FRAGMENT,
  PROJECT_SUBSCRIPTION_FRAGMENT,
  SEARCH_PROJECT_FRAGMENT,
} from "@amenda-domains/fragments/projects";
import { PaginationLimit, SearchCollections } from "@amenda-constants";
import {
  devConsole,
  getSearchComponents,
  useHandleNotFound,
} from "@amenda-utils";
import { gql, useClient, useSubscription } from "urql";
import {
  useAppStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useCallback, useState } from "react";

import { REGIONAL_FACTOR_FRAGMENT } from "@amenda-domains/fragments/regionalFactors";
import { addMatchForCollection } from "@amenda-components/Shared/common";
import { getSelectableSearchComponents } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useProjectStore } from "@amenda-domains/mutations/projects";

const PROJECT_CREATED_SUBSCRIPTION = gql`
  ${PROJECT_SUBSCRIPTION_FRAGMENT}
  subscription ($ownerId: String!, $tenantId: String!) {
    projectCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...ProjectSubsFragment
    }
  }
`;

const PROJECT_UPDATED_SUBSCRIPTION = gql`
  ${PROJECT_SUBSCRIPTION_FRAGMENT}
  subscription ($ownerId: String!, $tenantId: String!) {
    projectUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...ProjectSubsFragment
    }
  }
`;

const PROJECT_DELETED_SUBSCRIPTION = gql`
  subscription ($ownerId: String!, $tenantId: String!) {
    projectDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  ${PROJECT_FRAGMENT}
  query GetAllProjects(
    $limit: Int
    $previous: String
    $next: String
    $selectProjectFields: Object
    $ids: [ID!]
    $isDeleted: Boolean
    $sort: Object
    $formValues: Object
  ) {
    getAllProjects(
      limit: $limit
      previous: $previous
      next: $next
      ids: $ids
      isDeleted: $isDeleted
      sort: $sort
      formValues: $formValues
      selectProjectFields: $selectProjectFields
    ) {
      docsCount
      hasNext
      hasPrevious
      previous
      next
      projects {
        ...ProjectFragment
      }
    }
  }
`;

export const GET_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  query GetProject($id: ID, $isDeleted: Boolean) {
    project: getProject(_id: $id, isDeleted: $isDeleted) {
      ...ProjectFragment
    }
  }
`;

const SEARCH_PROJECTS = gql`
  ${SEARCH_PROJECT_FRAGMENT}
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      projects {
        ...SearchProjectFragment
      }
    }
  }
`;

const GET_USER_PROJECTS = gql`
  ${PROJECT_FRAGMENT}
  query GetUserProjects(
    $isDeleted: Boolean
    $limit: Int
    $previous: String
    $next: String
    $selectProjectFields: Object
    $userId: ID
  ) {
    projectData: getUserProjects(
      isDeleted: $isDeleted
      limit: $limit
      previous: $previous
      next: $next
      selectProjectFields: $selectProjectFields
      userId: $userId
    ) {
      projects {
        ...ProjectFragment
      }
    }
  }
`;

export const useProjectCreatedSubscription = () => {
  const upsertOrFilterProject = useProjectStore(
    (state) => state.upsertOrFilterProject,
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: PROJECT_CREATED_SUBSCRIPTION,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    (messages, response) => {
      const project = response?.projectCreated;

      if (project) {
        upsertOrFilterProject(project);
      }
    },
  );
};

export const useProjectUpdatedSubscription = () => {
  const upsertOrFilterProject = useProjectStore(
    (state) => state.upsertOrFilterProject,
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: PROJECT_UPDATED_SUBSCRIPTION,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    (messages, response) => {
      const project = response?.projectUpdated;

      if (project) {
        upsertOrFilterProject(project);
      }
    },
  );
};

export const useProjectDeletedSubscription = () => {
  const upsertOrFilterProject = useProjectStore(
    (state) => state.upsertOrFilterProject,
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: PROJECT_DELETED_SUBSCRIPTION,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    (messages, response) => {
      const project = response?.projectDeleted;

      if (project) {
        upsertOrFilterProject(project);
      }
    },
  );
};

export const useGetProject = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const { handleNotFound } = useHandleNotFound();
  const setIsFetchingProject = useProjectStore(
    (state) => state.setIsFetchingProject,
  );

  const getProject = useCallback(
    async ({ context = {}, callback, ...variables }: Record<string, any>) => {
      setLoading(true);
      setIsFetchingProject(true);
      const response = await client.query(GET_PROJECT, variables, context);
      const data = response?.data;

      callback ? callback(data?.project) : setSelectedProject(data?.project);
      if (isEmpty(data?.project)) {
        handleNotFound();
      }
      setLoading(false);
      setIsFetchingProject(false);
    },
    [client, setSelectedProject, setIsFetchingProject, handleNotFound],
  );

  return {
    loading,
    getProject,
  };
};

interface SearchProjectsArgs {
  callback?: (projects: any[]) => void;
  filters?: Record<string, any>;
  isCollection?: boolean;
  resourceIds?: string[];
  searchTerm?: string;
  skipStorage?: boolean;
}

export const useSearchProjects = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const setProjects = useProjectStore((state) => state.setProjects);
  const components = useProjectStore((state) => state.projectFormComponents);

  const searchProjects = useCallback(
    async ({
      filters,
      isCollection,
      resourceIds,
      searchTerm,
      callback,
      skipStorage = false,
    }: SearchProjectsArgs) => {
      const searchableComponents = getSearchComponents(components);
      const values = getSelectableSearchComponents(
        searchableComponents,
        "formValues",
      );
      const searchAggregations = [
        {
          $search: {
            index: "autocomplete",
            compound: {
              should: [
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "name",
                  },
                },
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "number",
                  },
                },
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "address.name",
                  },
                },
              ],
            },
          },
        },
        {
          $addFields: {
            score: {
              $meta: "searchScore",
            },
          },
        },
        {
          $setWindowFields: {
            output: {
              maxScore: {
                $max: "$score",
              },
            },
          },
        },
        {
          $addFields: {
            normalizedScore: {
              $divide: ["$score", "$maxScore"],
            },
          },
        },
      ];
      let allAggregations: any[] = [];

      if (searchTerm) {
        allAggregations = searchAggregations;
      }

      let matchAggregations: any[] = [
        {
          $match: {
            isDeleted: false,
          },
        },
      ];
      if (!isEmpty(filters)) {
        matchAggregations = [
          {
            $match: {
              ...filters,
              isDeleted: false,
            },
          },
        ];
      }
      if (isCollection) {
        matchAggregations = [
          {
            $match: {
              ...filters,
              ...addMatchForCollection(resourceIds ?? []),
            },
          },
        ];
      }
      allAggregations = [
        ...allAggregations,
        ...matchAggregations,
        {
          $project: {
            name: 1,
            number: 1,
            galleryUrl: 1,
            "formValues.name": 1,
            "formValues.number": 1,
            "formValues.address": 1,
            "formValues.status": 1,
            "formValues.gross_floor_area": 1,
            "formValues.net_floor_area": 1,
            "formValues.property_size": 1,
            ...values,
          },
        },
      ];

      try {
        setLoading(true);
        const response = await client.query(
          SEARCH_PROJECTS,
          {
            collections: [SearchCollections.Projects],
            aggregations: {
              [SearchCollections.Projects]: allAggregations,
            },
          },
          {
            customKey: "searchProjects",
            requestPolicy: "network-only",
          },
        );

        const { data } = response;
        if (!skipStorage) {
          callback
            ? callback(data?.search?.projects)
            : setProjects(data?.search);
        }
        setLoading(false);
        return data?.search?.projects;
      } catch (err) {
        devConsole?.error("amenda:something went wrong", err);
        setLoading(false);
      }
    },
    [client, components, setProjects],
  );

  return {
    loading,
    searchProjects,
  };
};

export const useGetUserProjects = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const setContactProjects = useProjectStore(
    (state) => state.setContactProjects,
  );

  const getUserProjects = useCallback(
    async ({
      callback,
      context = {
        requestPolicy: "cache-and-network",
      },
      ...args
    }: {
      limit: number;
      isDeleted: boolean;
      userId: string;
      context?: Record<string, any>;
      callback?: (projects: any[]) => void;
    }) => {
      setLoading(true);

      const variables = {
        ...args,
        selectProjectFields: {
          _id: 1,
          name: 1,
          number: 1,
          galleryUrl: 1,
          tenantId: 1,
          address: 1,
          attachmentIds: 1,
          excludeFromCostEstimation: 1,
          formValues: {
            name: 1,
            number: 1,
            address: 1,
            status: 1,
            gross_floor_area: 1,
            net_floor_area: 1,
            property_size: 1,
          },
        },
      };

      const response = await client.query(
        GET_USER_PROJECTS,
        variables,
        context,
      );
      const { data } = response;
      const projects = data?.projectData?.projects;

      if (projects) {
        callback ? callback(projects) : setContactProjects(projects);
      }
      setLoading(false);
    },
    [client, setContactProjects],
  );

  return {
    loading,
    getUserProjects,
  };
};

export type GetAllProjectsArgs = {
  ids?: string[];
  isCollection?: boolean;
  isSimilaritySearch?: boolean;
  context?: Record<string, any>;
  formValues?: Record<string, any>;
  paginationProps?: PaginationQueryProps;
  selectProjectFields?: Record<string, any>;
  selectProjectFormValueFields?: Record<string, any>;
  callback?: (args: { projects: any[] }) => void;
};

const setLoader = ({
  value,
  isCollection = false,
  setIsFetching,
  setIsSearching,
}: {
  value: boolean;
  isCollection?: boolean;
  setIsFetching: (val: boolean) => void;
  setIsSearching: (val: boolean) => void;
}) => {
  if (!isCollection) {
    setIsFetching(value);
  } else {
    setIsSearching(value);
  }
};

export const useGetAllProjects = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const components = useProjectStore((state) => state.projectFormComponents);
  const setProjects = useProjectStore((state) => state.setProjects);
  const setIsFetching = useProjectStore((state) => state.setIsFetching);
  const setIsSearching = useProjectStore((state) => state.setIsSearching);

  const searchableComponents = getSearchComponents(components);
  const values = getSelectableSearchComponents(searchableComponents);
  const stringifiedValues = JSON.stringify(values);

  const getAllProjects = useCallback(
    async (args: GetAllProjectsArgs = {}) => {
      const {
        ids,
        isCollection,
        formValues,
        context = {},
        isSimilaritySearch = false,
        selectProjectFields = {
          _id: 1,
          name: 1,
          number: 1,
          galleryUrl: 1,
          tenantId: 1,
          address: 1,
          attachmentIds: 1,
          excludeFromCostEstimation: 1,
        },
        selectProjectFormValueFields = {},
        paginationProps = {
          limit: PaginationLimit.projects,
        },
        callback,
      } = args;

      setLoading(true);
      setLoader({
        isCollection,
        value: true,
        setIsFetching,
        setIsSearching,
      });

      const values = JSON.parse(stringifiedValues);
      const idProps = ids ? { ids } : {};
      const sortProps = isSimilaritySearch ? {} : { sort: { name: 1 } };
      const variables: any = {
        ...paginationProps,
        ...idProps,
        ...sortProps,
        isDeleted: false,
        selectProjectFields: {
          ...selectProjectFields,
          formValues: {
            name: 1,
            number: 1,
            address: 1,
            status: 1,
            gross_floor_area: 1,
            net_floor_area: 1,
            property_size: 1,
            ...values,
            ...selectProjectFormValueFields,
          },
        },
      };
      if (formValues) {
        variables.formValues = formValues;
      }

      const { data } = await client
        .query(GET_ALL_PROJECTS, variables, context)
        .toPromise();
      if (data?.getAllProjects) {
        callback
          ? callback(data.getAllProjects)
          : setProjects(data.getAllProjects);
      }
      setLoading(false);
      setLoader({
        isCollection,
        value: false,
        setIsFetching,
        setIsSearching,
      });
      return data?.getAllProjects?.projects;
    },
    [client, setProjects, setIsFetching, setIsSearching, stringifiedValues],
  );

  return {
    loading,
    getAllProjects,
  };
};

export const GET_ALL_COST_GROUPS = gql`
  ${COST_GROUP_FRAGMENT}
  query GetAllCostGroups(
    $bkiDate: DateTime
    $isDeleted: Boolean
    $limit: Int
    $next: String
    $previous: String
    $resourceId: String
    $type: String
    $versionDate: DateTime
  ) {
    getAllCostGroups(
      bkiDate: $bkiDate
      isDeleted: $isDeleted
      limit: $limit
      next: $next
      previous: $previous
      resourceId: $resourceId
      type: $type
      versionDate: $versionDate
    ) {
      next
      previous
      hasNext
      hasPrevious
      docsCount
      costGroups {
        ...CostGroupFragment
      }
    }
  }
`;

export const useGetAllCostGroups = () => {
  const setProjectCostGroups = useProjectStore(
    (state) => state.setProjectCostGroups,
  );
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);

  const getAllCostGroups = useCallback(
    async ({ context = {}, ...variables }: Record<string, any>) => {
      setLoading(true);

      const response = await client.query(
        GET_ALL_COST_GROUPS,
        variables,
        context,
      );
      const { data } = response;
      if (data?.getAllCostGroups) {
        setProjectCostGroups(data?.getAllCostGroups);
      }

      setLoading(false);
    },
    [client, setProjectCostGroups],
  );

  return {
    loading,
    getAllCostGroups,
  };
};

export const GET_NEIGHBOR_PROJECTS = gql`
  query GetNeighborProjects($args: ProjectNeighborArgs!) {
    getNeighborProjects(args: $args) {
      id: _id
      similarity
    }
  }
`;

export const useGetNeighborProjects = () => {
  const setSimilarityScores = useProjectStore(
    (state) => state.setSimilarityScores,
  );
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const clearProjects = useProjectStore((state) => state.clearProjects);
  const setOpenSimilaritySearch = useProjectStore(
    (state) => state.setOpenSimilaritySearch,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const setBottomSheetHeight = useAppStore(
    (state) => state.setBottomSheetHeight,
  );

  const getNeighborProjects = useCallback(
    async ({ context = {}, ...variables }: Record<string, any>) => {
      setLoading(true);

      const response = await client.query(
        GET_NEIGHBOR_PROJECTS,
        variables,
        context,
      );
      const { data } = response;

      if (data?.getNeighborProjects) {
        setSimilarityScores(data?.getNeighborProjects);
        clearProjects();
        setOpenSimilaritySearch(true);
        setBottomSheetHeight(120);
      } else {
        showNotification(
          AvailableNotificationTypes.Warning,
          "No projects were found matching these parameters. Can you try another set of parameters.",
        );
      }
      setLoading(false);
    },
    [
      client,
      clearProjects,
      setSimilarityScores,
      showNotification,
      setBottomSheetHeight,
      setOpenSimilaritySearch,
    ],
  );

  return {
    loading,
    getNeighborProjects,
  };
};

const GET_PROJECT_COST_ESTIMATE = gql`
  query GetProjectCostEstimate(
    $considerationCostGroups: [ConsiderationCostGroups!]
    $projects: [ProjectCostEstimateConfig!]!
    $considerationDate: DateTime
    $region: String!
  ) {
    getProjectCostEstimate(
      projects: $projects
      considerationCostGroups: $considerationCostGroups
      considerationDate: $considerationDate
      region: $region
    ) {
      costGroupKey
      costGroupCount
      costGroupTotal
      costGroupAverage
    }
  }
`;

const addToVisibleComponents = ({
  level,
  component,
  visibleComponents,
}: {
  level: number;
  component: any;
  visibleComponents: any[];
}) => {
  const index = visibleComponents.findIndex((c) => c.id === component.id);
  const firstHalf = visibleComponents.slice(0, index + 1);
  const secondHalf = visibleComponents.slice(index + 1);
  const components = (component?.components || []).map((c: any) => ({
    ...c,
    level,
  }));

  return [...firstHalf, ...components, ...secondHalf];
};

const useToggleCostGroupsOnDepth = () => {
  const setVisibleComponents = useAppStore(
    (state) => state.setVisibleComponents,
  );
  const setExpandedComponentGroup = useAppStore(
    (state) => state.setExpandedComponentGroup,
  );
  const costEstimateTab = useProjectStore((state) => state.costEstimateTab);

  const toggleCostGroupsOnDepth = useCallback(
    ({
      depth,
      selectedCostGroups,
      groupedCostGroupComponents,
    }: {
      depth: number;
      groupedCostGroupComponents: any[];
      selectedCostGroups: string[];
    }) => {
      if (costEstimateTab === "quantity") return;

      let trips = depth;
      let level = 1;
      let costGroupComponents: any[] = [];
      const updatedExpandedCostGroups: Record<string, boolean> = {};
      let updatedVisibleComponents: any[] = [...groupedCostGroupComponents];

      selectedCostGroups.forEach((costGroup) => {
        const index = groupedCostGroupComponents.findIndex(
          (c) => c.id === costGroup,
        );
        if (index > -1) {
          costGroupComponents.push(groupedCostGroupComponents[index]);
        }
      });

      while (trips > 1) {
        const currLevel = level;
        const childCostGroupComponents: any[] = [];
        let components = [...updatedVisibleComponents];

        costGroupComponents.forEach((component) => {
          if (component?.components) {
            updatedExpandedCostGroups[component.id] = true;
            components = addToVisibleComponents({
              component,
              level: currLevel,
              visibleComponents: components,
            });
            childCostGroupComponents.push(...component.components);
          }
        });

        costGroupComponents = childCostGroupComponents;
        updatedVisibleComponents = components;
        trips -= 1;
        level += 1;
      }

      setVisibleComponents(updatedVisibleComponents);
      setExpandedComponentGroup(updatedExpandedCostGroups);
    },
    [setVisibleComponents, setExpandedComponentGroup, costEstimateTab],
  );

  return { toggleCostGroupsOnDepth };
};

export const useGetProjectCostEstimate = () => {
  const client = useClient();
  const setIsFetchingCostEstimate = useProjectStore(
    (state) => state.setIsFetchingCostEstimate,
  );
  const setCostEstimateValues = useProjectStore(
    (state) => state.setCostEstimateValues,
  );
  const clearCostEstimateValues = useProjectStore(
    (state) => state.clearCostEstimateValues,
  );
  const { toggleCostGroupsOnDepth } = useToggleCostGroupsOnDepth();
  const showNotification = useAppStore((state) => state.showNotification);

  const getProjectCostEstimate = useCallback(
    async ({
      depth,
      estimationMode,
      componentByCode,
      componentsByCode,
      selectedCostGroups,
      considerationValues,
      inferredCalcCostGroups,
      costGroupQuantityComponents,
      groupedCostGroupComponents,
      ...variables
    }: Record<string, any>) => {
      clearCostEstimateValues();
      setIsFetchingCostEstimate(true);

      const response = await client.query(
        GET_PROJECT_COST_ESTIMATE,
        variables,
        {
          requestPolicy: "network-only",
        },
      );

      const { data, error } = response;
      if (data?.getProjectCostEstimate) {
        setCostEstimateValues({
          estimationMode,
          componentByCode,
          componentsByCode,
          considerationValues,
          inferredCalcCostGroups,
          costGroupQuantityComponents,
          totalProjects: variables.projects.length,
          costEstimateValues: data.getProjectCostEstimate,
        });
        toggleCostGroupsOnDepth({
          depth,
          selectedCostGroups,
          groupedCostGroupComponents,
        });
      }
      if (error) {
        showNotification(
          AvailableNotificationTypes.Warning,
          "No cost groups found for the projects provided and selected consideration groups, try different consideration groups",
        );
      }
      setIsFetchingCostEstimate(false);
    },
    [
      client,
      showNotification,
      setIsFetchingCostEstimate,
      setCostEstimateValues,
      clearCostEstimateValues,
      toggleCostGroupsOnDepth,
    ],
  );

  return {
    getProjectCostEstimate,
  };
};

const GET_PROJECT_COST_AGGREGATION = gql`
  query GetProjectCostAggregation(
    $resourceIds: [String!]
    $considerationCostGroup: Float
    $considerationDate: DateTime
    $referenceQuantity: String
    $type: String
  ) {
    getProjectCostAggregation(
      resourceIds: $resourceIds
      referenceQuantity: $referenceQuantity
      considerationCostGroup: $considerationCostGroup
      considerationDate: $considerationDate
      type: $type
    ) {
      averageCost
      minimumCost
      maximumCost
      sumCost
    }
  }
`;

const formatErrorMessages = (message = "") => {
  if (message.includes("No cost groups found for the provided project id")) {
    return "No cost groups found for the projects provided";
  }
  return message;
};

export const useGetProjectCostAggregation = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const showNotification = useAppStore((state) => state.showNotification);

  const getProjectCostAggregation = useCallback(
    async ({ callback, context = {}, ...variables }: Record<string, any>) => {
      setLoading(true);

      const response = await client.query(
        GET_PROJECT_COST_AGGREGATION,
        variables,
        context,
      );

      const { data, error } = response;
      if (data?.getProjectCostAggregation) {
        callback(data?.getProjectCostAggregation);
      }
      if (error?.message) {
        showNotification(
          AvailableNotificationTypes.Error,
          formatErrorMessages(error.message),
        );
      }
      setLoading(false);
    },
    [client, showNotification],
  );

  return {
    loading,
    getProjectCostAggregation,
  };
};

const GET_LATEST_BKI_DATE = gql`
  query GetLatestBKIDate {
    latestBKIDate: getLatestBKIDate
  }
`;

export const useGetLatestBKIDate = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setLatestBKIDate = useProjectStore((state) => state.setLatestBKIDate);

  const getLatestBKIDate = useCallback(async () => {
    setLoading(true);
    const { data } = await client.query(GET_LATEST_BKI_DATE, {}).toPromise();

    if (data?.latestBKIDate) {
      setLatestBKIDate(data.latestBKIDate);
    }
    setLoading(false);
  }, [client, setLatestBKIDate]);

  return {
    loading,
    getLatestBKIDate,
  };
};

/*
write a query in urql that looks like
getBKIValue(
building_type: String
date: DateTime!
): Float
*/
const GET_BKI_VALUE = gql`
  query GetBKIValue($building_type: String, $date: DateTime!) {
    bkiValue: getBKIValue(building_type: $building_type, date: $date)
  }
`;

export const useGetBKIValue = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setBKIValue = useProjectStore((state) => state.setBKIValue);

  const getBKIValue = useCallback(
    async ({ callback, ...variables }: Record<string, any>) => {
      setLoading(true);
      const { data } = await client
        .query(GET_BKI_VALUE, variables, {
          requestPolicy: "cache-and-network",
        })
        .toPromise();

      if (data?.bkiValue) {
        callback ? callback(data.bkiValue) : setBKIValue(data.bkiValue);
      }
      setLoading(false);
    },
    [client, setBKIValue],
  );

  return {
    loading,
    getBKIValue,
  };
};

const GET_ALL_REGIONAL_FACTORS = gql`
  ${REGIONAL_FACTOR_FRAGMENT}
  query GetAllRegionalFactors(
    $ids: [ID!]
    $limit: Int
    $city: String
    $sort: Object
    $next: String
    $previous: String
    $selectRegionalFactorFields: Object
  ) {
    getAllRegionalFactors(
      ids: $ids
      limit: $limit
      city: $city
      next: $next
      sort: $sort
      previous: $previous
      selectRegionalFactorFields: $selectRegionalFactorFields
    ) {
      next
      previous
      hasNext
      docsCount
      hasPrevious
      regionalFactors {
        ...RegionalFactorFragment
      }
    }
  }
`;

interface GetAllRegionalFactorsArgs extends PaginationQueryProps {
  ids?: string[];
  city?: string;
  callback?: (regions: any[]) => void;
  selectRegionalFactorFields?: any;
}

export const useGetAllRegionalFactors = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setRegionalFactors = useProjectStore(
    (state) => state.setRegionalFactors,
  );

  const getAllRegionalFactors = useCallback(
    async ({ callback, ...variables }: GetAllRegionalFactorsArgs) => {
      setLoading(true);
      const { data } = await client
        .query(GET_ALL_REGIONAL_FACTORS, {
          ...variables,
          sort: {
            city: 1,
          },
        })
        .toPromise();

      if (data?.getAllRegionalFactors) {
        callback
          ? callback(data.getAllRegionalFactors.regionalFactors)
          : setRegionalFactors(data.getAllRegionalFactors);
      }
      setLoading(false);
    },
    [client, setRegionalFactors],
  );

  return {
    loading,
    getAllRegionalFactors,
  };
};

const GET_ALL_PROJECTS_OPTIONAL = gql`
  query GetAllProjects(
    $limit: Int
    $previous: String
    $next: String
    $selectProjectFields: Object
    $ids: [ID!]
    $isDeleted: Boolean
    $sort: Object
    $formValues: Object
  ) {
    getAllProjects(
      limit: $limit
      previous: $previous
      next: $next
      ids: $ids
      isDeleted: $isDeleted
      sort: $sort
      formValues: $formValues
      selectProjectFields: $selectProjectFields
    ) {
      docsCount @_optional
      projects @_optional {
        id: _id
        name
        galleryUrl
        address {
          coordinates
          name
        }
      }
    }
  }
`;

interface GetAllProjectsOptionalArgs {
  ids?: string[];
  limit?: number;
  callback: (projects: any[]) => void;
}

export const useGetAllProjectsOptional = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);

  const getAllProjects = useCallback(
    async ({ callback, ...variables }: GetAllProjectsOptionalArgs) => {
      setLoading(true);

      const { data } = await client
        .query(GET_ALL_PROJECTS_OPTIONAL, {
          isDeleted: false,
          selectProjectFields: {
            _id: 1,
            name: 1,
            number: 1,
            galleryUrl: 1,
            address: 1,
          },
          ...variables,
        })
        .toPromise();
      if (data?.getAllProjects?.projects) {
        callback(data.getAllProjects.projects);
      }
      setLoading(false);
    },
    [client],
  );

  return {
    loading,
    getAllProjects,
  };
};

const GET_ALL_CONSTRUCTION_INDICES = gql`
  query GetAllConstructionIndices {
    getAllConstructionIndices {
      id: _id
      bauleistungen_am_bauwerk {
        february
        may
        august
        november
      }
      year
    }
  }
`;

export const useGetAllConstructionIndices = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const setConstructionPriceIndices = useProjectStore(
    (state) => state.setConstructionPriceIndices,
  );

  const getAllConstructionIndices = useCallback(async () => {
    setLoading(true);
    const { data } = await client
      .query(GET_ALL_CONSTRUCTION_INDICES, {})
      .toPromise();

    if (data?.getAllConstructionIndices) {
      setConstructionPriceIndices(data.getAllConstructionIndices);
    }
    setLoading(false);
  }, [client, setConstructionPriceIndices]);

  return {
    loading,
    getAllConstructionIndices,
  };
};

const SEARCH_REGIONAL_FACTORS = gql`
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    searchResults: search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      regional_factors {
        id: _id
        city
      }
    }
  }
`;

export const useSearchRegionalFactors = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const setRegionalFactors = useProjectStore(
    (state) => state.setRegionalFactors,
  );

  const searchRegionalFactors = useCallback(
    async (searchTerm: string, callback?: (regions: any[]) => void) => {
      const aggregations = [
        {
          $search: {
            index: "autocomplete",
            compound: {
              should: [
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "city",
                  },
                },
              ],
            },
          },
        },
        {
          $match: {
            isDeleted: false,
          },
        },
        {
          $addFields: {
            score: {
              $meta: "searchScore",
            },
          },
        },
        {
          $setWindowFields: {
            output: {
              maxScore: {
                $max: "$score",
              },
            },
          },
        },
        {
          $addFields: {
            normalizedScore: {
              $divide: ["$score", "$maxScore"],
            },
          },
        },
      ];

      try {
        setLoading(true);
        const response = await client.query(SEARCH_REGIONAL_FACTORS, {
          collections: [SearchCollections.RegionalFactors],
          aggregations: {
            [SearchCollections.RegionalFactors]: aggregations,
          },
        });

        const { data } = response;
        if (data?.searchResults) {
          const regions = data.searchResults.regional_factors;

          callback
            ? callback(regions)
            : setRegionalFactors({
                regionalFactors: regions,
              });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [client, setRegionalFactors],
  );

  return {
    loading,
    searchRegionalFactors,
  };
};

const GET_REGIONAL_FACTOR = gql`
  query getRegionalFactor($id: ID, $city: String, $factors: Object) {
    getRegionalFactor(_id: $id, city: $city, factors: $factors) {
      id: _id
      city
      factors
    }
  }
`;

interface GetRegionalFactorArgs {
  callback: (region: any) => void;
  id: string;
}

export const useGetRegionalFactor = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getRegionalFactor = useCallback(
    async ({ callback, ...variables }: GetRegionalFactorArgs) => {
      setLoading(true);
      const response = await client.query(GET_REGIONAL_FACTOR, variables);
      const data = response?.data;

      callback(data?.getRegionalFactor ?? {});
      setLoading(false);
    },
    [client],
  );

  return {
    loading,
    getRegionalFactor,
  };
};
