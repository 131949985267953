import { Navigate, useLocation } from "react-router-dom";

import { BCFDemo } from "./BCFDemo";
import { FC } from "react";
import { useRouteQuery } from "@amenda-utils";

enum DemoQueryParams {
  Forms = "forms",
  BCF = "bcf",
  Cost = "cost-parameters",
}

export const DemoComponents: FC = () => {
  const query = useRouteQuery();
  const showBCF = query.get(DemoQueryParams.BCF);

  return <div className="px-8 py-2">{showBCF && <BCFDemo />}</div>;
};

export const ShowDemoComponentsOrRedirect = () => {
  const isProductionEnv = process.env.NODE_ENV === "production";
  const query = useRouteQuery();
  const showBCF = query.get(DemoQueryParams.BCF);
  const { pathname } = useLocation();

  return (
    <>
      {isProductionEnv && !showBCF ? (
        <Navigate to={`${pathname}?${DemoQueryParams.BCF}=true`} />
      ) : (
        <DemoComponents />
      )}
    </>
  );
};
