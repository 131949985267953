import { FC, ReactNode, forwardRef } from "react";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const colors = {
  primary: {
    linkComponent: "text-blue-600 hover:text-blue-800",
  },
  secondary: {
    linkComponent: "text-gray-50 hover:bg-gray-100",
  },
  default: {
    linkComponent: "text-gray-700 hover:text-gray-800",
  },
  danger: {
    linkComponent: "text-red-600 hover:text-red-700",
  },
};

const sizes = {
  xs: "text-xs font-medium",
  sm: "text-sm font-medium leading-4",
  md: "text-sm font-medium",
  lg: "text-base font-medium",
  xl: "text-base font-medium",
};

interface LinkProps {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  path?: string;
  size?: "md" | "lg" | "sm" | "xs" | "xl";
  variant?: "primary" | "secondary" | "default" | "danger";
  onClick?: () => void;
}

export const Link: FC<LinkProps> = forwardRef<HTMLButtonElement, LinkProps>(
  (
    {
      children = "",
      className,
      size = "md",
      variant = "default",
      onClick,
      path,
      ...props
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const { linkComponent } = colors[variant];
    const linkSize = sizes[size];

    const handleClick = () => {
      if (path) {
        return navigate(path);
      }
      return onClick?.();
    };
    return (
      <button
        type="button"
        className={clsx(
          "font-apercu no-underline hover:underline focus:outline-none",
          className,
          clsx(linkSize, linkComponent),
          {
            "cursor-not-allowed": !!props.disabled,
          },
        )}
        onClick={handleClick}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  },
);
