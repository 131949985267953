import { FC, ReactNode } from "react";
import {
  useAttachmentCreatedSubscription,
  useAttachmentDeletedSubscription,
  useAttachmentUpdatedSubscription,
  useCollectionCreatedSubscription,
  useCollectionDeletedSubscription,
  useCollectionUpdatedSubscription,
  useContactCreatedSubscription,
  useContactDeletedSubscription,
  useContactUpdatedSubscription,
  useMaterialCreatedSubscription,
  useMaterialDeletedSubscription,
  useMaterialUpdatedSubscription,
  useProjectCreatedSubscription,
  useProjectDeletedSubscription,
  useProjectUpdatedSubscription,
  useTimelineActivityCreatedSubscription,
  useTimelineActivityDeletedSubscription,
  useTimelineActivityUpdatedSubscription,
  useUserCreatedSubscription,
  useUserDeletedSubscription,
  useUserUpdatedSubscription,
} from "@amenda-domains/queries";

interface Props {
  children: ReactNode;
}

// ensures subscriptions are mounted regardless of route
export const SubscriptionWrapper: FC<Props> = ({ children }) => {
  useUserCreatedSubscription();
  useUserUpdatedSubscription();
  useUserDeletedSubscription();
  useContactCreatedSubscription();
  useContactUpdatedSubscription();
  useContactDeletedSubscription();
  useProjectCreatedSubscription();
  useProjectUpdatedSubscription();
  useProjectDeletedSubscription();
  useMaterialCreatedSubscription();
  useMaterialUpdatedSubscription();
  useMaterialDeletedSubscription();
  useAttachmentCreatedSubscription();
  useAttachmentUpdatedSubscription();
  useAttachmentDeletedSubscription();
  useCollectionCreatedSubscription();
  useCollectionUpdatedSubscription();
  useCollectionDeletedSubscription();
  useTimelineActivityCreatedSubscription();
  useTimelineActivityUpdatedSubscription();
  useTimelineActivityDeletedSubscription();

  return <>{children}</>;
};
