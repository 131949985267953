import { FC, ReactNode, useEffect, useRef, useState } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";
import {
  useGetRegionalFactor,
  useSearchRegionalFactors,
} from "@amenda-domains/queries";

import AsyncSelect from "react-select/async";
import { ChevronDown } from "lucide-react";
import { DebounceTimes } from "@amenda-constants";
import { EmptyIndicator } from "./EmptyIndicator";
import { ErrorMessage } from "@amenda-components/FormComponents";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import clsx from "clsx";
import { components } from "react-select";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

interface ReadOnlyRegionalSelectBaseProps {
  regionId?: string;
  children: (region?: any) => ReactNode;
}

export const ReadOnlyRegionalSelectBase: FC<
  ReadOnlyRegionalSelectBaseProps
> = ({ children, regionId }) => {
  const { getRegionalFactor } = useGetRegionalFactor();
  const [selectedRegion, setSelectedRegion] = useState<Record<string, any>>();

  useEffect(() => {
    if (regionId) {
      getRegionalFactor({
        id: regionId,
        callback: setSelectedRegion,
      });
    }

    return () => {
      setSelectedRegion(undefined);
    };
  }, [regionId, getRegionalFactor]);

  return <>{children(selectedRegion)}</>;
};

interface Props {
  disabled?: boolean;
  error?: string;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  id?: string;
  label?: string;
  optional?: ReactNode;
  onChange?: (value: any) => void;
  regionId?: string;
  shouldReturnId?: boolean;
}

export const ReadOnlyRegionalSelect: FC<{
  label?: string;
  regionId?: string;
}> = ({ label, regionId }) => {
  return (
    <ReadOnlyRegionalSelectBase regionId={regionId}>
      {(region) => (
        <TitleAndDescription
          container="column"
          title={label}
          description={region?.city ?? <EmptyIndicator />}
        />
      )}
    </ReadOnlyRegionalSelectBase>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

export const RegionalSelect: FC<Props> = ({
  label,
  error,
  regionId,
  id = "regionId",
  disabled = false,
  hasMenuOverflow = true,
  hideErrorMessage,
  optional,
  onChange = () => {},
  shouldReturnId = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { getRegionalFactor, loading: isLoading } = useGetRegionalFactor();
  const { searchRegionalFactors, loading } = useSearchRegionalFactors();
  const [selectedRegion, setSelectedRegion] = useState<Record<string, any>>();

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string, resolve: (users: any[]) => void) => {
      await searchRegionalFactors(searchTerm, resolve);
    }, DebounceTimes.Search),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        await debouncedSearch(inputValue, resolve);
      }
    });

  useEffect(() => {
    if (regionId) {
      getRegionalFactor({
        id: regionId,
        callback: setSelectedRegion,
      });
    }
  }, [regionId, getRegionalFactor]);

  return (
    <div className="w-full group/regionalSelect" ref={ref}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <div className="w-full">
        <AsyncSelect
          id={id}
          cacheOptions
          isMulti={false}
          value={selectedRegion}
          onChange={(value: any) => {
            if (shouldReturnId) {
              onChange(value?.id);
            } else {
              onChange(value);
            }
          }}
          menuPlacement="auto"
          isClearable={false}
          isDisabled={disabled || isLoading}
          placeholder={t("Search regions")}
          loadingMessage={() => t("Loading") + "..."}
          className={customStyling.select.containerClass}
          isLoading={loading}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => option.city}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? t("No results found") : t("Start typing")
          }
          components={{
            DropdownIndicator,
          }}
          styles={{
            ...customStyling.select.styleOverride,
            ...selectMenuOverflow(hasMenuOverflow, ref),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          {...selectMenuOverflowProps(hasMenuOverflow)}
        />
      </div>
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
