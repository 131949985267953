import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NotFoundWidget } from "@amenda-components/App";

export const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("Not Found")} />
      <NotFoundWidget />
    </>
  );
};
