import { Link2, Unlink } from "lucide-react";

import { FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  message?: string;
  isOpen?: boolean;
  openFaceDetectionModal: () => void;
}

export const LabelDetectedFace: FC<Props> = ({
  id,
  isOpen,
  message,
  openFaceDetectionModal,
}) => {
  const { t } = useTranslation();

  return (
    <ReactTooltip
      id={id}
      clickable={true}
      positionStrategy="fixed"
      place="top-start"
      isOpen={isOpen}
      className="amenda-tooltip !p-0 !bg-white !opacity-100 !text-gray-800 !rounded-none hover:z-[60] hover:shadow-xl group-hover:z-[60] group-hover:shadow-xl"
    >
      <div className="w-full h-full flex items-center">
        <span className="font-bold px-2">{message || t("Add contact")}</span>
        <button
          type="button"
          className="bg-gray-800 text-white h-full px-2.5 py-1.5"
          onClick={openFaceDetectionModal}
        >
          {message ? (
            <Unlink className="w-5 h-5" />
          ) : (
            <Link2 className="w-5 h-5" />
          )}
        </button>
      </div>
    </ReactTooltip>
  );
};
