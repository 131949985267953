import * as Yup from "yup";

import {
  ComponentDisplayCondition,
  NestedPageComponentProps,
  PageComponentProps,
  SingleValidation,
  availableLayouts,
} from "@amenda-types";
import { FormComponentTypes, yupLocaleObject } from "@amenda-constants";
import { batchHandler, processConditionBase } from "./data";

import { isBoolean } from "lodash";

Yup.setLocale(yupLocaleObject);

const validationVariants: Record<string, any> = {
  any: Yup.mixed().nullable(),
  boolean: Yup.bool(),
  email: Yup.string().email(),
  object: Yup.object(),
  number: Yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  string: Yup.string(),
  array: Yup.array().ensure(),
  dateRange: Yup.object({
    to: Yup.string().label("To"),
    from: Yup.string().label("From"),
  }),
};

const processProperties = (properties: any, schema: any) => {
  Object.keys(properties).forEach((key) => {
    const args = properties[key];

    if (key === "required" && args === false) return;
    if (!!args && typeof schema?.[key] === "function") {
      schema = schema[key](isBoolean(args) ? undefined : args);
    }
  });
  return schema;
};

const getSingleFieldValidation = (validation: SingleValidation) => {
  const { type, properties } = validation;
  let validationVariant = validationVariants[type] || validationVariants.any;

  if (Array.isArray(properties)) {
    const conditional = properties[0] ?? {};

    validationVariant = validationVariant.when(conditional.when, {
      is: (value: any) => {
        return processConditionBase(
          conditional as ComponentDisplayCondition,
          value,
        );
      },
      then: (schema: any) => {
        const properties = conditional.then ?? {};

        schema = processProperties(properties, schema);
        return schema;
      },
      otherwise: (schema: any) => {
        const properties = conditional.otherwise ?? {};

        schema = processProperties(properties, schema);
        return schema;
      },
    });
  } else {
    validationVariant = processProperties(properties, validationVariant);
  }
  return validationVariant;
};

const generateSchema = (components: PageComponentProps[]) => {
  let schema: Record<string, any> = {};
  batchHandler(components, (component) => {
    if (component?.validation) {
      const validation = getSingleFieldValidation(component.validation);
      schema[component.id] = validation;
    }
  });
  return schema;
};

export const getValidationSchema = (components: PageComponentProps[]) => {
  const schema = generateSchema(components);
  const validationSchema = Yup.object(schema);
  return validationSchema as Yup.ObjectSchema<any>;
};

const removeNestedFormComponents = (components: PageComponentProps[]) => {
  const nestedComponentWrapperIds: string[] = [];

  components.forEach((c) => {
    if (c.layout === availableLayouts.nestedForm) {
      nestedComponentWrapperIds.push(c.id);
    }
  });

  let updatedComponents = components.filter((c) => {
    if (nestedComponentWrapperIds.includes(c.id)) {
      return false;
    } else if (c?.parentId && nestedComponentWrapperIds.includes(c.parentId)) {
      return false;
    }
    return true;
  });

  return updatedComponents;
};

export const getNestedFormValidation = (
  component?: NestedPageComponentProps,
) => {
  const schema = component?.components
    ? getValidationSchema(component.components)
    : Yup.object();

  return schema;
};

export const getValidationWithPermissions = (
  components: PageComponentProps[],
  processFormPermissions: (
    components: PageComponentProps[],
  ) => PageComponentProps[],
) => {
  let updatedComponents = processFormPermissions(components);
  updatedComponents = removeNestedFormComponents(updatedComponents);

  return getValidationSchema(updatedComponents);
};

export const getComponentsDefaultValues = (
  components: PageComponentProps[],
) => {
  let defaultValues: Record<string, any> = {};
  batchHandler(components, (component) => {
    if (component?.validation?.default) {
      defaultValues[component.id] = component.validation.default;
    }
  });
  return defaultValues;
};

export const getComponentsByIdAndType = (components: any[]) => {
  let componentsByIdAndType: Record<string, FormComponentTypes> = {};
  components.forEach((component) => {
    componentsByIdAndType = {
      ...componentsByIdAndType,
      [component.id]: component.component,
    };
  });

  return componentsByIdAndType;
};
