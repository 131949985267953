import {
  AllowedCollectionType,
  AvailableImageVariants,
  ReactTableKeys,
} from "@amenda-types";
import { ChangeEvent, FC, useCallback, useEffect } from "react";
import { Image, ProgressIndicator } from "@amenda-components/App";
import {
  getDefaultSelection,
  useTableRowsSelection,
} from "@amenda-components/Shared/reactTableHelpers";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { ColumnDef } from "@tanstack/react-table";
import { PaginationLimit } from "@amenda-constants";
import { ReactTable } from "@amenda-components/Shared";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { dynamicTableColumns } from "@amenda-components/PageBuilder/dynamicTableColumns";
import { getProjectArgs } from "./common";
import { isEmpty } from "lodash";
import { isRestricted } from "@amenda-utils";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  projects: any[];
  isCollection?: boolean;
  components: any[];
  componentsById: Record<string, any>;
}

const TableGallery: FC<{
  project: any;
}> = ({ project }) => {
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const selectedSimilarityScore = useProjectStore(
    (state) => state.selectedSimilarityScore,
  );
  const openSimilaritySearch = useProjectStore(
    (state) => state.openSimilaritySearch,
  );

  const projectSimilarityScore = similarityScoreById[project.id];
  const isMasked =
    openSimilaritySearch && projectSimilarityScore < selectedSimilarityScore;

  return (
    <div className="w-full">
      <Image
        magicSize="gallery"
        background="bg-transparent"
        isMasked={isMasked}
        url={project.galleryUrl}
        variant={AvailableImageVariants.cover}
      />
    </div>
  );
};

const TableProgressIndicator: FC<{ row: any }> = ({ row: project }) => {
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const selectedSimilarityScore = useProjectStore(
    (state) => state.selectedSimilarityScore,
  );
  const openSimilaritySearch = useProjectStore(
    (state) => state.openSimilaritySearch,
  );

  const projectSimilarityScore = similarityScoreById[project.id];

  return (
    <div>
      {openSimilaritySearch && (
        <ProgressIndicator
          showDefaultColor={projectSimilarityScore < selectedSimilarityScore}
          progress={projectSimilarityScore}
        />
      )}
    </div>
  );
};

const getStaticColumns = (
  componentsById: Record<string, any>,
  permissions: Record<string, any>,
) => {
  const projectName = componentsById["name"];
  const projectNumber = componentsById["number"];
  const projectAddress = componentsById["address"];

  const columns: ColumnDef<any>[] = [
    {
      id: "name",
      minSize: 200,
      header: "Name",
      meta: {
        label: "Name",
        showColumn: !isRestricted(projectName, permissions),
      },
      accessorFn: (row) => row?.name,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper>
            <span>{project?.name}</span>
          </TableCellWrapper>
        );
      },
    },
    {
      id: "number",
      minSize: 200,
      header: "Kürzel",
      meta: {
        label: "Kürzel",
        showColumn: !isRestricted(projectNumber, permissions),
      },
      accessorFn: (row) => row?.number,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper>
            <span>{project?.number}</span>
          </TableCellWrapper>
        );
      },
    },
    {
      id: "address",
      minSize: 300,
      header: "Anschrift",
      meta: {
        label: "Anschrift",
        showColumn: !isRestricted(projectAddress, permissions),
      },
      accessorFn: (row) => row?.address?.name,
      cell: ({ row }) => {
        const project = row?.original;

        return (
          <TableCellWrapper>
            <span>{project?.address?.name}</span>
          </TableCellWrapper>
        );
      },
    },
  ];

  return columns.filter((column) => Boolean((column?.meta as any)?.showColumn));
};

export const ProjectTableView: FC<Props> = ({
  projects,
  components,
  componentsById,
  isCollection,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );
  const pagination = useProjectStore((state) => state.pagination);
  const selectedProjects = useProjectStore((state) => state.selectedProjects);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const setSelectedProjects = useProjectStore(
    (state) => state.setSelectedProjects,
  );
  const toggleSelectedProjects = useProjectStore(
    (state) => state.toggleSelectedProjects,
  );
  const { onRowSelectionChange, onAllRowsSelectionChange } =
    useTableRowsSelection<{ id: string }>({
      addSelectedRows: setSelectedProjects,
      toggleSelectedRow: toggleSelectedProjects,
    });
  const { getAllProjects, loading } = useGetAllProjects();
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const setInitialTableColumnVisibility = useAppStore(
    (state) => state.setInitialTableColumnVisibility,
  );
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const visibleColumns = useAppStore(
    (state) => state.tableColumnVisibility[ReactTableKeys.Projects],
  );
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];

  const { defaultSelection } = getDefaultSelection(projects, selectedProjects);
  const isLoading = loading || isFetching || isSearching;
  const dynamicColumnIds = components
    .filter((component) => Boolean(component.component))
    .filter(
      (component) => !["name", "number", "address"].includes(component.id),
    )
    .map((component) => component.id);
  const dynamicColumns = dynamicTableColumns({
    permissions,
    componentsById,
    dynamicColumnIds,
    flattenData: (data = {}) => {
      return {
        ...data,
        ...(data?.formValues || {}),
      };
    },
  });
  const dynamicColumnsIds = dynamicColumns.map((column) => column.id);

  const handleClick = (project: any) => (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedProject(project);
    navigate(`/projekte/${project.id}`);
  };

  const fetchNextPage = useCallback(async () => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      pagination?.hasNext
    ) {
      await getAllProjects({
        ...getProjectArgs({
          isCollection,
          similarProjectIds,
          visibleColumns,
          collectionProjectIds: selectedCollection?.resourceIds,
        }),
        paginationProps: {
          next: pagination?.next,
          limit: PaginationLimit.projects,
        },
      });
    }
  }, [
    isCollection,
    similarProjectIds,
    visibleColumns,
    searchTerm,
    sidebarFilters,
    pagination?.next,
    pagination?.hasNext,
    selectedCollection?.resourceIds,
    getAllProjects,
  ]);

  useEffect(() => {
    setInitialTableColumnVisibility(
      ReactTableKeys.Projects,
      dynamicColumnsIds as string[],
    );
  }, [setInitialTableColumnVisibility, dynamicColumnsIds]);

  return (
    <ReactTable
      data={projects}
      isLoading={isLoading}
      showSelectorColumn
      isFullWidth
      isConfigurable
      canDownload
      maxEstimatedRowHeight={125}
      tableId={ReactTableKeys.Projects}
      containerClass="h-[calc(100vh-11.5rem)] w-full"
      defaultRowSelection={defaultSelection}
      TableRowActions={TableProgressIndicator}
      pagination={pagination}
      onRowClick={handleClick}
      onRowSelectionChange={onRowSelectionChange}
      onAllRowsSelectionChange={onAllRowsSelectionChange}
      fetchNextPage={fetchNextPage}
      columns={[
        {
          id: "gallery",
          minSize: 50,
          enableHiding: true,
          meta: {
            label: t("Gallery"),
          },
          cell: ({ row }) => {
            const project = row?.original;

            return <TableGallery project={project} />;
          },
        },
        ...getStaticColumns(componentsById, permissions),
        ...dynamicColumns,
      ]}
    />
  );
};
