import { FormTab, ProjectCostGroup } from "@amenda-types";
import {
  addReadOnlyProperty,
  processReadAndEditPermissions,
} from "@amenda-components/Shared/common";
import { devConsole, formatDate, sortByDates } from "@amenda-utils";

import { AllowedCostGroupTypes } from "@amenda-constants";
import { Option } from "@amenda-types";

export const CostGroupTypes = [
  AllowedCostGroupTypes.Rollover,
  AllowedCostGroupTypes.Frame,
  AllowedCostGroupTypes.Estimation,
  AllowedCostGroupTypes.Calculation,
  AllowedCostGroupTypes.Statement,
];

type CostGroupOption = {
  label: string;
  isGroup: boolean;
  costGroup?: any;
};

const processComponentPermissions = (
  component: any,
  permissions: Record<string, any>
) => {
  const { id, formId } = component;
  const formPermissions = permissions[formId] || {};
  const permission = formPermissions[id];

  if (!permission) return true;
  return processReadAndEditPermissions(permission);
};

export const processCostGroupPermissions =
  (permissions: Record<string, any>) => (components: any[]) => {
    return components
      .filter((c) => processComponentPermissions(c, permissions))
      .map((c) => addReadOnlyProperty(c, permissions));
  };

export const getOptionsFromCostGroups = (
  projectCostGroupsByType: Record<string, ProjectCostGroup[]>
) => {
  const options: CostGroupOption[] = [];

  Object.keys(projectCostGroupsByType).forEach((type) => {
    options.push({
      label: type,
      isGroup: true,
    });

    options.push(
      ...sortByDates(projectCostGroupsByType[type] || [], "versionDate").map(
        (costGroup) => ({
          costGroup,
          isGroup: false,
          label: formatDate(costGroup.versionDate),
        })
      )
    );
  });

  return options;
};

export const getSelectedCostGroup = (
  projectCostGroups: ProjectCostGroup[],
  selectedCostGroupId?: string
) => {
  let foundCostGroup;
  if (selectedCostGroupId) {
    foundCostGroup = projectCostGroups.find(
      (costGroup) => costGroup.id === selectedCostGroupId
    );
  }

  return foundCostGroup;
};

export const getTypeFromValue = (value = "") => {
  return value.split("-")[0];
};

export const transformCostGroupForm = (
  componentCodeById: Record<
    string,
    {
      code: string;
      referenceQuantity: string;
    }
  >,
  { cost, quantities }: Record<"cost" | "quantities", any>
) => {
  const data: any = {};

  Object.entries(cost || {}).forEach(([key, value]) => {
    if (Number.isFinite(value) || Number.isNaN(value)) {
      if (componentCodeById[key]) {
        data.values = [
          ...(data.values || []),
          {
            componentId: key,
            totalCost: value,
            key: componentCodeById[key].code,
          },
        ];
      } else {
        devConsole?.error("[amenda] componentCodeById is missing key", key);
      }
    }
  });

  Object.entries(quantities || {}).forEach(([key, value]) => {
    if (Number.isFinite(value) || Number.isNaN(value)) {
      data.quantities = {
        ...(data.quantities || {}),
        [key]: value,
      };
    }
  });

  return data;
};

export const transformCostGroupData = (data: Record<string, any> = {}) => {
  const { values, quantities } = data;
  const formValues: Record<"cost" | "quantities", any> = {
    cost: {},
    quantities: {},
  };

  Object.entries(quantities || {}).forEach(([key, value]) => {
    formValues.quantities[key] = value;
  });

  (values || []).forEach(({ componentId, totalCost }: any) => {
    formValues.cost[componentId] = totalCost;
  });

  return formValues;
};

export const getComponentCodeById = (components: any[]) => {
  const componentCodeById: Record<
    string,
    {
      code: string;
      referenceQuantity: string;
    }
  > = {};

  components.forEach((component) => {
    if (Number.isFinite(Number(component?.properties?.code))) {
      componentCodeById[component.id] = {
        code: component.properties.code,
        referenceQuantity: component.properties?.referenceQuantity,
      };
    }
  });

  return { componentCodeById };
};

export const getComponentByCode = (components: any[]) => {
  const componentByCode: Record<string, any> = {};

  components.forEach((component) => {
    if (!Number.isNaN(Number(component?.properties?.code))) {
      componentByCode[component.properties.code] = component;
    }
  });

  return { componentByCode };
};

export const getSelectCostGroupOptions = (
  projectCostGroupsByType: Record<string, ProjectCostGroup[]>
) => {
  const maximumCostGroupsPerType = 3;

  return CostGroupTypes.map((type) => {
    const availableCostGroups = projectCostGroupsByType[type] || [];
    return {
      label: type,
      value: type,
      unavailable: availableCostGroups.length >= maximumCostGroupsPerType,
    };
  });
};

export const getSubmitBtnOptions = (
  formId: string,
  disableDeleteBtn: boolean
) => {
  return [
    {
      value: "save",
      label: "Save",
      properties: {
        type: "submit",
        form: formId,
      },
    },
    {
      value: "delete",
      label: "Delete",
      properties: {
        disabled: disableDeleteBtn,
      },
    },
  ];
};

const sources = ["interne Ermittlung", "externe Ermittlung", "Angebot", "BKI"];

export const SourcesOptions = sources.map((source) => ({
  value: source,
  label: source,
}));

export const ServicePhaseOptions = [
  { value: "1", label: "LP 1" },
  { value: "2", label: "LP 2" },
  { value: "3", label: "LP 3" },
  { value: "4", label: "LP 4" },
  { value: "5", label: "LP 5" },
  { value: "6", label: "LP 6" },
  { value: "7", label: "LP 7" },
  { value: "8", label: "LP 8" },
  { value: "9", label: "LP 9" },
];

export enum CostGroupColumns {
  Quantity = "quantity",
  TotalAmount = "totalAmount",
  RowClear = "clearRow",
}

export const costGroupCostColumnHeaders = [
  {
    id: CostGroupColumns.TotalAmount,
    label: "Total Amount",
  },
  {
    id: CostGroupColumns.RowClear,
    label: "",
    className: "align-middle",
  },
];

export const costGroupQuantityColumnHeaders = [
  {
    id: CostGroupColumns.Quantity,
    label: "Quantity",
  },
  {
    id: CostGroupColumns.RowClear,
    label: "",
    className: "align-middle",
  },
];

export const getOptionsFromCostGroupsForms = (forms?: FormTab[]) => {
  const options: Option[] = [];
  const form = forms?.[0];

  if (form) {
    form.components.forEach(({ id, properties, validation }) => {
      if (validation) {
        options.push({
          value: id,
          label: properties.label,
        });
      }
    });
  }
  return options;
};
