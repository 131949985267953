import { FC, useState } from "react";
import ReactEasyCrop from "react-easy-crop";
import { getBlobFromCroppedImage } from "@amenda-utils";

export const handleCroppingImage = async ({
  src,
  croppedImagePixelInfo,
  retainOriginalScale,
}: {
  src: string;
  croppedImagePixelInfo: any;
  retainOriginalScale?: boolean;
}) => {
  const blob = await getBlobFromCroppedImage({
    src,
    croppedImagePixelInfo,
    retainOriginalScale,
  });
  let croppedImage;
  let croppedImageSrc;
  if (blob) {
    croppedImage = new File([blob], "image.jpeg", {
      type: blob.type,
    });
    croppedImageSrc = URL.createObjectURL(blob);
  }
  return {
    croppedImage,
    src: croppedImageSrc,
  };
};

interface CropImageProps {
  aspectRatio?: number;
  croppedShape?: "rect" | "round";
  crop?: { x: number; y: number };
  cropSize?: any;
  containerClassName?: string;
  cropAreaClassName?: string;
  disabled?: boolean;
  fitObject?: "contain" | "horizontal-cover" | "vertical-cover";
  mediaClassName?: string;
  minZoom?: number;
  onMediaLoaded?: (arg: {
    setCropSize: (arg: { width: number; height: number }) => void;
    setAspectRatio: (arg: number) => void;
    setCrop: (arg: { x: number; y: number }) => void;
    setZoom: (arg: number) => void;
  }) => void;
  restrictPosition?: boolean;
  src: string;
  onCropComplete: (arg: {
    croppedArea: any;
    croppedAreaPixels: any;
    zoom?: any;
    crop?: any;
  }) => void;
  onCropAreaChange?: (arg: {
    croppedArea: any;
    croppedAreaPixels: any;
  }) => void;
  showGrid?: boolean;
  style?: any;
}

export const CropImage: FC<CropImageProps> = ({
  aspectRatio: defaultAspectRatio = 4 / 3,
  containerClassName = "w-full",
  croppedShape = "rect",
  crop: defaultCrop = { x: 0, y: 0 },
  cropSize: defaultCropSize,
  disabled = false,
  fitObject = "contain",
  mediaClassName = "",
  minZoom,
  onMediaLoaded,
  restrictPosition = true,
  src,
  onCropComplete: handleCropComplete,
  onCropAreaChange: handleCropAreaChange,
  showGrid = false,
  style,
  ...rest
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState(defaultCrop);
  const [cropSize, setCropSize] = useState(defaultCropSize);
  const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);

  const onCropChange = (crop: any) => {
    if (!disabled) {
      setCrop(crop);
    }
  };
  const onZoomChange = (zoom: any) => {
    if (!disabled) {
      setZoom(zoom);
    }
  };
  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    if (!disabled) {
      handleCropComplete({ croppedArea, croppedAreaPixels, zoom, crop });
    }
  };
  const onCropAreaChange = (croppedArea: any, croppedAreaPixels: any) => {
    if (!disabled) {
      handleCropAreaChange?.({ croppedArea, croppedAreaPixels });
    }
  };
  return (
    <ReactEasyCrop
      aspect={aspectRatio}
      crop={crop}
      cropSize={cropSize}
      cropShape={croppedShape}
      classes={{
        mediaClassName,
        containerClassName,
        ...rest,
      }}
      image={src}
      minZoom={minZoom}
      onCropChange={onCropChange}
      onCropComplete={onCropComplete}
      onZoomChange={onZoomChange}
      onMediaLoaded={() =>
        onMediaLoaded?.({
          setCropSize,
          setAspectRatio,
          setCrop,
          setZoom,
        })
      }
      onCropAreaChange={onCropAreaChange}
      objectFit={fitObject}
      showGrid={showGrid}
      style={{
        cropAreaStyle: {
          border: 0,
          boxShadow: "none",
        },
      }}
      zoom={zoom}
      restrictPosition={restrictPosition}
    />
  );
};
