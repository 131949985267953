import { FC, Fragment, ReactNode } from "react";
import { useRouteQuery, useStoreToken } from "@amenda-utils";

interface Props {
  children: ReactNode;
}

export const PdfLayout: FC<Props> = ({ children }) => {
  const query = useRouteQuery();
  useStoreToken(query);
  return <Fragment>{children}</Fragment>;
};
