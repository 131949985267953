import { FC, useEffect, useState } from "react";

import { AvailableImageVariants } from "@amenda-types";
import { Image } from "@amenda-components/App";
import { Topic } from "@parametricos/bcf-js";
import { isEmpty } from "lodash";

interface Props {
  bcfTopic: Topic;
  size: string;
  magicSize: string;
  variant?: AvailableImageVariants;
}

export const BCFImage: FC<Props> = ({ bcfTopic, ...rest }) => {
  const [bcfSnapDataUrl, setBcfSnapDataUrl] = useState("");

  useEffect(() => {
    const getViewpoint = async () => {
      const viewPoint =
        !isEmpty(bcfTopic.markup?.viewpoints) &&
        bcfTopic.markup?.viewpoints?.[0];

      if (viewPoint) {
        const data = await bcfTopic.getViewpointSnapshot(viewPoint);
        data && setBcfSnapDataUrl(window.URL.createObjectURL(data));
      }
    };
    getViewpoint();
  }, [bcfTopic]);

  return (
    <Image
      lazyLoad={false}
      enableHighlighting={false}
      url={bcfSnapDataUrl}
      {...rest}
    />
  );
};
