import { DeleteModal, MaxWidthLayout } from "@amenda-components/Shared";
import { FC, useEffect, useState } from "react";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import { useLocation, useParams } from "react-router-dom";
import {
  useMaterialsStore,
  useUpdateMaterial,
} from "@amenda-domains/mutations";

import { GeneralAccessTypes } from "@amenda-constants";
import { LoaderWrapper } from "@amenda-components/App";
import { MaterialActionBar } from "./MaterialActionBar";
import { MaterialDetailsTabs } from "./MaterialDetailsTabs";
import MaterialPriceChart from "./MaterialPriceChart";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import { isAddingNewMaterial } from "./common";
import { useGetMaterial } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

//fetch prices from backend

const mockData = [
  { totalPrice: 120, creationDate: "2023-01-15T12:00:00.000Z" },
  { totalPrice: 75, creationDate: "2023-02-05T12:00:00.000Z" },
  { totalPrice: 180, creationDate: "2023-03-10T12:00:00.000Z" },
  { totalPrice: 95, creationDate: "2023-04-22T12:00:00.000Z" },
  { totalPrice: 130, creationDate: "2023-05-03T12:00:00.000Z" },
  { totalPrice: 55, creationDate: "2023-06-18T12:00:00.000Z" },
  { totalPrice: 160, creationDate: "2023-07-08T12:00:00.000Z" },
  { totalPrice: 70, creationDate: "2023-08-29T12:00:00.000Z" },
  { totalPrice: 105, creationDate: "2023-09-14T12:00:00.000Z" },
];

export const MaterialDetails: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { updateMaterial, loading } = useUpdateMaterial();
  const selectedMaterial = useMaterialsStore((state) => state.selectedMaterial);
  const { materialId } = useParams<{ materialId: string }>();
  const { getMaterial, loading: loadingGetMaterial } = useGetMaterial();
  const selectedMaterialId = useMaterialsStore(
    (state) => state.selectedMaterialId,
  );
  const [openShareModal, setOpenShareModal] = useState(false);

  const id = materialId || selectedMaterialId;

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseShare = () => setOpenShareModal(false);

  const handleDelete = async () => {
    await updateMaterial({
      input: {
        isDeleted: true,
        _id: selectedMaterial?.id,
      },
    });
    setOpenModal(false);
  };

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    const share = {
      type,
      users: users.map((u) => ({
        id: u._id,
        role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };

    await updateMaterial({
      input: {
        share,
        _id: selectedMaterial?.id,
      },
    });
    setOpenShareModal(false);
  };

  useEffect(() => {
    const getMaterialAsync = async () => {
      if (id) {
        await getMaterial({
          id,
          context: {
            requestPolicy: "cache-and-network",
          },
        });
      }
    };

    getMaterialAsync();
  }, [getMaterial, id]);

  if (loadingGetMaterial && !isAddingNewMaterial(pathname)) {
    return (
      <MaxWidthLayout className="overflow-y-auto">
        <LoaderWrapper />
      </MaxWidthLayout>
    );
  }
  return (
    <MaxWidthLayout
      wrapperClassName="h-full"
      className="h-full overflow-y-auto overscroll-y-contain"
    >
      <DeleteModal
        title="Delete material"
        description="Are you sure you want to delete this material?"
        loading={loading}
        openModal={openModal}
        handleDelete={handleDelete}
        handleCloseModal={handleCloseModal}
      />
      <ShareModal
        title="Share the unit prices with"
        loading={loading}
        openModal={openShareModal}
        ownerId={selectedMaterial?.ownerId}
        shareType={selectedMaterial?.share?.type}
        shareWith={selectedMaterial?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <div className="relative z-0 flex-1 focus:outline-none xl:order-last pb-6">
        {/* Hiding Chart for now by setting mockData.length to > 100 */}
        {!isAddingNewMaterial(pathname) && mockData.length > 100 && (
          <div className="w-full lg:h-[260px] h-[100px]">
            <MaterialPriceChart data={mockData} />
          </div>
        )}
        <div className="flex flex-col w-full pt-4 md:pt-8">
          <div className="flex flex-row justify-between items-center mx-auto w-full px-4 sm:px-6 lg:px-8 bg-white">
            <div className="flex flex-col">
              {isAddingNewMaterial(pathname) ? (
                <h1 className="text-xl lg:text-3xl font-bold text-gray-900">
                  {selectedMaterial?.name || t("New Material")}
                </h1>
              ) : (
                <h1 className="text-xl lg:text-3xl font-bold text-gray-900">
                  {selectedMaterial?.name}
                </h1>
              )}
            </div>
            <MaterialActionBar
              material={selectedMaterial}
              setOpenModal={setOpenModal}
              setOpenShareModal={setOpenShareModal}
            />
          </div>
        </div>
        {(isAddingNewMaterial(pathname) || !!selectedMaterial?.id) && (
          <MaterialDetailsTabs key={selectedMaterial?.id || "new_material"} />
        )}
      </div>
    </MaxWidthLayout>
  );
};
