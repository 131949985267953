import { Avatar, SingleFileUploader } from "@amenda-components/App";

import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { NestedPageComponentProps } from "@amenda-types";
import { useAttachmentStore } from "@amenda-domains/mutations";

export const UserProfileForm: FC<{
  control: any;
  currentUserInfo: any;
  formComponentTree: NestedPageComponentProps;
}> = ({ control, currentUserInfo, formComponentTree }) => {
  const setAvatarFile = useAttachmentStore((state) => state.setAvatarFile);

  return (
    <div className="divide-y divide-gray-200">
      <div className="pt-2">
        <div className="sm:col-span-2 sm:pb-2 pb-6">
          <SingleFileUploader
            isDisabled={Boolean(currentUserInfo?.photoURL)}
            onFileChange={setAvatarFile}
          >
            {({ file, getFileSrc, openFileUploader }) => (
              <button
                className="hover:opacity-60 transition-opacity duration-300 ease-in-out"
                type="button"
                onClick={openFileUploader}
              >
                <Avatar
                  className="h-16 w-16 min-w-16 min-h-16"
                  iconClassName="h-14 w-14"
                  src={getFileSrc(currentUserInfo?.photoURL)}
                  ignoreMagicLinks={Boolean(file?.name)}
                />
              </button>
            )}
          </SingleFileUploader>
        </div>
        <ComponentTreeRenderer
          config={formComponentTree}
          readOnly={false}
          globalProps={{
            control,
          }}
        />
      </div>
    </div>
  );
};
