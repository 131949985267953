import { Avatar, Button } from "@amenda-components/App";
import { FC, memo } from "react";
import {
  useAppStore,
  useCreateTimelineActivity,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedTimelineTypes } from "@amenda-types";
import { TimelineBody } from "./TimelineBody";
import { activityTimelineSchema } from "@amenda-constants";
import clsx from "clsx";
import { getActivityTimelineFormValues } from "./common";
import { sanitizeData } from "@amenda-utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  resourceId: string;
  type: AllowedTimelineTypes;
  textAreaClassName?: string;
}

export const PrimaryTimelineTextArea: FC<Props> = memo(
  ({ type, resourceId: timelineResourceId, textAreaClassName }) => {
    const { t } = useTranslation();
    const currentUser = useUsersStore((state) => state.currentUser);
    const { createTimelineActivity, loading } = useCreateTimelineActivity();
    const { control, handleSubmit, reset } = useForm({
      values: getActivityTimelineFormValues({}),
      resolver: yupResolver(activityTimelineSchema),
    });
    const clearSelectedActivityTimeline = useAppStore(
      (state) => state.clearSelectedActivityTimeline,
    );
    const {
      searchTerm,
      caretPosition,
      cursorPosition,
      showTimelineContactsSearch,
    } = useAppStore((state) => state.timelinePrimaryValues);
    const setTimelinePrimaryValues = useAppStore(
      (state) => state.setTimelinePrimaryValues,
    );

    const setTimelineContactsSearch = (show: boolean) =>
      setTimelinePrimaryValues("showTimelineContactsSearch", show);
    const setCursorPosition = (position: number) =>
      setTimelinePrimaryValues("cursorPosition", position);
    const setCaretPosition = (position: { top: number; left: number }) =>
      setTimelinePrimaryValues("caretPosition", position);
    const setSearchTerm = (searchTerm: string) =>
      setTimelinePrimaryValues("searchTerm", searchTerm);

    const onSubmit = async (data: any) => {
      let input = sanitizeData({
        ...data,
        type,
        resourceId: timelineResourceId,
        createdById: currentUser?.id,
        date: new Date().toISOString(),
      });

      await createTimelineActivity({ input });
      clearSelectedActivityTimeline();
      reset(getActivityTimelineFormValues({}));
    };

    return (
      <div className="flex flex-col gap-px w-full items-start">
        <div className="flex md:flex-row flex-col mr-0 gap-3 w-full">
          <div className="mt-1">
            <Avatar
              src={currentUser?.photoURL}
              className="w-10 h-10 min-w-10 min-h-10"
              iconClassName="h-9 w-9"
            />
          </div>
          <div className="flex flex-col gap-1 grow">
            <div
              className={clsx("flex items-center justify-between", {
                "space-x-2": !textAreaClassName,
              })}
            />
            <form
              className="flex flex-wrap items-start"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TimelineBody
                control={control}
                textAreaClassName={textAreaClassName}
                searchTerm={searchTerm}
                caretPosition={caretPosition}
                cursorPosition={cursorPosition}
                showTimelineContactsSearch={showTimelineContactsSearch}
                setTimelineContactsSearch={setTimelineContactsSearch}
                setCursorPosition={setCursorPosition}
                setCaretPosition={setCaretPosition}
                setSearchTerm={setSearchTerm}
              />
              <Button variant="primary" type="submit" loading={loading}>
                {t("Submit")}
              </Button>
            </form>
          </div>
        </div>
        <div className="border-l border-dashed border-gray-300 ml-5 w-px h-6" />
      </div>
    );
  },
);
