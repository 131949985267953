import { DetectRouteChange } from "@amenda-components/App";
import { FC } from "react";

export const DetectFormNavigation: FC<{
  isFormDirty: boolean;
  message?: string;
}> = ({
  isFormDirty,
  message = "Leaving this page will reset the form. Are you sure you want to continue?",
}) => {
  return <DetectRouteChange blockChange={isFormDirty} message={message} />;
};
