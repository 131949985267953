import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";

interface Props {
  children: ReactNode;
}

export const MaterialsLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet title="einheitspreise" />
      <div className="relative flex h-full">{children}</div>
    </>
  );
};
