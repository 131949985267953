import { CreateOrEditMaterialPercentageValues } from "./CreateOrEditMaterialPercentageValues";
import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { MaterialReadOnlyPercentageValues } from "./MaterialReadOnlyPercentageValues";

export const MaterialPercentageValues: FC<FormComponentProps> = ({
  config,
  global,
  readOnly,
}) => {
  const { id, properties } = config;
  const { control, data } = global || {};
  const isReadOnly = readOnly || properties?.readOnly;

  if (control && !isReadOnly) {
    return <CreateOrEditMaterialPercentageValues id={id} control={control} />;
  }
  return <MaterialReadOnlyPercentageValues values={data?.[id]} />;
};
