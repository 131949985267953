import { AllowedContactType, Option } from "@amenda-types";
import { Avatar, HelperMessage, LoaderWrapper } from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import { MailIcon, PhoneIcon } from "lucide-react";

import { flattenUserDetails } from "./common";
import { getMailOrPhoneRef } from "@amenda-components/FormComponents/common";
import { isEmpty } from "lodash";
import { useGetAllContacts } from "@amenda-domains/queries";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  values?: string | Option[];
  isEmail?: boolean;
}

const iconClassName = "h-5 w-5 text-gray-800";

const DisplayPhoneOrEmail: FC<Props> = ({ isEmail = false, values }) => {
  const value = Array.isArray(values) ? values[0]?.value : values;

  if (!value) return null;
  return (
    <div className="flex items-center space-x-2">
      {isEmail ? (
        <MailIcon className={iconClassName} />
      ) : (
        <PhoneIcon className={iconClassName} />
      )}

      <a
        className="amenda-contact-subline hover:text-blue-600 truncate"
        href={getMailOrPhoneRef(isEmail ? "email" : "phone", value)}
      >
        {value}
      </a>
    </div>
  );
};

export const LinkedPersonsCard: FC<{ companyId: any }> = ({ companyId }) => {
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);
  const [companyContacts, setCompanyContacts] = useState<any[]>([]);
  const { getAllContacts, loading } = useGetAllContacts();

  const handleSelectUser = (user: any) => () => {
    setSelectedUserId(user);
  };

  useEffect(() => {
    if (companyId) {
      getAllContacts({
        isDeleted: false,
        type: AllowedContactType.person,
        contactDetails: {
          company: companyId,
        },
        callback: ({ users = [] }: any) => {
          setCompanyContacts(users);
        },
      });
    }
  }, [getAllContacts, companyId]);

  if (loading) {
    return <LoaderWrapper className="h-80" />;
  } else if (isEmpty(companyContacts)) {
    return <HelperMessage message="Keine verknüpften Personen vorhanden." />;
  }
  return (
    <div className="mx-auto mt-8 ">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-2">
        {companyContacts.map((c) => {
          const contact = flattenUserDetails(c);

          return (
            <div
              onClick={handleSelectUser(contact)}
              key={contact.id}
              className="flex cursor-pointer bg-white border border-gray-300 px-4 py-5 space-x-2 hover:bg-gray-50"
            >
              <div className="px-2">
                <Avatar
                  className="h-12 w-12 text-base"
                  name={`${contact?.firstName} ${contact?.lastName}`}
                />
              </div>
              <div className="overflow-hidden">
                <p className="text-base font-medium text-gray-900">
                  {`${contact.firstName ?? ""} ${contact.lastName ?? ""}`}
                </p>
                <DisplayPhoneOrEmail isEmail values={contact?.email} />
                <DisplayPhoneOrEmail values={contact?.phone} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
