import { AllowedCategories, AvailableNotificationTypes } from "@amenda-types";
import { FC, useEffect, useState } from "react";
import {
  useAppStore,
  useAttachmentStore,
  useGenerateAttachmentUrl,
  useSingleFileUpload,
  useTenantStore,
  useUpdateTenant,
} from "@amenda-domains/mutations";

import { AdminSettingsLayout } from "@amenda-components/Settings";
import { CenteredLayout } from "@amenda-components/Shared";
import { TenantFormWrapper } from "@amenda-components/Tenant";
import { isEmpty } from "lodash";
import { useGetCurrentTenant } from "@amenda-domains/queries";

export const Tenant: FC = () => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const { getCurrentTenant } = useGetCurrentTenant();
  const { singleFileUpload } = useSingleFileUpload();
  const { updateTenant } = useUpdateTenant();
  const [loading, setLoading] = useState(false);
  const showNotification = useAppStore((state) => state.showNotification);
  const selectedImage = useAttachmentStore((state) => state.selectedImageFile);

  const handleUpdateTenant = async (input: any) => {
    await updateTenant({
      input,
    });
  };

  const { generateAttachmentUrl } = useGenerateAttachmentUrl();

  const uploadLogo = async (file: any, displayName: string) => {
    await singleFileUpload({
      file,
      category: AllowedCategories.Company,
    }).then((data) => {
      if (data.file) {
        const { filename, bucketname, mimetype } = data.file;
        generateAttachmentUrl({
          input: {
            bucketname,
            filename,
            type: mimetype?.includes("svg") ? "svg" : "image",
          },
          callback: (logoUrl) => handleUpdateTenant({ logoUrl, displayName }),
        });
      }
    });
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    await handleUpdateTenant(data);
    if (!isEmpty(selectedImage?.name)) {
      await uploadLogo(selectedImage, data.displayName);
    }
    await showNotification(
      AvailableNotificationTypes.Success,
      "Company details updated",
    );
    setLoading(false);
  };

  useEffect(() => {
    getCurrentTenant({
      requestPolicy: "cache-and-network",
    });
  }, [getCurrentTenant]);

  return (
    <AdminSettingsLayout>
      <CenteredLayout>
        <TenantFormWrapper
          tenant={primaryTenant}
          loading={loading}
          onFormSubmit={handleSubmit}
        />
      </CenteredLayout>
    </AdminSettingsLayout>
  );
};
