import {
  useGetAllContacts,
  useGetContact,
  useSearchContacts,
} from "@amenda-domains/queries";

import { AllowedCollectionType } from "@amenda-types";
import { AppRoutes } from "@amenda-types";
import { BlockedRoutes } from "@amenda-constants";
import { ContactDirectoryWrapper } from "./ContactDirectoryWrapper";
import { useEffect } from "react";
import { useFormStore } from "@amenda-domains/mutations";

export const ContactsCollectionPage = () => {
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const { getAllContacts } = useGetAllContacts();
  const { searchContacts } = useSearchContacts();
  const { getContact } = useGetContact();

  const collectionType = AllowedCollectionType.Contacts;
  const selectedCollection = selectedCollectionByType[collectionType];

  useEffect(() => {
    return () => {
      clearSelectedCollectionByType(collectionType);
    };
  }, [clearSelectedCollectionByType, collectionType]);

  return (
    <ContactDirectoryWrapper
      autoSelect={true}
      isCollection={true}
      canFilterByType={true}
      canDeleteUsers={true}
      collectionType={collectionType}
      collectionResourceIds={selectedCollection.resourceIds}
      singularTitle="Contact"
      pluralTitle="Contacts"
      rootRoute={AppRoutes.Contacts}
      collectionRoute={BlockedRoutes.ContactsCollection}
      getContact={getContact}
      getAllContacts={getAllContacts}
      searchContacts={searchContacts}
    />
  );
};
