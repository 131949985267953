import { FC, ReactNode, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  useImageRecognitionStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { DetectedFaceModal } from "./DetectedFaceModal";
import { GeneralPermissionTypes } from "@amenda-types";
import { LabelDetectedFace } from "./LabelDetectedFace";
import { Sparkle } from "@amenda-components/App";
import { isEmpty } from "lodash";

interface Props {
  imageDimensions?: any;
  isLoading: boolean;
  detectedFaces: any[];
  children?: ReactNode;
}

interface FaceImgProps {
  id: string;
  face: any;
  imageDimensions?: any;
}

const WrapperContainer: FC<Pick<Props, "imageDimensions" | "children">> = ({
  children,
  imageDimensions,
}) => {
  return (
    <div className="absolute z-20 w-full h-full flex justify-center items-center">
      <div
        style={{
          position: "absolute",
          width: imageDimensions?.width || "auto",
          height: imageDimensions?.height || "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

const FaceImg: FC<FaceImgProps> = ({ id, face, imageDimensions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const setDetectedFaceModal = useImageRecognitionStore(
    (state) => state.setDetectedFaceModal,
  );

  const {
    label,
    sourceURL,
    dimensions: { height, width, left, top },
  } = face;

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  const openFaceDetectionModal = () => {
    setDetectedFaceModal(true, face);
  };

  return (
    <div className="absolute group">
      <img
        src={sourceURL}
        alt={label}
        style={{
          width,
          height,
          left,
          top,
          position: "absolute",
        }}
      />
      <LabelDetectedFace
        id={id}
        message={label}
        isOpen={isOpen}
        openFaceDetectionModal={openFaceDetectionModal}
      />
      <div
        data-tooltip-id={id}
        style={{
          position: "absolute",
          top: Math.max(top - 10, 0),
          left: Math.max(left - 10, 0),
          width: imageDimensions?.width
            ? Math.min(width + 20, imageDimensions?.width)
            : width + 20,
          height: imageDimensions?.height
            ? Math.min(height + 20, imageDimensions?.height)
            : height + 20,
        }}
        onClick={toggleTooltip}
        className="border z-10 border-dashed border-white rounded-3xl bg-white/25 cursor-pointer"
      />
    </div>
  );
};

export const DetectedFaces: FC<Props> = ({
  isLoading,
  detectedFaces,
  imageDimensions,
}) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const allowFaceDetection =
    mediaGeneralPermissions[GeneralPermissionTypes.CanDetectFaces];

  if (isLoading) {
    return (
      <WrapperContainer imageDimensions={imageDimensions}>
        <Sparkle totalElements={100} />
      </WrapperContainer>
    );
  }
  if (isEmpty(detectedFaces) || !allowFaceDetection) {
    return null;
  }
  return (
    <WrapperContainer imageDimensions={imageDimensions}>
      <DetectedFaceModal />
      <div className="relative w-full h-full">
        {detectedFaces.map((face: any, i: number) => {
          const key = `face-${i}-${face.id}`;

          return (
            <FaceImg
              key={key}
              id={key}
              face={face}
              imageDimensions={imageDimensions}
            />
          );
        })}
      </div>
    </WrapperContainer>
  );
};
