import { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
  error?: string;
  className?: string;
}

export const ErrorMessage: FC<Props> = ({
  id,
  error,
  className = "text-xs",
}) => {
  const { t } = useTranslation();

  const errorId = id?.concat("-error");

  return (
    <p
      className={clsx("font-apercu pt-1", className, {
        "text-transparent": !error,
        "text-red-600": !!error,
      })}
      id={errorId}
    >
      {error ? t(error) : ""}
    </p>
  );
};
