import { ArrowLongLeftIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { Link } from ".";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  path: string;
}

export const BackButton: FC<Props> = ({ label, path }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => navigate(path);

  return (
    <Link className="flex items-center" onClick={handleClick}>
      <ArrowLongLeftIcon className="mr-2 h-6 w-8" />
      {t(label)}
    </Link>
  );
};
