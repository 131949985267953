import { AllowedCollectionType, ReactTableKeys } from "@amenda-types";
import { FC, useEffect } from "react";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { LoaderWrapper } from "@amenda-components/App";
import { PaginationLimit } from "@amenda-constants";
import { ProjectCard } from "./ProjectCard";
import clsx from "clsx";
import { getProjectArgs } from "./common";
import isEmpty from "lodash/isEmpty";
import { isSafeCollection } from "@amenda-utils";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useInView } from "react-intersection-observer";

interface Props {
  componentsById: Record<string, any>;
  projects: any[];
  isCollection?: boolean;
}

export const ProjectGridView: FC<Props> = ({
  isCollection,
  projects,
  componentsById,
}) => {
  const { ref, inView } = useInView();
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const { getAllProjects } = useGetAllProjects();
  const pagination = useProjectStore((state) => state.pagination);
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const visibleColumns = useAppStore(
    (state) => state.tableColumnVisibility[ReactTableKeys.Projects],
  );
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];

  useEffect(() => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      inView &&
      pagination?.hasNext &&
      isSafeCollection(isCollection, selectedCollection?.resourceIds)
    ) {
      getAllProjects({
        ...getProjectArgs({
          isCollection,
          similarProjectIds,
          visibleColumns,
          collectionProjectIds: selectedCollection?.resourceIds,
        }),
        paginationProps: {
          next: pagination?.next,
          limit: PaginationLimit.projects,
        },
      });
    }
  }, [
    inView,
    searchTerm,
    sidebarFilters,
    isCollection,
    similarProjectIds,
    visibleColumns,
    pagination?.next,
    pagination?.hasNext,
    selectedCollection?.resourceIds,
    getAllProjects,
  ]);

  return (
    <div>
      {isSearching ? (
        <LoaderWrapper />
      ) : (
        <div
          className={clsx(
            "grid grid-cols gap-2 md:gap-2 lg:gap-2 xxl:gap-8 transition-all duration-500",
            {
              "grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5":
                !sidebarOpen,
              "grid-cols-2 md:grid-cols-2 lg:grid-cols-4": sidebarOpen,
            },
          )}
        >
          {projects.map((project) => {
            const projectName = componentsById["name"];
            const projectNumber = componentsById["number"];
            const projectAddress = componentsById["address"];

            return (
              <ProjectCard
                key={project.id}
                project={project}
                projectName={projectName}
                projectNumber={projectNumber}
                projectAddress={projectAddress}
              />
            );
          })}
        </div>
      )}
      {isFetching && <LoaderWrapper className="h-44" />}
      <div ref={ref} />
    </div>
  );
};
