import { gql } from "urql";

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    id: _id
    name
    filename
    url
    previewUrl
    isDeleted
    type
    bucketname
    extractedImages
    formValues
    metadata
    originalFilename
    ownerId
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const SUBSCRIPTION_ATTACHMENT_FRAGMENT = gql`
  fragment SubscriptionAttachmentFragment on AttachmentSubscription {
    id: _id
    name
    filename
    url
    previewUrl
    isDeleted
    type
    bucketname
    extractedImages
    createdAt
    metadata
    description
    createdAt
    formValues
    originalFilename
    ownerId
    share {
      type
      users {
        id
        role
      }
    }
  }
`;
