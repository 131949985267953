import { FC, useEffect } from "react";
import { useAttachmentStore, useFormStore } from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { ConstructionDetailsPageWrapper } from "./ConstructionDetailsPageWrapper";

export const ConstructionDetailsCollectionPage: FC = () => {
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );

  useEffect(() => {
    return () => {
      clearSelectedAttachments();
      clearSelectedCollectionByType(AllowedCollectionType.ConstructionDetails);
    };
  }, [clearSelectedAttachments, clearSelectedCollectionByType]);

  return (
    <ConstructionDetailsPageWrapper
      isCollection={true}
      collectionType={AllowedCollectionType.ConstructionDetails}
    />
  );
};
