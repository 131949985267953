import { formatDate, formatNumbers, isRestricted } from "@amenda-utils";
import { isArray, isNil, isString } from "lodash";

import { Avatar } from "@amenda-components/App";
import { ColumnDef } from "@tanstack/react-table";
import { FormComponentTypes } from "@amenda-constants";
import { LabelledInputTableCell } from "@amenda-components/FormComponents";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import { ReadOnlyRegionalSelectBase } from "@amenda-components/Shared";
import { ReadOnlySearchAndSelectBase } from "@amenda-components/Shared/ReadOnlySearchAndSelect";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { getUserName } from "@amenda-components/Contacts/common";

const getTableCellValue = (component: any, value: any) => {
  const options: any[] = component?.properties?.options || [];
  const contactType = component?.properties?.contactType;
  let displayedValue: any;

  switch (component?.component) {
    case FormComponentTypes.DatePicker:
      if (!value) return <span />;
      return <span>{formatDate(value)}</span>;
    case FormComponentTypes.DatePickerRange:
      if (!value) return <span />;
      return (
        <span>
          {[value?.to, value?.from]
            ?.filter(Boolean)
            ?.map((date) => formatDate(date))
            .join(" - ")}
        </span>
      );
    case FormComponentTypes.Select:
      if (!value) return <span />;
      displayedValue =
        options.find((option) => option.value === value)?.label || value;

      return <span>{displayedValue}</span>;
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Badges:
    case FormComponentTypes.Checkbox:
      if (!value) return <span />;
      const displayedValues = value
        ?.map(
          (v: any) => options.find((option) => option.value === v)?.label || v,
        )
        .join(", ");

      return <span>{displayedValues}</span>;
    case FormComponentTypes.RadioButton:
      if (!value) return <span />;
      displayedValue =
        options.find((opt: any) => opt?.value === value)?.label || value;

      return <span>{displayedValue}</span>;
    case FormComponentTypes.Keyword:
      return (
        <ReadOnlyKeywordsBase keywords={value}>
          {(loading, selectedKeywords) => (
            <p className="break-words">
              {selectedKeywords?.map(({ name }) => name).join(", ")}
            </p>
          )}
        </ReadOnlyKeywordsBase>
      );
    case FormComponentTypes.SearchAndSelect:
      if (!value) return <span />;

      const userIds = Array.isArray(value) ? value : [value];

      return (
        <ReadOnlySearchAndSelectBase
          userIds={userIds}
          contactType={contactType}
        >
          {(users) => (
            <>
              {users.map((user) => (
                <div key={user.id} className="flex items-center space-x-2">
                  <Avatar
                    name={getUserName(user)}
                    src={user?.photoURL}
                    className="h-9 w-9 min-h-9 min-w-9"
                    iconClassName="h-8 w-8"
                  />
                  <p className="break-words" key={user.id}>
                    {getUserName(user)}
                  </p>
                </div>
              ))}
            </>
          )}
        </ReadOnlySearchAndSelectBase>
      );
    case FormComponentTypes.Switch:
      return <span>{Boolean(value) ? "Ja" : "Nein"}</span>;
    case FormComponentTypes.RegionalSelect:
      return (
        <ReadOnlyRegionalSelectBase regionId={value}>
          {(region) => {
            return <span>{region?.city}</span>;
          }}
        </ReadOnlyRegionalSelectBase>
      );
    case FormComponentTypes.AddressSearch:
      return <span>{value?.name}</span>;
    case FormComponentTypes.LabelledInput:
      if (!value || isString(value)) return <span>{value}</span>;

      return (
        <div className="w-full flex flex-wrap">
          <LabelledInputTableCell
            values={value}
            type={component?.properties?.type}
          />
        </div>
      );
    default: {
      if (value && isFinite(value)) {
        return <span>{formatNumbers(value)}</span>;
      }
      if (isArray(value)) {
        return null;
      }
      return <span>{value}</span>;
    }
  }
};

interface Props {
  dynamicColumnIds: string[];
  componentsById: Record<string, any>;
  permissions: Record<string, any>;
  flattenData?: (data?: any) => any;
}

const resolveCellValues = ({
  columnId,
  component,
  flattenData,
}: {
  columnId: string;
  component: any;
  flattenData?: (data?: any) => any;
}): ColumnDef<any> => {
  const label =
    component?.properties?.label || component?.properties?.readOnlyLabel;

  return {
    minSize: 240,
    header: label,
    id: columnId,
    meta: {
      label,
    },
    accessorFn: (row) => row?.[columnId],
    cell: ({ row }) => {
      const data = flattenData ? flattenData(row?.original) : row?.original;

      return (
        <TableCellWrapper>
          {getTableCellValue(component, data?.[columnId])}
        </TableCellWrapper>
      );
    },
  };
};

const getColumns = (
  dynamicColumnIds: string[],
  componentsById: Record<string, any>,
) => {
  return dynamicColumnIds
    .map((columnId) => ({
      columnId,
      component: componentsById[columnId],
    }))
    .filter(({ component }) => !isNil(component));
};

export const dynamicTableColumns = ({
  dynamicColumnIds,
  componentsById,
  permissions,
  flattenData,
}: Props): ColumnDef<any>[] => {
  return getColumns(dynamicColumnIds, componentsById)
    .filter(({ component }) => !isRestricted(component, permissions))
    .map((props) => {
      return resolveCellValues({ flattenData, ...props });
    });
};

export const dynamicTableColumnsNoPermissions = ({
  dynamicColumnIds,
  componentsById,
  flattenData,
}: Omit<Props, "permissions">): ColumnDef<any>[] => {
  return getColumns(dynamicColumnIds, componentsById).map((props) => {
    return resolveCellValues({ flattenData, ...props });
  });
};
