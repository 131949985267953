import { FC, useState } from "react";
import {
  getArgsFromKeywords,
  transformCollectionToForm,
  updateCollectionShare,
} from "./common";
import { isEmpty, isString } from "lodash";
import {
  useFormStore,
  useSettingsStore,
  useUpdateCollection,
} from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { CollectionsModalWrapper } from "./CollectionsModalWrapper";
import { sanitizeData } from "@amenda-utils";
import { useGetAllUsers } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  goBackRoute: string;
  collectionType: AllowedCollectionType;
  handleClose: () => void;
  getResourceIds: (args: any) => Promise<string[]>;
}

export const EditCollectionModal: FC<Props> = ({
  isOpen,
  goBackRoute,
  collectionType,
  handleClose,
  getResourceIds,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAllUsers } = useGetAllUsers();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const systemRoles = useSettingsStore((state) => state.systemRoles);
  const { updateCollection } = useUpdateCollection();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );

  const selectedCollection = selectedCollectionByType[collectionType];

  const getUsersFromKeyword = async (keywords: any[]) => {
    if (isEmpty(keywords)) return [];

    const args = getArgsFromKeywords(keywords);
    const users = await getAllUsers({
      userDetails: args,
      skipStorage: true,
    });
    return users?.map((user: any) => user.id) || [];
  };

  const getResourceIdsAsync = async (args: any) => {
    let resourceIds: string[] = [];
    if (!isEmpty(args)) {
      resourceIds = await getResourceIds(args);
    }
    return resourceIds || [];
  };

  const handleSubmit = async (
    {
      description,
      expirationDate,
      tags = [],
      shareWithRoles = [],
      shareWithContacts = [],
      shareWithUserTags = [],
      ...rest
    }: any,
    reset: () => void,
  ) => {
    setUpdateLoading(true);

    const args = getArgsFromKeywords(tags);
    const keywordUserIds = await getUsersFromKeyword(shareWithUserTags);
    const selectedResourceIds = await getResourceIdsAsync(args);
    const shares = updateCollectionShare({
      keywordUserIds,
      shareWithRoles,
      shareWithContacts,
      expirationDate,
    });
    let input = {
      ...rest,
      shares,
      description,
      collectionType,
      resourceIds: selectedResourceIds,
      keywords: tags?.map((v: any) => {
        if (isString(v)) {
          return v;
        }
        return v.value;
      }),
      _id: selectedCollection.id,
    };
    input = sanitizeData(input);

    await updateCollection({
      input,
    });
    reset();
    setUpdateLoading(false);
    handleClose();
  };

  const handleDeleteCollection = async () => {
    setDeleteLoading(true);
    await updateCollection({
      input: {
        isDeleted: true,
        _id: selectedCollection.id,
      },
    });
    setDeleteLoading(false);
    navigate(goBackRoute);
  };

  return (
    <CollectionsModalWrapper
      title="Edit collection"
      isOpen={isOpen}
      values={transformCollectionToForm(selectedCollection, systemRoles)}
      collectionType={collectionType}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      footerChildren={(formId) => {
        return (
          <div className="grid grid-cols-2 gap-4 w-full">
            <Button
              className="w-full flex justify-center"
              type="button"
              variant="danger"
              loading={deleteLoading}
              onClick={handleDeleteCollection}
            >
              {t("Delete collection")}
            </Button>
            <Button
              form={formId}
              className="w-full flex justify-center"
              type="submit"
              variant="primary"
              loading={updateLoading}
            >
              {t("Save changes")}
            </Button>
          </div>
        );
      }}
    />
  );
};
