import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";

import { BlockedRoutes } from "@amenda-constants";
import { GeneralPermissionTypes } from "@amenda-types";
import isEmpty from "lodash/isEmpty";
import { matchPath } from "react-router-dom";
import { processMagicLinks } from "@amenda-utils";

const hasRange = (range: number, value: number) =>
  value >= range && value < range + 10;

export const getIndicatorColor = (progress: number) => {
  return {
    "text-red-600": hasRange(0, progress) || hasRange(10, progress),
    "text-red-400": hasRange(20, progress) || hasRange(30, progress),
    "text-yellow-500": hasRange(40, progress) || hasRange(50, progress),
    "text-green-400": hasRange(60, progress),
    "text-green-600":
      hasRange(70, progress) ||
      hasRange(80, progress) ||
      hasRange(90, progress) ||
      progress === 100,
  };
};

const checkPathAndPermissions = ({
  pathname,
  adminGeneralPermissions,
  projectsGeneralPermissions,
  unitPriceGeneralPermissions,
  contactsGeneralPermissions,
}: {
  pathname: string;
  adminGeneralPermissions: Record<GeneralPermissionTypes, boolean>;
  projectsGeneralPermissions: Record<GeneralPermissionTypes, boolean>;
  contactsGeneralPermissions: Record<GeneralPermissionTypes, boolean>;
  unitPriceGeneralPermissions: Record<GeneralPermissionTypes, boolean>;
}) => {
  const editProject = matchPath(
    {
      path: BlockedRoutes.ProjectEdit,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );
  const editContact = matchPath(
    {
      path: BlockedRoutes.ContactsEdit,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );

  switch (pathname) {
    case BlockedRoutes.ProjectNew:
      return !projectsGeneralPermissions[GeneralPermissionTypes.Create];
    case BlockedRoutes.MaterialNew:
      return !unitPriceGeneralPermissions[GeneralPermissionTypes.Create];
    default:
      if (editProject) {
        return !projectsGeneralPermissions[GeneralPermissionTypes.Edit];
      } else if (editContact) {
        return !contactsGeneralPermissions[GeneralPermissionTypes.Edit];
      } else if (pathname.startsWith(BlockedRoutes.Admin)) {
        return !adminGeneralPermissions[GeneralPermissionTypes.FullAccess];
      }
      return false;
  }
};

export const shouldRedirectRestrictedPaths = (
  pathname: string,
  permissions: Record<string, any>,
) => {
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );
  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );
  const adminGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Admin,
  );

  if (isEmpty(permissions)) {
    return false;
  }
  return checkPathAndPermissions({
    pathname,
    adminGeneralPermissions,
    projectsGeneralPermissions,
    contactsGeneralPermissions,
    unitPriceGeneralPermissions,
  });
};

export const getNotFoundWidgetOptions = (
  Icons: Record<string, any>,
  permissions: Record<string, any>,
) => {
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );

  const items = [
    {
      key: "contacts",
      title: "Browse Contacts",
      description:
        "All your Contacts and Companies in one single place to quickly find what you are looking for",
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
      link: "/kontaktverzeichnis",
    },
  ];

  if (projectsGeneralPermissions[GeneralPermissionTypes.Create]) {
    items.push({
      key: "projects",
      title: "Create a new Project",
      description:
        "Collect your Project`s data in custom forms and start working with that data",
      link: BlockedRoutes.ProjectNew,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    });
  }
  if (unitPriceGeneralPermissions[GeneralPermissionTypes.Create]) {
    items.push({
      key: "unitPrice",
      title: "Create a new Unit Price",
      description: "Create a new Unit Price and start working with that data",
      link: BlockedRoutes.MaterialNew,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    });
  }

  return items.map((item) => ({
    ...item,
    Icon: Icons[item.key],
  }));
};

export const getProcessedUrl = ({
  url,
  magicSize,
  ignoreMagicLinks,
  showLoader,
}: {
  url: string;
  magicSize?: string;
  ignoreMagicLinks?: boolean;
  showLoader?: boolean;
}) => {
  const placeHolderUrl = "/images/noImagePlaceholder.svg";
  let processedUrl = ignoreMagicLinks
    ? url
    : processMagicLinks(url, { size: magicSize });

  if (!showLoader) {
    processedUrl = processedUrl || placeHolderUrl;
  }
  return processedUrl;
};
