export const isCoordinateValid = (coordinates?: number[]) => {
  return coordinates && coordinates.length === 2;
};

export const generateBoundsCoordinates = (coordinates: any) => {
  const long = Number(coordinates?.[0]);
  const lat = Number(coordinates?.[1]);
  return [
    [long, lat],
    [long, lat],
  ];
};
