import { ConstructionDetails } from "@amenda-components/ConstructionDetails";
import { FC } from "react";
import { ProjectConstructionDetailsWrapper } from "./EditProjectConstructionDetails";

interface Props {
  projectId: string;
}

export const ReadOnlyProjectConstructionDetails: FC<Props> = ({
  projectId,
}) => {
  return (
    <ProjectConstructionDetailsWrapper projectId={projectId}>
      {({ attachments, collectionType, searchAttachments }) => (
        <ConstructionDetails
          canUpload={false}
          collectionType={collectionType}
          isCollection={false}
          canAssignAttachments={false}
          canCreateCollection={false}
          attachments={attachments}
          searchAttachments={searchAttachments}
        />
      )}
    </ProjectConstructionDetailsWrapper>
  );
};
