import { Control, UseFormSetValue } from "react-hook-form";
import { getComponentsFromForms, groupComponents } from "@amenda-utils";

import { AvailableForms } from "@amenda-constants";
import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { SettingsPermissionConfig } from "@amenda-components/Settings";
import { permissionColumns } from "./common";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  control: Control;
  setValue: UseFormSetValue<any>;
  globalData: Record<string, any>;
}

export const SettingGalleryPermissions: FC<Props> = ({
  control,
  setValue,
  globalData,
}) => {
  const forms = useProjectStore((state) => state.forms);

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  let groupedComponents = groupComponents(components);

  return (
    <ComponentsTreeView
      readOnly={false}
      control={control}
      setValue={setValue}
      globalData={globalData}
      components={groupedComponents}
      columns={permissionColumns}
      label="media permissions"
      searchPlaceholder="Filter..."
      className="max-h-full h-[35rem]"
      RowBody={SettingsPermissionConfig}
    />
  );
};
