import {
  ChevronDownIcon,
  MapPinIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { ControlProps, components } from "react-select";
import {
  FC,
  ForwardRefExoticComponent,
  ReactNode,
  useRef,
  useState,
} from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";

import AsyncSelect from "react-select/async";
import { ErrorMessage } from "./ErrorMessage";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  value?: any;
  label?: string;
  error?: string;
  isMulti?: boolean;
  optional?: ReactNode;
  isClearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  children?: ReactNode;
  StartIcon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  onBlur?: () => void;
  onChange: (values: any) => void;
  getOptionValue: (option: any) => string;
  getOptionLabel: (option: any) => string;
  loadOptions: (value: string) => Promise<any[]>;
}

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <MapPinIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
      {children}
    </components.Control>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.ClearIndicator>
  );
};

export const AddressSearchInput: FC<Props> = ({
  error,
  id,
  label,
  value,
  disabled = false,
  isClearable = true,
  isMulti = false,
  optional,
  hasMenuOverflow = false,
  hideErrorMessage = false,
  placeholder = "Search for an address",
  onBlur,
  onChange,
  loadOptions,
  getOptionValue,
  getOptionLabel,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>("");

  const addressValue = value?.coordinates ? value : null;

  const handleInputChange = (inputValue: string) => setInputValue(inputValue);

  return (
    <div ref={ref}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <div>
        <div className="w-full">
          <AsyncSelect
            inputId={id}
            cacheOptions
            isMulti={isMulti}
            isDisabled={disabled}
            value={addressValue}
            placeholder={placeholder && t(placeholder)}
            className={customStyling.select.containerClass}
            inputValue={inputValue}
            loadOptions={loadOptions}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onInputChange={handleInputChange}
            menuPlacement="auto"
            noOptionsMessage={({ inputValue }) =>
              inputValue ? t("No results found") : t("Start typing")
            }
            loadingMessage={() => t("Loading") + "..."}
            isClearable={isClearable}
            onBlur={onBlur}
            onChange={(value) => {
              onChange(value);
              setInputValue("");
            }}
            styles={{
              ...customStyling.select.styleOverride,
              ...selectMenuOverflow(hasMenuOverflow, ref),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            components={{
              Control,
              ClearIndicator,
              DropdownIndicator,
            }}
            {...selectMenuOverflowProps(hasMenuOverflow)}
          />
        </div>
      </div>
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
