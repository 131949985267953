import { FC, memo } from "react";
import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { FormComponentProps } from "@amenda-types";
import { ProjectForm } from "@amenda-components/Projects";
import { SearchContactAndRole } from "@amenda-components/Contacts";
import { devConsole } from "@amenda-utils";
import { useParams } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";

const SearchAndSelect: FC<FormComponentProps> = ({ config, global }) => {
  const { id, properties } = config;
  const { projectId } = useParams<{ projectId?: string }>();

  if (!properties.contactType) {
    devConsole?.warn("amenda:contact type is missing");
    return null;
  }
  return (
    <SearchContactAndRole
      id={id}
      projectId={projectId}
      onBlur={global.onBlur}
      control={global.control}
      disabled={properties?.readOnly}
      {...properties}
    />
  );
};

interface Props {
  form: any;
}

export const ProjectFormsWrapper: FC<Props> = memo(({ form }) => {
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);

  if (isFetchingProject) {
    return <LoaderWrapper />;
  } else if (!form.components) {
    return (
      <div className="mt-8">
        <HelperMessage message="The configuration is not yet done" />
      </div>
    );
  }
  return (
    <ProjectForm
      key={form.id}
      components={form.components}
      customFormComponents={{
        SearchAndSelect,
      }}
    />
  );
});
