import {
  AllProjectsView,
  CostEstimationModal,
  ProjectLayout,
  ProjectsActionBar,
} from "@amenda-components/Projects";

import { AggregationWidgetViewerWrapper } from "@amenda-components/Dashboard";
import { FC } from "react";

interface Props {
  isCollection?: boolean;
}

export const ProjectsPageWrapper: FC<Props> = ({ isCollection = false }) => {
  return (
    <ProjectLayout
      title={isCollection ? "Projects collection" : "All Projects"}
    >
      <ProjectsActionBar isCollection={isCollection} />
      <AggregationWidgetViewerWrapper />
      <CostEstimationModal />
      <AllProjectsView isCollection={isCollection} />
    </ProjectLayout>
  );
};
