import { FC } from "react";
import clsx from "clsx";
import { processMagicLinks } from "@amenda-utils";

interface ImageProps {
  containerHeight?: string;
  className?: string;
  src?: string;
  label?: string;
  width: number;
  height: number;
}

export const Image: FC<ImageProps> = ({
  src,
  label,
  height,
  width,
  className = "",
}) => {
  const imageSrc = src
    ? processMagicLinks(src, {
        w: 1600,
      })
    : src;

  return (
    <div
      className={clsx(
        `relative w-full h-full bg-white rounded overflow-hidden aspect-w-${width} aspect-h-${height}`,
        className,
      )}
    >
      <img
        src={imageSrc}
        alt={label}
        className="w-full h-full object-center object-cover"
      />
    </div>
  );
};
