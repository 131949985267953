import { Control, Controller, useController } from "react-hook-form";
import {
  DatePicker,
  MiniSwitch,
  ReactSelect,
} from "@amenda-components/FormComponents";
import { FC, useEffect, useState } from "react";

import { Option } from "@amenda-types";
import { isEmpty } from "lodash";
import { useGetKeywords } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  control: Control<any>;
  componentIds?: string[];
}

const ExpirationDatePicker = ({ control }: { control: Control<any> }) => {
  const currentDate = new Date();

  return (
    <div className="mb-3">
      <Controller
        name="expirationDate"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <DatePicker
              id="expirationDate"
              label="Expires At"
              minDate={currentDate}
              value={value}
              error={error?.message}
              withPortal={true}
              onChange={onChange}
            />
          );
        }}
      />
    </div>
  );
};

export const CollectionUserTags: FC<Props> = ({
  id,
  control,
  componentIds = [],
}) => {
  const { t } = useTranslation();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });
  const {
    field: { value: isShared, onChange: setIsShared },
  } = useController({
    control,
    defaultValue: false,
    name: "shareable",
  });
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  const options = keywords.map((k) => ({
    value: k.id,
    label: k.name,
    componentId: k.componentId,
  }));

  const handleChange = (value: Option[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (!isEmpty(componentIds)) {
      getKeywords({
        componentIds,
        setKeywords,
      });
    }
  }, [getKeywords, componentIds]);

  if (!isShared) {
    return (
      <div className="flex justify-between">
        <label htmlFor={id} className="amenda-component-label">
          {t("Share with Contacts")}
        </label>
        <span className="text-sm text-gray-500" id="email-optional">
          <MiniSwitch
            label="Auto create tags"
            value={isShared}
            onChange={setIsShared}
          />
        </span>
      </div>
    );
  }
  return (
    <div className="w-full">
      <ExpirationDatePicker control={control} />
      <ReactSelect
        id={id}
        isMulti
        label="Share with Contacts of Tags"
        isLoading={loading}
        options={options}
        error={error?.message}
        value={value}
        hasMenuOverflow={true}
        onChange={handleChange}
        optional={
          <MiniSwitch
            label="Auto create tags"
            value={isShared}
            onChange={setIsShared}
          />
        }
      />
    </div>
  );
};
