import { AvailableNotificationTypes, ReactTableKeys } from "@amenda-types";
import { ChangeEvent, FC, useState } from "react";
import { DeleteModal, ReactTable } from "@amenda-components/Shared";
import { IconButton, Image } from "@amenda-components/App";
import { LinkIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  copyToClickBoard,
  getComponentsById,
  getComponentsFromForms,
} from "@amenda-utils";
import {
  getDefaultSelection,
  useTableRowsSelection,
} from "@amenda-components/Shared/reactTableHelpers";
import {
  useAppStore,
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { MediaGalleryProps } from "./common";
import { dynamicTableColumns } from "@amenda-components/PageBuilder/dynamicTableColumns";
import { useTranslation } from "react-i18next";

type Props = Pick<
  MediaGalleryProps,
  "attachments" | "fetchPaginatedAttachments"
>;

const TableActionsCell: FC<{
  row: any;
}> = ({ row: attachment }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { updateAttachment, loading } = useUpdateAttachment();
  const showNotification = useAppStore((state) => state.showNotification);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCopyToClickboard = () => {
    copyToClickBoard({
      text: attachment?.url || "",
      onSuccess: () => {
        showNotification(
          AvailableNotificationTypes.Success,
          "Link copied to clipboard"
        );
      },
    });
  };

  const handleDeleteAttachment = async () => {
    await updateAttachment({
      input: {
        _id: attachment?.id,
        isDeleted: true,
      },
    });
  };

  return (
    <div>
      <DeleteModal
        title="Delete Attachment"
        description="Are you sure you want to delete this attachment?"
        openModal={openModal}
        loading={loading}
        handleDelete={handleDeleteAttachment}
        handleCloseModal={handleCloseModal}
      />
      <div className="flex space-x-2 items-center">
        <IconButton
          label="copy link"
          Icon={LinkIcon}
          onClick={handleCopyToClickboard}
        />
        <IconButton
          label="delete attachment"
          Icon={TrashIcon}
          onClick={handleOpenModal}
        />
      </div>
    </div>
  );
};

const GalleryImage: FC<{
  src: string;
}> = ({ src }) => {
  const selectedImageVariant = useAttachmentStore(
    (state) => state.selectedImageVariant
  );

  return (
    <div className="w-full">
      <Image
        background="bg-transparent"
        magicSize="gallery"
        url={src}
        variant={selectedImageVariant}
      />
    </div>
  );
};

export const GalleryTable: FC<Props> = ({
  attachments,
  fetchPaginatedAttachments,
}) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {}
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment
  );
  const setSelectedAttachments = useAttachmentStore(
    (state) => state.setSelectedAttachments
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment
  );
  const setIsGalleryModalOpen = useAttachmentStore(
    (state) => state.setIsGalleryModalOpen
  );
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const { onRowSelectionChange, onAllRowsSelectionChange } =
    useTableRowsSelection<{ id: string }>({
      addSelectedRows: setSelectedAttachments,
      toggleSelectedRow: toggleSelectedAttachment,
    });

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const componentsById = getComponentsById(components);
  const { defaultSelection } = getDefaultSelection(
    attachments,
    selectedAttachments
  );
  const dynamicColumnIds = components
    .filter((component) => Boolean(component.component))
    .map((component) => component.id);

  const handleClick =
    (attachment: any) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedAttachment(attachment);
      setIsGalleryModalOpen(true);
    };

  return (
    <ReactTable
      data={attachments}
      isLoading={isSearching || isFetching}
      showSelectorColumn
      isFullWidth
      isConfigurable
      canDownload
      maxEstimatedRowHeight={125}
      tableId={ReactTableKeys.Gallery}
      containerClass="h-[calc(100vh-11.5rem)] w-full"
      defaultRowSelection={defaultSelection}
      onRowClick={handleClick}
      onRowSelectionChange={onRowSelectionChange}
      onAllRowsSelectionChange={onAllRowsSelectionChange}
      TableRowActions={TableActionsCell}
      fetchNextPage={fetchPaginatedAttachments}
      columns={[
        {
          id: "gallery",
          minSize: 20,
          meta: {
            label: t("Gallery"),
          },
          cell: ({ row }) => {
            const attachment = row?.original;

            return <GalleryImage src={attachment?.url} />;
          },
        },
        ...dynamicTableColumns({
          permissions,
          componentsById,
          dynamicColumnIds,
          flattenData: (data = {}) => {
            return {
              ...data,
              ...(data?.formValues || {}),
              ...(data?.metadata || {}),
            };
          },
        }),
      ]}
    />
  );
};
