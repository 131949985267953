import { FC, ReactNode } from "react";

import { HoverVariants } from "@amenda-types";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import clsx from "clsx";

interface Props {
  id: string;
  isSelected?: boolean;
  children: ReactNode;
  selectorType?: HoverVariants.Checkbox | HoverVariants.RadioButton;
  onClick?: () => void;
}

export const ResourceSelector: FC<Props> = ({
  children,
  id,
  isSelected = false,
  onClick,
  selectorType = HoverVariants.Checkbox,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative group border hover:border-gray-300 w-full"
    >
      <div className="top-1 left-1 absolute m-1 z-20">
        {selectorType === HoverVariants.Checkbox ? (
          <SingleCheckbox
            id={id}
            checked={isSelected}
            className={clsx("h-5 w-5", {
              "md:hidden group-hover:block": !isSelected,
            })}
            onChange={onClick}
          />
        ) : (
          <SingleRadioButton
            id={id}
            checked={isSelected}
            className={clsx({
              "md:hidden group-hover:block": !isSelected,
            })}
            onChange={onClick}
          />
        )}
      </div>
      {children}
    </button>
  );
};
