import {
  CostEstimationModalDesktopSidebar,
  CostEstimationModalMainContent,
} from "./CostEstimationComponents";
import { FC, useCallback, useEffect, useState } from "react";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperBase,
} from "@amenda-components/PageBuilder";
import {
  getComponentByCode,
  processCostGroupPermissions,
} from "@amenda-components/CostGroup/common";
import {
  getComponentsByCode,
  getConsiderationCostGroup,
  getConsiderationDate,
  getMissingQuantities,
  transformCostEstimateValues,
} from "./common";
import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import { isEmpty, trim } from "lodash";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { costEstimateSchema } from "@amenda-constants";
import { useGetProjectCostEstimate } from "@amenda-domains/queries";

interface Props {
  handleOpenNewModal: () => void;
  handleOpenExistingModal: () => void;
  closeCostEstimationModal: () => void;
  cleanUpAndCloseCostEstimationModal: () => void;
}

export const CostEstimationModalContent: FC<Props> = ({
  handleOpenNewModal,
  handleOpenExistingModal,
  closeCostEstimationModal,
  cleanUpAndCloseCostEstimationModal,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const { getProjectCostEstimate } = useGetProjectCostEstimate();
  const selectedProjects = useProjectStore((state) => state.selectedProjects);
  const setCostEstimationModalValues = useProjectStore(
    (state) => state.setCostEstimationModalValues,
  );
  const forms = useProjectStore((state) => state.forms);
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole,
  );
  const costEstimationModalValues = useProjectStore(
    (state) => state.costEstimationModalValues,
  );
  const similarityScoreById = useProjectStore(
    (state) => state.similarityScoreById,
  );
  const latestBKIDate = useProjectStore((state) => state.latestBKIDate);

  const costGroupCostForms = forms?.[AvailableForms.CostGroupsCost];
  const costGroupQuantityForms = forms?.[AvailableForms.CostGroupsQuantity];
  const costGroupQuantityComponents = getComponentsFromForms(
    costGroupQuantityForms,
  );
  const costGroupCostComponents = getComponentsFromForms(costGroupCostForms);
  const { componentsByCode } = getComponentsByCode(costGroupCostComponents);
  const { componentByCode } = getComponentByCode(costGroupCostComponents);
  const permissions = currentUserSystemRole?.permissions || {};
  const groupedCostGroupComponents = groupComponentsByParent(
    costGroupCostComponents,
    processCostGroupPermissions(permissions),
  )?.components;
  const groupedQuantityGroupComponents = groupComponentsByParent(
    costGroupQuantityComponents,
    processCostGroupPermissions(permissions),
  )?.components;

  const openNewModal = () => {
    handleOpenNewModal();
    closeCostEstimationModal();
  };

  const openExistingModal = () => {
    handleOpenExistingModal();
    closeCostEstimationModal();
  };

  const getWeightedProjects = ({
    calculation,
    selectedProjects,
    similarityScoreById,
  }: {
    calculation?: boolean;
    selectedProjects: string[];
    similarityScoreById: Record<string, number>;
  }) => {
    const defaultWeight = 1;

    if (Boolean(calculation)) {
      return selectedProjects.map((id) => {
        let weight = similarityScoreById[id];
        weight = weight ? weight / 100 : defaultWeight;

        return {
          id,
          weight,
        };
      });
    }
    return selectedProjects.map((id) => ({
      id,
      weight: defaultWeight,
    }));
  };

  const handleCostEstimate = useCallback(
    async (formValues: any) => {
      const missingQuantities = getMissingQuantities({
        formValues,
        componentByCode,
        groupedCostGroupComponents,
        costGroupQuantityComponents,
      });
      if (!isEmpty(missingQuantities)) {
        return;
      }

      const { considerationCostGroups, inferredCalcCostGroups } =
        getConsiderationCostGroup({
          ...formValues,
          componentByCode,
          componentsByCode,
          costGroupQuantityComponents,
          groupedCostGroupComponents,
        });
      const projects = getWeightedProjects({
        ...formValues,
        selectedProjects,
        similarityScoreById,
      });

      await getProjectCostEstimate({
        ...formValues,
        projects,
        componentByCode,
        componentsByCode,
        inferredCalcCostGroups,
        considerationCostGroups,
        groupedCostGroupComponents,
        costGroupQuantityComponents,
        considerationDate: getConsiderationDate(
          formValues?.considerationDate,
          latestBKIDate.dateIso,
        ),
        region: trim(formValues?.region?.city),
      });
    },
    [
      componentByCode,
      componentsByCode,
      selectedProjects,
      similarityScoreById,
      groupedCostGroupComponents,
      costGroupQuantityComponents,
      latestBKIDate.dateIso,
      getProjectCostEstimate,
    ],
  );

  const handleSubmit = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    setCostEstimationModalValues(sanitizedData);
    await handleCostEstimate(sanitizedData);
  };

  useEffect(() => {
    if (!isMounted && !isEmpty(costEstimationModalValues)) {
      handleCostEstimate(costEstimationModalValues);
    }
    setIsMounted(true);
  }, [isMounted, costEstimationModalValues, handleCostEstimate]);

  return (
    <FormAutoSaveWrapperBase
      ignoreResourceIdAlways={true}
      className="flex w-full h-full"
      inputSchema={costEstimateSchema}
      values={transformCostEstimateValues(costEstimationModalValues)}
      onSubmit={handleSubmit}
    >
      {({ control }) => (
        <>
          {control && (
            <>
              <CostEstimationModalDesktopSidebar control={control} />
              <CostEstimationModalMainContent
                control={control}
                costGroupCostFormName={costGroupCostForms?.[0]?.name}
                costGroupQuantityFormName={costGroupQuantityForms?.[0]?.name}
                groupedCostGroupComponents={groupedCostGroupComponents}
                groupedQuantityGroupComponents={groupedQuantityGroupComponents}
                costGroupCostComponents={costGroupCostComponents}
                handleOpenModal={openNewModal}
                handleOpenExistingModal={openExistingModal}
                handleClose={cleanUpAndCloseCostEstimationModal}
              />
            </>
          )}
        </>
      )}
    </FormAutoSaveWrapperBase>
  );
};
