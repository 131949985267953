import { FC } from "react";
import { useWatch } from "react-hook-form";
import {
  CalculatorFieldBase,
  CalculatorFieldBaseProps,
} from "@amenda-components/FormComponents";

interface CalculatorProps extends Omit<CalculatorFieldBaseProps, "mathTokens"> {
  operation: string;
  triggerOperationKey: string;
}

export const CalculatorTextField: FC<CalculatorProps> = ({
  control,
  operation,
  triggerOperationKey,
  ...props
}) => {
  const autoCalculate: boolean = useWatch({
    control,
    name: triggerOperationKey,
  });

  return (
    <CalculatorFieldBase
      control={control}
      mathTokens={operation}
      autoCalculate={autoCalculate}
      {...props}
    />
  );
};
