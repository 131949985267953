import {
  DatePickerBase,
  DatePickerProps,
} from "@amenda-components/FormComponents";
import { FC, ReactNode } from "react";

import { ErrorMessage } from "./ErrorMessage";
import { useTranslation } from "react-i18next";

export const DatePicker: FC<
  DatePickerProps & {
    error?: string;
    optional?: ReactNode;
    hideErrorMessage?: boolean;
  }
> = ({ id, error, label, optional, hideErrorMessage, ...rest }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full group/datePicker">
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <DatePickerBase
        id={id}
        label={label}
        className="amenda-component h-11"
        {...rest}
      />
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
