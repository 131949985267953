import {
  AllowedCollectionType,
  AvailableNotificationTypes,
  GeneralPermissionTypes,
  ProjectView,
  ReactTableKeys,
} from "@amenda-types";
import {
  BlockedRoutes,
  DebounceTimes,
  SimilaritySearchLimit,
} from "@amenda-constants";
import { Button, Link, Spinner, Tooltip } from "@amenda-components/App";
import {
  ChartPieIcon,
  CurrencyEuroIcon,
  FunnelIcon,
  MapIcon,
  PlusIcon,
  Squares2X2Icon,
  TableCellsIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  CollectionButton,
  CollectionDropdown,
  DeleteModal,
  ExpandableSearch,
} from "@amenda-components/Shared";
import { FC, useRef, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { debounce, isEmpty } from "lodash";
import { getProjectArgs, isMapOrTableView } from "./common";
import {
  useAppStore,
  useDashboardStore,
  useDeleteProjects,
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
  useUpdateCollection,
} from "@amenda-domains/mutations";
import { useGetAllProjects, useSearchProjects } from "@amenda-domains/queries";

import { CircleMinusIcon } from "lucide-react";
import { ReactSVG } from "react-svg";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { abortRequest } from "@amenda-domains/abortExchange";
import clsx from "clsx";
import { transformFilters } from "@amenda-components/Shared/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  isCollection?: boolean;
}

export const ProjectsActionBar: FC<Props> = ({ isCollection = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const setShowAggregations = useDashboardStore(
    (state) => state.setShowAggregations,
  );
  const projectView = useProjectStore((state) => state.projectView);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const showAggregations = useDashboardStore((state) => state.showAggregations);
  const setProjectView = useProjectStore((state) => state.setProjectView);
  const openSearchModal = useProjectStore((state) => state.openSearchModal);
  const setOpenSearchModal = useProjectStore(
    (state) => state.setOpenSearchModal,
  );
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const { docsCount = 0 } = useProjectStore((state) => state.pagination);
  const allProjects = useProjectStore((state) => state.projects);
  const selectedProjects = useProjectStore((state) => state.selectedProjects);
  const clearSelectedProjects = useProjectStore(
    (state) => state.clearSelectedProjects,
  );
  const setSelectedProjects = useProjectStore(
    (state) => state.setSelectedProjects,
  );
  const setCostEstimateModal = useProjectStore(
    (state) => state.setCostEstimateModal,
  );
  const { deleteProjects, loading } = useDeleteProjects();
  const [deleteRelatedResources, setDeleteRelatedResources] =
    useState<boolean>(false);
  const { getAllProjects } = useGetAllProjects();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { searchProjects } = useSearchProjects();
  const setIsSearching = useProjectStore((state) => state.setIsSearching);
  const visibleColumns = useAppStore(
    (state) => state.tableColumnVisibility[ReactTableKeys.Projects],
  );
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const selectedCollection = useFormStore(
    (state) => state.selectedCollectionByType[AllowedCollectionType.Projects],
  );
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const { sidebarFilters, searchTerm, setSearchTerm } =
    useSidebarFiltersWithPath();

  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const totalProjects = Math.max(docsCount, allProjects.length);
  const allowSimilaritySearch = totalProjects >= SimilaritySearchLimit;
  const hasSelectedProjects = !isEmpty(selectedProjects);

  const toggleShowAggregations = () => setShowAggregations(!showAggregations);

  const handleOpenSimilaritySearch = () => {
    if (allowSimilaritySearch) {
      setOpenSearchModal(true);
    } else {
      showNotification(
        AvailableNotificationTypes.General,
        t("SimilaritySearchLimit", {
          limit: SimilaritySearchLimit,
        }),
      );
    }
  };

  const handleCostEstimate = () => {
    setCostEstimateModal(true);
  };

  const handleSelectAll = () => {
    setSelectedProjects(allProjects.map((p: any) => p.id));
  };

  const handleNewProject = () => {
    navigate(BlockedRoutes.ProjectNew);
    clearProjectValues();
  };

  const handleMap = () => {
    setProjectView(
      projectView === ProjectView.Map ? ProjectView.Gallery : ProjectView.Map,
    );
  };
  const handleClose = () => setOpenModal(false);

  const handleToggleView = () =>
    setProjectView(
      isMapOrTableView(projectView) ? ProjectView.Gallery : ProjectView.Table,
    );

  const handleCheckBox = () => {
    setDeleteRelatedResources(!deleteRelatedResources);
  };

  const handleArchiveProjects = async () => {
    if (selectedProjects.length > 0) {
      await deleteProjects({
        deleteRelatedResources,
        ids: selectedProjects,
      });
      clearSelectedProjects();
    }
    handleClose();
  };

  const handleResourceIds = async (formValues: any) => {
    const projects = await getAllProjects({
      formValues,
      callback: (data: any) => {},
    });
    return projects?.map((project: any) => project.id);
  };

  const handleRemoveFromCollection = async () => {
    if (selectedCollection?.id) {
      await updateCollection({
        input: {
          _id: selectedCollection.id,
          aggregation: {
            $pull: {
              resourceIds: {
                $in: selectedProjects,
              },
            },
          },
        },
      });
      clearSelectedProjects();
    }
  };

  const handleDeleteProjects = async () => {
    if (isCollection) {
      await handleRemoveFromCollection();
    } else {
      setOpenModal(true);
    }
  };

  const debounceSearch = useRef(
    debounce(
      async ({
        filters,
        isCollection,
        resourceIds,
        searchTerm,
        visibleColumns,
        similarProjectIds,
      }: {
        filters: Record<string, any>;
        isCollection: boolean;
        resourceIds?: string[];
        searchTerm: string;
        visibleColumns: Record<string, boolean>;
        similarProjectIds: string[];
      }) => {
        setIsSearching(true);
        if (!isEmpty(searchTerm) || !isEmpty(filters)) {
          await searchProjects({
            isCollection,
            filters,
            resourceIds,
            searchTerm,
          });
        } else {
          abortRequest("searchProjects");

          await getAllProjects(
            getProjectArgs({
              isCollection,
              similarProjectIds,
              visibleColumns,
              collectionProjectIds: resourceIds,
            }),
          );
        }
        setIsSearching(false);
      },
      DebounceTimes.Search,
    ),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await debounceSearch({
      searchTerm,
      isCollection,
      visibleColumns,
      similarProjectIds,
      filters: transformFilters(sidebarFilters),
      resourceIds: selectedCollection?.resourceIds,
    });
  };

  return (
    <>
      <DeleteModal
        title="Delete Projects"
        description="Are you sure you want to delete these projects?"
        loading={loading}
        openModal={openModal}
        handleDelete={handleArchiveProjects}
        handleCloseModal={handleClose}
      >
        <div className="px-4 pb-4">
          <SingleCheckbox
            id="remove-attachments"
            label="Delete all files associated with the project"
            checked={deleteRelatedResources}
            onChange={handleCheckBox}
          />
        </div>
      </DeleteModal>
      <div className="w-full sticky top-0 z-30 bg-white pb-2">
        <div className="w-full flex flex-row items-center justify-between">
          <CollectionDropdown
            label="Projects"
            goBackLabel="All Projects"
            resourceIds={selectedProjects}
            collectionType={AllowedCollectionType.Projects}
            routes={{
              goBack: "/projekte",
              collection: "/projekte/sammlungen",
            }}
            getResourceIds={handleResourceIds}
            clearSelectedResources={clearSelectedProjects}
          />
          <div className="flex justify-end items-center">
            <ExpandableSearch
              searchTerm={searchTerm}
              handleSearch={handleSearch}
            />
            {hasSelectedProjects && (
              <>
                {!isCollection && (
                  <CollectionButton
                    btnClassName="mr-2"
                    resourceIds={selectedProjects}
                    collectionRoute="/projekte/sammlungen"
                    collectionType={AllowedCollectionType.Projects}
                    getResourceIds={handleResourceIds}
                    clearSelectedResources={clearSelectedProjects}
                  />
                )}
                <Tooltip
                  id="info-btn"
                  position="bottom"
                  message="Cost estimate"
                >
                  <Button
                    className="focus:ring-0"
                    size="xs"
                    onClick={handleCostEstimate}
                  >
                    <CurrencyEuroIcon className="h-5 w-5" />
                  </Button>
                </Tooltip>
                <div>
                  {projectsGeneralPermissions[
                    GeneralPermissionTypes.Delete
                  ] && (
                    <Tooltip
                      id="delete-btn"
                      position="bottom"
                      message={
                        isCollection ? "Remove from collection" : "Delete"
                      }
                    >
                      <Button
                        size="xs"
                        variant={updateCollectionLoader ? "primary" : "default"}
                        onClick={handleDeleteProjects}
                      >
                        {isCollection ? (
                          <CircleMinusIcon className="h-5 w-5" />
                        ) : (
                          <TrashIcon className="h-5 w-5" />
                        )}
                        {updateCollectionLoader && (
                          <Spinner className="border-white" spinnerSize="xs" />
                        )}
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </>
            )}
            {!hasSelectedProjects && (
              <div className="flex flex-row justify-end">
                {!isCollection && (
                  <Tooltip
                    id="widgets-btn"
                    position="bottom"
                    message="Show Widgets"
                  >
                    <Button
                      className={clsx("focus:ring-0", {
                        "bg-gray-900": showAggregations,
                      })}
                      size="xs"
                      variant={showAggregations ? "secondary" : "default"}
                      onClick={toggleShowAggregations}
                    >
                      <ChartPieIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip
                  id="toggle-view-btn"
                  position="bottom"
                  message="Toggle View"
                >
                  <Button size="xs" onClick={handleToggleView}>
                    {isMapOrTableView(projectView) ? (
                      <Squares2X2Icon className="h-5 w-5" />
                    ) : (
                      <TableCellsIcon className="h-5 w-5" />
                    )}
                  </Button>
                </Tooltip>
                <Tooltip id="map-btn" position="bottom" message="Show Map">
                  <Button
                    size="xs"
                    onClick={handleMap}
                    variant={
                      projectView === ProjectView.Map ? "secondary" : "default"
                    }
                  >
                    <MapIcon className="h-5 w-5" />
                  </Button>
                </Tooltip>
              </div>
            )}
            {!hasSelectedProjects && !isCollection && (
              <Tooltip id="similarity-search" message="Run Similarity Search">
                <Button
                  className={clsx("focus:ring-0", {
                    "bg-gray-900": openSearchModal,
                  })}
                  size="xs"
                  variant="default"
                  disabled={openSearchModal}
                  onClick={handleOpenSimilaritySearch}
                >
                  <ReactSVG
                    src="/svg/similarity_search_icon.svg"
                    className="h-5 w-5"
                  />
                </Button>
              </Tooltip>
            )}
            <div className="flex flex-row">
              <Tooltip
                id="filters-btn"
                position="bottom"
                message="Show Filters"
              >
                <Button
                  className={clsx("focus:ring-0", {
                    "bg-gray-900": sidebarOpen,
                  })}
                  size="xs"
                  variant={sidebarOpen ? "secondary" : "default"}
                  onClick={() => setOpenSidebar(!sidebarOpen)}
                >
                  <FunnelIcon className="h-5 w-5" />
                </Button>
              </Tooltip>
              {projectsGeneralPermissions[GeneralPermissionTypes.Create] && (
                <Tooltip
                  id="new-project-btn"
                  position="bottom"
                  message="New Project"
                >
                  <Button
                    variant="default"
                    size="xs"
                    onClick={handleNewProject}
                    withGroup
                  >
                    <PlusIcon className="h-5 w-5" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="h-6">
          {hasSelectedProjects && (
            <div className="flex flex-row items-center">
              <span className="amenda-component-label font-apercu no-underline focus:outline-none text-sm"></span>

              {projectView === ProjectView.Gallery && (
                <>
                  <Link className="group" onClick={clearSelectedProjects}>
                    <span className="amenda-component-label text-sm">
                      {t("Deselect All")} ({selectedProjects.length.toString()})
                    </span>
                  </Link>
                  <Link className="ml-3" onClick={handleSelectAll}>
                    <span>{t("Select All")}</span>
                  </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
