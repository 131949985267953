import { FC, memo } from "react";

import { AllowedCollectionType } from "@amenda-types";
import { CollectionDropdown } from "@amenda-components/Shared";
import { IconButtonBase } from "@amenda-components/App";
import { XIcon } from "lucide-react";
import { useGetAllMaterials } from "@amenda-domains/queries";
import { useMaterialsStore } from "@amenda-domains/mutations";

export const MaterialsCollectionDropdown: FC = memo(() => {
  const { getAllMaterials } = useGetAllMaterials();
  const resourceIds = useMaterialsStore((state) => state.selectedMaterialIds);
  const clearSelectedMaterialIds = useMaterialsStore(
    (state) => state.clearSelectedMaterialIds,
  );
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const handleClose = () => {
    setOpenMaterialListSlideOver(false);
  };

  const handleGetResourceIds = async (args: any) => {
    const materials = await getAllMaterials({
      skipStore: true,
      formValues: args,
    });
    return materials?.map((material: any) => material.id);
  };

  return (
    <div className="w-full flex items-center justify-between pb-4">
      <CollectionDropdown
        wrapperClassName="justify-between"
        label="Materials"
        goBackLabel="All Materials"
        resourceIds={resourceIds}
        collectionType={AllowedCollectionType.Materials}
        routes={{
          goBack: "/einheitspreise",
          collection: "/einheitspreise/sammlungen",
        }}
        getResourceIds={handleGetResourceIds}
        clearSelectedResources={clearSelectedMaterialIds}
      />
      <div className="block md:hidden">
        <IconButtonBase variant="clean" label="Close" onClick={handleClose}>
          <XIcon className="w-6 h-6" />
        </IconButtonBase>
      </div>
    </div>
  );
});
