import {
  AvailableNotificationTypes,
  FileUploaderDropZoneValidator,
  MediaDropzoneUploadStatus,
} from "@amenda-types";
import { FC, ReactNode, memo, useCallback, useEffect } from "react";
import { useAppStore, useAttachmentStore } from "@amenda-domains/mutations";

import { FileLimits } from "./common";
import clsx from "clsx";
import { devConsole } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";

interface Props {
  noClick?: boolean;
  noKeyboard?: boolean;
  noDrag?: boolean;
  maxFileSize?: number;
  disabled?: boolean;
  acceptedFileTypes?: Record<string, string[]>;
  hideBorder?: boolean;
  maxFiles?: number;
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
  onDrop?: (acceptedFiles: any[]) => void;
  onDropRejected?: (rejectedFiles: any[]) => void;
  validator?: (file: File) => FileUploaderDropZoneValidator;
}

export const FileUploaderDropZone: FC<Props> = memo(
  ({
    className,
    children,
    disabled,
    noClick,
    hideBorder,
    wrapperClassName = "w-full",
    maxFileSize = FileLimits["50MB"],
    acceptedFileTypes = {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    onDrop: handleOnDrop,
    ...rest
  }) => {
    const showNotification = useAppStore((state) => state.showNotification);
    const setFilesToUpload = useAttachmentStore(
      (state) => state.setFilesToUpload,
    );
    const uploadStatus = useAttachmentStore(
      (state) => state.mediaDropZoneFiles.status,
    );
    const updateMediaDropzoneFiles = useAttachmentStore(
      (state) => state.updateMediaDropzoneFiles,
    );

    const onDrop = useCallback(
      (acceptedFiles: any[]) => {
        if (isEmpty(acceptedFiles)) return;
        if (!handleOnDrop) {
          showNotification(
            AvailableNotificationTypes.General,
            `Uploading file(s) ${acceptedFiles.length}`,
          );
          setFilesToUpload(acceptedFiles);
        } else {
          handleOnDrop(acceptedFiles);
        }
      },
      [handleOnDrop, showNotification, setFilesToUpload],
    );

    const onError = useCallback((error: any) => {
      devConsole?.warn("amenda:FileUploaderDropZone, error, ", error);
    }, []);

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      disabled,
      noClick,
      maxSize: maxFileSize,
      multiple: true,
      accept: acceptedFileTypes,
      onDrop,
      onError,
      ...rest,
    });

    useEffect(() => {
      return () => {
        updateMediaDropzoneFiles({
          status: MediaDropzoneUploadStatus.Success,
        });
      };
    }, [updateMediaDropzoneFiles]);

    return (
      <div className={wrapperClassName}>
        <div
          {...getRootProps({
            className: clsx(
              "border-dashed border-2 transition duration-75 ease-in-out",
              className,
              {
                "cursor-pointer": !noClick,
                "cursor-not-allowed": disabled,
                "border-red-500 px-2":
                  isDragReject ||
                  uploadStatus === MediaDropzoneUploadStatus.Error,
                "border-green-500 px-2": isDragAccept,
                "border-gray-300 px-2": !isDragActive && !hideBorder,
                "border-transparent": !isDragActive && hideBorder,
              },
            ),
          })}
        >
          <input {...getInputProps()} />
          {children}
        </div>
      </div>
    );
  },
);
