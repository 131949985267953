import { FC, useEffect } from "react";
import { useFormStore, useProjectStore } from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { ProjectsPageWrapper } from "./ProjectsPageWrapper";

export const AllProjectsCollectionPage: FC = () => {
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const clearPagination = useProjectStore((state) => state.clearPagination);

  useEffect(() => {
    clearPagination();

    return () => {
      clearSelectedCollectionByType(AllowedCollectionType.Projects);
    };
  }, [clearSelectedCollectionByType, clearPagination]);

  return <ProjectsPageWrapper isCollection={true} />;
};
