import { BlockedRoutes, PaginationLimit } from "@amenda-constants";
import { FC, useEffect } from "react";

import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { HelperMessage } from "@amenda-components/App";
import { Link } from "react-router-dom";
import { ProjectSnippet } from "@amenda-components/Projects";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const RecentProjects: FC = () => {
  const { t } = useTranslation();
  const { getAllProjects, loading } = useGetAllProjects();
  const projects = useProjectStore((state) => state.projects);

  useEffect(() => {
    getAllProjects({
      paginationProps: {
        limit: PaginationLimit.dashboardPreviews,
      },
      selectProjectFields: {
        _id: 1,
        name: 1,
        galleryUrl: 1,
        tenantId: 1,
        address: 1,
      },
    });
  }, [getAllProjects]);

  return (
    <div className="bg-white overflow-hidden h-full">
      <div className="px-4 py-6">
        <h2 className="text-base font-medium text-gray-900">
          {t("Recent Project Updates")}
        </h2>
        <div className="flow-root mt-6">
          <div className="py-2 flex justify-center">
            <div className="grid grid-cols-1 w-full">
              {projects.length >= 1 ? (
                <ProjectSnippet projects={projects} loader={loading} />
              ) : (
                <HelperMessage
                  Icon={BuildingOffice2Icon}
                  message="Get started by creating a new project."
                  helpText="Please create a Project first"
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-6">
          {projects.length >= 1 ? (
            <Link to="/projekte" className="amenda-button">
              {t("View all")}
            </Link>
          ) : (
            <Link
              to={BlockedRoutes.ProjectNew}
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              {t("Create New Project")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
