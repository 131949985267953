import {
  AllowedCollectionType,
  AllowedContactType,
  AppRoutes,
} from "@amenda-types";
import {
  useGetAllUsers,
  useGetUser,
  useSearchUsers,
} from "@amenda-domains/queries";

import { BlockedRoutes } from "@amenda-constants";
import { ContactDirectoryWrapper } from "./ContactDirectoryWrapper";
import { FC } from "react";
import { useParams } from "react-router-dom";

export const UsersPage: FC = () => {
  const { userId } = useParams<{ userId?: string }>();
  const { getAllUsers } = useGetAllUsers();
  const { searchUsers } = useSearchUsers();
  const { getUser } = useGetUser();

  return (
    <ContactDirectoryWrapper
      autoSelect={!userId}
      singularTitle="Colleague"
      pluralTitle="Colleagues"
      collectionType={AllowedCollectionType.Users}
      rootRoute={AppRoutes.Colleagues}
      collectionRoute={BlockedRoutes.ColleaguesCollection}
      defaultContactType={AllowedContactType.office}
      getContact={getUser}
      getAllContacts={getAllUsers}
      searchContacts={searchUsers}
    />
  );
};
