import { FC, useEffect } from "react";

import { AllowedAttachmentType } from "@amenda-types";
import { PdfListView } from "@amenda-components/FileUploads";
import { ProjectTemplates } from "@amenda-components/Projects";
import { TemplateModal } from "@amenda-components/Templates";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useSearchAttachments } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

export const ProjectTemplateDesigner: FC<{ projectId?: string }> = ({
  projectId,
}) => {
  const { t } = useTranslation();
  const { searchAttachments, loading } = useSearchAttachments();
  const attachments = useAttachmentStore((state) => state.attachments);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      setAttachments({
        attachments: [],
      });

      if (projectId) {
        await searchAttachments({
          filters: {
            type: AllowedAttachmentType.pdf,
            "formValues.project": projectId,
          },
        });
      }
    };

    searchAttachmentAsync();

    return () => {
      setAttachments({
        attachments: [],
      });
    };
  }, [projectId, setAttachments, searchAttachments]);

  return (
    <>
      <TemplateModal projectId={projectId} />
      <div className="py-5">
        <ProjectTemplates projectId={projectId} />
        <h3 className="amenda-form-heading">{t("Project Designs")}</h3>
        <PdfListView
          loading={loading}
          uploadedFiles={attachments}
          canUpdateGallery={true}
        />
      </div>
    </>
  );
};
