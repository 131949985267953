import { FormBuilderComponent, availableLayouts } from "@amenda-types";

import { FormCategories } from "./forms";
import { generateReadableId } from "@amenda-utils";

export const getTemplateComponents = (
  category?: FormCategories,
  formName?: string,
): FormBuilderComponent[] => {
  const initialComponentId = generateReadableId("Initial Component");
  const layout =
    category === FormCategories.Projects
      ? availableLayouts.twoColumnsFullWidth
      : availableLayouts.fullColumnGrid;
  const label =
    category === FormCategories.Projects
      ? "Two columns grid"
      : "Full column grid";
  const parentId = generateReadableId(label);

  const components = [
    {
      componentId: initialComponentId,
      parentId: null,
      order: 0,
      layout: availableLayouts.default,
      properties: { label: formName ?? "Initial Info" },
    },
    {
      componentId: parentId,
      parentId: initialComponentId,
      order: 0,
      layout,
      properties: {
        label,
      },
    },
  ];
  const formComponents = [
    {
      isSearchable: true,
      parentId,
      layout: availableLayouts.fullColumn,
      component: "Input",
      properties: { label: "Title", type: "text" },
      validation: {
        type: "string",
        properties: { label: "Title", required: false },
      },
    },
    {
      isSearchable: true,
      isBulkEditable: true,
      parentId,
      layout: availableLayouts.fullColumn,
      component: "Keyword",
      properties: { label: "Tags", canCreateResourceSpecificKeywords: true },
      validation: {
        type: "array",
        properties: { label: "Tags", required: false },
      },
    },
    {
      isSearchable: true,
      isBulkEditable: true,
      parentId,
      layout: availableLayouts.halfColumn,
      component: "RadioButton",
      properties: {
        label: "Terms of Use",
        horizontal: true,
        options: [
          { value: "free_to_use", label: "Free to use" },
          { value: "internal_use_only", label: "Internal use only" },
          { value: "on_request", label: "On request" },
        ],
      },
      validation: {
        type: "string",
        properties: { label: "Terms of Use", required: false },
      },
    },
    {
      parentId,
      layout: availableLayouts.halfColumn,
      component: "Select",
      properties: {
        label: "Material Klassifizierung",
        options: [
          { value: "bepflanzung", label: "Bepflanzung" },
          { value: "beschichtung", label: "Beschichtung" },
          { value: "beton", label: "Beton" },
          {
            value: "bezeichnung_material_materialklassifizierung",
            label: "Bezeichnung Material Materialklassifizierung",
          },
        ],
      },
      validation: {
        type: "string",
        properties: {
          label: "Material Klassifizierung",
          required: false,
        },
      },
    },
    {
      parentId,
      layout: availableLayouts.fullColumn,
      component: "LabelledInput",
      properties: {
        label: "E-Mail-Adresse",
        type: "email",
        isFullWidth: true,
      },
      validation: {
        properties: { label: "E-Mail-Adresse" },
      },
    },
    {
      parentId,
      layout: availableLayouts.fullColumn,
      component: "Textarea",
      validation: {
        type: "string",
        properties: { label: "Description", required: false },
      },
      properties: { label: "Description" },
    },
  ];

  components.push(
    ...formComponents.map((c, i) => {
      const id = generateReadableId(c?.properties?.label);

      return {
        ...c,
        componentId: id,
        order: i,
        validation: {
          ...(c?.validation || {}),
          componentId: id,
        },
      };
    }),
  );

  return components;
};
