import { AvailableImageVariants } from "@amenda-types";
import { FC } from "react";
import { Image } from "@amenda-components/App";
import { formatYear } from "@amenda-utils";
import { useTranslation } from "react-i18next";

export interface TemplateThumbnailProps {
  name: string;
  orientation: string;
  parentTemplateName: string;
  format?: string;
  updatedAt: string;
  type?: string;
  isUserTemplate: boolean;
  thumbnailUrl?: string;
}

interface Props {
  thumbnail: TemplateThumbnailProps;
  showLastUpdatedDate?: boolean;
  handleSelectThumbnail: (thumbnail: TemplateThumbnailProps) => void;
}

export const TemplateThumbnail: FC<Props> = ({
  thumbnail,
  showLastUpdatedDate = true,
  handleSelectThumbnail,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => handleSelectThumbnail(thumbnail)}
        className="flex flex-col overflow-hidden h-auto w-52 group relative cursor-pointer"
      >
        <div className="shrink-0">
          <Image
            ignoreMagicLinks={true}
            url={`/images/${thumbnail.name}.jpg`}
            size="pb-16/17"
            lazyLoad={false}
            background="bg-gray-100"
            variant={AvailableImageVariants.contain}
          />
        </div>
      </div>
      <div className="flex-1 p-2">
        <p className="mt-2 block text-xs font-medium text-gray-900 truncate pointer-events-none">
          {thumbnail.parentTemplateName
            ? thumbnail.parentTemplateName
            : thumbnail.name}
        </p>
        {thumbnail && (
          <p className="block text-xs font-medium text-gray-500 pointer-events-none">
            {`Format: ${thumbnail?.format?.toUpperCase()}, ${thumbnail?.type?.toUpperCase()}`}
          </p>
        )}
        {showLastUpdatedDate && (
          <p className="block text-xs font-medium text-gray-500 pointer-events-none">
            {t("Last update:")} {formatYear(thumbnail.updatedAt)}
          </p>
        )}
      </div>
    </>
  );
};
