export enum AvailableForms {
  Project = "projects.default",
  Gallery = "gallery.default",
  Materials = "materials.default",
  Company = "contacts.company.default",
  Persons = "contacts.persons.default",
  Office = "contacts.office.default",
  CurrentUser = "contacts.loggedInUser.default",
  CostGroupsCost = "costGroups.cost.default",
  CostGroupsQuantity = "costGroups.quantity.default",
  ContactsCreation = "contacts.creation.default",
  ConstructionDetails = "constructionDetails.default",
}

export enum FormCategories {
  Projects = "projects",
  Media = "gallery",
  UnitPrice = "materials",
  Contacts = "contacts",
  OfficeContacts = "contacts.office",
  PersonsContacts = "contacts.persons",
  CompanyContacts = "contacts.company",
  CostGroups = "costGroups",
  ConstructionDetails = "constructionDetails",
}

export enum FormComponentTypes {
  AddressSearch = "AddressSearch",
  Badges = "Badges",
  CalculatorTextField = "CalculatorTextField",
  Checkbox = "Checkbox",
  ColoredSelect = "ColoredSelect",
  DatePicker = "DatePicker",
  DatePickerRange = "DatePickerRange",
  Input = "Input",
  Image = "Image",
  RadioButton = "RadioButton",
  Select = "Select",
  Keyword = "Keyword",
  MultiSelect = "MultiSelect",
  Switch = "Switch",
  Textarea = "Textarea",
  Hidden = "Hidden",
  Save = "Save",
  SearchAndSelect = "SearchAndSelect",
  SearchAndSelectProjects = "SearchAndSelectProjects",
  Title = "Title",
  LabelledInput = "LabelledInput",
  LabelledContactInputs = "LabelledContactInputs",
  MaterialCostInput = "MaterialCostInput",
  MaterialPercentageValues = "MaterialPercentageValues",
  MaterialPhoto = "MaterialPhoto",
  SelectCostGroups = "SelectCostGroups",
  RegionalSelect = "RegionalSelect",
}
