import { AvailablePermissions, permissionHeaders } from "@amenda-types";
import {
  findMatchingComponents,
  getLeafNodeComponents,
} from "@amenda-components/PageBuilder/common";
import {
  getCostGroupPermissionsName,
  getPermissionName,
} from "@amenda-components/Settings/common";

import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { CostGroupComponentStaticId } from "@amenda-constants";
import { FC } from "react";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import getFromPath from "lodash/get";
import { isEmpty } from "lodash";
import { useWatch } from "react-hook-form";

interface Props {
  name: string;
  isChecked: boolean;
  onClick: () => void;
}

const PermissionRadioButton: FC<Props> = ({ name, isChecked, onClick }) => {
  return (
    <div className="w-full flex justify-end py-3">
      <SingleRadioButton
        id={name}
        checked={isChecked}
        className="h-4 w-4 text-gray-900"
        onChange={onClick}
      />
    </div>
  );
};

const GeneralSettingsPermissionConfig: FC<ComponentsTreeViewRowProps> = ({
  isOpen,
  control,
  component,
  searchTerm,
  column,
  componentsById,
  level = 0,
  setValue,
  expandAllComponentsInGroup,
}) => {
  const form = useWatch({ control });

  const name = getPermissionName(component);
  const parentComponent = componentsById[component.id];
  const hasChildren = Boolean(parentComponent?.components);
  const components = getLeafNodeComponents(
    parentComponent?.components || [],
    level + 1
  );
  const header = permissionHeaders.find(({ label }) => label === column.id);

  const findMatchingChildComponents = () => {
    if (searchTerm) {
      return findMatchingComponents(components, searchTerm);
    }
    return components;
  };

  const setChildrenValues = (value?: string) => {
    const components = findMatchingChildComponents();
    components.forEach((component) => {
      setValue?.(getPermissionName(component), value);
    });
  };

  const changeParentNoPermissions = (component: any, value?: string) => {
    const parentComponent = componentsById[component.parentId];
    if (!parentComponent) return;

    const parentValue = getFromPath(form, getPermissionName(parentComponent));
    if (
      parentValue === AvailablePermissions.Restricted &&
      value !== AvailablePermissions.Restricted
    ) {
      setValue?.(getPermissionName(parentComponent), value);
    }
  };

  const setParentValues = (value?: string) => {
    setValue?.(getPermissionName(parentComponent), value);
    changeParentNoPermissions(parentComponent, value);
  };

  const setParentAndChildrenValues = (value?: string) => () => {
    setParentValues(value);
    setChildrenValues(value);
  };

  const onClickParent = (value?: string) => {
    if (!isOpen) {
      expandAllComponentsInGroup(components);
    }
    setTimeout(setParentAndChildrenValues(value), 50);
  };

  const onClickChild = (value?: string) => {
    setValue?.(getPermissionName(component), value);
    changeParentNoPermissions(component, value);
  };

  const onClick = (permission?: string) => {
    hasChildren ? onClickParent(permission) : onClickChild(permission);
  };

  const isChecked = (permission?: string) => {
    if (hasChildren) {
      const components = findMatchingChildComponents();

      return isEmpty(components)
        ? false
        : components.every((component) => {
            const permissionName = getPermissionName(component);
            return Boolean(getFromPath(form, permissionName) === permission);
          });
    }
    return Boolean(getFromPath(form, name) === permission);
  };

  return (
    <PermissionRadioButton
      key={name}
      name={name}
      isChecked={isChecked(header?.value)}
      onClick={() => onClick(header?.value)}
    />
  );
};

const CostGroupPermissionConfig: FC<ComponentsTreeViewRowProps> = ({
  control,
  column,
  setValue,
}) => {
  const form = useWatch({ control });

  const name = getCostGroupPermissionsName();
  const header = permissionHeaders.find(({ label }) => label === column.id);

  const isChecked = (permission?: string) => {
    return Boolean(getFromPath(form, name) === permission);
  };

  const onClick = (permission?: string) => {
    setValue?.(name, permission);
  };

  return (
    <PermissionRadioButton
      key={name}
      name={name}
      isChecked={isChecked(header?.value)}
      onClick={() => onClick(header?.value)}
    />
  );
};

export const SettingsPermissionConfig: FC<ComponentsTreeViewRowProps> = (
  props
) => {
  const { component } = props;
  const type = component?.component;

  if (type === CostGroupComponentStaticId) {
    return <CostGroupPermissionConfig {...props} />;
  }
  return <GeneralSettingsPermissionConfig {...props} />;
};
