import { FC } from "react";
import clsx from "clsx";
import { formatNumbers } from "@amenda-utils";

export const AppendSymbol: FC<{
  className?: string;
  symbol: string;
  value: number | string;
}> = ({ className, symbol, value }) => {
  const formattedValue = formatNumbers(value);
  const defaultValue = isNaN(Number(value)) ? "_" : formattedValue;

  switch (symbol) {
    case "metres_sqr":
    case "m²":
      return (
        <div className={clsx(className)}>
          {defaultValue} m<sup>2</sup>
        </div>
      );
    case "currency_euro":
      return <div className={clsx(className)}>{defaultValue} &#128;</div>;
    default:
      return (
        <div className={clsx(className)}>
          {defaultValue} {symbol}
        </div>
      );
  }
};
