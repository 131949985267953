import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface AlertProps {
  btnLabelSuccess?: string;
  isOpen: boolean;
  message: string;
  onCancel: () => void;
  onSubmit: () => void;
  title?: string;
}

export const Alert: FC<AlertProps> = ({
  btnLabelSuccess = "Submit",
  isOpen,
  message,
  onCancel,
  onSubmit,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-[90]"
      onClose={() => {}}
    >
      <DialogBackdrop className="fixed inset-0 overflow-y-auto bg-black/10 backdrop-blur-sm" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className="max-w-sm lg:max-w-xl bg-white p-4 w-full md:w-1/2 border border-gray-900 shadow-md absolute top-10 overflow-auto ease-out duration-300 data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <div className="flex">
            <div className="shrink-0">
              <ExclamationCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {title && (
                <h3 className="text-lg font-sans  text-gray-900">{t(title)}</h3>
              )}
              <div className="max-w-sm mt-2 text-sm font-apercu text-gray-900">
                <p>{t(message)}</p>
              </div>
              <div className="mt-6">
                <div className="-mx-2 -my-1.5 flex flex-col lg:flex-row gap-2">
                  <button
                    onClick={onCancel}
                    type="button"
                    className="w-[240px] ml-2 bg-white border border-gray-900 px-2 py-1.5 text-sm font-apercu text-gray-900 hover:text-white hover:bg-gray-900 focus:outline-none"
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="w-[240px] ml-2 bg-white border border-gray-900 hover:border-red-500 px-2 py-1.5 text-sm font-apercu text-gray-900 hover:text-white hover:bg-red-500 focus:outline-none"
                  >
                    {t(btnLabelSuccess)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};
