import { FC, ReactNode, useEffect } from "react";
import {
  useGetAllForms,
  useGetCurrentTenant,
  useGetCurrentUser,
} from "@amenda-domains/queries";
import {
  useSettingsStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { devConsole } from "@amenda-utils";
import { useGetSystemRole } from "@amenda-domains/queries/settings";
import { useOrganization } from "@frigade/react";
import { useUser } from "@frigade/react";

interface Props {
  children: ReactNode;
}

export const FormsEarlyLoader: FC<Props> = ({ children }) => {
  const { getSystemRole } = useGetSystemRole();
  const { getAllForms } = useGetAllForms();
  const setCurrentUserSystemRole = useSettingsStore(
    (state) => state.setCurrentUserSystemRole,
  );
  const { getCurrentUser } = useGetCurrentUser();
  const { getCurrentTenant } = useGetCurrentTenant();
  const currentUser = useUsersStore((state) => state.currentUser);
  const { setUserIdWithProperties } = useUser();
  const { setOrganizationIdWithProperties } = useOrganization();
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    getCurrentTenant();
  }, [getCurrentTenant]);

  useEffect(() => {
    if (currentUser.id) {
      getSystemRole({
        usersIds: [currentUser.id],
        callback: setCurrentUserSystemRole,
      });
      setUserIdWithProperties(currentUser.id, {
        name: currentUser.firstName + " " + currentUser.lastName,
        email: currentUser.email,
        organization: primaryTenant.tenantId,
      });
      setOrganizationIdWithProperties(primaryTenant.tenantId, {
        name: primaryTenant.displayName,
        logo: primaryTenant.logoUrl,
      });
    }
  }, [
    currentUser.email,
    currentUser.firstName,
    currentUser.id,
    currentUser.lastName,
    currentUser.tenantId,
    getSystemRole,
    primaryTenant.displayName,
    primaryTenant.logoUrl,
    primaryTenant.tenantId,
    setCurrentUserSystemRole,
    setOrganizationIdWithProperties,
    setUserIdWithProperties,
  ]);

  useEffect(() => {
    devConsole?.info("amenda:FormsEarlyLoader: fetching forms");
    getAllForms();
  }, [getAllForms]);

  return <>{children}</>;
};
