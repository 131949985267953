import { AllowedCollectionType } from "@amenda-types";
import { CollectionMenu } from "@amenda-components/Shared";
import { ContactDirectoryProps } from "./types";
import { FC } from "react";

interface Props extends Pick<ContactDirectoryProps, "canDeleteUsers"> {
  label?: string;
  isDeleting?: boolean;
  isCollection: boolean;
  resourceIds: string[];
  collectionRoute: string;
  collectionType: AllowedCollectionType;
  getResourceIds: (args: any) => Promise<string[]>;
  handleDeleteUsers: () => void;
}

export const ContactsCollectionMenu: FC<Props> = ({
  isCollection,
  canDeleteUsers,
  handleDeleteUsers,
  ...rest
}) => {
  return (
    <CollectionMenu
      {...rest}
      isCollection={isCollection}
      showDelete={Boolean(isCollection || canDeleteUsers)}
      handleDeleteFromCollection={handleDeleteUsers}
    />
  );
};
