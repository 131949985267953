import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FC, useRef } from "react";

import { AvailableNotificationTypes } from "@amenda-types";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useAppStore } from "@amenda-domains/mutations";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const defaultTitle: Record<string, string> = {
  general: "Info",
  success: "Success",
  warning: "Attention needed",
  error: "Something went wrong",
};

const NotificationCard: FC = () => {
  const { t } = useTranslation();
  const closeBtnRef = useRef(null);
  const notification = useAppStore((state) => state.notification);
  const closeNotification = useAppStore((state) => state.closeNotification);

  const { openNotification = false, type, message } = notification || {};
  const Icon =
    type === AvailableNotificationTypes.Success
      ? CheckCircleIcon
      : type === AvailableNotificationTypes.Error
        ? XMarkIcon
        : AvailableNotificationTypes.Warning
          ? ExclamationTriangleIcon
          : null;

  return (
    <Transition
      show={openNotification}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed right-0 top-0 z-[100] w-96 mt-2 lg:mr-2 md:mx-1 bg-white shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              {Icon && (
                <div
                  className={clsx(
                    "mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
                    {
                      "bg-green-100":
                        type === AvailableNotificationTypes.Success,
                      "bg-red-100": type === AvailableNotificationTypes.Error,
                      "bg-orange-100":
                        type === AvailableNotificationTypes.Warning,
                    },
                  )}
                >
                  <Icon
                    className={clsx("h-6 w-6", {
                      "text-green-600":
                        type === AvailableNotificationTypes.Success,
                      "text-red-600": type === AvailableNotificationTypes.Error,
                      "text-orange-600":
                        type === AvailableNotificationTypes.Warning,
                    })}
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {type && (
                <p className="text-base font-medium text-gray-500">
                  {t(defaultTitle[type])}
                </p>
              )}
              {message && (
                <p className="font-apercu mt-1 text-sm text-gray-900">
                  {t(message)}
                </p>
              )}
            </div>
            <div className="ml-4 shrink-0 flex">
              <button
                ref={closeBtnRef}
                type="button"
                className="bg-white rounded-md inline-flex text-gray-600 hover:text-gray-800 z-20"
                onClick={closeNotification}
              >
                <span className="sr-only">{t("Close")}</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const Notifications: FC = () => {
  const notification = useAppStore((state) => state.notification);
  const closeNotification = useAppStore((state) => state.closeNotification);

  useEffect(() => {
    const timeout = () => setTimeout(closeNotification, 6000);

    if (notification?.openNotification) {
      timeout();
    }

    return () => {
      clearTimeout(timeout());
    };
  }, [notification?.openNotification, closeNotification]);

  return <>{createPortal(<NotificationCard />, document.body)}</>;
};
