import { gql, useClient } from "urql";
import { useCallback, useState } from "react";

import { TENANT_FRAGMENT } from "@amenda-domains/fragments/tenant";
import { useTenantStore } from "@amenda-domains/mutations";

export const GET_CURRENT_TENANT = gql`
  ${TENANT_FRAGMENT}
  query GetCurrentTenant {
    getCurrentTenant {
      ...TenantFragment
    }
  }
`;

export const useGetCurrentTenant = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setPrimaryTenant = useTenantStore((state) => state.setPrimaryTenant);

  const getCurrentTenant = useCallback(
    async (context: Record<string, any> = {}) => {
      setLoading(true);

      const { data } = await client
        .query(GET_CURRENT_TENANT, {}, context)
        .toPromise();

      if (data?.getCurrentTenant) {
        setPrimaryTenant(data.getCurrentTenant);
      }
      setLoading(false);
      return data?.getCurrentTenant;
    },
    [client, setPrimaryTenant]
  );

  return {
    loading,
    getCurrentTenant,
  };
};
