import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { QuantityEstimateColumns } from "./common";
import { TextField } from "@amenda-components/FormComponents";
import { Tooltip } from "@amenda-components/App";
import { useController } from "react-hook-form";

const QuantityComponents: FC<ComponentsTreeViewRowProps & { id: string }> = ({
  id,
  component,
  control,
  globalData,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });

  const message =
    globalData?.missingQuantities?.includes(component.id) &&
    !Number.isFinite(value)
      ? "This value is being referenced in costs, enter quantity to enable calculations"
      : error?.message;

  return (
    <div className="w-full flex justify-end">
      <div className="md:min-w-48 max-w-sm">
        <Tooltip id={id} position="right" className="w-full" message={message}>
          <TextField
            id={id}
            value={value}
            type="number"
            inputClassName="h-6 text-xs font-apercu text-right pr-8"
            error={message}
            hideErrorMessage={true}
            endAddOnText={component?.properties?.endAddOnText}
            onChange={onChange}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export const QuantityEstimateBody: FC<ComponentsTreeViewRowProps> = ({
  component,
  ...rest
}) => {
  const id = `considerationValues.${component.id}`;

  if (rest.column.id === QuantityEstimateColumns.Placeholder) {
    return <div />;
  }
  return (
    <QuantityComponents key={id} id={id} component={component} {...rest} />
  );
};
