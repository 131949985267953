import {
  AllowedCategories,
  AvailableNotificationTypes,
  TemplateProps,
} from "@amenda-types";
import { gql, useMutation } from "urql";

import { FormComponentTypes } from "@amenda-constants";
import { create } from "zustand";
import { getDefaultURL } from "@amenda-utils";
import { immer } from "zustand/middleware/immer";
import { isEmpty } from "lodash";
import { useAppStore } from "./app";

const modifyUserTemplates = (
  userTemplates: any[] = [],
  currentTemplateData: any = {}
) => {
  const userTemplateIds = userTemplates.map((userTemplate) => userTemplate.id);
  if (isEmpty(currentTemplateData)) return userTemplates;
  if (userTemplateIds.includes(currentTemplateData.id)) {
    return userTemplates.map((userTemplate) =>
      userTemplate.id === currentTemplateData.id
        ? currentTemplateData
        : userTemplate
    );
  }
  return [...userTemplates, { ...currentTemplateData }];
};

const isDefaultTemplate = (template: TemplateProps) => {
  return !!template.structure && !template.templateId;
};

const addInfoToUserTemplates = (defaultTemplates: any[], template: any) => {
  const foundTemplate = defaultTemplates.find(
    (defaultTemplate) => defaultTemplate.id === template.templateId
  );
  return {
    ...template,
    structure: foundTemplate?.structure || [],
    format: foundTemplate?.format,
  };
};

type State = {
  selectedTemplate: Record<string, any>;
  selectedTemplateData: Record<string, any>;
  defaultTemplates: any[];
  userTemplates: any[];
  openTemplateDesignModal: boolean;
  templateImage: Record<string, any>;
  selectedPhoto: Record<string, any>;
  photoSlots: Record<string, any>;
  formTemplateProperties: Record<string, any>;
  isEditing: boolean;
};

type Actions = {
  resetTemplateBuilder: () => void;
  setSelectedTemplateData: (template: Record<string, any>) => void;
  setIsEditing: (isEditing: boolean) => void;
  setSelectedTemplate: (template: Record<string, any>) => void;
  clearSelectedTemplateData: () => void;
  setDefaultTemplates: (defaultTemplates: any[]) => void;
  setFormTemplateProperties: (
    formTemplateProperties: Record<string, any>,
    merge?: boolean
  ) => void;
  setUserTemplates: (selectedTemplateData: Record<string, any>) => void;
  setTemplateDesignModal: (openTemplateDesignModal: boolean) => void;
  setSelectedPhoto: (selectedPhoto: Record<string, any>) => void;
  updateTemplateImage: (key: string, value: any) => void;
  setInitialPhotoSlots: (components: any[]) => void;
  togglePhotoSlots: (lockPhotoId: string) => void;
  clearTemplateImage: () => void;
};

export const useTemplatesStore = create(
  immer<State & Actions>((set, get) => ({
    selectedTemplate: {},
    selectedTemplateData: {},
    defaultTemplates: [],
    userTemplates: [],
    openTemplateDesignModal: false,
    templateImage: {},
    selectedPhoto: {},
    photoSlots: {},
    formTemplateProperties: {},
    isEditing: false,
    clearTemplateImage: () =>
      set((state) => {
        state.templateImage = {};
      }),
    togglePhotoSlots: (lockPhotoId) =>
      set((state) => {
        const photoSlots = get().photoSlots;

        Object.keys(photoSlots).forEach((key) => {
          if (key === lockPhotoId) {
            state.photoSlots[key] = !photoSlots[key];
          } else {
            state.photoSlots[key] = true;
          }
        });
      }),
    setInitialPhotoSlots: (components) =>
      set((state) => {
        let photoSlots: Record<string, boolean> = {};
        components.forEach((component) => {
          if (component?.component === FormComponentTypes.Image) {
            photoSlots = {
              ...photoSlots,
              [component.id]: true,
            };
          }
        });

        state.photoSlots = photoSlots;
      }),
    updateTemplateImage: (key, value) =>
      set((state) => {
        state.templateImage[key] = value;
      }),
    setSelectedPhoto: (selectedPhoto) =>
      set((state) => {
        state.selectedPhoto = selectedPhoto;
      }),
    setTemplateDesignModal: (openTemplateDesignModal) =>
      set((state) => {
        state.openTemplateDesignModal = openTemplateDesignModal;
      }),
    setUserTemplates: (selectedTemplateData) =>
      set((state) => {
        state.userTemplates = modifyUserTemplates(
          state.userTemplates,
          selectedTemplateData
        );
      }),
    setFormTemplateProperties: (formTemplateProperties, merge = false) =>
      set((state) => {
        if (merge) {
          state.formTemplateProperties = {
            ...state.formTemplateProperties,
            ...formTemplateProperties,
          };
        } else {
          state.formTemplateProperties = formTemplateProperties;
        }
      }),
    setDefaultTemplates: (defaultTemplates) =>
      set((state) => {
        state.defaultTemplates = defaultTemplates;
      }),
    clearSelectedTemplateData: () =>
      set((state) => {
        state.selectedTemplateData = {};
      }),
    setSelectedTemplate: (template) =>
      set((state) => {
        if (template && isDefaultTemplate(template)) {
          state.selectedTemplate = template;
        } else {
          const selectedTemplate = addInfoToUserTemplates(
            state.defaultTemplates,
            template
          );
          state.selectedTemplate = selectedTemplate;
          state.selectedTemplateData = template;
        }
      }),
    setIsEditing: (isEditing) =>
      set((state) => {
        state.isEditing = isEditing;
      }),
    setSelectedTemplateData: (template) =>
      set((state) => {
        state.selectedTemplateData = template;
      }),
    resetTemplateBuilder: () =>
      set((state) => {
        state.selectedTemplate = {};
        state.selectedTemplateData = {};
        state.defaultTemplates = [];
        state.userTemplates = [];
        state.openTemplateDesignModal = false;
        state.templateImage = {};
        state.selectedPhoto = {};
        state.photoSlots = {};
        state.formTemplateProperties = {};
        state.isEditing = false;
      }),
  }))
);

const UPSERT_TEMPLATE_DATA = gql`
  mutation UpsertTemplateData($input: ProjectTemplateInput!) {
    templateData: upsertTemplateData(input: $input) {
      id: _id
      name
      projectId
      templateId
      createdAt
      updatedAt
      values
      thumbnailUrl
    }
  }
`;

export const UPSERT_TEMPLATE = gql`
  mutation upsertTemplate($input: TemplateInput!) {
    template: upsertTemplate(input: $input) {
      id: _id
      type
      format
      orientation
      createdAt
      updatedAt
      tenantId
      structure
    }
  }
`;

const GENERATE_TEMPLATE_PREVIEW_FROM_URL = gql`
  mutation ($input: UrlToTemplatePreviewInput!) {
    isGeneratingTemplatePreview: generateTemplatePreviewFromUrl(input: $input)
  }
`;

export const useUpsertTemplateData = (handleComplete: (data: any) => void) => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callUpsertTemplateData] = useMutation(UPSERT_TEMPLATE_DATA);
  const setSelectedTemplateData = useTemplatesStore(
    (state) => state.setSelectedTemplateData
  );

  const upsertTemplateData = (variables: Record<string, any>) => {
    return callUpsertTemplateData(variables)
      .then(({ data }) => {
        if (data?.templateData) {
          setSelectedTemplateData(data.templateData);
          handleComplete(data.templateData);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    upsertTemplateData,
    loading: result.fetching,
  };
};

export const useGenerateTemplatePreview = () => {
  const tuple = useMutation(GENERATE_TEMPLATE_PREVIEW_FROM_URL);
  const url = getDefaultURL();
  const generateTemplatePreview = tuple[1];

  const handleGenerateTemplatePreview = ({
    filename,
    resourceId,
    path,
  }: {
    path: string;
    filename: string;
    resourceId: string;
  }) =>
    generateTemplatePreview({
      input: {
        url: `${url}/${path}/generate-pdf`,
        options: {
          category: AllowedCategories.Projects,
          filename,
          resourceId,
        },
      },
    });

  return {
    handleGenerateTemplatePreview,
  };
};
