import { FC, ReactNode } from "react";

import { GalleryModal } from "@amenda-components/Gallery/GalleryModal";
import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  children: ReactNode;
}

export const ProjectLayout: FC<Props> = ({ children, title }) => {
  const { t } = useTranslation();

  return (
    <MaxWidthLayout>
      <GalleryModal />
      <Helmet title={t(title)} />
      <div className="flex flex-col h-full">{children}</div>
    </MaxWidthLayout>
  );
};
