import { ActionBar, Tooltip } from "@amenda-components/App";
import {
  AllowedContactType,
  AvailableNotificationTypes,
  GeneralPermissionTypes,
} from "@amenda-types";
import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  NotebookTabsIcon,
  SaveIcon,
  Share2Icon,
  SheetIcon,
} from "lucide-react";
import {
  useAppStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useNavigate, useParams } from "react-router-dom";

import { ContactDirectoryProps } from "./types";
import { FC } from "react";
import clsx from "clsx";
import { downloadVCard } from "@amenda-utils";
import { flattenUserDetails } from "./common";
import { useGetContact } from "@amenda-domains/queries";

interface Props
  extends Pick<ContactDirectoryProps, "canDeleteUsers" | "rootRoute"> {
  isCollection: boolean;
  contact: any;
  setOpenModal: (open: boolean) => void;
  setOpenShareModal: (open: boolean) => void;
}

export const ContactActionBar: FC<Props> = ({
  contact,
  canDeleteUsers,
  rootRoute,
  setOpenModal,
  setOpenShareModal,
}) => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const currentUser = useUsersStore((state) => state.currentUser);
  const isSubmitting = useUsersStore((state) => state.isSubmitting);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const { getContact, loading } = useGetContact();
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );

  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );

  const handleOpenUsersTableView = () => {
    setOpenUsersTableView(true);
  };

  const handleEdit = () => {
    navigate(`${rootRoute}/${contact.id}`);
  };

  const handleReadOnly = () => {
    navigate(rootRoute);
  };

  const handleDelete = () => setOpenModal(true);

  const handleDownloadVCard = async () => {
    try {
      let company = {};
      if (contact?.company) {
        company = await getContact({
          id: contact.company,
          callback: () => {},
        });
        company = flattenUserDetails(company);
      }
      const name = [contact?.lastName, contact?.firstName]
        .filter(Boolean)
        .join(", ");

      downloadVCard(
        {
          ...contact,
          company,
        },
        name,
      );
      showNotification(AvailableNotificationTypes.Success, "VCard Downloaded");
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Issue whilte downloading VCard",
      );
    }
  };

  return (
    <ActionBar className="flex flex-row md:justify-end py-2">
      {({ IconButton }) => {
        return (
          <>
            <div className="block lg:hidden">
              <Tooltip id="tableView" message="Open table view">
                <IconButton
                  Icon={SheetIcon}
                  label="Open table view"
                  variant="default"
                  onClick={handleOpenUsersTableView}
                />
              </Tooltip>
            </div>
            {contact?.type === AllowedContactType.person && (
              <Tooltip id="notebook" message="Open contact as .vcf">
                <IconButton
                  Icon={NotebookTabsIcon}
                  label="VCF Download"
                  variant="default"
                  className={clsx({
                    "animate-pulse text-green-600": loading,
                  })}
                  onClick={handleDownloadVCard}
                />
              </Tooltip>
            )}
            {userId ? (
              <>
                <Tooltip
                  id="autoSave"
                  message="Your Data is automatically saved"
                >
                  <IconButton
                    Icon={SaveIcon}
                    label="Autosave"
                    className={clsx({
                      "animate-pulse text-green-600": isSubmitting,
                    })}
                    variant="base"
                  />
                </Tooltip>
                <Tooltip id="closeAndSave" message="Save and Close">
                  <IconButton
                    Icon={CheckIcon}
                    label="Save and Close"
                    variant="default"
                    onClick={handleReadOnly}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {contactsGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message="Share">
                    <IconButton
                      Icon={Share2Icon}
                      label="Share"
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    />
                  </Tooltip>
                )}
                {canDeleteUsers &&
                  contactsGeneralPermissions[GeneralPermissionTypes.Delete] &&
                  contact?.id !== currentUser?.id && (
                    <Tooltip id="deleteContact" message="Delete">
                      <IconButton
                        Icon={TrashIcon}
                        label="Delete Contact"
                        variant="default"
                        onClick={handleDelete}
                      />
                    </Tooltip>
                  )}
                {contactsGeneralPermissions[GeneralPermissionTypes.Edit] && (
                  <Tooltip id="editContact" message="Edit">
                    <IconButton
                      Icon={PencilIcon}
                      label="Edit Contact"
                      variant="default"
                      onClick={handleEdit}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </>
        );
      }}
    </ActionBar>
  );
};
