import { RefObject } from "react";
import { StylesConfig } from "react-select";

export const selectMenuOverflowProps: any = (hasMenuOverflow: boolean) => {
  return hasMenuOverflow
    ? {
        menuPosition: "fixed",
        menuPlacement: "auto",
        menuPortalTarget: document.body,
      }
    : {};
};

export const selectMenuOverflow = (
  hasMenuOverflow: boolean,
  ref?: RefObject<HTMLDivElement>,
  props?: {
    preventLineBreak?: boolean;
  },
): Partial<StylesConfig<any, boolean, any>> => {
  if (!hasMenuOverflow) {
    return {};
  }
  let providedMenuList: any = {
    width: `${ref?.current?.clientWidth ? `${ref.current.clientWidth}px` : "100%"}`,
  };
  let providedMenu: any = {};

  if (props?.preventLineBreak) {
    providedMenuList = {
      width: "max-content",
      minWidth: "120px",
    };
  }

  return {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 80,
      fontSize: "0.85rem",
      fontFamily: "Apercu",
    }),
    menu: (provided: any) => ({
      ...provided,
      ...providedMenu,
      maxHeight: "250px",
      overflowY: "visible",
      position: "relative",
      boxShadow: "unset",
    }),
    menuList: (provided: any) => ({
      ...provided,
      ...providedMenuList,
      backgroundColor: "#fff",
      "--tw-shadow":
        "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      "--tw-shadow-colored":
        "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
      boxShadow:
        "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    }),
  };
};

export const selectStylesControlOverrideMini: Partial<
  StylesConfig<any, boolean, any>
> = {
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      borderColor: "none",
    },
    borderColor: state.menuIsOpen
      ? "rgb(107 114 128 / var(--tw-border-opacity))"
      : "rgb(209 213 219 / var(--tw-border-opacity))",
    boxShadow: "none",
    minHeight: "auto",
    height: "2.25rem",
  }),
};

export const selectStylesControlOverride: Partial<
  StylesConfig<any, boolean, any>
> = {
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      borderColor: "none",
    },
    borderColor: "rgb(249 250 251)",
    backgroundColor: "rgb(243 244 246)",
    boxShadow: "none",
    minHeight: "2.75rem",
  }),
};

export const customStyling = {
  select: {
    containerClass: "amenda-component amenda-no-ring-around-input text-sm",
    styleOverride: {
      control: (provided, state) => ({
        ...provided,
        "&:hover": {
          borderColor: "none",
        },
        borderColor: state.menuIsOpen
          ? "rgb(107 114 128 / var(--tw-border-opacity))"
          : "rgb(209 213 219 / var(--tw-border-opacity))",
        boxShadow: "none",
        minHeight: "2.75rem",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: "4px 8px",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "black",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        fontSize: "inherit",
        color: "rgb(107 114 128)",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "black",
      }),
      option: (provided, state) => ({
        ...provided,
        "--tw-bg-opacity": 1,
        backgroundColor: state.isFocused
          ? "rgba(24, 24, 27, var(--tw-bg-opacity))" //bg-gray-900
          : undefined,
        color: state.isFocused ? "white" : undefined,
        "&:active": {
          backgroundColor: "rgba(24, 24, 27, var(--tw-bg-opacity))",
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
        "--tw-shadow":
          "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        "--tw-shadow-colored":
          "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
        boxShadow:
          "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
        zIndex: 50,
      }),
    } as StylesConfig<any, boolean, any>,
  },
};
