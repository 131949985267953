import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { firebaseApp, useAuthStore } from "@amenda-domains/mutations";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { AuthFormType } from "@amenda-types";
import { Spinner } from "@amenda-components/App";

interface Props {
  children: ReactNode;
}

export const FirebaseProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const setAuth = useAuthStore((state) => state.setAuth);

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    onAuthStateChanged(auth, (user) => {
      let isSignedIn: boolean = false;
      let loggedInUser: Record<string, any> = {};
      if (user) {
        const { displayName, email, tenantId, photoURL, uid } = user;
        isSignedIn = true;
        loggedInUser = {
          displayName,
          email,
          tenantId,
          photoURL,
          uid,
        };
      }
      setAuth({
        isSignedIn,
        loggedInUser,
        formType: AuthFormType.Login,
      });
      setLoading(false);
    });
  }, [setAuth]);

  if (loading) {
    return (
      <div className="flex justify-center h-screen items-center">
        <Spinner variant="primary" />
      </div>
    );
  }
  return <Fragment>{children}</Fragment>;
};
