import { Chip, Tag, Tags } from "@amenda-components/App";
import { FC, ReactNode, useEffect, useState } from "react";

import { EmptyIndicator } from "./EmptyIndicator";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useGetKeywords } from "@amenda-domains/queries";

interface Props {
  keywords?: string[];
  children: (
    loading: boolean,
    selectedKeywords?: {
      id: string;
      name: string;
    }[],
  ) => ReactNode;
}

export const ReadOnlyKeywordsBase: FC<Props> = ({ keywords, children }) => {
  const [selectedKeywords, setSelectedKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  useEffect(() => {
    const getKeywordsAsync = () => {
      const ids: string[] = !isNil(keywords)
        ? Array.isArray(keywords)
          ? keywords.filter(Boolean)
          : [keywords]
        : [];

      if (!isEmpty(ids)) {
        return getKeywords({
          ids,
          setKeywords: setSelectedKeywords,
        });
      }
      return setSelectedKeywords([]);
    };

    getKeywordsAsync();
    return () => {
      setSelectedKeywords([]);
    };
  }, [getKeywords, keywords]);

  return <>{children(loading, selectedKeywords)}</>;
};

export const ReadOnlyKeywordHeaders: FC<Omit<Props, "children">> = ({
  keywords,
}) => {
  return (
    <div className="w-full">
      <ReadOnlyKeywordsBase keywords={keywords}>
        {(loading, selectedKeywords) => (
          <TitleAndDescription
            container="column"
            description={
              loading ? (
                <span>...</span>
              ) : selectedKeywords ? (
                <div className="w-full flex flex-wrap items-center">
                  {selectedKeywords.map((keyword) => (
                    <Chip
                      key={keyword.id}
                      className="bg-gray-200 text-gray-600 h-8 mr-2 mb-1"
                      label={keyword.name}
                    />
                  ))}
                </div>
              ) : (
                <div />
              )
            }
          />
        )}
      </ReadOnlyKeywordsBase>
    </div>
  );
};

export const ReadOnlyKeywords: FC<
  Omit<Props, "children"> & {
    label: string;
  }
> = ({ keywords, label }) => {
  return (
    <ReadOnlyKeywordsBase keywords={keywords}>
      {(loading, selectedKeywords) => (
        <TitleAndDescription
          container="column"
          title={label}
          description={
            loading ? (
              <span>...</span>
            ) : !isEmpty(selectedKeywords) ? (
              <Tags>
                {selectedKeywords?.map(({ id, name }) => (
                  <Tag key={id}>{name}</Tag>
                ))}
              </Tags>
            ) : (
              <EmptyIndicator />
            )
          }
        />
      )}
    </ReadOnlyKeywordsBase>
  );
};
