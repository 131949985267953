import "@amenda-styles/index.css";

import * as Sentry from "@sentry/react";

import { Metric } from "web-vitals";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./appRouter";
import { devConsole } from "@amenda-utils";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.replayIntegration({ maskAllInputs: false }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      autoInject: false,
      colorScheme: "system",
    }),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    const location = window.location;
    const transformedEvent = event;
    transformedEvent.breadcrumbs = transformedEvent?.breadcrumbs?.filter(
      ({ category }) => category !== "console",
    );
    if (location.hostname === "localhost") {
      devConsole?.log("[Local:Sentry] event", transformedEvent);
      return null;
    }
    return transformedEvent;
  },
});

const sendToSentry = (metric: Metric) => {
  if (metric.rating === "needs-improvement" || metric.rating === "poor") {
    Sentry.captureMessage("Web Vitals", {
      level: "info",
      extra: {
        id: metric.id,
        rating: metric.rating,
        name: metric.name,
        delta: metric.delta,
      },
    });
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<RouterProvider router={appRouter} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToSentry);
