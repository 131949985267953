import { Link } from "@amenda-components/App";
import { FC } from "react";

interface MicrosoftLoginButtonProps {
  onClick: () => void;
  name?: string;
}

export const MicrosoftLoginButton: FC<MicrosoftLoginButtonProps> = ({
  onClick,
  name = "Mit Microsoft anmelden",
}) => {
  return (
    <Link
      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-900 hover:no-underline"
      onClick={onClick}
    >
      <svg height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
        <path fill="#f3f3f3" d="M0 0h23v23H0z" />
        <path fill="#f35325" d="M1 1h10v10H1z" />
        <path fill="#81bc06" d="M12 1h10v10H12z" />
        <path fill="#05a6f0" d="M1 12h10v10H1z" />
        <path fill="#ffba08" d="M12 12h10v10H12z" />
      </svg>
      <span className="font-apercu ml-2 no-underlin hover:no-underline">
        {name}
      </span>
    </Link>
  );
};
