import { useLocation, useParams } from "react-router-dom";

import { MaterialsPageWrapper } from "./MaterialsPageWrapper";
import { isAddingNewMaterial } from "@amenda-components/Materials/common";

export const AllMaterials = () => {
  const { pathname } = useLocation();
  const { materialId } = useParams<{ materialId: string }>();

  const preventAutoSelect = materialId || isAddingNewMaterial(pathname);

  return <MaterialsPageWrapper autoSelect={!preventAutoSelect} />;
};
