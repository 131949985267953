import { AuthLayout, ResetPasswordForm } from "@amenda-components/Auth";

import { FC } from "react";

export const ResetPassword: FC = () => {
  return (
    <AuthLayout label="Reset Your Password">
      <ResetPasswordForm />
    </AuthLayout>
  );
};
