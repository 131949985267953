import { AvailableForms, PermissionComponentKey } from "@amenda-constants";
import { Button, Modal } from "@amenda-components/App";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperWithConditions,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import {
  getComponentsDefaultValues,
  getComponentsFromForms,
  getFullName,
  getValidationSchema,
} from "@amenda-utils";
import {
  useCreateContact,
  useProjectStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { CreateResourceShareForm } from "@amenda-components/Shared/ShareModal";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const formId = "create-contact-form";

export const CreateContactModal: FC = () => {
  const { t } = useTranslation();
  const modals = useUsersStore((state) => state.modals);
  const setCreateContactModal = useUsersStore(
    (state) => state.setCreateContactModal,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const forms = useProjectStore((state) => state.forms ?? {});
  const { createContact, loading } = useCreateContact();

  const createContactForm = forms[AvailableForms.ContactsCreation];
  const components = getComponentsFromForms(createContactForm);
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );
  const defaultShareType =
    contactsGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];
  const validationSchema = getValidationSchema(components);
  const defaultValues = getComponentsDefaultValues(components);

  const { control, setValue, trigger, getValues, reset } = useForm<any>({
    values: {
      shareType: defaultShareType,
    },
  });

  const handleClose = () => {
    setCreateContactModal(false);
  };

  const onSubmit = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const isValid = await trigger();

    const { shareType, contacts } = getValues();
    const { contactType, companyName, firstName, lastName, ...rest } =
      sanitizedData;

    const share = {
      type: shareType,
      users: contacts.map((c: any) => ({
        id: c.id,
        role: c[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };
    let input: any = {
      share: isValid ? share : { type: defaultShareType },
    };

    if (contactType === AllowedContactType.person) {
      input = {
        type: contactType,
        firstName,
        lastName,
        fullName: getFullName(firstName, lastName),
        contactDetails: rest,
        ...input,
      };

      await createContact({
        input,
      });
    } else if (contactType === AllowedContactType.company) {
      input = {
        type: contactType,
        fullName: companyName,
        contactDetails: {
          companyName,
          ...rest,
        },
        ...input,
      };

      await createContact({
        input,
      });
    } else if (contactType === "both") {
      const companyInput = {
        type: AllowedContactType.company,
        fullName: companyName,
        contactDetails: {
          companyName,
          ...rest,
        },
        ...input,
      };

      const company = await createContact({
        input: companyInput,
      });
      const personsInput = {
        type: AllowedContactType.person,
        firstName,
        lastName,
        fullName: getFullName(firstName, lastName),
        contactDetails: {
          ...rest,
          company: company?.id,
        },
        ...input,
      };

      await createContact({
        input: personsInput,
      });
    }

    reset({});
    handleClose();
  };

  return (
    <Modal
      isOpen={modals.openCreateContactModal}
      onClose={handleClose}
      size="md"
      className="xl:w-4/12 lg:w-8/12 w-11/12"
      contentClassName="flex flex-col max-h-[calc(100vh-10rem)] min-h-[500px] overflow-y-auto"
      title="Contact Info"
      closeModalFromTitle={true}
      loading={loading}
      footerChildren={({ loading }) => (
        <div className="w-full">
          <Button
            className="w-full flex justify-center"
            withGroup
            variant="primary"
            form={formId}
            loading={loading}
            type="submit"
          >
            {t("Save")}
          </Button>
        </div>
      )}
    >
      <div className="flex-grow">
        <FormAutoSaveWrapperWithConditions
          formId={formId}
          className="py-0"
          ignoreResourceIdAlways={true}
          defaultValues={defaultValues}
          inputSchema={validationSchema}
          components={components}
          values={{}}
          disableAutosave={true}
          onSubmit={onSubmit}
        />
      </div>
      <div className="w-full">
        <CreateResourceShareForm control={control} setValue={setValue} />
      </div>
    </Modal>
  );
};
