import * as Yup from "yup";

import { AvailableForms, FormComponentTypes } from "./forms";
import { FormTab, PageComponentProps, availableLayouts } from "@amenda-types";

import { LocaleObject } from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import setPath from "lodash/set";

export const LoginSchema = Yup.object({
  email: Yup.string().email().required().label("E-mail"),
  password: Yup.string().required().label("Passwort"),
});

export const AddUserSchema = Yup.object({
  email: Yup.string().email().required().label("E-mail"),
});

export const yupLocaleObject: LocaleObject = {
  mixed: {
    required: ({ path }) => `${path} ist erforderlich`,
    notType: ({ path, type }) => {
      switch (type) {
        case "number":
          return `${path} muss eine Zahl sein`;
        case "string":
          return `${path} muss eine Zeichenfolge sein`;
        case "boolean":
          return `${path} muss ein Boolean sein`;
        default:
          return `${path} ist ungültig`;
      }
    },
  },
  string: {
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    min: ({ min }) => `Mindestens ${min} Zeichen`,
    url: ({ value }) => {
      const startsWithHttp =
        value.startsWith("http://") || value.startsWith("https://");
      if (!startsWithHttp) {
        return "Die URL sollte mit http oder https beginnen";
      }
      return "Bitte geben Sie eine gültige URL ein";
    },
  },
  number: {
    min: ({ min, path }) => `${path} sollte nicht darunter liegen ${min}`,
    max: ({ max, path }) => `${path} sollte nicht überschritten werden ${max}`,
    integer: "Bitte geben Sie ein ein. Wert, der keine Dezimalstellen enthält",
  },
  array: {
    min: ({ min, path }) => `Minimale anzahl an ${path} ist ${min}`,
    max: ({ max, path }) => `Maximale anzahl an ${path} ist ${max}`,
  },
};

Yup.setLocale(yupLocaleObject);

export const RegistrationSchema = Yup.object({
  consent: Yup.bool()
    .required()
    .oneOf([true], "Please accept the terms and conditions"),
  displayName: Yup.string().required().label("Name Ihres Büros"),
  firstName: Yup.string().required().label("Vorname"),
  lastName: Yup.string().required().label("Nachname"),
  email: Yup.string().email().required().label("E-mail"),
  password: Yup.string()
    .required("Bitte geben Sie Ihr Passwort ein")
    .label("Passwort")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Muss mindestens 8 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten",
    ),
  confirmPassword: Yup.string()
    .required()
    .label("Passwort bestätigen")
    .oneOf([Yup.ref("password")], "Passwörter müssen übereinstimmen"),
});

export const UpdateProfileSchema = Yup.object({
  firstName: Yup.string().required().label("Vorname"),
  lastName: Yup.string().required().label("Nachname"),
  password: Yup.string()
    .required("Bitte geben Sie Ihr Passwort ein")
    .label("Passwort")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Muss mindestens 8 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten",
    ),
  confirmPassword: Yup.string()
    .required()
    .label("Passwort bestätigen")
    .oneOf([Yup.ref("password")], "Passwörter müssen übereinstimmen"),
});

export const CompanySchema = Yup.object({
  displayName: Yup.string().label("Display Name"),
  officeAddress: Yup.mixed().label("Office Address"),
  technicalContactPerson: Yup.string().label("Technical Contact"),
  responsibleContactPerson: Yup.string().label("Responsible Contact"),
  billingEmailAddress: Yup.string().label("Billing E-mail"),
  taxId: Yup.string().label("Tax ID Number"),
});

export const SecuritySchema = Yup.object({
  currentPassword: Yup.string().required().label("Aktuelles Passwort"),
  newPassword: Yup.string()
    .required("Bitte geben Sie Ihr Passwort ein")
    .label("Passwort")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Muss mindestens 8 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten",
    ),
  confirmPassword: Yup.string()
    .required()
    .label("Passwort bestätigen")
    .oneOf([Yup.ref("newPassword")], "Passwörter müssen übereinstimmen"),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Bitte geben Sie Ihr Passwort ein")
    .label("Passwort")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Muss mindestens 8 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten",
    ),
  confirmPassword: Yup.string()
    .required()
    .label("Passwort bestätigen")
    .oneOf([Yup.ref("password")], "Passwörter müssen übereinstimmen"),
});

export const ResetPasswordSchema = Yup.object({
  email: Yup.string().email().required().label("E-mail"),
});

export const roleInputSchema = Yup.object({
  name: Yup.string().required().label("Role Name"),
  permissions: Yup.object().label("Permissions"),
  description: Yup.string().required().label("Description"),
});

export const similaritySearchInputSchema = Yup.object({
  similarityConfig: Yup.object(),
});

export const userInputSchema = Yup.object({
  email: Yup.string().email().required().label("Email"),
  status: Yup.string().label("Status"),
  systemRole: Yup.mixed().label("Role"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
});

const getFormComponents = (c: PageComponentProps) => c?.id && c?.component;

export const getCostGroupId = (
  key: "cost" | "quantities",
  c: PageComponentProps | string,
) => {
  const id = typeof c === "string" ? c : c?.id;
  return [key, id].filter(Boolean).join(".");
};

export const getCostGroupInputSchema = (forms: FormTab[] = []) => {
  const costComponents: PageComponentProps[] = [];
  const quantityComponents: PageComponentProps[] = [];

  forms.forEach((form) => {
    if (form?.category === AvailableForms.CostGroupsCost) {
      costComponents.push(...form?.components?.filter(getFormComponents));
    } else if (form?.category === AvailableForms.CostGroupsQuantity) {
      quantityComponents.push(...form?.components?.filter(getFormComponents));
    }
  });

  const valuesSchema: Record<string, any> = {};

  costComponents.forEach((c) => {
    setPath(
      valuesSchema,
      getCostGroupId("cost", c),
      Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .label("Cost"),
    );
  });
  quantityComponents.forEach((c) => {
    setPath(
      valuesSchema,
      getCostGroupId("quantities", c),
      Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .label("Quantity"),
    );
  });

  return Yup.object(valuesSchema);
};

export const costEstimateSchema = Yup.object({
  depth: Yup.number()
    .required()
    .label("Kostentiefe")
    .required("Kostentiefe ist erforderlich"),
  calculation: Yup.boolean().label("Berechnung"),
  estimationMode: Yup.boolean().label("Estimation mode"),
  region: Yup.mixed().required().label("Berechnung für Region"),
  considerationDate: Yup.string()
    .required()
    .label("Stand der Kostenermittlung"),
  selectedCostGroups: Yup.array().of(Yup.string()).label("Kostengruppe"),
  considerationValues: Yup.object().label("Kostenwerte"),
});

export const costGroupSchema = Yup.object({
  type: Yup.string().required().label("Type"),
  bkiDate: Yup.string().required().label("Stand der Kostenermittlung"),
  note: Yup.string().label("Beschreibung"),
  versionDate: Yup.string().required().label("Datum"),
  source: Yup.string().required().label("Quelle"),
  servicePhase: Yup.array()
    .of(Yup.string())
    .required()
    .label("Leistungsphasen"),
  generalContractorFactor: Yup.number()
    .integer()
    .min(0)
    .max(100)
    .required()
    .label("GU-Aufschlag"),
});

export const labelledInputSchema = async (
  type: "email" | "phone",
  values: any = {},
) => {
  const validation = {
    label: Yup.mixed().required().label("Label"),
    value:
      type === "email"
        ? Yup.string().email().required().label("Wert")
        : Yup.string()
            .test(
              "is-phone-number",
              "Dies ist keine gültige Telefonnummer",
              (value) => {
                if (value) {
                  return isValidPhoneNumber(value, "DE");
                }
                return false;
              },
            )
            .label("Wert"),
  };
  const schema = Yup.object(validation);

  try {
    await schema.validate(values, { strict: true });
  } catch (err: any) {
    return err.errors;
  }
};

export const materialUnitPriceSchema = Yup.object({
  total_purchase_quantity: Yup.number()
    .integer()
    .required()
    .label("Gesamt Abnahmemenge"),
  total_price: Yup.number().required().label("Gesamtpreis"),
  indication_year: Yup.string().label("Ermittlungsdatum (Angabe aus dem Jahr)"),
  source: Yup.string().required().label("Quelle"),
  status: Yup.boolean().label("Status: archiviert"),
});

export const materialPercentageValuesSchema = Yup.object({
  percentage: Yup.number().min(0).max(100).required().label("Prozentsatz"),
  indication_year: Yup.string().label("Ermittlungsdatum (Angabe aus dem Jahr)"),
  source: Yup.string().required().label("Quelle"),
  status: Yup.boolean().label("Status: archiviert"),
});

export const costEstimateProjectSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  number: Yup.string().label("Nummer"),
});

export const collectionSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  description: Yup.string().label("Beschreibung"),
  expirationDate: Yup.string()
    .when("shareable", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .label("Läuft ab am"),
  tags: Yup.array().of(Yup.mixed()).label("Tags"),
  shareable: Yup.boolean().label("Freigeben"),
  shareWithUserTags: Yup.array().of(Yup.mixed()).label("Mit Tags teilen"),
  shareWithContacts: Yup.array().of(Yup.mixed()).label("Mit Kontakten teilen"),
  shareWithRoles: Yup.array().of(Yup.mixed()).label("Mit Rollen teilen"),
});

export const activityTimelineSchema = Yup.object({
  body: Yup.string().min(3).max(1200).required().label("Body"),
  activity: Yup.string().required().label("Activity"),
});

export const detectedFaceSchema = Yup.object({
  label: Yup.string().min(3).required().label("Label"),
});

export const sharingPermissionsSchema = Yup.object({
  shareType: Yup.string().required().label("Access"),
  contacts: Yup.array().of(Yup.mixed()).label("Kontakten"),
});

export const sidebarFiltersSchema = Yup.object({});

export const getFormBuilderSchema = (config: PageComponentProps) => {
  let yupObject: Record<string, Yup.AnySchema> = {
    label:
      config?.component === FormComponentTypes.Checkbox
        ? Yup.string().label("Label")
        : Yup.string().required().label("Label"),
    placeholder: Yup.string().label("Placeholder"),
    isSearchable: Yup.boolean().label("Is Searchable"),
    isGroupable: Yup.boolean().label("Is Groupable"),
    isBulkEditable: Yup.boolean().label("Is Bulk Editable"),
    options: Yup.array().of(Yup.mixed()).label("Options"),
    hasHeaderProperties: Yup.boolean().label("Allow header properties"),
    headerLayout: Yup.string()
      .when("hasHeaderProperties", {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .label("Layout"),
    titleClassName: Yup.string().label("CSS tailwind classes"),
    descriptionClassName: Yup.string().label("CSS tailwind classes"),
    withTitle: Yup.boolean().label("Show title?"),
    displayConditions: Yup.array().label("Display Conditions"),
    conditionalValidations: Yup.array().label("Validations"),
    hasConditionalValidations: Yup.boolean().label(
      "Has conditional validations?",
    ),
  };

  if (!Boolean(config?.id)) {
    yupObject = {
      ...yupObject,
      layout: Yup.string().required().label("Layout"),
      component: Yup.string()
        .when("layout", {
          is: (val: any) =>
            [
              availableLayouts.fullColumn,
              availableLayouts.halfColumn,
              availableLayouts.oneThirdColumn,
              availableLayouts.twoThirdColumn,
            ].includes(val),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema,
        })
        .label("Komponente"),
    };
  }

  return Yup.object(yupObject);
};
