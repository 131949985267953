import { FC, useEffect, useState } from "react";
import { useProjectStore, useTenantStore } from "@amenda-domains/mutations";

import { AppRoutes } from "@amenda-types";
import { BottomSheet } from "@amenda-components/Shared";
import { SimilaritySearchFormWrapper } from "./SimilaritySearchFormWrapper";
import { SimilaritySearchResultsFilter } from "./SimilaritySearchResultsFilter";
import { generateSimilarityComponents } from "./common";
import { getComponentsById } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";
import { useGetNeighborProjects } from "@amenda-domains/queries";
import { useLocation } from "react-router-dom";

export const SimilaritySearchBottomSheet: FC = () => {
  const { pathname } = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const openSearchModal = useProjectStore((state) => state.openSearchModal);
  const setOpenSearchModal = useProjectStore(
    (state) => state.setOpenSearchModal
  );
  const clearSelectedProjects = useProjectStore(
    (state) => state.clearSelectedProjects
  );
  const setOpenSimilaritySearch = useProjectStore(
    (state) => state.setOpenSimilaritySearch
  );
  const setSimilaritySearchModalValues = useProjectStore(
    (state) => state.setSimilaritySearchModalValues
  );
  const setSimilaritySearchComponents = useProjectStore(
    (state) => state.setSimilaritySearchComponents
  );
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const projectFormComponents = useProjectStore(
    (state) => state.projectFormComponents
  );
  const similaritySearchModalValues = useProjectStore(
    (state) => state.similaritySearchModalValues
  );
  const { getNeighborProjects } = useGetNeighborProjects();

  const componentsByIds = getComponentsById(projectFormComponents);

  const handleCloseOnMount = () => {
    setIsMounted(false);
    setOpenSearchModal(false);
    setOpenSimilaritySearch(false);
  };

  const handleClose = () => {
    clearSelectedProjects();
    handleCloseOnMount();
    setSimilaritySearchModalValues([]);
    setSimilaritySearchComponents([]);
  };

  useEffect(() => {
    const isProjectRoot = pathname === AppRoutes.Projects;
    const config = primaryTenant?.settings?.similaritySearchConfig;

    const handleRunSimilaritySearch = async () => {
      if (!isEmpty(similaritySearchModalValues)) {
        setOpenSimilaritySearch(true);
        setOpenSearchModal(true);
        await getNeighborProjects({
          args: {
            formValues: similaritySearchModalValues,
          },
        });
      }
    };

    const handleMountComponents = () => {
      if (
        !isEmpty(config) &&
        !isEmpty(componentsByIds) &&
        isEmpty(similaritySearchModalValues)
      ) {
        const components = generateSimilarityComponents(
          componentsByIds,
          config
        );
        setSimilaritySearchComponents(components);
      }
    };

    if (isProjectRoot && !isMounted) {
      handleMountComponents();
      handleRunSimilaritySearch();
      setIsMounted(true);
    }
  }, [
    pathname,
    isMounted,
    componentsByIds,
    similaritySearchModalValues,
    primaryTenant?.settings?.similaritySearchConfig,
    getNeighborProjects,
    setOpenSearchModal,
    setOpenSimilaritySearch,
    setSimilaritySearchComponents,
  ]);

  return (
    <BottomSheet
      label="Similarity Search"
      isOpen={openSearchModal}
      closeOnRouteChange={true}
      handleClose={handleClose}
      handleCloseOnMount={handleCloseOnMount}
    >
      {({ bottomSheetHeight }) => (
        <>
          <SimilaritySearchResultsFilter
            bottomSheetHeight={bottomSheetHeight}
          />
          <SimilaritySearchFormWrapper bottomSheetHeight={bottomSheetHeight} />
        </>
      )}
    </BottomSheet>
  );
};
