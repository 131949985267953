import { EmptyAttachments } from "./EmptyAttachments";
import { FC } from "react";
import { GalleryGridView } from "./GalleryGridView";
import { GalleryTable } from "./GalleryTable";
import { GalleryView } from "@amenda-types";
import { MediaGalleryProps } from "./common";
import { isEmpty } from "lodash";
import { useAttachmentStore } from "@amenda-domains/mutations";

type Props = Pick<
  MediaGalleryProps,
  "attachments" | "pagination" | "canUpload" | "fetchPaginatedAttachments"
>;

export const AllAttachmentsView: FC<Props> = ({
  attachments,
  pagination,
  canUpload,
  fetchPaginatedAttachments,
}) => {
  const selectedGalleryView = useAttachmentStore(
    (state) => state.selectedGalleryView,
  );
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);

  const isLoading = isSearching || isFetching;

  if (!isLoading && isEmpty(attachments)) {
    return <EmptyAttachments canUpload={canUpload} pagination={pagination} />;
  } else if (selectedGalleryView === GalleryView.Grid) {
    return (
      <GalleryGridView
        canUpload={canUpload}
        attachments={attachments}
        fetchPaginatedAttachments={fetchPaginatedAttachments}
      />
    );
  }
  return (
    <GalleryTable
      attachments={attachments}
      fetchPaginatedAttachments={fetchPaginatedAttachments}
    />
  );
};
