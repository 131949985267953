import { Link as CustomLink, SlideOver, Tooltip } from "@amenda-components/App";
import { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogOutIcon, MessageSquareDotIcon, SearchIcon } from "lucide-react";
import { useAppStore, useTenantStore } from "@amenda-domains/mutations";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Modal } from "./Modal";
import { NavigationProps } from "@amenda-types";
import { SearchModal } from "@amenda-components/SearchComponents";
import { allBlue } from "@amenda-styles/theme";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { matchUrls } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
}

export const Logo: FC = () => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  if (primaryTenant?.logoUrl) {
    return (
      <img
        alt="amenda"
        className="max-w-[40px] lg:max-w-[180px]"
        src={primaryTenant?.logoUrl}
      />
    );
  }
  return (
    <img
      alt="amenda"
      src="/images/logo.svg"
      className="pl-2 pt-1 h-[25px] w-auto"
    />
  );
};

const NotificationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const notificationMessages = useAppStore(
    (state) => state.notificationMessages,
  );
  const clearNotificationMessage = useAppStore(
    (state) => state.clearNotificationMessage,
  );

  const handleClick = (message: any) => () => {
    window.open(message.path);
    clearNotificationMessage(message);
  };

  return (
    <Modal title="Notifications" isOpen={isOpen} size="md" onClose={onClose}>
      {!isEmpty(notificationMessages) ? (
        <>
          {notificationMessages.map((message) => (
            <div key={message.path}>
              <CustomLink
                className={clsx(
                  allBlue.menus.panelItem.lg,
                  `hover:${allBlue.menus.panelItemActive.lg} hover:no-underline cursor-pointer w-full py-1`,
                )}
                onClick={handleClick(message)}
              >
                {message.message}
              </CustomLink>
            </div>
          ))}
        </>
      ) : (
        <p className="w-full px-2 py-4 text-center text-gray-700 text-sm font-medium">
          {t("No notifications found!")}
        </p>
      )}
    </Modal>
  );
};

export const SidebarFooter: FC<Props> = ({
  className = "pt-8 pb-2 bottom-0 absolute",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div className={className}>
      <NotificationModal isOpen={isOpen} onClose={handleClose} />
      <nav className="flex-1 px-2 w-full flex flex-row">
        {primaryTenant?.logoUrl && <PoweredBy />}
        <Tooltip id="logoutBtn" message="Logout">
          <Link
            to="/logout"
            className="group flex items-center py-2 px-2 w-full text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          >
            <LogOutIcon className="shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
          </Link>
        </Tooltip>
        <Tooltip id="notificationsBtn" message="Notifications">
          <button
            type="button"
            onClick={handleOpen}
            className="group flex items-center py-2 px-2 w-full text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          >
            <MessageSquareDotIcon className="shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
          </button>
        </Tooltip>
      </nav>
    </div>
  );
};

interface SidebarProps {
  navigation: NavigationProps[];
  closeSlideOver?: () => void;
}

export const PoweredBy: FC = () => {
  return (
    <div className="flex min-w-24 h-12">
      <div className="flex ">
        <div className="flex ">
          <span className="text-xxs text-gray-500 font-medium">
            Powered by{" "}
            <img
              alt="amenda"
              width="30px"
              src="/images/logo.svg"
              className="pl-2 pt-1 h-[15px] w-auto"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export const SidebarMenuItems: FC<SidebarProps> = ({
  navigation,
  closeSlideOver,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleSearch = () => toggleSearchModal(true);
  const isSlideOver = Boolean(closeSlideOver);

  const handleClick = (navigation: any) => () => {
    closeSlideOver?.();
    navigate(navigation.path);
  };

  return (
    <>
      <SearchModal />
      <nav className="pl-4 space-y-1">
        {!isSlideOver && (
          <button
            type="button"
            onClick={handleSearch}
            className="group flex items-center py-2 mt-4 pl-1 h-12 w-full text-sm font-medium text-gray-200 hover:bg-white hover:text-gray-900 rounded-tl-md rounded-bl-md"
          >
            <SearchIcon className="mr-3 shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
            <span className="opacity-0 group-hover:opacity-100 ease-in duration-300">
              {t("Search all")}
            </span>
          </button>
        )}
        {navigation.map((navigation) => {
          const pathMatches = matchUrls(pathname, navigation);

          return (
            <button
              type="button"
              key={navigation.path}
              onClick={handleClick(navigation)}
              className={clsx(
                "group flex items-center pl-4 pr-2 h-[64px] w-full text-sm font-medium rounded-tl-md rounded-bl-md",
                {
                  "bg-white text-gray-900 rounded-tl-md rounded-bl-md":
                    pathMatches,
                  "text-gray-500 hover:bg-gray-50 hover:text-gray-900":
                    !pathMatches,
                },
              )}
            >
              <span>{t(navigation.label)}</span>
            </button>
          );
        })}
      </nav>
    </>
  );
};

export const SidebarMobileMenu: FC<SidebarProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSearch = () => toggleSearchModal(true);

  return (
    <>
      <SlideOver show={isOpen} title="Menu" onClose={handleClose}>
        <div className="w-full h-full bg-[#F3F4F6] py-2">
          <SidebarMenuItems {...props} closeSlideOver={handleClose} />
          <SidebarFooter />
        </div>
      </SlideOver>
      <div className="lg:hidden block mb-12">
        <div className="absolute flex justify-between bg-white z-[60] w-full border-b border-gray-200 px-1 pt-0.5 pb-1">
          <div className="flex items-center space-x-2">
            <button type="button" className="p-1" onClick={handleOpen}>
              <Bars3Icon className="h-8 w-8" />
            </button>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <button type="button" onClick={handleSearch}>
            <MagnifyingGlassIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </>
  );
};
