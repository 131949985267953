import { GalleryModal, MoveAttachmentModal } from "@amenda-components/Gallery";

import { AllAttachmentsView } from "./AllAttachmentsView";
import { AttachmentsActionBar } from "./AttachmentsActionBar";
import { FC } from "react";
import { FaceAPIProvider } from "@amenda-context";
import { MediaGalleryProps } from "./common";

export const MediaGallery: FC<MediaGalleryProps> = ({ ...props }) => {
  return (
    <FaceAPIProvider>
      <GalleryModal />
      <MoveAttachmentModal />
      <AttachmentsActionBar {...props} />
      <AllAttachmentsView {...props} />
    </FaceAPIProvider>
  );
};
