import {
  AggregationOperations,
  AvailableWidgetTypes,
  ChartVariations,
} from "@amenda-types";
import { DynamicCharts, OperationsMenu } from "@amenda-components/Dashboard";
import { FC, useEffect, useState } from "react";
import { Skeleton, Tooltip } from "@amenda-components/App";
import {
  calculateAvg,
  calculateSum,
  chartOptions,
  findMax,
  findMin,
  operationOptions,
} from "@amenda-components/Dashboard/common";

import { formatNumbers } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useDashboardStore } from "@amenda-domains/mutations";

interface WidgetProps {
  id: string;
  label: string;
  loading?: boolean;
  isEditing?: boolean;
  componentId: string;
  adornment: string;
  type: AvailableWidgetTypes;
  operation: AggregationOperations;
}

const Widget: FC<WidgetProps> = ({
  id,
  type,
  label,
  isEditing,
  operation,
  adornment,
  componentId,
  loading = false,
}) => {
  const [calculatedValue, setCalculatedValue] = useState<number>();
  const data = useDashboardStore((state) => state.aggregationData);
  const updateWidgetProps = useDashboardStore(
    (state) => state.updateWidgetProps
  );

  const options: any[] =
    type === AvailableWidgetTypes.Formulae ? operationOptions : chartOptions;
  const processOperation = (op: AggregationOperations, values: number[]) => {
    let result = 0;
    switch (op) {
      case AggregationOperations.AVERAGE:
        result = calculateAvg(values);
        break;
      case AggregationOperations.MINIMUM:
        result = findMin(values);
        break;
      case AggregationOperations.MAXIMUM:
        result = findMax(values);
        break;
      case AggregationOperations.SUM:
      default:
        result = calculateSum(values);
        break;
    }
    return result;
  };

  const handleChange = ({ value }: any) => {
    updateWidgetProps(id, {
      operation: value,
    });
  };

  useEffect(() => {
    if (!isEmpty(data) && componentId && operation) {
      const widgetData = data[componentId];
      const result = processOperation(operation, widgetData || []);

      setCalculatedValue(result);
    }
  }, [data, operation, componentId]);

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="overflow-auto h-[95%]">
        {loading ? (
          <Skeleton height={6} />
        ) : (
          <>
            {type === AvailableWidgetTypes.Formulae ? (
              <div className="flex flex-row">
                {formatNumbers(Number(calculatedValue))}
                <span className="ml-1">{adornment}</span>
              </div>
            ) : (
              <>
                <p className="text-gray-500 font-apercu text-sm mb-1">
                  {label}
                </p>
                <DynamicCharts
                  chartVariant={operation as any as ChartVariations}
                />
              </>
            )}
          </>
        )}
        {type === AvailableWidgetTypes.Formulae && (
          <p className="text-gray-500 font-apercu text-sm">{label}</p>
        )}
      </div>
      <Tooltip
        id={id}
        className="flex self-end"
        message={!isEditing ? "Click edit to modify widget" : undefined}
      >
        <OperationsMenu
          disabled={!isEditing}
          options={options}
          label={operation}
          closeOnHover={true}
          selectedOption={operation}
          onChange={handleChange}
        />
      </Tooltip>
    </div>
  );
};

interface Props {
  loading?: boolean;
  isEditingWidgets?: boolean;
  widget: {
    id: string;
    properties: any;
  };
}

export const AggregationWidget: FC<Props> = ({
  widget,
  loading,
  isEditingWidgets,
}) => {
  return (
    <Widget
      id={widget.id}
      loading={loading}
      isEditing={isEditingWidgets}
      {...widget.properties}
    />
  );
};
