import { sanitizeData, sanitizeNestedData } from "@amenda-utils";

import { GeneralPermissionTypes } from "@amenda-types";

export const isAddingNewMaterial = (pathname: string) => {
  return pathname.includes("/neu");
};

export const transformFormToMaterial = ({
  form,
  resourceId,
  defaultShareType,
}: {
  form: Record<string, any>;
  resourceId?: string;
  defaultShareType?: GeneralPermissionTypes;
}) => {
  const { name, ...rest } = form;
  const data: Record<string, any> = {
    name,
    _id: resourceId,
    formValues: {
      ...rest,
    },
  };

  if (defaultShareType && !resourceId) {
    data.share = {
      type: defaultShareType,
    };
  }

  return sanitizeNestedData(data);
};

export const transformMaterialToForm = (material: Record<string, any> = {}) => {
  const { formValues, ...rest } = material;
  const form = {
    ...rest,
    ...(formValues || {}),
  };

  return sanitizeData(form);
};

export const materialSourcesOptions = [
  { value: "erfahrungswert", label: "Erfahrungswert" },
  { value: "angebot", label: "Angebot (inkl. 10% Zuschlag)" },
  { value: "herstellerangabe", label: "Herstellerangabe" },
  { value: "externer_fachplaner", label: "externer Fachplaner" },
  { value: "eigene_recherche", label: "eigene Recherche" },
  { value: "stlb_bau", label: "STLB-BAU" },
  { value: "bki", label: "BKI" },
  { value: "haendler_vertrieb", label: "Händler/Vertrieb" },
];

export const getMaterialSourceOption = (value: string) => {
  return materialSourcesOptions.find((option) => option.value === value);
};

export const staticMaterialLabels = {
  activity: {
    label: "Activity",
    value: "activity",
  },
};

export const getStaticMaterialTabs = (
  materialGeneralPermissions: any,
  isDisabled: boolean,
) => {
  return Object.keys(staticMaterialLabels)
    .map((key) => {
      return staticMaterialLabels[key as keyof typeof staticMaterialLabels];
    })
    .filter(({ value }) => {
      if (value === staticMaterialLabels.activity.value) {
        return Boolean(
          materialGeneralPermissions[GeneralPermissionTypes.Activity],
        );
      }
      return true;
    })
    .map((tabs) => ({
      ...tabs,
      disabled: isDisabled,
    }));
};
