import { useAppStore, useUpdateTenant } from "@amenda-domains/mutations";

import { AdminSettingsLayout } from "@amenda-components/Settings";
import { AvailableNotificationTypes } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { FC } from "react";
import { SimilaritySearchConfigFormWrapper } from "@amenda-components/Projects";
import { useTranslation } from "react-i18next";

const formId = "similaritySearchConfigForm";

export const SimilaritySearch: FC = () => {
  const { t } = useTranslation();
  const { updateTenant, updateTenantLoader } = useUpdateTenant();
  const showNotification = useAppStore((state) => state.showNotification);

  const handleSave = async (input: any) => {
    await updateTenant({
      input,
    });
    showNotification(AvailableNotificationTypes.Success, "Configuration saved");
  };

  return (
    <AdminSettingsLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Similarity Search")}</h1>
          <p className="amenda-settings-subline"></p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button
            type="submit"
            variant="primary"
            size="md"
            className="amenda-button"
            form={formId}
            loading={updateTenantLoader}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <SimilaritySearchConfigFormWrapper
          formId={formId}
          handleSave={handleSave}
        />
      </div>
    </AdminSettingsLayout>
  );
};
