import { KeenSliderInstance, KeenSliderPlugin } from "keen-slider/react";

import { MutableRefObject } from "react";

const FILE_SIZE_UNITS = ["B", "KB", "MB", "GB", "TB"];

export const FileLimits = {
  "50MB": 52428800,
  "5MB": 5242880,
};

const getFileSizeUnit = (size: number) => {
  const kb = 1024;
  return Math.floor(Math.log(size) / Math.log(kb));
};

export const formatFileSizeResult = (size: number) => {
  if (size === 0) return "0 Bytes";
  return `${getFileSize(size)} ${FILE_SIZE_UNITS[getFileSizeUnit(size)]}`;
};

export const getFileSize = (size: number) => {
  const kb = 1024;
  return parseFloat((size / Math.pow(kb, getFileSizeUnit(size))).toFixed(2));
};

export const getFileType = (file: any) => {
  if (file.type) {
    return file.type;
  }
  return (
    file.name?.substring(file.name?.lastIndexOf(".") + 1, file.name?.length) ||
    file.name
  );
};

export const isValidFileSize = (file: any, limit = FileLimits["50MB"]) =>
  file.size < limit;

export const isValidFileType = (file: any, validTypes: string[]) =>
  validTypes.includes(file.type);

export const thumbnailPlugin = (
  instanceSliderRef: MutableRefObject<KeenSliderInstance | null>,
  setSelectedFileId: (id: string) => void
): KeenSliderPlugin => {
  return (slider) => {
    const removeActive = () => {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    };
    const addActive = (id: number) => {
      setSelectedFileId(slider.slides[id].id);
      slider.slides[id].classList.add("active");
    };
    const addClickEvents = () => {
      slider.slides.forEach((slide, id) => {
        slide.addEventListener("click", () => {
          instanceSliderRef.current?.moveToIdx?.(id);
        });
      });
    };

    slider.on("created", () => {
      if (!instanceSliderRef.current) return;

      addActive(slider.track.details.rel);
      addClickEvents();
      instanceSliderRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
};
