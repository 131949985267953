import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import {
  chartConfig,
  handleChartData,
} from "@amenda-components/Dashboard/common";

import { Bar } from "react-chartjs-2";
import { ChartVariations } from "@amenda-types";
import { FC } from "react";
import { Line } from "react-chartjs-2";
import { devConsole } from "@amenda-utils";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const testLabels = [
  "Museum",
  "Büro- und Geschäftshaus",
  "Parkhaus",
  "Gewerbe",
  "Coworking",
];
const testData = [12, 19, 3, 5, 2, 3];

const renderCharts = ({
  t,
  chartVariant,
  data,
}: {
  t: (value: string) => string;
  chartVariant: ChartVariations;
  data: {
    labels: string[];
    datasets: any[];
  };
}) => {
  try {
    switch (chartVariant) {
      case ChartVariations.Pie:
        return <Pie data={data} options={chartConfig} />;
      case ChartVariations.Line:
        return <Line data={data} options={chartConfig} />;
      case ChartVariations.Bar:
        return <Bar data={data} options={chartConfig} />;
      case ChartVariations.Doughnut:
        return <Doughnut data={data} options={chartConfig} />;
      default:
        return <Doughnut data={data} options={chartConfig} />;
    }
  } catch (err) {
    devConsole?.warn(
      `amenda:an error occurred while trying to render the chart`,
      " error",
      err
    );
    return (
      <p>
        {t(
          "An error occurred while trying to render the chart. Please try again later"
        )}
      </p>
    );
  }
};

interface Props {
  chartVariant: ChartVariations;
  labels?: string[];
  data?: number[];
}

export const DynamicCharts: FC<Props> = ({
  chartVariant,
  labels = testLabels,
  data = testData,
}) => {
  const { t } = useTranslation();

  devConsole?.log("ChartVariant:", chartVariant);
  devConsole?.log("Labels:", labels);
  devConsole?.log("Data:", data);

  return (
    <div className="h-[10rem] w-full">
      {renderCharts({
        t,
        chartVariant,
        data: handleChartData(data, labels),
      })}
    </div>
  );
};
