import { AllowedContactType, availableLayouts } from "@amenda-types";
import { Control, Controller } from "react-hook-form";
import {
  SingleSelect,
  Switch,
  TextField,
} from "@amenda-components/FormComponents";
import { getComponentOptions, getLayoutOptions } from "./common";

import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
}

const FormComponentFields: FC<Props> = ({ control }) => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const { selectedFormComponent } = formBuilderState ?? {};
  const { config } = selectedFormComponent ?? {};

  return (
    <>
      <Controller
        name="placeholder"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            id="placeholder"
            label="Placeholder"
            error={error?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {[FormComponentTypes.Title].includes(config?.component) && (
        <Controller
          name="className"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              id="className"
              label="CSS tailwind classes"
              error={error?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      {[FormComponentTypes.Input].includes(config?.component) && (
        <>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <SingleSelect
                  id="type"
                  label="Type"
                  isClearable={false}
                  error={error?.message}
                  value={value}
                  getOptionLabel={(option) => t(option.label)}
                  hasMenuOverflow={true}
                  onChange={onChange}
                  options={[
                    { value: "number", label: "Number" },
                    { value: "email", label: "Email" },
                    { value: "password", label: "Password" },
                    { value: "text", label: "Text" },
                  ]}
                />
              );
            }}
          />
          <Controller
            name="startAddOnText"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id="startAddOnText"
                label="Add on text on the left"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="endAddOnText"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id="endAddOnText"
                label="Add on text on the right"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </>
      )}
      {[
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.LabelledContactInputs,
      ].includes(config?.component) && (
        <Controller
          name="contactType"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SingleSelect
                id="contactType"
                label="Contact type"
                isClearable={false}
                error={error?.message}
                value={value}
                getOptionLabel={(option) => t(option.label)}
                hasMenuOverflow={true}
                onChange={onChange}
                options={[
                  { value: AllowedContactType.office, label: "Office" },
                  { value: AllowedContactType.company, label: "Company" },
                  { value: AllowedContactType.person, label: "Persons" },
                ]}
              />
            );
          }}
        />
      )}
      {[FormComponentTypes.Textarea].includes(config?.component) && (
        <Controller
          name="rows"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              id="rows"
              label="Rows"
              type="number"
              error={error?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      {[FormComponentTypes.LabelledInput].includes(config?.component) && (
        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SingleSelect
                id="type"
                label="Type"
                isClearable={false}
                error={error?.message}
                value={value}
                getOptionLabel={(option) => t(option.label)}
                hasMenuOverflow={true}
                onChange={onChange}
                options={[
                  { value: "email", label: "Email" },
                  { value: "phone", label: "Phone" },
                ]}
              />
            );
          }}
        />
      )}
      {[
        FormComponentTypes.Badges,
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.SelectCostGroups,
      ].includes(config?.component) && (
        <Controller
          name="isMulti"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const handleChange = () => {
              field.onChange(!field.value);
            };

            return (
              <Switch
                label="Allow multiple selections?"
                checked={field.value}
                error={error?.message}
                onChange={handleChange}
              />
            );
          }}
        />
      )}
      {[FormComponentTypes.RadioButton].includes(config?.component) && (
        <Controller
          name="horizontal"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const handleChange = () => {
              field.onChange(!field.value);
            };

            return (
              <Switch
                label="Display horizontally?"
                checked={field.value}
                error={error?.message}
                onChange={handleChange}
              />
            );
          }}
        />
      )}
      {[FormComponentTypes.Switch].includes(config?.component) && (
        <>
          <Controller
            name="showLabel"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const handleChange = () => {
                field.onChange(!field.value);
              };

              return (
                <Switch
                  label="Show label?"
                  checked={field.value}
                  error={error?.message}
                  onChange={handleChange}
                />
              );
            }}
          />
          <Controller
            name="labelRight"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const handleChange = () => {
                field.onChange(!field.value);
              };

              return (
                <Switch
                  label="Show label on the right?"
                  checked={field.value}
                  error={error?.message}
                  onChange={handleChange}
                />
              );
            }}
          />
        </>
      )}
      <Controller
        name="isSearchable"
        control={control}
        render={({ field, fieldState: { error } }) => {
          const handleChange = () => {
            field.onChange(!field.value);
          };

          return (
            <Switch
              label="Allow search using this field?"
              checked={field.value}
              error={error?.message}
              onChange={handleChange}
            />
          );
        }}
      />
      <Controller
        name="isGroupable"
        control={control}
        render={({ field, fieldState: { error } }) => {
          const handleChange = () => {
            field.onChange(!field.value);
          };

          return (
            <Switch
              label="Allow grouping search results using this field?"
              checked={field.value}
              error={error?.message}
              onChange={handleChange}
            />
          );
        }}
      />
      <Controller
        name="isBulkEditable"
        control={control}
        render={({ field, fieldState: { error } }) => {
          const handleChange = () => {
            field.onChange(!field.value);
          };

          return (
            <Switch
              label="Allow bulk edit on this field?"
              checked={field.value}
              error={error?.message}
              onChange={handleChange}
            />
          );
        }}
      />
    </>
  );
};

export const FormBuilderComponentForm: FC<Props> = ({ control }) => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);

  const { selectedForm, selectedFormComponent, openCreateComponentModal } =
    formBuilderState ?? {};
  const { config } = selectedFormComponent ?? {};
  const parentComponent = (selectedForm?.components ?? []).find(
    (c) => c.id === config?.parentId,
  );

  return (
    <div className="flex flex-col space-y-2">
      <Controller
        name="label"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            id="label"
            label="Label"
            error={error?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {(Boolean(config?.component) || Boolean(openCreateComponentModal)) && (
        <Controller
          name="layout"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SingleSelect
              id="layout"
              label="Layout"
              isClearable={false}
              error={error?.message}
              value={value}
              getOptionLabel={(option) => t(option.label)}
              hasMenuOverflow={true}
              onChange={onChange}
              options={getLayoutOptions(parentComponent?.layout)}
            />
          )}
        />
      )}
      {Boolean(openCreateComponentModal) &&
        parentComponent?.layout &&
        parentComponent.layout !== availableLayouts.default && (
          <Controller
            name="component"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SingleSelect
                id="component"
                label="Component"
                isClearable={false}
                error={error?.message}
                value={value}
                getOptionLabel={(option) => t(option.label)}
                hasMenuOverflow={true}
                onChange={onChange}
                options={getComponentOptions()}
              />
            )}
          />
        )}
      {([FormComponentTypes.RadioButton, FormComponentTypes.Title].includes(
        config?.component,
      ) ||
        availableLayouts.default === parentComponent?.layout) && (
        <Controller
          name="description"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              id="description"
              label="Description"
              error={error?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      {!!config?.component && <FormComponentFields control={control} />}
    </div>
  );
};
