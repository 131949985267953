import { AllowedTimelineTypes, TabItem } from "@amenda-types";
import {
  AttachmentActionBar,
  AttachmentDetails,
  GalleryReferences,
} from "@amenda-components/Gallery";
import { FC, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { getStaticGalleryTabs, staticGalleryLabels } from "./common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { HeadlessTabs } from "@amenda-components/App";
import { Timeline } from "@amenda-components/Timeline";

const showPanel =
  (loading: boolean, attachmentId: string) => (tab: TabItem) => {
    if (tab.value === staticGalleryLabels.reference.value) {
      return <GalleryReferences />;
    } else if (tab.value === staticGalleryLabels.activity.value) {
      return (
        <Timeline
          resourceId={attachmentId}
          type={AllowedTimelineTypes.Material}
          className="w-full"
          textAreaClassName="w-60"
        />
      );
    }
    return <AttachmentDetails loading={loading} />;
  };

export const GalleryTabs: FC<{ loading: boolean }> = ({ loading }) => {
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {}
  );

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media
  );

  const availableForms = forms?.[AvailableForms.Gallery];

  const availableTabs = useMemo(() => {
    const tabs: TabItem[] =
      availableForms?.map((availableForm) => ({
        value: availableForm.id,
        label: availableForm.name,
      })) || [];
    tabs.push(...getStaticGalleryTabs(mediaGeneralPermissions));
    return tabs;
  }, [availableForms, mediaGeneralPermissions]);

  if (!availableForms) {
    return null;
  }
  return (
    <div className="px-4 pb-3 pt-4 h-full overflow-y-auto">
      <div className="hidden lg:block">
        <AttachmentActionBar />
      </div>
      <div className="pt-4">
        <HeadlessTabs tabs={availableTabs}>
          {showPanel(loading, selectedAttachment?.id)}
        </HeadlessTabs>
      </div>
    </div>
  );
};
