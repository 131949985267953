import { MultiSelect, SingleSelect } from "@amenda-components/FormComponents";
import { Tag, Tags } from "@amenda-components/App";

import { AvailableForms } from "@amenda-constants";
import { Controller } from "react-hook-form";
import { EmptyIndicator } from "@amenda-components/Shared";
import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";
import { getOptionsFromCostGroupsForms } from "@amenda-components/CostGroup/common";
import { useProjectStore } from "@amenda-domains/mutations";

interface CostGroupSelectFieldProps {
  id: string;
  value: any;
  hasMenuOverflow: boolean;
  options: any[];
  properties: any;
  error?: string;
  onChange: (value: any) => void;
}

const getValue = (value: any, isMulti: boolean) => {
  if (isMulti) {
    return Array.isArray(value) ? value : [value];
  }
  return Array.isArray(value) ? value[0] : value;
};

const CostGroupSelectField: FC<CostGroupSelectFieldProps> = ({
  id,
  error,
  options,
  properties,
  hasMenuOverflow,
  value: inputValue,
  onChange,
}) => {
  const isMulti = Boolean(properties?.isMulti);
  const value = getValue(inputValue, isMulti);

  if (isMulti) {
    return (
      <MultiSelect
        id={id}
        error={error}
        values={value}
        disabled={Boolean(properties?.readOnly)}
        hasMenuOverflow={hasMenuOverflow}
        options={options}
        onChange={onChange}
        {...properties}
      />
    );
  }
  return (
    <SingleSelect
      id={id}
      error={error}
      value={value}
      disabled={Boolean(properties?.readOnly)}
      hasMenuOverflow={hasMenuOverflow}
      options={options}
      onChange={onChange}
      {...properties}
    />
  );
};

interface ReadOnlyCostGroupProps {
  value: any | any[];
  options: any[];
  isMulti: boolean;
}

const ReadOnlyCostGroup: FC<ReadOnlyCostGroupProps> = ({
  value,
  options,
  isMulti,
}) => {
  let selectedOptions = Array.isArray(value) ? value : [value];
  selectedOptions = selectedOptions
    .map((option) => {
      return options.find((o) => o.value === option);
    })
    .filter(Boolean);

  if (selectedOptions.length <= 0) {
    return <EmptyIndicator />;
  }
  return (
    <Tags>
      {selectedOptions.map((option) => (
        <Tag key={option.value}>{option.label}</Tag>
      ))}
    </Tags>
  );
};

export const SelectCostGroups: FC<FormComponentProps> = ({
  config,
  global,
  readOnly,
}) => {
  const forms = useProjectStore((state) => state.forms);

  const { id, properties } = config;
  const costGroupForms = forms?.[AvailableForms.CostGroupsCost];
  const options = getOptionsFromCostGroupsForms(costGroupForms);

  return (
    <div className="w-full">
      {readOnly ? (
        <TitleAndDescription
          container="column"
          title={properties.label}
          description={
            <ReadOnlyCostGroup
              options={options}
              value={global.data?.[id]}
              isMulti={Boolean(properties?.isMulti)}
            />
          }
        />
      ) : (
        <Controller
          name={id}
          control={global.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <CostGroupSelectField
                id={id}
                options={options}
                properties={properties}
                value={value}
                error={error?.message}
                onChange={onChange}
                hasMenuOverflow={Boolean(global?.hasMenuOverflow)}
              />
            );
          }}
        />
      )}
    </div>
  );
};
