import {
  Control,
  useController,
  useFieldArray,
  useFormState,
} from "react-hook-form";
import { FC, ReactNode, RefObject, useRef } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
  selectStylesControlOverride,
} from "@amenda-styles/customStyling";
import {
  flattenUserDetails,
  getContactRequestArgs,
  getUserName,
} from "@amenda-components/Contacts/common";
import {
  useGetAllContacts,
  useGetAllUsers,
  useSearchContacts,
  useSearchUsers,
} from "@amenda-domains/queries";

import { AllowedContactType } from "@amenda-types";
import AsyncSelect from "react-select/async";
import { ContactCardWrapper } from "./ContactCardWrapper";
import { DebounceTimes } from "@amenda-constants";
import { ErrorMessage } from "@amenda-components/FormComponents";
import { PlusIcon } from "lucide-react";
import { components } from "react-select";
import debounce from "lodash/debounce";
import { getErrorFromFieldError } from "@amenda-components/Shared/common";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations";

interface ChildProps {
  contact: any;
  selectedRoles?: string[];
  remove: () => void;
  update: (value: any) => void;
}

interface SharedSelectProps {
  contactType: AllowedContactType;
  isMulti?: boolean;
  disabled?: boolean;
  variant?: "default" | "searchBox";
  isCreatable?: boolean;
}

interface ReactSelectProps extends SharedSelectProps {
  loading: boolean;
  value: any | any[];
  children: ReactNode;
  containerRef?: RefObject<HTMLDivElement>;
  addContactCb: (contact: any) => void;
  handleChange: (value?: any) => void;
  loadOptions: (searchTerm: string) => Promise<any>;
}

type LoadOptionsOnMountFunc = (
  ids: string[],
  callback: (users: any[]) => void,
) => Promise<void>;

interface ReactSelectWrapperProps extends SharedSelectProps {
  id: string;
  loading: boolean;
  control: Control<any>;
  containerRef?: RefObject<HTMLDivElement>;
  loadOptionsOnMount: LoadOptionsOnMountFunc;
  children: (props: ChildProps) => ReactNode;
  loadOptions: (searchTerm: string) => Promise<any>;
}

interface Props extends SharedSelectProps {
  id: string;
  label?: string;
  readOnly?: boolean;
  control: Control<any>;
  optional?: ReactNode;
  children: (props: ChildProps) => ReactNode;
}

const DropdownIndicator = () => {
  return null;
};

const ClearIndicator = () => {
  return null;
};

const IndicatorSeparator = () => {
  return null;
};

const MenuList = (props: any) => {
  const { t } = useTranslation();
  const setOpenPersonSlideOver = useUsersStore(
    (state) => state.setOpenPersonSlideOver,
  );
  const setOpenCompanySlideOver = useUsersStore(
    (state) => state.setOpenCompanySlideOver,
  );

  const {
    children,
    selectProps: { contactType, inputValue, isCreatable, addContactCb },
  } = props;

  const handleClick = () => {
    if (contactType === AllowedContactType.person) {
      setOpenPersonSlideOver(true, addContactCb);
    } else {
      setOpenCompanySlideOver(true, addContactCb);
    }
  };

  return (
    <components.MenuList {...props}>
      {children}
      {inputValue &&
        isCreatable &&
        contactType !== AllowedContactType.office && (
          <div className="flex flex-col text-xs text-gray-500 w-full py-2">
            <button
              className="flex items-center justify-center w-full py-1 hover:text-blue-500"
              onClick={handleClick}
              type="button"
            >
              <PlusIcon className="h-5 w-5" />
              {t("Add contact")}
            </button>
          </div>
        )}
    </components.MenuList>
  );
};

const ReactSelect: FC<ReactSelectProps> = ({
  contactType,
  value,
  isMulti,
  isCreatable = true,
  loading,
  disabled,
  children,
  containerRef,
  variant = "default",
  addContactCb,
  loadOptions,
  handleChange,
}) => {
  const { t } = useTranslation();

  const handleInputChange = (inputValue: string) => {
    return inputValue;
  };

  return (
    <>
      <div className="w-full">
        <AsyncSelect
          contactType={contactType}
          addContactCb={addContactCb}
          isMulti={isMulti}
          isCreatable={isCreatable}
          value={value}
          isDisabled={disabled}
          onChange={handleChange}
          onInputChange={handleInputChange}
          menuPlacement="auto"
          isClearable={false}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          placeholder={t("Search contacts")}
          className={customStyling.select.containerClass}
          isLoading={loading}
          loadingMessage={() => t("Loading") + "..."}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? t("No results found") : t("Start typing")
          }
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) =>
            getUserName(flattenUserDetails(option))
          }
          components={{
            ClearIndicator,
            DropdownIndicator,
            IndicatorSeparator,
            MenuList,
          }}
          styles={{
            ...customStyling.select.styleOverride,
            ...selectMenuOverflow(true, containerRef),
            ...(variant === "searchBox" ? selectStylesControlOverride : {}),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          {...selectMenuOverflowProps(true)}
        />
      </div>
      {children}
    </>
  );
};

const ContactSelect: FC<ReactSelectWrapperProps> = ({
  contactType,
  id,
  control,
  children,
  containerRef,
  loadOptionsOnMount,
  ...props
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: id,
    control: control,
  });

  const handleChange = (value: any | null) => {
    if (value) {
      onChange(value.id);
    } else {
      onChange(value);
    }
  };

  const addContactCb = (contact: any) => {
    if (contact) {
      onChange(contact.id);
    }
  };

  return (
    <ReactSelect
      contactType={contactType}
      containerRef={containerRef}
      value={value}
      addContactCb={addContactCb}
      handleChange={handleChange}
      {...props}
    >
      <>
        {value && (
          <div className="pt-4 flex flex-col space-y-1">
            <ContactCardWrapper userId={value} contactType={contactType}>
              {(contact) =>
                children({
                  contact,
                  remove: () => onChange(null),
                  update: (value) => onChange(value),
                })
              }
            </ContactCardWrapper>
          </div>
        )}
        <ErrorMessage id={id} error={error?.message} className="text-xs" />
      </>
    </ReactSelect>
  );
};

const ContactsSelect: FC<ReactSelectWrapperProps> = ({
  contactType,
  id,
  control,
  children,
  containerRef,
  loadOptionsOnMount,
  ...props
}) => {
  const { fields, update, remove, replace, prepend } = useFieldArray({
    control,
    name: id,
    keyName: "arrId",
  });
  const { errors } = useFormState({
    control,
    name: id,
    exact: true,
  });
  const fieldError = errors[id];
  const error = Array.isArray(fieldError)
    ? getErrorFromFieldError(fieldError)
    : (fieldError?.message as string);

  const handleChange = (value: any[] | null) => {
    let values = value ?? [];
    values = values.map((c) => ({
      contactId: c?.contactId ?? c?.id,
      roles: c?.roles,
    }));

    replace(values);
  };

  const addContactCb = (contact: any) => {
    if (contact) {
      prepend({ contactId: contact.id });
    }
  };

  return (
    <ReactSelect
      contactType={contactType}
      containerRef={containerRef}
      value={fields}
      addContactCb={addContactCb}
      handleChange={handleChange}
      {...props}
    >
      <>
        {fields.length > 0 && (
          <ul className="pt-4 flex flex-col space-y-1">
            {fields.map((field, index) => {
              const userId = (field as any)?.contactId;
              const selectedRoles = (field as any)?.roles;

              return (
                <li key={index}>
                  <ContactCardWrapper userId={userId} contactType={contactType}>
                    {(contact) =>
                      children({
                        contact,
                        selectedRoles,
                        remove: () => remove(index),
                        update: (value) => update(index, value),
                      })
                    }
                  </ContactCardWrapper>
                </li>
              );
            })}
          </ul>
        )}
        <ErrorMessage id={id} error={error} className="text-xs" />
      </>
    </ReactSelect>
  );
};

export const HeadlessContacts: FC<Props> = ({
  id,
  label,
  isMulti,
  optional,
  contactType,
  ...props
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { searchUsers, loading: loadingSearchUsers } = useSearchUsers();
  const { searchContacts, loading: loadingSearchContacts } =
    useSearchContacts();
  const { getAllUsers, loading: loadingGetAllUsers } = useGetAllUsers();
  const { getAllContacts, loading: loadingGetAllContacts } =
    useGetAllContacts();

  const isLoading =
    loadingSearchUsers ||
    loadingSearchContacts ||
    loadingGetAllUsers ||
    loadingGetAllContacts;

  const debouncedSearch = useRef(
    debounce(
      async (
        args: { searchTerm: string } & any,
        resolve: (users: any[]) => void,
      ) => {
        const cb =
          args.type === AllowedContactType.office
            ? searchUsers
            : searchContacts;
        await cb({
          ...args,
          callback: resolve,
        });
      },
      DebounceTimes.Search,
    ),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        const args = getContactRequestArgs({
          contactType,
          autoSelect: false,
          isCollection: false,
          searchTerm: inputValue,
        });

        await debouncedSearch(args, resolve);
      }
    });

  const loadOptionsOnMount: LoadOptionsOnMountFunc = async (ids, callback) => {
    const args = getContactRequestArgs({
      contactType,
      autoSelect: false,
      isCollection: false,
    });
    const cb =
      contactType === AllowedContactType.office ? getAllUsers : getAllContacts;

    await cb({
      ...args,
      ids,
      callback,
    });
  };

  return (
    <div className="pb-4 w-full group/headlessContacts" ref={ref}>
      <div className="w-full flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      {isMulti ? (
        <ContactsSelect
          contactType={contactType}
          id={id}
          isMulti={isMulti}
          containerRef={ref}
          loading={isLoading}
          loadOptions={loadOptions}
          loadOptionsOnMount={loadOptionsOnMount}
          {...props}
        />
      ) : (
        <ContactSelect
          contactType={contactType}
          id={id}
          isMulti={isMulti}
          containerRef={ref}
          loading={isLoading}
          loadOptions={loadOptions}
          loadOptionsOnMount={loadOptionsOnMount}
          {...props}
        />
      )}
    </div>
  );
};
