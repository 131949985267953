import {
  ContactListHeader,
  ContactListView,
} from "@amenda-components/Contacts";
import {
  ContactSearchProps,
  getContactRequestArgs,
  isUserSearchFiltersEmpty,
  isUserSelected,
} from "./common";
import { FC, useRef } from "react";
import { useFormStore, useUsersStore } from "@amenda-domains/mutations";

import { ContactDirectoryProps } from "./types";
import { DebounceTimes } from "@amenda-constants";
import { SidebarContainer } from "@amenda-components/Shared";
import { SlideOver } from "@amenda-components/App";
import { abortRequest } from "@amenda-domains/abortExchange";
import { debounce } from "lodash";

interface Props extends ContactDirectoryProps {
  users: any[];
  groupedUsers: Record<string, any>;
  selectedUser?: any;
  isCollection: boolean;
}

type ContactListHeaderWrapperProps = ContactDirectoryProps & {
  isCollection: boolean;
};

const ContactListHeaderWrapper: FC<ContactListHeaderWrapperProps> = ({
  isCollection,
  ...props
}) => {
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );

  const { getAllContacts, searchContacts, collectionType } = props;
  const selectedCollection = selectedCollectionByType[collectionType];

  const debounceSearch = useRef(
    debounce(async ({ collection, ...rest }: ContactSearchProps) => {
      const args: any = getContactRequestArgs({
        isCollection,
        autoSelect: true,
        collectionResourceIds: collection?.resourceIds,
        ...rest,
      });

      if (!isUserSearchFiltersEmpty(rest)) {
        await searchContacts(args);
      } else {
        abortRequest("searchUsers");
        await getAllContacts({
          ...args,
          context: {
            requestPolicy: "network-only",
          },
        });
      }
    }, DebounceTimes.Search),
  ).current;

  const runSearch = async (props: ContactSearchProps) => {
    await debounceSearch({
      ...props,
      collection: selectedCollection,
    });
  };

  return (
    <ContactListHeader
      isCollection={isCollection}
      handleSearch={runSearch}
      {...props}
    />
  );
};

export const ContactList: FC<Props> = ({
  users,
  selectedUser,
  groupedUsers,
  ...props
}) => {
  const isSelectedContact = (c: any) => isUserSelected(c, selectedUser);

  return (
    <SidebarContainer className="w-72 lg:w-96 border-r">
      <ContactListHeaderWrapper {...props} />
      <ContactListView
        users={users}
        groupedContacts={groupedUsers}
        isSelectedContact={isSelectedContact}
        {...props}
      />
    </SidebarContainer>
  );
};

export const ContactListMobile: FC<Props> = ({
  users,
  selectedUser,
  groupedUsers,
  ...props
}) => {
  const isSelectedContact = (c: any) => isUserSelected(c, selectedUser);
  const openContactListSlideOver = useUsersStore(
    (state) => state.openContactListSlideOver,
  );
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );

  const handleClose = () => {
    setOpenContactListSlideOver(false);
  };

  return (
    <SlideOver show={openContactListSlideOver} onClose={handleClose}>
      <div className="w-full h-screen overflow-y-auto relative">
        <div className="w-full sticky top-0 z-20 bg-white">
          <ContactListHeaderWrapper {...props} />
        </div>
        <ContactListView
          users={users}
          groupedContacts={groupedUsers}
          isSelectedContact={isSelectedContact}
          {...props}
        />
      </div>
    </SlideOver>
  );
};
