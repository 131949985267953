import * as Sentry from "@sentry/react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { Link, LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  signIn,
  useAuthStore,
  useSignInWithMicrosoft,
} from "@amenda-domains/mutations";

import { AuthFormType } from "@amenda-types";
import { FC } from "react";
import { LoginSchema } from "@amenda-constants";
import { MicrosoftLoginButton } from "@amenda-components/Auth/MicrosoftLoginButton";
import { TextField } from "@amenda-components/FormComponents";
import { useGetTenantId } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface LoginInput {
  email: string;
  password: string;
}

export const DefaultLoginForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getTenantId, loading } = useGetTenantId();
  const { signInWithMicrosoft, loader } = useSignInWithMicrosoft();
  const toggleAuthFormType = useAuthStore((state) => state.toggleAuthFormType);
  const { handleSubmit, control } = useForm<LoginInput>({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<LoginInput> = async (form) => {
    await getTenantId({
      tenantUserEmail: form.email,
      callback: (tenantId) =>
        signIn({
          ...form,
          tenantId,
        }),
    });
    navigate("/");
    Sentry.setUser({ email: form.email });
  };

  if (loader) {
    return <LoaderWrapper />;
  }
  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div>
          <MicrosoftLoginButton onClick={signInWithMicrosoft} />
        </div>
        <div className="relative mt-5">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="font-apercu px-2 bg-white text-gray-500">
              {t("or")}
            </span>
          </div>
        </div>
      </div>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="email"
                placeholder="E-Mail Adresse"
                error={error?.message}
                value={value}
                type="email"
                onChange={onChange}
                StartIcon={EnvelopeIcon}
              />
            );
          }}
        />
      </div>

      <div className="space-y-1">
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="password"
                placeholder="Passwort"
                error={error?.message}
                value={value}
                type="password"
                onChange={onChange}
                StartIcon={LockClosedIcon}
              />
            );
          }}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm">
          <Link
            onClick={() => toggleAuthFormType(AuthFormType.ResetPassword)}
            className="font-apercu font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t("Forgot your password?")}
          </Link>
        </div>
      </div>

      <div>
        <button type="submit" className="amenda-button">
          {loading && <Spinner variant="primary" spinnerSize="xs" />}
          {t("Sign in")}
        </button>
      </div>
    </form>
  );
};
