import "react-datepicker/dist/react-datepicker.css";

import { CalendarIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  DatePickerHeader,
  DatePickerMonthPicker,
  DatePickerTimeComparisonProps,
  DatePickerYearPicker,
  getDisabledDates,
} from "./DatePickerHeader";
import { FC, useState } from "react";
import { Sizes, datePickerTheme } from "@amenda-styles/theme";
import { formatMonth, formatYear, resolveDate } from "@amenda-utils";

import { AvailableLanguages } from "@amenda-constants";
import DayJs from "dayjs";
import ReactDatePicker from "react-datepicker";
import clsx from "clsx";
import isBetween from "dayjs/plugin/isBetween";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export interface DatePickerProps extends DatePickerTimeComparisonProps {
  id: string;
  label: string;
  placeholder?: string;
  value: string;
  size?: keyof Sizes;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  withPortal?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

DayJs.extend(isBetween);

export const DatePickerBase: FC<DatePickerProps> = ({
  id,
  label,
  placeholder,
  value,
  startDate,
  endDate,
  className,
  size,
  disabled,
  readOnly,
  withPortal = false,
  onChange,
  onBlur,
  ...rest
}) => {
  const { t } = useTranslation();
  const language = useAppStore((state) => state.language);
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [showMonthPicker, setShowMonthPicker] = useState(false);

  const locales: Record<AvailableLanguages, string> = {
    [AvailableLanguages.English]: "en-GB",
    [AvailableLanguages.German]: "de-DE",
  };
  const isDisabled = Boolean(disabled);
  const { calendarDayCss } = datePickerTheme();
  const hideCalendarSmOnly = size === "sm" && !isEmpty(value);

  const handleChange = (date: Date | null) => {
    const value = date ? date.toISOString() : "";
    onChange(value);
  };

  const clearDate = () => {
    if (!isDisabled) {
      onChange("");
    }
  };

  const onYearClick = () => {
    setShowYearPicker((prev) => !prev);
    setShowMonthPicker(false);
  };

  const onMonthClick = () => {
    setShowMonthPicker((prev) => !prev);
    setShowYearPicker(false);
  };

  return (
    <div
      className={clsx(
        "relative flex items-stretch flex-grow amenda-date-picker",
        {
          "amenda-date-picker-portal": withPortal,
        },
      )}
    >
      <div className="z-10 absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {!hideCalendarSmOnly && (
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
      </div>
      <ReactDatePicker
        id={id}
        readOnly={readOnly}
        disabled={disabled}
        placeholderText={placeholder}
        ariaLabelledBy={t(label)}
        locale={locales[language]}
        withPortal={withPortal}
        className={clsx("w-full", className, {
          "pl-10": !hideCalendarSmOnly,
          "pl-2": hideCalendarSmOnly,
          "bg-gray-100": disabled || readOnly,
        })}
        selected={resolveDate(value)}
        onChange={handleChange}
        popperPlacement="top"
        popperClassName="!z-50"
        autoComplete="off"
        showYearPicker={showYearPicker}
        showMonthYearPicker={showMonthPicker}
        fixedHeight
        dateFormat="dd.MM.yyyy"
        startDate={resolveDate(startDate)}
        endDate={resolveDate(endDate)}
        calendarClassName="w-90 shadow-lg !font-apercu text-gray-400 bg-white px-4 py-1 !border-gray-300 !rounded-none"
        dayClassName={(date: any) =>
          calendarDayCss({
            isToday: DayJs().isSame(date, "day"),
            isSelected: Boolean(value) && DayJs(value).isSame(date, "day"),
            disabled: getDisabledDates({
              date,
              startDate,
              endDate,
              ...rest,
            }),
            class: clsx({
              "!rounded-l-xl !bg-gray-800":
                value && startDate && DayJs(startDate).isSame(date, "day"),
              "!rounded-r-xl !bg-gray-800":
                value && endDate && DayJs(endDate).isSame(date, "day"),
              "!bg-gray-800":
                value &&
                startDate &&
                endDate &&
                DayJs(date).isBetween(startDate, endDate, "day"),
            }),
          })
        }
        onCalendarClose={() => {
          onBlur && onBlur();
          setShowYearPicker(false);
          setShowMonthPicker(false);
        }}
        renderYearContent={(year: number) => (
          <DatePickerYearPicker value={value} year={year} {...rest} />
        )}
        renderMonthContent={(
          month: number,
          shortMonth: string,
          longMonth: string,
          day: any,
        ) => (
          <DatePickerMonthPicker
            value={value}
            month={month}
            shortMonth={shortMonth}
            longMonth={longMonth}
            day={day}
            {...rest}
          />
        )}
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          increaseMonth,
          decreaseMonth,
        }: any) => (
          <DatePickerHeader
            month={formatMonth(date)}
            year={formatYear(date)}
            showYearPicker={showYearPicker}
            showMonthPicker={showMonthPicker}
            increaseYear={increaseYear}
            decreaseYear={decreaseYear}
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            onYearClick={onYearClick}
            onMonthClick={onMonthClick}
          />
        )}
        {...rest}
      />
      {!!value && (
        <div
          onClick={clearDate}
          className={clsx("absolute inset-y-0 right-0 pr-3 flex items-center", {
            "cursor-pointer": !isDisabled,
          })}
        >
          <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};
