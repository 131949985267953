import {
  AllowedCollectionType,
  AppRoutes,
  AvailablePermissions,
  ComponentValidationType,
  FormTab,
  LogicalOperators,
} from "@amenda-types";
import {
  ApiFilterTypes,
  AvailableForms,
  FormComponentTypes,
} from "@amenda-constants";
import {
  Bars4Icon,
  EnvelopeIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { isArray, isEmpty, isString } from "lodash";
import { sanitizeData, transformSearchFilters } from "@amenda-utils";

export interface CollectionProps {
  resourceIds: string[];
  collectionType: AllowedCollectionType;
  getResourceIds: (args: any) => Promise<string[]>;
  clearSelectedResources: () => void;
}

export enum LayoutTypes {
  Grid = "grid",
  List = "list",
  Table = "table",
  Directory = "directory",
}

export const PermissionSharePath = "amendaPermissionSharePath";

export const getFilterType = (component: any) => {
  if (component?.component === FormComponentTypes.Hidden) {
    return ApiFilterTypes.MetaData;
  } else if (component?.component === FormComponentTypes.Keyword) {
    return ApiFilterTypes.Keywords;
  }
  return ApiFilterTypes.Values;
};

export const getInputNumberFields = (component: any) => {
  return component.validationType === ComponentValidationType.Number;
};

export const getSearchValue = (value?: string) => {
  return {
    filterType: ApiFilterTypes.Values,
    componentType: FormComponentTypes.Input,
    isNumberInputField: false,
    value,
  };
};

export const isSearchFilterEmpty = (filters?: any) => {
  if (isEmpty(filters)) return true;
  return Object.keys(filters).every((key) => {
    return isEmpty(filters[key].value);
  });
};

export const processSearchFilters = ({
  component,
  value,
  other = {},
}: {
  component: any;
  value: any;
  other?: Record<string, any>;
}) => {
  return {
    ...other,
    value,
    filterType: getFilterType(component),
    componentType: component.component,
    isNumberInputField: getInputNumberFields(component),
  };
};

export const processReadAndEditPermissions = (
  permission: AvailablePermissions,
) =>
  [AvailablePermissions.Read, AvailablePermissions.Edit].includes(permission);

export const processComponentPermissions = (
  component: any,
  permissions: Record<string, any>,
) => {
  const { id, formId } = component;
  const formPermissions = permissions[formId] || {};
  const permission = formPermissions[id];

  if (!permission) return false;
  return processReadAndEditPermissions(permission);
};

export const addReadOnlyProperty = (
  component: any,
  permissions: Record<string, any>,
) => {
  const { id, formId } = component;
  const formPermissions = permissions[formId] || {};
  const permission = formPermissions[id];

  return {
    ...component,
    properties: {
      readOnly: permission === AvailablePermissions.Read,
      ...(component?.properties || {}),
    },
  };
};

export const processFormPermissions =
  (permissions: Record<string, any>) => (components: any[]) => {
    return components
      .filter((c) => processComponentPermissions(c, permissions))
      .map((c) => addReadOnlyProperty(c, permissions));
  };

export const getComponentIcons = (type?: string) => {
  switch (type) {
    case "EnvelopeIcon":
      return EnvelopeIcon;
    case "GlobeAltIcon":
      return GlobeAltIcon;
    case "PhoneIcon":
      return PhoneIcon;
    case "Bars4Icon":
      return Bars4Icon;
    case "MapPinIcon":
      return MapPinIcon;
    case "UserCircleIcon":
      return UserCircleIcon;
    default:
      return null;
  }
};

export const getFormsByCollectionType = (
  collectionType: AllowedCollectionType,
  forms: Record<string, FormTab[]> | undefined,
) => {
  let availableForms: FormTab[] | undefined;

  switch (collectionType) {
    case AllowedCollectionType.Projects:
      availableForms = forms?.[AvailableForms.Project];
      break;
    case AllowedCollectionType.Attachments:
      availableForms = forms?.[AvailableForms.Gallery];
      break;
    case AllowedCollectionType.Users:
      availableForms = [
        ...(forms?.[AvailableForms.Persons] || []),
        ...(forms?.[AvailableForms.Company] || []),
      ];
      break;
    case AllowedCollectionType.Contacts:
      availableForms = forms?.[AvailableForms.Office];
      break;
    default:
      break;
  }
  return availableForms;
};

export enum ResourceSharingPermissionTypes {
  Edit = "edit",
  View = "view",
  Admin = "admin",
}

export const sharePermissionsOptions = [
  {
    label: "View only",
    value: ResourceSharingPermissionTypes.View,
  },
  {
    label: "Edit",
    value: ResourceSharingPermissionTypes.Edit,
  },
  {
    label: "Admin",
    value: ResourceSharingPermissionTypes.Admin,
  },
];

export const sharePermissionsSecondaryOptions = [
  {
    label: "Remove access",
    value: "removeAccess",
  },
];

export const getArgsFromKeywords = (keywords: any[]) => {
  const args: Record<string, string[]> = {};

  keywords.forEach((keyword) => {
    const { value, componentId } = keyword;

    if (args[componentId]) {
      args[componentId].push(value);
    } else {
      args[componentId] = [value];
    }
  });
  return args;
};

export const updateCollectionShare = ({
  keywordUserIds,
  expirationDate,
  shareWithRoles,
  shareWithContacts,
}: {
  keywordUserIds: string[];
  expirationDate: string;
  shareWithRoles: any[];
  shareWithContacts: any[];
}) => {
  const userRolesIds = shareWithRoles.map((share: any) => {
    if (isString(share)) {
      return share;
    }
    return share.id;
  });
  const userIds = shareWithContacts.map((share: any) => {
    if (isString(share)) {
      return share;
    }
    return share.id;
  });

  if (isEmpty(userIds) && isEmpty(userRolesIds) && isEmpty(keywordUserIds)) {
    return [];
  }

  const share = {
    userRolesIds,
    expirationDate,
    userIds: [...userIds, ...keywordUserIds],
  };
  const safeShare = sanitizeData(share);
  return [safeShare];
};

export const transformCollectionToForm = (
  collection: any,
  systemRoles: any[],
) => {
  const { name, description, keywords, shares } = collection;
  const { expirationDate, userRolesIds, userIds } = shares?.[0] || {};
  const form = {
    name,
    description: description ?? "",
    expirationDate,
    tags: keywords,
    shareable: !isEmpty(shares),
    shareWithContacts: userIds,
    shareWithRoles: userRolesIds?.map((id: string) => {
      const role = systemRoles?.find((r: any) => r.id === id);

      return {
        value: role?.id,
        label: role?.name,
        description: role?.description,
      };
    }),
  };

  return sanitizeData(form);
};

// enrich search filters with logical operators
const enrichSearchFilters = (selectedFilter: any, data: any) => {
  const { operation } = selectedFilter;

  if (!operation || operation === LogicalOperators.AND) {
    return { [LogicalOperators.AND]: data };
  }
  if (operation === LogicalOperators.OR) {
    return {
      [LogicalOperators.OR]: data,
    };
  }
  return {
    $exists: true,
    [LogicalOperators.NOT]: data,
  };
};

export const transformFilters = (
  searchFilters: Record<string, any>,
  valuesKey = "formValues",
) => {
  const { keywords, values, metadata } = transformSearchFilters(searchFilters);
  let filters: Record<string, any> = {};

  if (values) {
    Object.keys(values).forEach((key) => {
      if (
        [
          FormComponentTypes.Select,
          FormComponentTypes.MultiSelect,
          FormComponentTypes.Keyword,
          FormComponentTypes.Badges,
          FormComponentTypes.Checkbox,
          FormComponentTypes.RadioButton,
          FormComponentTypes.ColoredSelect,
          FormComponentTypes.SearchAndSelect,
          FormComponentTypes.SearchAndSelectProjects,
          FormComponentTypes.RegionalSelect,
        ].includes(searchFilters[key]["componentType"])
      ) {
        filters = {
          ...filters,
          [`${valuesKey}.${key}`]: enrichSearchFilters(
            searchFilters[key],
            values[key],
          ),
        };
      } else if (
        FormComponentTypes.AddressSearch === searchFilters[key]["componentType"]
      ) {
        filters = {
          ...filters,
          [`${valuesKey}.${key}.name`]: values[key],
        };
      } else {
        filters = {
          ...filters,
          [`${valuesKey}.${key}`]: values[key],
        };
      }
    });
  }
  if (keywords) {
    Object.keys(keywords).forEach((key) => {
      filters = {
        ...filters,
        [`${valuesKey}.${key}`]: enrichSearchFilters(
          searchFilters[key],
          keywords[key],
        ),
      };
    });
  }
  if (metadata) {
    Object.keys(metadata).forEach((key) => {
      filters = {
        ...filters,
        [`metadata.${key}`]: isArray(metadata[key])
          ? metadata[key][0]
          : metadata[key],
      };
    });
  }
  return filters;
};

export const getErrorFromFieldError = (fieldError: any[]) => {
  let error = "";

  fieldError.forEach((err) => {
    if (!isEmpty(err)) {
      Object.keys(err).forEach((key) => {
        error = key + ": " + err[key]?.message;
        return;
      });
      return;
    }
  });

  return error;
};

export const addMatchForCollection = (resourceIds: string[]) => {
  return {
    isDeleted: false,
    _id: {
      $in: resourceIds,
    },
  };
};

export const isAttachmentOrProjectCollection = (pathname: string) => {
  return (
    pathname.includes(AppRoutes.ProjectsCollection) ||
    pathname.includes(AppRoutes.AttachmentsCollection)
  );
};
