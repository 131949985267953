import {
  ContactList,
  ContactListMobile,
  ContactOverview,
  ContactsFullScreenTable,
  ContactsLayout,
} from "@amenda-components/Contacts";
import { FC, useEffect, useState } from "react";
import {
  getContactRequestArgs,
  isUserSearchFiltersEmpty,
} from "@amenda-components/Contacts/common";
import { isMobile, useRunOnMountOnly } from "@amenda-utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { ArrowLeft } from "lucide-react";
import { Button } from "@amenda-components/App";
import { ContactDirectoryProps } from "@amenda-components/Contacts/types";
import { MainContainer } from "@amenda-components/Shared";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

interface Props extends ContactDirectoryProps {
  autoSelect: boolean;
  isCollection?: boolean;
  collectionResourceIds?: string[];
}

export const ContactDirectoryWrapper: FC<Props> = ({
  autoSelect,
  isCollection = false,
  collectionResourceIds = [],
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const users = useUsersStore((state) => state.users);
  const groupedUsers = useUsersStore((state) => state.groupedUsers);
  const selectedUser = useUsersStore((state) => state.selectedUser);
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );
  const { searchFilters } = useUserSearchFiltersWithPath();
  const clearSelectedUser = useUsersStore((state) => state.clearSelectedUser);

  const { rootRoute, pluralTitle, getAllContacts, searchContacts } = props;

  const runOnMount = async () => {
    const args = getContactRequestArgs({
      autoSelect,
      isCollection,
      collectionResourceIds,
      ...searchFilters,
    });

    clearSelectedUser();
    if (isCollection && isEmpty(collectionResourceIds)) {
      clearSelectedUsers();
      return;
    }

    if (!isUserSearchFiltersEmpty(searchFilters)) {
      await searchContacts(args);
    } else {
      await getAllContacts(args);
    }
  };

  const handleOpenSlideOver = () => {
    if (userId) {
      navigate(rootRoute);
    }
    setOpenContactListSlideOver(true);
  };

  useRunOnMountOnly(
    runOnMount,
    JSON.stringify([isCollection, collectionResourceIds]),
  );

  useEffect(() => {
    if (!isMounted && !userId) {
      setIsMounted(true);
      if (isMobile(window)) {
        setOpenContactListSlideOver(true);
      }
    }
  }, [isMounted, userId, setOpenContactListSlideOver]);

  return (
    <ContactsLayout>
      <ContactsFullScreenTable
        users={users}
        isCollection={isCollection}
        {...props}
      />
      <ContactList
        users={users}
        isCollection={isCollection}
        selectedUser={selectedUser}
        groupedUsers={groupedUsers}
        {...props}
      />
      <ContactListMobile
        users={users}
        isCollection={isCollection}
        selectedUser={selectedUser}
        groupedUsers={groupedUsers}
        {...props}
      />
      <MainContainer className="w-full h-full overflow-y-auto overscroll-y-contain pt-8 md:pt-0 pb-10">
        <div className="md:hidden absolute top-0 left-0 z-20 w-full px-2 flex items-end bg-white">
          <Button onClick={handleOpenSlideOver} variant="default">
            <span className="flex flex-row mr-2 items-center">
              <ArrowLeft className="w-4" /> {t(`All ${pluralTitle}`)}
            </span>
          </Button>
        </div>
        <ContactOverview isCollection={isCollection} {...props} />
      </MainContainer>
    </ContactsLayout>
  );
};
