import {
  AllowedAttachmentType,
  AppRoutes,
  AvailableImageVariants,
  RoundedVariants,
} from "@amenda-types";
import { FC, useEffect, useState } from "react";
import { FullScreenModal, Image } from "@amenda-components/App";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import {
  convertExtractedImages,
  isConstructionView,
  isEditingConstructionDetails,
} from "./helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ConstructionDetailActionBar } from "./ConstructionDetailActionBar";
import { ConstructionDetailsForm } from "./ConstructionDetailsForm";
import { ThumbnailSliderView } from "@amenda-components/FileUploads";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useGetAttachment } from "@amenda-domains/queries";

export const ViewConstructionDetails: FC = () => {
  const { pathname } = useLocation();
  const { constructionDetailId } = useParams<{
    constructionDetailId: string;
  }>();
  const navigate = useNavigate();
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const { getAttachment, loading } = useGetAttachment();
  const [selectedUrl, setSelectedUrl] = useState<string>("");
  const openModal = isConstructionView(pathname);
  const isEditing = isEditingConstructionDetails(pathname);
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );
  const goBackRoute = useAttachmentStore(
    (state) => state.attachmentProject?.goBackRoute,
  );

  const handleClose = () => {
    setAttachmentProject({});
    navigate(goBackRoute ?? AppRoutes.ConstructionDetails);
  };

  useEffect(() => {
    if (selectedAttachment?.url) {
      setSelectedUrl(selectedAttachment.url);
    }

    return () => {
      setSelectedUrl("");
    };
  }, [selectedAttachment?.url]);

  useEffect(() => {
    if (openModal && constructionDetailId) {
      getAttachment({
        id: constructionDetailId,
        type: AllowedAttachmentType.pdf,
        selectedAttachmentFields: {
          metadata: 1,
          url: 1,
          name: 1,
          originalFilename: 1,
        },
      });
    }
  }, [openModal, constructionDetailId, getAttachment]);

  return (
    <FullScreenModal isOpen={openModal} onClose={handleClose}>
      <MainContainer className="px-4 pb-2 lg:pb-4 lg:pr-12">
        <span className="text-lg font-apercuMedium">
          {selectedAttachment?.formValues?.name ??
            selectedAttachment?.originalFilename}
        </span>
        <div className="h-full w-full grid md:grid-cols-7 lg:grid-cols-5 xl:grid-cols-7 gap-2 pt-4">
          <div className="col-span-1 hidden md:block h-full w-full px-0.5 py-2 lg:py-6 bg-gray-100">
            <ThumbnailSliderView
              vertical={true}
              files={convertExtractedImages(
                selectedAttachment?.extractedImages,
              )}
              onClick={(file) => {
                setSelectedUrl(file?.url);
              }}
            />
          </div>
          <div className="md:col-span-6 lg:col-span-4 xl:col-span-6 h-full overflow-y-auto overflow-y-contain px-3 py-4 lg:py-12 bg-gray-100">
            <Image
              isLoading={loading}
              showLoader={true}
              url={selectedUrl}
              size="h-full"
              magicSize="fullscreen"
              background="bg-gray-100"
              lazyLoad={false}
              variant={AvailableImageVariants.contain}
              rounded={RoundedVariants.none}
              enableHighlighting={false}
            />
          </div>
          <div className="col-span-1 block md:hidden h-min w-full px-0.5 py-2 lg:py-6 bg-gray-100">
            <ThumbnailSliderView
              files={convertExtractedImages(
                selectedAttachment?.extractedImages,
              )}
              onClick={(file) => {
                setSelectedUrl(file?.url);
              }}
            />
          </div>
        </div>
      </MainContainer>
      <SidebarContainer className="p-2 xl:p-4 bg-gray-100 lg:w-[25vw]">
        <div className="relative bg-white h-full w-full overflow-y-auto overscroll-y-contain">
          <ConstructionDetailActionBar
            isEditing={isEditing}
            handleClose={handleClose}
          />
          <ConstructionDetailsForm
            className="px-4 pt-4 pb-6"
            readOnly={!isEditing}
            handleClose={handleClose}
          />
        </div>
      </SidebarContainer>
    </FullScreenModal>
  );
};
