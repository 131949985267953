import { AuthLayout } from "@amenda-components/Auth";
import { FC } from "react";
import { HelperMessage } from "@amenda-components/App";
import { encodeWithSecret } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@amenda-domains/mutations";

export const ProfileUpdatedSuccessPage: FC = () => {
  const navigate = useNavigate();
  const currentUser = useUsersStore((state) => state.currentUser);

  const goToLogin = () => {
    navigate(
      `/login/by-token?token=${encodeWithSecret({
        email: currentUser?.email,
      })}`,
    );
  };

  return (
    <AuthLayout label="Account updated">
      <HelperMessage
        message="You have successfully updated you account"
        actionText="Login"
        onClick={goToLogin}
      />
    </AuthLayout>
  );
};
