import { Button, LoaderWrapper, Spinner } from "@amenda-components/App";
import { FC, useCallback, useRef, useState } from "react";
import {
  useGetAllSystemRoles,
  useSearchSystemRoles,
} from "@amenda-domains/queries";
import {
  useSettingsStore,
  useTenantStore,
  useUpdateTenant,
} from "@amenda-domains/mutations";

import { DebounceTimes } from "@amenda-constants";
import { ReactTable } from "@amenda-components/Shared";
import { ReactTableKeys } from "@amenda-types";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddRoles: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => navigate("/admin/rollen/neu");

  return (
    <div className="max-w-lg mx-auto">
      <div>
        <div className="text-center">
          <ShieldCheckIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h2 className="mt-2 text-lg font-medium text-gray-900">
            {t("Create roles")}
          </h2>
          <p className="font-apercu mt-1 text-sm text-gray-500">
            {t(
              "You haven’t added any roles to your Organization yet. As the owner of this Organization, you can manage all roles",
            )}
          </p>
          <div className="mt-5 mb-10">
            <Button
              type="button"
              variant="primary"
              className="px-4"
              size="md"
              onClick={handleClick}
            >
              {t("New Role")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const RolesTable: FC<{ roles: any[] }> = ({ roles }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, getAllSystemRoles } = useGetAllSystemRoles();
  const pagination = useSettingsStore((state) => state.pagination);
  const { searchSystemRoles, loading: searchingSystemRoles } =
    useSearchSystemRoles();
  const setSearchTerm = useSettingsStore((state) => state.setSearchTerm);
  const { updateTenant, updateTenantLoader } = useUpdateTenant();
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  const handleEdit = (role: any) => () => {
    navigate(`/admin/rollen/${role.id}`);
  };

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length > 0) {
        await searchSystemRoles(searchTerm);
      } else {
        await getAllSystemRoles();
      }
    }, DebounceTimes.Search),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await debouncedSearch(searchTerm);
  };

  const handleSetDefaultRole = async (defaultRoleId: string) => {
    setSelectedRoleId(defaultRoleId);
    await updateTenant({
      input: {
        defaultRoleId,
      },
    });
    setSelectedRoleId(null);
  };

  const handleNextPage = useCallback(async () => {
    if (pagination?.hasNext) {
      await getAllSystemRoles({
        next: pagination?.next,
      });
    }
  }, [pagination?.next, pagination?.hasNext, getAllSystemRoles]);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="align-middle inline-block w-full">
          <div className="overflow-hidden px-2">
            <ReactTable
              isFullWidth
              isLoading={loading || searchingSystemRoles}
              data={roles}
              containerClass="w-full h-[calc(100vh-18.5rem)]"
              maxEstimatedRowHeight={60}
              pagination={pagination}
              tableId={ReactTableKeys.Roles}
              fetchNextPage={handleNextPage}
              handleSearch={handleSearch}
              columns={[
                {
                  id: "roleName",
                  header: t("Role"),
                  minSize: 250,
                  enablePinning: false,
                  accessorFn: (row) => row?.name,
                  cell: ({ row }) => {
                    const role = row.original;

                    return (
                      <TableCellWrapper className="w-full flex-col items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {role.name}
                        </div>
                        <div className="font-apercu text-sm text-gray-400">
                          {role.description}
                        </div>
                      </TableCellWrapper>
                    );
                  },
                },
                {
                  id: "role",
                  header: t("User"),
                  enablePinning: false,
                  cell: ({ row }) => {
                    const role = row.original;

                    return (
                      <TableCellWrapper>
                        <div className="font-apercu whitespace-nowrap text-sm text-gray-500">
                          <span className="mr-1">
                            {role.usersIds?.length || 0}
                          </span>
                          {t("users")}
                        </div>
                      </TableCellWrapper>
                    );
                  },
                },
                {
                  id: "default",
                  header: t("Default Role"),
                  enablePinning: false,
                  cell: ({ row }) => {
                    const role = row.original;

                    return (
                      <TableCellWrapper>
                        <div className="flex">
                          {updateTenantLoader && role.id === selectedRoleId ? (
                            <Spinner spinnerSize="xs" />
                          ) : (
                            <div className="px-2">
                              <SingleRadioButton
                                id="defaultRole"
                                checked={
                                  role.id === primaryTenant?.defaultRoleId
                                }
                                onChange={() => handleSetDefaultRole(role.id)}
                              />
                            </div>
                          )}
                        </div>
                      </TableCellWrapper>
                    );
                  },
                },
                {
                  id: "actions",
                  enablePinning: false,
                  header: () => (
                    <span className="sr-only">{t("Edit/Assign Role")}</span>
                  ),
                  cell: ({ row }) => {
                    const role = row.original;

                    return (
                      <TableCellWrapper>
                        <div className="flex space-x-1">
                          <Button
                            size="xs"
                            variant="default"
                            className="border border-1"
                            onClick={handleEdit(role)}
                          >
                            {t("Edit")}
                          </Button>
                        </div>
                      </TableCellWrapper>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const RolesSettingsTable: FC<{
  loading: boolean;
  roles: any[];
}> = ({ loading, roles }) => {
  const searchTerm = useSettingsStore((state) => state.searchTerm);

  if (loading) {
    return <LoaderWrapper className="bg-gray-50" variant="default" />;
  }
  return (
    <>
      {roles.length < 1 && !searchTerm ? (
        <AddRoles />
      ) : (
        <RolesTable roles={roles} />
      )}
    </>
  );
};
