import {
  AllowedCollectionType,
  GeneralPermissionTypes,
  Pagination,
  ProjectView,
  ReactTableKeys,
} from "@amenda-types";
import { FC, useEffect } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { ProjectGridView, ProjectTableView } from "@amenda-components/Projects";
import { getComponentsById, isSafeCollection } from "@amenda-utils";
import {
  useAppStore,
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { BlockedRoutes } from "@amenda-constants";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { HelperMessage } from "@amenda-components/App";
import { ProjectMapView } from "@amenda-components/Maps";
import { getProjectArgs } from "./common";
import { isEmpty } from "lodash";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useGetAllProjects } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";

const getValue = ({
  value,
  pagination,
  defaultValue,
}: {
  value: any;
  defaultValue: any;
  pagination?: Pagination;
}) => {
  if (pagination?.docsCount && pagination.docsCount > 0) {
    return value;
  }
  return defaultValue;
};

interface Props {
  isCollection?: boolean;
}

export const AllProjectsView: FC<Props> = ({ isCollection }) => {
  const navigate = useNavigate();
  const pagination = useProjectStore((state) => state.pagination);
  const projects = useProjectStore((state) => state.projects);
  const projectView = useProjectStore((state) => state.projectView);
  const components = useProjectStore((state) => state.projectFormComponents);
  const { getAllProjects } = useGetAllProjects();
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const visibleColumns = useAppStore(
    (state) => state.tableColumnVisibility[ReactTableKeys.Projects],
  );

  const selectedCollection =
    selectedCollectionByType?.[AllowedCollectionType.Projects];

  const componentsById = getComponentsById(components);
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();

  const handleClick = () => {
    navigate(BlockedRoutes.ProjectNew);
  };

  useEffect(() => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      isSafeCollection(isCollection, selectedCollection?.resourceIds)
    ) {
      getAllProjects(
        getProjectArgs({
          isCollection,
          similarProjectIds,
          visibleColumns,
          collectionProjectIds: selectedCollection?.resourceIds,
        }),
      );
    }
  }, [
    sidebarFilters,
    searchTerm,
    isCollection,
    similarProjectIds,
    visibleColumns,
    selectedCollection?.resourceIds,
    getAllProjects,
  ]);

  if (!isFetching && !isSearching && isEmpty(projects)) {
    return (
      <HelperMessage
        actionText="Create Project"
        Icon={BuildingOffice2Icon}
        message={getValue({
          pagination,
          value: "No Projects Found",
          defaultValue: projectsGeneralPermissions[
            GeneralPermissionTypes.Create
          ]
            ? "Get started by creating a new project."
            : "No Projects found",
        })}
        helpText={getValue({
          pagination,
          value: "Try changing your search filters",
          defaultValue: projectsGeneralPermissions[
            GeneralPermissionTypes.Create
          ]
            ? "Please create a Project first"
            : "Contact your administrator to add projects",
        })}
        onClick={getValue({
          pagination,
          value: undefined,
          defaultValue:
            projectsGeneralPermissions[GeneralPermissionTypes.Create] &&
            handleClick,
        })}
      />
    );
  }
  return (
    <div>
      {projectView === ProjectView.Gallery && (
        <ProjectGridView
          isCollection={isCollection}
          projects={projects}
          componentsById={componentsById}
        />
      )}
      {projectView === ProjectView.Table && (
        <ProjectTableView
          isCollection={isCollection}
          projects={projects}
          components={components}
          componentsById={componentsById}
        />
      )}
      {projectView === ProjectView.Map && (
        <ProjectMapView projects={projects} />
      )}
    </div>
  );
};
