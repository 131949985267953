import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Button, IconButtonBase, Tooltip } from "@amenda-components/App";
import {
  Control,
  Controller,
  UseFormSetValue,
  useFieldArray,
} from "react-hook-form";
import {
  DatePicker,
  MultiSelect,
  SingleSelect,
  Switch,
  TextField,
} from "@amenda-components/FormComponents";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
} from "./FormBuilderDndComponents";
import { GripVerticalIcon, XIcon } from "lucide-react";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Conditions } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import { PlusIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { getComponentsById } from "@amenda-utils";
import { getConditionOptions } from "./common";
import isNil from "lodash/isNil";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  components: any[];
  setValue: UseFormSetValue<any>;
}

interface DisplayConditionProps {
  index: number;
  field: Record<string, any>;
  components: any[];
  control: Control<any>;
  componentsById: Record<string, any>;
  remove: () => void;
}

interface FormBuilderCreateComponentConditionProps {
  components: any[];
  componentsById: Record<string, any>;
  append: (value: any) => void;
}

interface FormBuilderConditionExpectedValueProps {
  id: string;
  value: any;
  components: any[];
  componentId?: string;
  condition?: Conditions;
  error?: string;
  onChange: (value: any) => void;
}

interface DragOverlayComponentProps {
  components: any[];
  componentsById: Record<string, any>;
  activeIndex: number;
  activeField: Record<string, any>;
}

const isDragValid = (active: Active, over: Over) => {
  return active.id !== over.id;
};

const getFieldId = (label: string, index: number) => {
  return `displayConditions.${index}.${label}`;
};

export const FormBuilderConditionExpectedValue: FC<
  FormBuilderConditionExpectedValueProps
> = ({ id, value, error, components, componentId, condition, onChange }) => {
  const label = "Value";
  const type =
    condition &&
    [Conditions.GreaterThan, Conditions.LessThan].includes(condition)
      ? "number"
      : "text";
  if (!componentId) return null;

  const component = components.find((c) => c.id === componentId);
  if (!component) return null;
  if (
    [
      FormComponentTypes.Select,
      FormComponentTypes.MultiSelect,
      FormComponentTypes.RadioButton,
      FormComponentTypes.Checkbox,
      FormComponentTypes.Badges,
    ].includes(component.component)
  ) {
    const resolvedValue = Array.isArray(value) ? value : [value]; //TODO: temp fix for bad forms

    return (
      <MultiSelect
        id={id}
        label={label}
        isClearable={false}
        hasMenuOverflow={true}
        hideErrorMessage={true}
        values={resolvedValue}
        options={component?.properties?.options ?? []}
        error={error}
        onChange={onChange}
      />
    );
  } else if ([FormComponentTypes.DatePicker].includes(component.component)) {
    return (
      <DatePicker
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        withPortal={true}
        hideErrorMessage={true}
      />
    );
  }
  return (
    <TextField
      id={id}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
      hideErrorMessage={true}
    />
  );
};

export const FormBuilderCreateComponentCondition: FC<
  FormBuilderCreateComponentConditionProps
> = ({ components, componentsById, append }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<{
    when?: FormComponentTypes;
    condition?: Conditions;
    value?: any;
  }>({});

  const isDisabled =
    isNil(values.when) || isNil(values.condition) || isNil(values.value);

  const onSubmit = () => {
    append(values);
    setValues({});
  };

  return (
    <div className="w-full grid grid-cols-4 gap-1">
      <div className="col-span-2">
        <SingleSelect
          id="when"
          label="When"
          className=""
          isClearable={false}
          value={values.when}
          getOptionValue={(c) => c.id}
          getOptionLabel={(c) => c.properties?.label ?? ""}
          hasMenuOverflow={true}
          hideErrorMessage={true}
          options={components}
          onChange={(value) => setValues((prev) => ({ ...prev, when: value }))}
        />
      </div>
      <div className="col-span-2">
        <SingleSelect
          id="condition"
          label="Condition"
          className=""
          isClearable={false}
          value={values.condition}
          getOptionLabel={(option) => t(option.label)}
          hasMenuOverflow={true}
          hideErrorMessage={true}
          options={getConditionOptions(componentsById[values?.when ?? ""])}
          onChange={(value) =>
            setValues((prev) => ({ ...prev, condition: value }))
          }
        />
      </div>
      <div className="col-span-4">
        <FormBuilderConditionExpectedValue
          id="value"
          value={values.value}
          componentId={values.when}
          condition={values.condition as Conditions}
          components={components}
          onChange={(value) => setValues((prev) => ({ ...prev, value }))}
        />
      </div>
      <div className="col-span-4">
        <div className="flex justify-end pb-2">
          <Button
            withGroup={true}
            size="xs"
            variant="primary"
            disabled={isDisabled}
            onClick={onSubmit}
          >
            <div className="w-full flex items-center space-x-1">
              <PlusIcon className="w-4 h-4 stroke-[3]" />
              <span>{t("Add")}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const DisplayConditions: FC<DisplayConditionProps> = ({
  index,
  field,
  control,
  components,
  componentsById,
  remove,
}) => {
  const { t } = useTranslation();

  return (
    <FormBuilderSortableItem
      id={field.arrId}
      data={{
        index,
        field,
      }}
    >
      {(isDragging) => (
        <div
          className={clsx(
            "w-full grid grid-cols-4 gap-1 p-2 group/displayCondition border border-dashed border-2 border-transparent hover:border-gray-300",
            {
              invisible: isDragging,
            },
          )}
        >
          <div className="col-span-4 w-full flex justify-between invisible group-hover/displayCondition:visible">
            <IconButtonBase className="cursor-grab px-0.5 py-1 mr-1">
              <GripVerticalIcon className="w-5 h-5 stroke-[2]" />
            </IconButtonBase>
            <Tooltip
              id="deleteCondition"
              positionStrategy="fixed"
              message="Remove display condition?"
            >
              <IconButtonBase variant="dangerAlt" onClick={remove}>
                <XIcon className="w-5 h-5" />
              </IconButtonBase>
            </Tooltip>
          </div>
          {index > 0 && (
            <div className="col-span-4 flex justify-center">
              <Controller
                name={getFieldId("and", index)}
                control={control}
                defaultValue={false}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="flex items-center space-x-2">
                      <span className="amenda-component-label">{t("Or")}</span>
                      <Switch
                        label="And"
                        checked={value}
                        error={error?.message}
                        onChange={onChange}
                        showErrorMessage={false}
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}
          <div className="col-span-2">
            <Controller
              name={getFieldId("when", index)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SingleSelect
                    id={getFieldId("when", index)}
                    label="When"
                    className=""
                    isClearable={false}
                    value={value}
                    getOptionValue={(c) => c.id}
                    getOptionLabel={(c) => c.properties?.label ?? ""}
                    hasMenuOverflow={true}
                    hideErrorMessage={true}
                    onChange={onChange}
                    error={error?.message}
                    options={components}
                    disabled={true}
                  />
                );
              }}
            />
          </div>
          <div className="col-span-2">
            <Controller
              name={getFieldId("condition", index)}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SingleSelect
                    id={getFieldId("condition", index)}
                    label="Condition"
                    className=""
                    isClearable={false}
                    value={value}
                    getOptionLabel={(option) => t(option.label)}
                    hasMenuOverflow={true}
                    hideErrorMessage={true}
                    onChange={onChange}
                    error={error?.message}
                    options={getConditionOptions(componentsById[field.when])}
                  />
                );
              }}
            />
          </div>
          <div className="col-span-4">
            <Controller
              name={getFieldId("value", index)}
              control={control}
              defaultValue={false}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormBuilderConditionExpectedValue
                    id={getFieldId("value", index)}
                    value={value}
                    componentId={field.when}
                    components={components}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
        </div>
      )}
    </FormBuilderSortableItem>
  );
};

const DragOverlayComponent: FC<DragOverlayComponentProps> = ({
  activeIndex,
  activeField,
  components,
  componentsById,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-full grid grid-cols-4 gap-1 py-2">
      <div className="col-span-4 w-full">
        <IconButtonBase className="cursor-grab px-0.5 py-1 mr-1 text-gray-800">
          <GripVerticalIcon className="w-5 h-5 stroke-[2]" />
        </IconButtonBase>
      </div>
      {activeIndex > 0 && (
        <div className="col-span-4 flex justify-center">
          <div className="flex items-center space-x-2">
            <span className="amenda-component-label">{t("Or")}</span>
            <Switch
              label="And"
              checked={activeField?.and ?? false}
              onChange={() => {}}
              showErrorMessage={false}
            />
          </div>
        </div>
      )}
      <div className="col-span-2">
        <SingleSelect
          id="dragOverlayWhen"
          label="When"
          className=""
          isClearable={false}
          value={activeField?.when ?? ""}
          getOptionValue={(c) => c.id}
          getOptionLabel={(c) => c.properties?.label ?? ""}
          hasMenuOverflow={true}
          hideErrorMessage={true}
          onChange={() => {}}
          options={components}
          disabled={true}
        />
      </div>
      <div className="col-span-2">
        <SingleSelect
          id="dragOverlayCondition"
          label="Condition"
          className=""
          isClearable={false}
          value={activeField?.condition ?? ""}
          getOptionLabel={(option) => t(option.label)}
          hasMenuOverflow={true}
          hideErrorMessage={true}
          onChange={() => {}}
          options={getConditionOptions(componentsById[activeField?.when ?? ""])}
        />
      </div>
      <div className="col-span-4">
        <FormBuilderConditionExpectedValue
          id="dragOverlayValue"
          value={activeField?.value ?? ""}
          componentId={activeField?.when}
          components={components}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export const FormBuilderDisplayConditions: FC<Props> = ({
  control,
  components,
  setValue,
}) => {
  const { fields, remove, append, move } = useFieldArray({
    control,
    keyName: "arrId",
    name: "displayConditions",
  });
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const activeIndex = fields.findIndex((f) => f.arrId === activeId);
  const activeField = fields[activeIndex];
  const componentsById = getComponentsById(components);

  const handleDragEnd = async (active: Active, over: Over) => {
    const fromIndex = active.data?.current?.index;
    const toIndex = over.data?.current?.index;

    if (isNil(fromIndex) || isNil(toIndex)) return;
    move(fromIndex, toIndex);

    if (toIndex === 0) {
      setValue(getFieldId("and", 0), false);
    }
  };

  return (
    <div className="w-full flex flex-col p-1">
      <FormBuilderCreateComponentCondition
        components={components}
        componentsById={componentsById}
        append={append}
      />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToFirstScrollableAncestor]}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          const { active } = event;
          setActiveId(String(active.id));
        }}
        onDragEnd={(event) => {
          const { active, over } = event;
          setActiveId(null);
          if (over && isDragValid(active, over)) {
            handleDragEnd(active, over);
          }
        }}
      >
        <SortableContext
          id="formBuilderReadOnlyHeaders"
          items={fields.map((f) => f.arrId)}
          strategy={verticalListSortingStrategy}
        >
          <div className="w-full overflow-y-auto">
            <FormBuilderSortDroppable>
              {fields.map((field, index) => (
                <DisplayConditions
                  key={field.arrId}
                  field={field}
                  index={index}
                  control={control}
                  components={components}
                  componentsById={componentsById}
                  remove={() => remove(index)}
                />
              ))}
            </FormBuilderSortDroppable>
          </div>
        </SortableContext>
        <DragOverlay>
          <FormBuilderDragOverlayItem className="!bg-white border border-2 border-dashed border-gray-300">
            <DragOverlayComponent
              activeIndex={activeIndex}
              activeField={activeField}
              components={components}
              componentsById={componentsById}
            />
          </FormBuilderDragOverlayItem>
        </DragOverlay>
      </DndContext>
    </div>
  );
};
