import { FC, ForwardRefExoticComponent, ReactNode } from "react";
import { SearchBoxCore, SessionToken } from "@mapbox/search-js-core";

import { AddressSearchInput } from "./AddressSearchInput";
import { isEmpty } from "lodash";

interface Props {
  error?: string;
  id: string;
  label?: string;
  value: any;
  placeholder?: string;
  optional?: ReactNode;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  hasMenuOverflow?: boolean;
  StartIcon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  onChange: (value: any) => void;
  onBlur?: () => void;
}

export const AddressSearch: FC<Props> = ({
  value,
  onChange: handleChange,
  ...rest
}) => {
  const search = new SearchBoxCore({
    accessToken: process.env.REACT_APP_MAPBOX_CLIENT_ID || "",
  });
  const sessionToken = new SessionToken();

  const loadOptions = async (value: string) => {
    const { suggestions = [] } = await search.suggest(value, { sessionToken });

    return suggestions.filter((suggestion) => !isEmpty(suggestion.context));
  };

  const onChange = async (value: any) => {
    const location: {
      name: string | null;
      coordinates: [number, number] | null;
    } = {
      name: null,
      coordinates: null,
    };

    if (!!value) {
      const { features } = await search.retrieve(value, { sessionToken });

      if (features?.[0]?.properties?.full_address || value?.full_address) {
        location.name =
          features?.[0]?.properties?.full_address ?? value?.full_address;
      }
      if (features?.[0]?.geometry?.coordinates) {
        location.coordinates = features[0].geometry.coordinates as [
          number,
          number
        ];
      }
    }
    handleChange(location);
  };

  return (
    <div className="w-full group/addressSearch">
      <AddressSearchInput
        value={value}
        onChange={onChange}
        loadOptions={loadOptions}
        getOptionValue={(option) => option["mapbox_id"]}
        getOptionLabel={(option) => {
          return (
            option["full_address"] ||
            option["place_formatted"] ||
            option["name"]
          );
        }}
        {...rest}
      />
    </div>
  );
};
