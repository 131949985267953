import { Field, Switch as HuiSwitch, Label } from "@headlessui/react";

import { CheckIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "./ErrorMessage";
import { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  label: string;
  checked: boolean;
  error?: string;
  labelTop?: boolean;
  labelRight?: boolean;
  showLabel?: boolean;
  showErrorMessage?: boolean;
  onChange: (checked: boolean) => void;
  onBlur?: () => void;
}

export const Switch: FC<Props> = ({
  label,
  checked,
  error,
  className,
  labelTop = false,
  labelRight = false,
  showLabel = true,
  showErrorMessage = true,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-2 group/switch h-full flex flex-col justify-center">
      {showLabel && labelTop && (
        <span className="amenda-component-label">{t(label)}</span>
      )}
      <Field
        as="div"
        className={clsx("flex items-center", className, {
          "flex-row-reverse justify-end": labelRight,
        })}
      >
        <HuiSwitch
          checked={checked ?? false}
          onChange={onChange}
          onBlur={onBlur}
          className={clsx(
            "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200",
            {
              "bg-indigo-900": checked,
              "bg-gray-200": !checked,
            },
          )}
        >
          <span className="sr-only">{t(label)}</span>
          <span
            className={clsx(
              "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
              {
                "translate-x-5": checked,
                "translate-x-0": !checked,
              },
            )}
          >
            <span
              className={clsx(
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
                {
                  "opacity-0 ease-out duration-100": checked,
                  "opacity-100 ease-in duration-200": !checked,
                },
              )}
              aria-hidden="true"
            ></span>
            <span
              className={clsx(
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
                {
                  "opacity-100 ease-in duration-200": checked,
                  "opacity-0 ease-out duration-100": !checked,
                },
              )}
              aria-hidden="true"
            >
              <CheckIcon className="h-3 w-3 text-indigo-600" />
            </span>
          </span>
        </HuiSwitch>
        {showLabel && !labelTop && (
          <Label
            as="span"
            className={clsx({
              "mr-2": labelRight,
              "ml-2": !labelRight,
            })}
          >
            <span className="amenda-component-label">{t(label)}</span>
          </Label>
        )}
      </Field>
      {showErrorMessage && <ErrorMessage id={label} error={error} />}
    </div>
  );
};
