import { ChevronLeft, ChevronRight } from "lucide-react"; // Importing icons
import { Logo, SidebarFooter, SidebarMenuItems } from "./SidebarComponents";
import { useEffect, useState } from "react";

import { FC } from "react";
import { Link } from "react-router-dom";
import { NavigationProps } from "@amenda-types";

interface Props {
  navigation: NavigationProps[];
}

export const Sidebar: FC<Props> = ({ navigation }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  return (
    <div
      className={`relative hidden h-full lg:flex flex-col z-40 ${
        isCollapsed ? "group w-0 w-4 hover:w-6" : "w-[210px]"
      } transition-width duration-300 ease-in-out`}
    >
      <div className="absolute group-hover:bg-blue-100 top-0 lg:top-[35px] right-0 transform translate-x-full lg:translate-x-0">
        <button
          onClick={toggleSidebar}
          className={`p-2 bg-gray-400 hover:bg-gray-700 text-white hidden ${
            isCollapsed ? "rounded-r-md" : "rounded-l-md"
          }`}
        >
          {isCollapsed ? <ChevronRight size={10} /> : <ChevronLeft size={10} />}
        </button>
      </div>
      <div
        className={`flex flex-col flex-grow pt-5 pb-2 bg-[#F3F4F6] h-full ${
          isCollapsed ? "hidden" : "flex"
        }`}
      >
        <div className="relative flex flex-col overflow-y-auto h-full">
          <div className="px-4 py-2">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <SidebarMenuItems navigation={navigation} />
          <SidebarFooter className="h-12 sticky bottom-0 mt-auto" />
        </div>
      </div>
    </div>
  );
};
