import { Cache, UpdatesConfig } from "@urql/exchange-graphcache";
import {
  GET_ALL_ATTACHMENTS,
  GET_ALL_COLLECTIONS,
  GET_ALL_CONTACTS,
  GET_ALL_COST_GROUPS,
  GET_ALL_FORMS,
  GET_ALL_IMAGE_DESCRIPTORS,
  GET_ALL_MATERIALS,
  GET_ALL_PROJECTS,
  GET_ALL_SYSTEM_ROLES,
  GET_ALL_TIMELINE_ACTIVITIES,
  GET_ALL_USERS,
  GET_CURRENT_TENANT,
  GET_KEYWORDS,
  GET_SYSTEM_ROLE,
  GET_USER_WIDGETS,
} from "./queries";

import isNil from "lodash/isNil";
import uniq from "lodash/uniq";

const Query = "Query";

interface Item {
  id: string;
  tenantId?: string;
  isDeleted?: boolean;
}

const getTenantId = (cache: Cache) => {
  const data = cache.readQuery<any>({
    query: `
      query {
        getCurrentTenant { 
          tenantId
         }
      }
    `,
    variables: {},
  });
  return data?.getCurrentTenant?.tenantId;
};

const getCurrentUserId = (cache: Cache) => {
  const data = cache.readQuery<any>({
    query: `
      query {
        getCurrentUser { 
          _id
        }
      }
    `,
    variables: {},
  });

  return data?.getCurrentUser?._id;
};

const getCurrentSystemRoleId = (cache: Cache, userId: string) => {
  const data = cache.readQuery<any>({
    query: `
      query {
        getSystemRole(_id: $id, usersIds: $usersIds) {
          _id
        }
      }
    `,
    variables: {
      usersIds: [userId],
    },
  });

  return data?.getSystemRole?._id;
};

const removeItem = (item: Item, items: Item[]) => {
  return items.filter((i) => i.id !== item.id);
};

const addItem = (item: Item, items: Item[]) => {
  return [item, ...items];
};

const updateItem = (item: Item, items: Item[]) => {
  return items.map((i) => (i.id === item.id ? item : i));
};

const itemExists = (item: Item, items: Item[]) => {
  return items.some((i) => i.id === item.id);
};

const upsertItem = (item: Item, items: Item[]) => {
  return itemExists(item, items)
    ? updateItem(item, items)
    : addItem(item, items);
};

const updateOrRemoveItem = (item: Item, items: Item[]) => {
  if (Boolean(item.isDeleted)) {
    return removeItem(item, items);
  }
  return updateItem(item, items);
};

const upsertOrRemoveItem = (item: Item, items: Item[]) => {
  if (!items) {
    return items;
  }
  return itemExists(item, items)
    ? updateOrRemoveItem(item, items)
    : addItem(item, items);
};

const filterByIds = (ids: string[], items: Item[]) => {
  if (ids.length > 0) {
    return items.filter((item) => !ids.includes(item.id));
  }
  return items;
};

const addResourceIdsToCollections = ({
  collections,
  resourceIds,
  collectionIds,
}: {
  collections: any[];
  resourceIds: string[];
  collectionIds: string[];
}) => {
  return collections.map((collection) => {
    if (collectionIds.includes(collection.id)) {
      const updateResourcesIds = resourceIds.concat(
        collection.resourceIds || [],
      );

      collection.resourceIds = uniq(updateResourcesIds);
    }
    return collection;
  });
};

const shouldCollectionBeAdded = ({
  userId,
  roleId,
  collection,
}: {
  userId: string;
  roleId?: string;
  collection: any;
}) => {
  if (collection.ownerId === userId) {
    return true;
  } else if (collection.shares?.[0]?.userIds?.includes(userId)) {
    return true;
  } else if (collection.shares?.[0]?.userRolesIds?.includes(roleId)) {
    return true;
  }
  return false;
};

export const updates: UpdatesConfig = {
  Mutation: {
    assignSystemRoleToUser(result, _args, cache, _info) {
      if (result.assignSystemRoleToUser) {
        cache
          .inspectFields(Query)
          .filter(({ fieldName }) =>
            ["getSystemRole", "getAllSystemRoles"].includes(fieldName),
          )
          .forEach((field) => {
            cache.invalidate(Query, field.fieldKey);
          });
      }
    },
    createContact: (result, _args, cache, _info) => {
      const contact = result.createContact as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .filter((field) => isNil(field.arguments?.contactDetails))
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = upsertItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .filter((field) => {
          const companyId = (field.arguments?.contactDetails as any)?.company;
          const userCompanyId = contact.contactDetails?.company;

          return companyId && userCompanyId && companyId === userCompanyId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = upsertItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateContact: (result, _args, cache, _info) => {
      const contact = result.updateContact as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = updateOrRemoveItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
        });
    },
    updateUser: (result, _args, cache, _info) => {
      const user = result.updateUser as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.updateQuery<{
            getAllUsers: {
              users?: any[];
            };
          }>(
            {
              query: GET_ALL_USERS,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllUsers &&
                Boolean(field.arguments?.isActive) === Boolean(user?.isActive)
              ) {
                data.getAllUsers.users = updateOrRemoveItem(
                  user,
                  data.getAllUsers.users || [],
                );
              }
              return data;
            },
          );
        });
    },
    deleteAttachments(result, _args, cache, _info) {
      const hasDeletedAttachments = result.deleteAttachments as any;

      if (hasDeletedAttachments) {
        const deletedAttachmentIds = _args.attachmentIds as string[];
        cache
          .inspectFields(Query)
          .filter(({ fieldName }) => fieldName === "getAllAttachments")
          .forEach((field) => {
            cache.updateQuery<{
              getAllAttachments: {
                attachments?: any[];
              };
            }>(
              {
                query: GET_ALL_ATTACHMENTS,
                variables: field.arguments || {},
              },
              (data) => {
                if (data?.getAllAttachments?.attachments) {
                  data.getAllAttachments.attachments = filterByIds(
                    deletedAttachmentIds,
                    data.getAllAttachments.attachments,
                  );
                }
                return data;
              },
            );
          });
      }
    },
    updateTenant: (result, _args, cache, _info) => {
      const tenant = result.updateTenant as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getCurrentTenant")
        .forEach((field) => {
          cache.updateQuery<{
            getCurrentTenant: any;
          }>(
            {
              query: GET_CURRENT_TENANT,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getCurrentTenant) {
                data.getCurrentTenant = tenant;
              }
              return data;
            },
          );
        });
    },
    upsertComponentKeywords: (result, _args, cache, _info) => {
      const keyword = result.upsertComponentKeywords as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getKeywords")
        .filter((field) => {
          const args = field?.arguments?.args as any;

          if (Boolean(args?._ids)) {
            return args._ids.includes(keyword.id);
          }
          return args?.componentIds?.includes(keyword.componentId);
        })
        .forEach((field) => {
          cache.updateQuery<{
            getKeywords: any[];
          }>(
            {
              query: GET_KEYWORDS,
              variables: (field.arguments?.args as any) ?? {},
            },
            (data) => {
              const isDeleted = Boolean((_args?.input as any)?.isDeleted);

              if (data?.getKeywords) {
                const keywords = data.getKeywords ?? [];

                data.getKeywords = isDeleted
                  ? removeItem(keyword, keywords)
                  : upsertItem(keyword, keywords);
              } else if (!isDeleted) {
                data = {
                  getKeywords: [keyword],
                };
              }
              return data;
            },
          );
        });
    },
    createCostGroup: (result, _args, cache, _info) => {
      const costGroup = result.createCostGroup as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .filter((field) => {
          const { resourceId } = field.arguments || {};

          return resourceId === costGroup.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCostGroups?: {
              costGroups?: any[];
            };
          }>(
            {
              query: GET_ALL_COST_GROUPS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllCostGroups?.costGroups) {
                data.getAllCostGroups.costGroups = upsertItem(
                  costGroup,
                  data.getAllCostGroups.costGroups,
                );
              }
              return data;
            },
          );
        });
    },
    updateCostGroup: (result, _args, cache, _info) => {
      const costGroup = result.updateCostGroup as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .filter((field) => {
          const { resourceId } = field.arguments || {};

          return resourceId === costGroup.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCostGroups?: {
              costGroups?: any[];
            };
          }>(
            {
              query: GET_ALL_COST_GROUPS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllCostGroups?.costGroups) {
                data.getAllCostGroups.costGroups = updateOrRemoveItem(
                  costGroup,
                  data.getAllCostGroups.costGroups,
                );
              }
              return data;
            },
          );
        });
    },
    createProject(result, _args, cache, _info) {
      const project = result.createProject as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects) {
                data.getAllProjects.projects = upsertItem(
                  project,
                  data.getAllProjects.projects || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    updateProject(result, _args, cache, _info) {
      const project = result.updateProject as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects) {
                data.getAllProjects.projects = updateOrRemoveItem(
                  project,
                  data.getAllProjects.projects || [],
                );
              }
              return data;
            },
          );
        });
    },
    upsertSystemRole(result, _args, cache, _info) {
      const systemRole = result.upsertSystemRole as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllSystemRoles")
        .forEach((field) => {
          cache.updateQuery<{
            getAllSystemRoles: {
              roles?: any[];
            };
          }>(
            {
              query: GET_ALL_SYSTEM_ROLES,
              variables: field.arguments || {},
            },
            (data) => {
              const roles = data?.getAllSystemRoles?.roles;
              if (roles) {
                data.getAllSystemRoles.roles = upsertItem(systemRole, roles);
              }
              return data;
            },
          );
        });

      cache
        .inspectFields(Query)
        .filter(
          (field) =>
            field.fieldName === "getSystemRole" &&
            !isNil(field.arguments?.users),
        )
        .forEach((field) => {
          cache.updateQuery<{
            getSystemRole?: any;
          }>(
            {
              query: GET_SYSTEM_ROLE,
              variables: field.arguments || {},
            },
            (data) => {
              if (data) {
                data.getSystemRole = systemRole;
              }
              return data;
            },
          );
        });
    },
    deleteSystemRole(result, _args, cache, _info) {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllSystemRoles")
        .forEach((field) => {
          cache.updateQuery<{
            getAllSystemRoles: {
              roles?: any[];
            };
          }>(
            {
              query: GET_ALL_SYSTEM_ROLES,
              variables: field.arguments || {},
            },
            (data) => {
              const roles = data?.getAllSystemRoles?.roles;
              if (roles) {
                data.getAllSystemRoles.roles = removeItem(
                  {
                    id: _args.id,
                  } as any,
                  roles,
                );
              }
              return data;
            },
          );
        });
    },
    createAttachment(result, _args, cache, _info) {
      const attachment = result.createAttachment as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.updateQuery<{
            getAllAttachments: {
              attachments?: any[];
            };
          }>(
            {
              query: GET_ALL_ATTACHMENTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllAttachments) {
                data.getAllAttachments.attachments = upsertItem(
                  attachment,
                  data.getAllAttachments.attachments || [],
                );
              }
              return data;
            },
          );
        });
    },
    updateAttachment(result, _args, cache, _info) {
      const attachment = result.updateAttachment as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.updateQuery<{
            getAllAttachments: {
              attachments?: any[];
            };
          }>(
            {
              query: GET_ALL_ATTACHMENTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllAttachments) {
                data.getAllAttachments.attachments = updateOrRemoveItem(
                  attachment,
                  data.getAllAttachments.attachments || [],
                );
              }
              return data;
            },
          );
        });
    },
    upsertUserWidgets(result, _args, cache, _info) {
      const userWidget = result.widget as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getUserWidgets")
        .filter((field) => field.arguments?.type === userWidget.type)
        .forEach((field) => {
          cache.updateQuery<{
            widgets?: any[];
          }>(
            {
              query: GET_USER_WIDGETS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data) {
                data.widgets = upsertItem(userWidget, data.widgets || []);
              }
              return data;
            },
          );
        });
    },
    deleteCostGroups(result, _args, cache, _info) {
      const deletedCostGroups = result.deleteCostGroups as any[];
      const costGroupIds = deletedCostGroups.map((costGroup) => costGroup.id);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCostGroups")
        .forEach((field) => {
          cache.updateQuery<{
            getAllCostGroups?: {
              costGroups?: any[];
            };
          }>(
            {
              query: GET_ALL_COST_GROUPS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllCostGroups?.costGroups) {
                data.getAllCostGroups.costGroups = filterByIds(
                  costGroupIds,
                  data.getAllCostGroups.costGroups,
                );
              }
              return data;
            },
          );
        });
    },
    deleteProjects(result, _args, cache, _info) {
      const deletedProjects = result.deleteProjects as any[];
      const projectIds = deletedProjects.map((project) => project.id);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects?.projects) {
                data.getAllProjects.projects = filterByIds(
                  projectIds,
                  data.getAllProjects.projects,
                );
              }
              return data;
            },
          );
        });
    },
    createMaterial: (result, _args, cache, _info) => {
      const material = result.createMaterial as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.updateQuery<{
            getAllMaterials?: {
              materials?: any[];
            };
          }>(
            {
              query: GET_ALL_MATERIALS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllMaterials) {
                data.getAllMaterials.materials = upsertItem(
                  material,
                  data.getAllMaterials.materials || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    deleteUsers(result, _args, cache, _info) {
      const deletedUsers = result.deleteUsers as any[];
      const userIds = deletedUsers.map((u) => u.id);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.updateQuery<{
            getAllUsers: {
              users?: any[];
            };
          }>(
            {
              query: GET_ALL_USERS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllUsers?.users) {
                data.getAllUsers.users = filterByIds(
                  userIds,
                  data.getAllUsers.users,
                );
              }
              return data;
            },
          );
        });
    },
    deleteContacts(result, _args, cache, _info) {
      const deleteContacts = result.deleteContacts as any[];
      const contactIds = deleteContacts.map((u) => u.id);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts?.contacts) {
                data.getAllContacts.contacts = filterByIds(
                  contactIds,
                  data.getAllContacts.contacts,
                );
              }
              return data;
            },
          );
        });
    },
    updateMaterial: (result, _args, cache, _info) => {
      const material = result.updateMaterial as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.updateQuery<{
            getAllMaterials?: {
              materials?: any[];
            };
          }>(
            {
              query: GET_ALL_MATERIALS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllMaterials) {
                data.getAllMaterials.materials = updateOrRemoveItem(
                  material,
                  data.getAllMaterials.materials || [],
                );
              }
              return data;
            },
          );
        });

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getMaterial")
        .filter(
          (field) =>
            field.arguments?._id && field.arguments._id === material.id,
        )
        .forEach((field) => {
          cache.invalidate(Query, field.fieldKey);
        });
    },
    createCollection: (result, _args, cache, _info) => {
      const collection = result.createCollection as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .filter((field) => {
          const { collectionType } = field.arguments || {};
          return collectionType === collection.collectionType;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data) {
                data.getAllCollections = upsertItem(
                  collection,
                  data?.getAllCollections || [],
                );
              }

              return data;
            },
          );
        });
    },
    updateCollection: (result, _args, cache, _info) => {
      const collection = result.updateCollection as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .filter((field) => {
          const { collectionType } = field.arguments || {};
          return collectionType === collection.collectionType;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data) {
                data.getAllCollections = updateOrRemoveItem(
                  collection,
                  data.getAllCollections || [],
                );
              }
              return data;
            },
          );
        });
    },
    updateCollectionsResourceIds: (result, _args, cache, _info) => {
      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              const collections = data?.getAllCollections;
              const {
                input: { collectionIds, resourceIds },
              } = (_args as any) || {};

              if (collections) {
                data.getAllCollections = addResourceIdsToCollections({
                  collections,
                  resourceIds,
                  collectionIds,
                });
              }
              return data;
            },
          );
        });
    },
    createTimelineActivity: (result, _args, cache, _info) => {
      const timeline = result.createTimelineActivity as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .filter((field) => {
          const { resourceId } = field.arguments || {};
          return resourceId === timeline.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllTimelineActivities: {
              timelineActivities?: any[];
            };
          }>(
            {
              query: GET_ALL_TIMELINE_ACTIVITIES,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllTimelineActivities) {
                data.getAllTimelineActivities.timelineActivities = upsertItem(
                  timeline,
                  data?.getAllTimelineActivities?.timelineActivities || [],
                );
              }

              return data;
            },
          );
        });
    },
    updateTimelineActivity: (result, _args, cache, _info) => {
      const timeline = result.updateTimelineActivity as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .filter((field) => {
          const { resourceId } = field.arguments || {};
          return resourceId === timeline.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllTimelineActivities: {
              timelineActivities?: any[];
            };
          }>(
            {
              query: GET_ALL_TIMELINE_ACTIVITIES,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllTimelineActivities) {
                data.getAllTimelineActivities.timelineActivities =
                  updateOrRemoveItem(
                    timeline,
                    data?.getAllTimelineActivities?.timelineActivities || [],
                  );
              }

              return data;
            },
          );
        });
    },
    createImageDescriptor: (result, _args, cache, _info) => {
      const imageDescriptor = result.createImageDescriptor as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllImageDescriptors")
        .forEach((field) => {
          cache.updateQuery<{
            getAllImageDescriptors: {
              imageDescriptors?: any[];
            };
          }>(
            {
              query: GET_ALL_IMAGE_DESCRIPTORS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllImageDescriptors) {
                data.getAllImageDescriptors.imageDescriptors = upsertItem(
                  imageDescriptor,
                  data.getAllImageDescriptors?.imageDescriptors || [],
                );
              }

              return data;
            },
          );
        });
    },
    updateImageDescriptor: (result, _args, cache, _info) => {
      const imageDescriptor = result.updateImageDescriptor as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllImageDescriptors")
        .forEach((field) => {
          cache.updateQuery<{
            getAllImageDescriptors: {
              imageDescriptors?: any[];
            };
          }>(
            {
              query: GET_ALL_IMAGE_DESCRIPTORS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllImageDescriptors) {
                data.getAllImageDescriptors.imageDescriptors =
                  updateOrRemoveItem(
                    imageDescriptor,
                    data.getAllImageDescriptors?.imageDescriptors || [],
                  );
              }
              return data;
            },
          );
        });
    },
    createForm: (result, _args, cache, _info) => {
      const form = result.createForm as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.updateQuery<{
            getAllForms?: any[];
          }>(
            {
              query: GET_ALL_FORMS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllForms) {
                data.getAllForms = upsertItem(form, data.getAllForms || []);
              }
              return data;
            },
          );
        });
    },
    updateForm: (result, _args, cache, _info) => {
      const form = result.updateForm as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.updateQuery<{
            getAllForms?: any[];
          }>(
            {
              query: GET_ALL_FORMS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllForms) {
                data.getAllForms = updateOrRemoveItem(
                  form,
                  data.getAllForms || [],
                );
              }
              return data;
            },
          );
        });
    },
    deleteFormComponents: (result, _args, cache, _info) => {
      const form = result.deleteFormComponents as any;

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.updateQuery<{
            getAllForms?: any[];
          }>(
            {
              query: GET_ALL_FORMS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllForms) {
                data.getAllForms = updateOrRemoveItem(
                  form,
                  data.getAllForms || [],
                );
              }
              return data;
            },
          );
        });
    },
    updateMultipleForms: (result, _args, cache, _info) => {
      const forms = result.updateMultipleForms as any[];

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllForms")
        .forEach((field) => {
          cache.updateQuery<{
            getAllForms?: any[];
          }>(
            {
              query: GET_ALL_FORMS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllForms) {
                data.getAllForms = (forms ?? []).reduce(
                  (acc, form) => updateOrRemoveItem(form, acc),
                  data.getAllForms || [],
                );
              }
              return data;
            },
          );
        });
    },
  },
  Subscription: {
    attachmentCreated: (result, _args, cache, _info) => {
      const attachment = {
        ...(result.attachmentCreated as any),
        __typename: "Attachment",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.updateQuery<{
            getAllAttachments: {
              attachments?: any[];
            };
          }>(
            {
              query: GET_ALL_ATTACHMENTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllAttachments) {
                data.getAllAttachments.attachments = upsertItem(
                  attachment,
                  data.getAllAttachments.attachments || [],
                );
              }
              return data;
            },
          );
        });
    },
    attachmentUpdated: (result, _args, cache, _info) => {
      const attachment = {
        ...(result.attachmentUpdated as any),
        __typename: "Attachment",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.updateQuery<{
            getAllAttachments: {
              attachments?: any[];
            };
          }>(
            {
              query: GET_ALL_ATTACHMENTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllAttachments) {
                data.getAllAttachments.attachments = upsertOrRemoveItem(
                  attachment,
                  data.getAllAttachments.attachments || [],
                );
              }
              return data;
            },
          );
        });
    },
    attachmentDeleted: (result, _args, cache, _info) => {
      const attachment = {
        ...(result.attachmentDeleted as any),
        __typename: "Attachment",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllAttachments")
        .forEach((field) => {
          cache.updateQuery<{
            getAllAttachments: {
              attachments?: any[];
            };
          }>(
            {
              query: GET_ALL_ATTACHMENTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllAttachments) {
                data.getAllAttachments.attachments = removeItem(
                  attachment,
                  data.getAllAttachments.attachments || [],
                );
              }
              return data;
            },
          );
        });
    },
    projectCreated: (result, _args, cache, _info) => {
      const project = {
        ...(result.projectCreated as any),
        __typename: "Project",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects) {
                data.getAllProjects.projects = upsertItem(
                  project,
                  data.getAllProjects.projects || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    projectUpdated: (result, _args, cache, _info) => {
      const project = {
        ...(result.projectUpdated as any),
        __typename: "Project",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects) {
                data.getAllProjects.projects = upsertOrRemoveItem(
                  project,
                  data.getAllProjects.projects || [],
                );
              }
              return data;
            },
          );
        });
    },
    projectDeleted: (result, _args, cache, _info) => {
      const project = {
        ...(result.projectDeleted as any),
        __typename: "Project",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllProjects")
        .forEach((field) => {
          cache.updateQuery<{
            getAllProjects: {
              projects?: any[];
            };
          }>(
            {
              query: GET_ALL_PROJECTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllProjects) {
                data.getAllProjects.projects = removeItem(
                  project,
                  data.getAllProjects.projects || [],
                );
              }
              return data;
            },
          );
        });
    },
    userCreated: (result, _args, cache, _info) => {
      const user = {
        ...(result.userCreated as any),
        __typename: "User",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .filter((field) => isNil(field.arguments?.userDetails))
        .forEach((field) => {
          cache.updateQuery<{
            getAllUsers: {
              users?: any[];
            };
          }>(
            {
              query: GET_ALL_USERS,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllUsers &&
                Boolean(field.arguments?.isActive) === Boolean(user?.isActive)
              ) {
                data.getAllUsers.users = upsertItem(
                  user,
                  data.getAllUsers.users || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    userUpdated: (result, _args, cache, _info) => {
      const user = {
        ...(result.userUpdated as any),
        __typename: "User",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.updateQuery<{
            getAllUsers: {
              users?: any[];
            };
          }>(
            {
              query: GET_ALL_USERS,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllUsers &&
                Boolean(field.arguments?.isActive) === Boolean(user?.isActive)
              ) {
                data.getAllUsers.users = upsertOrRemoveItem(
                  user,
                  data.getAllUsers.users || [],
                );
              }
              return data;
            },
          );
        });
    },
    userDeleted: (result, _args, cache, _info) => {
      const user = {
        ...(result.userDeleted as any),
        __typename: "User",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllUsers")
        .forEach((field) => {
          cache.updateQuery<{
            getAllUsers: {
              users?: any[];
            };
          }>(
            {
              query: GET_ALL_USERS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllUsers) {
                data.getAllUsers.users = removeItem(
                  user,
                  data.getAllUsers.users || [],
                );
              }
              return data;
            },
          );
        });
    },
    contactCreated: (result, _args, cache, _info) => {
      const contact = {
        ...(result.contactCreated as any),
        __typename: "Contact",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .filter((field) => isNil(field.arguments?.contactDetails))
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = upsertItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .filter((field) => {
          const companyId = (field.arguments?.contactDetails as any)?.company;
          const userCompanyId = contact?.contactDetails?.company;

          return companyId && userCompanyId && companyId === userCompanyId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = upsertItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    contactUpdated: (result, _args, cache, _info) => {
      const contact = {
        ...(result.contactUpdated as any),
        __typename: "Contact",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = upsertOrRemoveItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
        });
    },
    contactDeleted: (result, _args, cache, _info) => {
      const contact = {
        ...(result.contactDeleted as any),
        __typename: "Contact",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllContacts")
        .filter((field) => field.arguments?.type === contact.type)
        .forEach((field) => {
          cache.updateQuery<{
            getAllContacts: {
              contacts?: any[];
            };
          }>(
            {
              query: GET_ALL_CONTACTS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllContacts) {
                data.getAllContacts.contacts = removeItem(
                  contact,
                  data.getAllContacts.contacts || [],
                );
              }
              return data;
            },
          );
        });
    },
    materialCreated: (result, _args, cache, _info) => {
      const material = {
        ...(result.materialCreated as any),
        __typename: "Material",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.updateQuery<{
            getAllMaterials?: {
              materials?: any[];
            };
          }>(
            {
              query: GET_ALL_MATERIALS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllMaterials) {
                data.getAllMaterials.materials = upsertItem(
                  material,
                  data.getAllMaterials.materials || [],
                );
              }
              return data;
            },
          );
          cache.invalidate(Query, field.fieldKey);
        });
    },
    materialUpdated: (result, _args, cache, _info) => {
      const material = {
        ...(result.materialUpdated as any),
        __typename: "Material",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.updateQuery<{
            getAllMaterials?: {
              materials?: any[];
            };
          }>(
            {
              query: GET_ALL_MATERIALS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllMaterials) {
                data.getAllMaterials.materials = upsertOrRemoveItem(
                  material,
                  data.getAllMaterials.materials || [],
                );
              }
              return data;
            },
          );
        });
    },
    materialDeleted: (result, _args, cache, _info) => {
      const material = {
        ...(result.materialDeleted as any),
        __typename: "Material",
      };

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllMaterials")
        .forEach((field) => {
          cache.updateQuery<{
            getAllMaterials?: {
              materials?: any[];
            };
          }>(
            {
              query: GET_ALL_MATERIALS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data?.getAllMaterials) {
                data.getAllMaterials.materials = removeItem(
                  material,
                  data.getAllMaterials.materials || [],
                );
              }
              return data;
            },
          );
        });
    },
    collectionCreated: (result, _args, cache, _info) => {
      const collection = {
        ...(result.collectionCreated as any),
        __typename: "Collection",
      };
      const tenantId = getTenantId(cache);
      const userId = getCurrentUserId(cache);
      const roleId = getCurrentSystemRoleId(cache, userId);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .filter((field) => {
          const { collectionType } = field.arguments || {};
          return collectionType === collection.collectionType;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data &&
                collection?.tenantId === tenantId &&
                shouldCollectionBeAdded({
                  collection,
                  userId,
                  roleId,
                })
              ) {
                data.getAllCollections = upsertItem(
                  collection,
                  data?.getAllCollections || [],
                );
              }

              return data;
            },
          );
        });
    },
    collectionUpdated: (result, _args, cache, _info) => {
      const collection = {
        ...(result.collectionUpdated as any),
        __typename: "Collection",
      };
      const tenantId = getTenantId(cache);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .filter((field) => {
          const { collectionType } = field.arguments || {};
          return collectionType === collection.collectionType;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data && collection?.tenantId === tenantId) {
                data.getAllCollections = updateOrRemoveItem(
                  collection,
                  data.getAllCollections || [],
                );
              }
              return data;
            },
          );
        });
    },
    collectionDeleted: (result, _args, cache, _info) => {
      const collection = {
        ...(result.collectionDeleted as any),
        __typename: "Collection",
      };
      const tenantId = getTenantId(cache);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllCollections")
        .filter((field) => {
          const { collectionType } = field.arguments || {};
          return collectionType === collection.collectionType;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllCollections?: any[];
          }>(
            {
              query: GET_ALL_COLLECTIONS,
              variables: field.arguments || {},
            },
            (data) => {
              if (data && collection?.tenantId === tenantId) {
                data.getAllCollections = updateOrRemoveItem(
                  collection,
                  data.getAllCollections || [],
                );
              }
              return data;
            },
          );
        });
    },
    timelineActivityCreated: (result, _args, cache, _info) => {
      const timeline = {
        ...(result.timelineActivityCreated as any),
        __typename: "TimelineActivity",
      };
      const tenantId = getTenantId(cache);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .filter((field) => {
          const { resourceId } = field.arguments || {};
          return resourceId === timeline.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllTimelineActivities: {
              timelineActivities?: any[];
            };
          }>(
            {
              query: GET_ALL_TIMELINE_ACTIVITIES,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllTimelineActivities &&
                timeline?.tenantId === tenantId
              ) {
                data.getAllTimelineActivities.timelineActivities = upsertItem(
                  timeline,
                  data?.getAllTimelineActivities?.timelineActivities || [],
                );
              }

              return data;
            },
          );
        });
    },
    timelineActivityUpdated: (result, _args, cache, _info) => {
      const timeline = {
        ...(result.timelineActivityUpdated as any),
        __typename: "TimelineActivity",
      };
      const tenantId = getTenantId(cache);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .filter((field) => {
          const { resourceId } = field.arguments || {};
          return resourceId === timeline.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllTimelineActivities: {
              timelineActivities?: any[];
            };
          }>(
            {
              query: GET_ALL_TIMELINE_ACTIVITIES,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllTimelineActivities &&
                timeline?.tenantId === tenantId
              ) {
                data.getAllTimelineActivities.timelineActivities =
                  updateOrRemoveItem(
                    timeline,
                    data?.getAllTimelineActivities?.timelineActivities || [],
                  );
              }

              return data;
            },
          );
        });
    },
    timelineActivityDeleted: (result, _args, cache, _info) => {
      const timeline = {
        ...(result.timelineActivityDeleted as any),
        __typename: "TimelineActivity",
      };
      const tenantId = getTenantId(cache);

      cache
        .inspectFields(Query)
        .filter(({ fieldName }) => fieldName === "getAllTimelineActivities")
        .filter((field) => {
          const { resourceId } = field.arguments || {};
          return resourceId === timeline.resourceId;
        })
        .forEach((field) => {
          cache.updateQuery<{
            getAllTimelineActivities: {
              timelineActivities?: any[];
            };
          }>(
            {
              query: GET_ALL_TIMELINE_ACTIVITIES,
              variables: field.arguments || {},
            },
            (data) => {
              if (
                data?.getAllTimelineActivities &&
                timeline?.tenantId === tenantId
              ) {
                data.getAllTimelineActivities.timelineActivities = removeItem(
                  timeline,
                  data?.getAllTimelineActivities?.timelineActivities || [],
                );
              }

              return data;
            },
          );
        });
    },
  },
};
