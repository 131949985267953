import { FC, ReactNode } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  id: string;
  className?: string;
  required?: boolean;
  wrapperClassName?: string;
  readOnly?: boolean;
  onChange?: (checked?: boolean) => void;
  onBlur?: () => void;
}

export const SingleCheckbox: FC<Props> = ({
  label,
  checked = false,
  className = "h-5 w-5",
  wrapperClassName = "flex flex-row items-center space-x-2",
  onChange,
  onBlur,
  id,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };

  // Function to conditionally translate label if it's a string
  const renderLabel = (label: ReactNode) => {
    if (typeof label === "string") {
      return t(label);
    }
    return label; // Directly return JSX or ReactNode without translation
  };

  return (
    <div className={wrapperClassName}>
      <input
        {...rest}
        id={id}
        type="checkbox"
        checked={checked}
        className={clsx("amenda-component text-[#2286E0]", className)}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {label && (
        <label htmlFor={id} className="text-sm font-apercu text-gray-900">
          {renderLabel(label)}
        </label>
      )}
    </div>
  );
};
