import { AllowedContactType, Option } from "@amenda-types";

import { ContactLabelledInput } from "./ContactLabelledInput";
import { FC } from "react";
import { HeadlessContacts } from "@amenda-components/Contacts";

interface Props {
  id: string;
  label: string;
  control: any;
  projectId: any;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
  hideRoleSelection?: boolean;
  contactType: AllowedContactType;
  readOnly?: boolean;
  roles?: Option[];
  onBlur?: () => void;
}

export const SearchContactAndRole: FC<Props> = ({
  id,
  isMulti = true,
  hideRoleSelection = false,
  projectId,
  roles = [],
  ...props
}) => {
  const showRoles = roles.length > 0 && !hideRoleSelection;

  return (
    <HeadlessContacts id={id} isMulti={isMulti} {...props}>
      {({ contact, selectedRoles, update, remove }) => (
        <ContactLabelledInput
          contact={contact}
          readOnly={!showRoles}
          selectedRoles={selectedRoles}
          availableLabels={roles}
          handleRemove={remove}
          handleUpdate={(roles) => {
            update({
              roles,
              contactId: contact.id,
            });
          }}
        />
      )}
    </HeadlessContacts>
  );
};
