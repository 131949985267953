import {
  Control,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  useForm,
  useWatch,
} from "react-hook-form";
import React, {
  FC,
  Fragment,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  devConsole,
  sanitizeData,
  sanitizeNestedData,
  useDeepCompareEffect,
} from "@amenda-utils";

import { DebounceTimes } from "@amenda-constants";
import { DetectFormNavigation } from "@amenda-components/FormComponents";
import { ObjectSchema } from "yup";
import debounce from "lodash/debounce";
import { getDirtyFieldsValue } from "./common";
import isEmpty from "lodash/isEmpty";
import { yupResolver } from "@hookform/resolvers/yup";

export interface FormAutoSaveResourceProps {
  resourceId?: string;
  resourceIds?: Record<string, string | string[]>;
}
interface ChildProps extends FormAutoSaveResourceProps {
  reset?: UseFormReset<any>;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
}

export interface FormAutoSaveBaseChildProps extends ChildProps {
  handleSubmitManually?: () => Promise<boolean>;
  handleFormSubmit?: (e: any) => Promise<void>;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

export interface FormAutoSaveBaseProps extends FormAutoSaveResourceProps {
  formId?: string;
  children: (props: FormAutoSaveBaseChildProps) => ReactNode;
  disableAutosave?: boolean;
  ignoreResourceIdAlways?: boolean;
  inputSchema: ObjectSchema<any>;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
  values: Record<string, any>;
  defaultValues?: Record<string, any>;
}

export interface FormAutoSaveSubmitProps extends FormAutoSaveResourceProps {
  data: Record<string, any>;
  dirtyData: Record<string, any>;
  sanitizedData: Record<string, any>;
  sanitizedDirtyData: Record<string, any>;
}

interface Props extends Omit<FormAutoSaveBaseProps, "children"> {
  className?: string;
  children: (props: ChildProps) => ReactNode;
}

interface AutoSaverProps {
  control: Control<any>;
  dirtyFields: Record<string, any>;
  disableAutosave: boolean;
  submitButtonRef: RefObject<HTMLButtonElement>;
}

const AutoSaver: FC<AutoSaverProps> = ({
  control,
  submitButtonRef,
  dirtyFields,
  disableAutosave,
}) => {
  const watchedValues = useWatch({ control });

  const runSubmit = useRef(
    debounce(async () => {
      submitButtonRef?.current?.click();
    }, DebounceTimes.Autosave),
  ).current;

  useDeepCompareEffect(() => {
    const keys = Object.keys(dirtyFields);

    if (!isEmpty(watchedValues) && !isEmpty(keys) && !disableAutosave) {
      runSubmit();
    }
  }, [watchedValues, dirtyFields, runSubmit]);

  return null;
};

export const FormAutoSaveBase: FC<FormAutoSaveBaseProps> = ({
  ignoreResourceIdAlways,
  inputSchema,
  onSubmit,
  resourceId,
  resourceIds,
  values,
  children,
  defaultValues,
  disableAutosave = false,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const {
    reset,
    trigger,
    control,
    setValue,
    getValues,
    formState: { dirtyFields, isSubmitted, errors },
    handleSubmit: handleSubmitCallback,
  } = useForm<any>({
    values,
    defaultValues,
    resolver: yupResolver(inputSchema),
    resetOptions: {
      keepValues: true,
      keepIsSubmitted: true,
      keepDirtyValues: true, // if true user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const hasResourceIds = () => {
    const labels = resourceIds && Object.keys(resourceIds);
    const hasAllResourceIds =
      labels &&
      labels.length > 0 &&
      labels.every((label) => !isEmpty(resourceIds[label]));

    return !isEmpty(resourceId) || hasAllResourceIds;
  };

  const onSubmitCallback = async (data: any) => {
    const isInitialSubmit = !isEmpty(data) && !hasSubmitted;
    const isSubsequentSubmit =
      !isEmpty(data) && hasSubmitted && hasResourceIds();

    const dirtyData = getDirtyFieldsValue(dirtyFields, data);
    const sanitizedData = sanitizeNestedData(data);
    const sanitizedDirtyData = sanitizeData(dirtyData);

    if (ignoreResourceIdAlways || isInitialSubmit || isSubsequentSubmit) {
      setHasSubmitted(true);
      await onSubmit({
        resourceId,
        resourceIds,
        data,
        dirtyData,
        sanitizedData,
        sanitizedDirtyData,
      });
      reset({}, { keepValues: true, keepIsSubmitted: true });
    }
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:form autosave", errors);
  };

  const handleSubmitManually = async () => {
    const isValid = await trigger();
    if (isValid) {
      await handleSubmitCallback(onSubmitCallback, onError)();
    }
    return isValid;
  };

  useEffect(() => {
    if (isSubmitted && isEmpty(errors)) {
      setHasSubmitted(true);
    }
  }, [isSubmitted, errors]);

  return (
    <Fragment>
      <DetectFormNavigation
        isFormDirty={!isSubmitted && !isEmpty(dirtyFields)}
      />
      <AutoSaver
        control={control}
        submitButtonRef={submitButtonRef}
        disableAutosave={disableAutosave}
        dirtyFields={dirtyFields}
      />
      {children({
        submitButtonRef,
        reset,
        setValue,
        getValues,
        control,
        resourceId,
        resourceIds,
        handleSubmitManually,
        handleFormSubmit: handleSubmitCallback(onSubmitCallback, onError),
      })}
    </Fragment>
  );
};

export const FormAutoSaveWrapperBase: FC<Props> = ({
  formId,
  children,
  className = "py-8",
  ...rest
}) => {
  return (
    <FormAutoSaveBase {...rest}>
      {({ handleFormSubmit, submitButtonRef, ...rest }) => {
        return (
          <form id={formId} className={className} onSubmit={handleFormSubmit}>
            {submitButtonRef && children(rest)}
            <button hidden type="submit" ref={submitButtonRef}>
              Submit
            </button>
          </form>
        );
      }}
    </FormAutoSaveBase>
  );
};
