import {
  AllowedCategories,
  AllowedContactType,
  AvailableNotificationTypes,
} from "@amenda-types";
import { FC, useState } from "react";
import {
  transformFormToUsers,
  transformUsersToForm,
} from "@amenda-components/Contacts/common";
import {
  useAppStore,
  useAttachmentStore,
  useGenerateAttachmentUrl,
  useSingleFileUpload,
  useUpdateUser,
  useUsersStore,
} from "@amenda-domains/mutations";

import { CurrentUserProfileForm } from "@amenda-components/Users";
import { SettingsProfileLayout } from "@amenda-components/Settings";

export const MyAccount: FC = () => {
  const { singleFileUpload } = useSingleFileUpload();
  const { updateUser } = useUpdateUser();
  const [loading, setLoading] = useState(false);
  const showNotification = useAppStore((state) => state.showNotification);
  const avatarImage = useAttachmentStore((state) => state.avatarFile);
  const currentUser = useUsersStore((state) => state.currentUser);

  const account = transformUsersToForm(currentUser);

  const handleUpdateUser = async ({ email, ...form }: any) => {
    const input = transformFormToUsers({
      form,
      id: currentUser?.id,
      type: AllowedContactType.office,
      uid: currentUser?.uid,
    });

    await updateUser({
      input,
    });
  };

  const { generateAttachmentUrl } = useGenerateAttachmentUrl();

  const uploadPhoto = async () => {
    if (avatarImage) {
      const data = await singleFileUpload({
        file: avatarImage,
        category: AllowedCategories.Users,
      });

      if (data.file) {
        const { filename, bucketname } = data.file;
        generateAttachmentUrl({
          input: {
            bucketname,
            filename,
            type: "image",
          },
          callback: (photoURL) =>
            handleUpdateUser({
              photoURL,
            }),
        });
      }
    }
  };

  const handleSubmit = async (form: any) => {
    setLoading(true);
    await handleUpdateUser(form);
    await uploadPhoto();
    await showNotification(
      AvailableNotificationTypes.Success,
      "Profile details updated!",
    );
    setLoading(false);
  };

  return (
    <SettingsProfileLayout>
      <CurrentUserProfileForm
        currentUserInfo={account}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </SettingsProfileLayout>
  );
};
