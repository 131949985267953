import { FC, useCallback, useRef } from "react";
import {
  generateSearchComponentTree,
  generateSearchValidation,
  getSimilaritySearchComponents,
  transformSimilaritySearchToFormValues,
} from "./common";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { SimilaritySearchForm } from "./SimilaritySearchForm";
import { transformSimilaritySearchData } from "./common";
import { useGetNeighborProjects } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  bottomSheetHeight: number;
}

export const SimilaritySearchFormWrapper: FC<Props> = ({
  bottomSheetHeight,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLButtonElement>(null);
  const permissionsByForm = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {}
  );
  const projectFormComponents = useProjectStore(
    (state) => state.projectFormComponents
  );
  const similaritySearchComponents = useProjectStore(
    (state) => state.similaritySearchComponents
  );
  const { loading, getNeighborProjects } = useGetNeighborProjects();
  const similaritySearchModalValues = useProjectStore(
    (state) => state.similaritySearchModalValues
  );
  const setSimilaritySearchModalValues = useProjectStore(
    (state) => state.setSimilaritySearchModalValues
  );

  const { components, parentComponent } = getSimilaritySearchComponents(
    permissionsByForm,
    projectFormComponents
  );
  const formComponentTree = generateSearchComponentTree(
    similaritySearchComponents,
    parentComponent
  );
  const inputSchema = generateSearchValidation(similaritySearchComponents);
  const componentIds = similaritySearchComponents.map((c) => c.id);

  const handleSubmit = useCallback(
    async (data: any) => {
      const formValues = transformSimilaritySearchData(data, componentIds);

      setSimilaritySearchModalValues(formValues);
      await getNeighborProjects({
        args: {
          formValues,
        },
        context: {
          requestPolicy: "network-only",
        },
      });
    },
    [componentIds, getNeighborProjects, setSimilaritySearchModalValues]
  );

  const onSuccess = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <div className="w-full h-full relative">
      <SimilaritySearchForm
        ref={ref}
        inputSchema={inputSchema}
        components={components}
        formComponentTree={formComponentTree}
        values={transformSimilaritySearchToFormValues(
          similaritySearchModalValues
        )}
        handleSubmit={handleSubmit}
      />
      {bottomSheetHeight > 220 && (
        <div className="w-full bg-white border-t absolute bottom-4 py-2 mb-7 flex justify-center lg:px-8 md:px-4 px-2">
          <div>
            <Button
              loading={loading}
              size="lg"
              variant="primary"
              className="px-10"
              onClick={onSuccess}
            >
              {t("Evaluate")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
