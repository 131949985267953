import { FC } from "react";
import { GripIcon } from "lucide-react";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import { SortItem } from "./DragAndDropWrapper";

export const HalfColumn: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
}) => {
  return (
    <SortItem
      className="col-span-6 md:col-span-3 group/half-col"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <IconButtonBase
          className="cursor-grab px-0.5 py-1 invisible group-hover/half-col:visible"
          variant="clean"
        >
          <GripIcon className="w-5 h-5 stroke-[2]" />
        </IconButtonBase>
      )}
      {children}
    </SortItem>
  );
};
