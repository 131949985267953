import * as Sentry from "@sentry/react";

import {
  ActionCodeURL,
  OAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  getIdToken,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  AuthFormType,
  AvailableNotificationTypes,
  SignInProps,
} from "@amenda-types";
import { createJSONStorage, persist } from "zustand/middleware";
import {
  getApiCustomToken,
  useGetEmailSignInLink,
} from "@amenda-domains/queries";
import { gql, useMutation } from "urql";
import { useCallback, useState } from "react";

import { DecodedToken } from "@amenda-utils";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { initializeApp } from "firebase/app";
import { useAppStore } from "./app";
import { useNavigate } from "react-router-dom";

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
});

type State = {
  isSignedIn: boolean;
  loggedInUser: Record<string, any>;
  formType: AuthFormType;
};

type Actions = {
  toggleAuthFormType: (formType: AuthFormType) => void;
  setAuth: (props: {
    isSignedIn: boolean;
    loggedInUser: Record<string, any>;
    formType: AuthFormType;
  }) => void;
};

const persistedStateVersion = 0.1;

export const useAuthStore = create(
  persist(
    immer<State & Actions>((set) => ({
      isSignedIn: false,
      loggedInUser: {},
      formType: AuthFormType.Login,
      toggleAuthFormType: (formType) =>
        set((state) => {
          state.formType = formType;
        }),
      setAuth: ({ isSignedIn, loggedInUser, formType }) =>
        set((state) => {
          state.isSignedIn = isSignedIn;
          state.loggedInUser = loggedInUser;
          state.formType = formType;
        }),
    })),
    {
      name: "auth-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        isSignedIn: state.isSignedIn,
        loggedInUser: state.loggedInUser,
      }),
    },
  ),
);

export const firebaseSignOut = async () => {
  const auth = getAuth(firebaseApp);
  await signOut(auth);
};

export const signIn = async ({ email, password, tenantId }: SignInProps) => {
  if (!tenantId) {
    throw new Error("User does not have a tenant or credentials are invalid");
  }
  try {
    const auth = getAuth(firebaseApp);
    auth.tenantId = tenantId;
    await setPersistence(auth, indexedDBLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }

  // Add a breadcrumb for user authentication
  Sentry.addBreadcrumb({
    category: "auth",
    message: "User authenticated " + email,
    level: "info",
  });
};

export const useSignInWithMicrosoft = () => {
  const navigate = useNavigate();
  const { getEmailSignInLink } = useGetEmailSignInLink();
  const [loader, setLoader] = useState<boolean>(false);
  const showNotification = useAppStore((state) => state.showNotification);

  const signInWithMicrosoft = useCallback(async () => {
    const auth = getAuth(firebaseApp);
    const provider = new OAuthProvider("microsoft.com");
    // Request user consent to access users for the tenant
    // force consent on login
    provider.setCustomParameters({ prompt: "consent" });

    try {
      setLoader(true);
      const credential = await signInWithPopup(auth, provider);
      const additionalUserInfo = getAdditionalUserInfo(credential);
      const email = additionalUserInfo?.profile?.userPrincipalName as string;
      if (!email) {
        throw new Error("No valid email found");
      }
      const data = await getEmailSignInLink({
        email,
      });
      if (!data?.signInLink) {
        throw new Error("User does not have a tenant or is not registered");
      }
      const actionCodeUrl = ActionCodeURL.parseLink(data?.signInLink);
      if (actionCodeUrl?.tenantId) {
        auth.tenantId = actionCodeUrl.tenantId;
      }
      await setPersistence(auth, indexedDBLocalPersistence);
      await signInWithEmailLink(auth, email, data?.signInLink);
      Sentry.setUser({ email });
      setLoader(false);
      navigate("/projekte");
    } catch (err) {
      if (err instanceof Error) {
        showNotification(AvailableNotificationTypes.Error, err.message);
      } else {
        showNotification(
          AvailableNotificationTypes.Error,
          "Something went wrong",
        );
      }
    }
  }, [showNotification, getEmailSignInLink, navigate]);

  return {
    loader,
    signInWithMicrosoft,
  };
};

const getFirebaseToken = async (tenantId?: string) => {
  const auth = getAuth(firebaseApp);
  if (tenantId) {
    auth.tenantId = tenantId;
  }
  if (!auth.currentUser) return;
  const token = await getIdToken(auth.currentUser);
  return token;
};

const processApiToken = async (jwtToken: DecodedToken) => {
  const { tenantId, uid } = jwtToken;
  const customToken = await getApiCustomToken(tenantId, uid);

  if (customToken) {
    const auth = getAuth(firebaseApp);
    auth.tenantId = tenantId;
    await signInWithCustomToken(auth, customToken);
    const token = await getFirebaseToken(tenantId);
    return token;
  }
};

const processDecodedToken = async (jwtToken: DecodedToken) => {
  try {
    const { customToken, tenantId } = jwtToken;
    const auth = getAuth(firebaseApp);
    auth.tenantId = tenantId;

    await signInWithCustomToken(auth, customToken);
    const token = await getFirebaseToken(tenantId);
    return token;
  } catch (err) {
    const token = await processApiToken(jwtToken);
    return token;
  }
};

export const processToken = async (jwtToken?: DecodedToken) => {
  let token;

  if (jwtToken) {
    token = await processDecodedToken(jwtToken);
  }
  if (token) return token;
  token = await getFirebaseToken();
  return token;
};

export const useVerifyResourceTenant = () => {
  const loggedInUser = useAuthStore((state) => state.loggedInUser);
  const isInTenant = (resource?: any) =>
    resource?.id && resource?.tenantId === loggedInUser?.tenantId;

  return { isInTenant };
};

const GENERATE_AND_EMAIL_PASSWORD_RESET_LINK = gql`
  mutation GenerateAndEmailPasswordResetLink($email: String!) {
    generateAndEmailPasswordResetLink(email: $email)
  }
`;

export const useGenerateAndEmailPasswordResetLink = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callGeneratePasswordResetLink] = useMutation(
    GENERATE_AND_EMAIL_PASSWORD_RESET_LINK,
  );

  const generateAndEmailPasswordResetLink = async (
    variables: Record<string, any>,
  ) => {
    return callGeneratePasswordResetLink(variables)
      .then(({ data }) => {
        if (data?.generateAndEmailPasswordResetLink) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Password reset link sent to email",
          );
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error?.message);
      });
  };

  return {
    generateAndEmailPasswordResetLink,
    loading: result.fetching,
  };
};

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $tenantId: String!
    $uid: String!
  ) {
    resetPassword(password: $password, tenantId: $tenantId, uid: $uid)
  }
`;

export const useResetPassword = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callResetPassword] = useMutation(RESET_PASSWORD);

  const resetPassword = async (variables: Record<string, any>) => {
    return callResetPassword(variables)
      .then(({ data }) => {
        if (data?.resetPassword) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Password reset successful",
          );
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error?.message);
      });
  };

  return {
    resetPassword,
    loading: result.fetching,
  };
};
