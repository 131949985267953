import { CheckCircle2, CheckIcon } from "lucide-react";
import { FC, MouseEvent, ReactNode } from "react";

import { CheckMarkRadio } from "./CheckMarkRadio";
import { HoverVariants } from "@amenda-types";
import { IconButton } from "./IconButton";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { PhotoIcon as PhotoOutlineIcon } from "@heroicons/react/24/outline";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { isCmndOrCtrlOrShiftPressed } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface HoverSelectorBaseProps {
  children: ReactNode;
  className?: string;
  isListCard?: boolean;
  handleSelect?: () => void;
  handleClick?: () => void;
  handleShiftSelect?: () => void;
}
interface Props extends HoverSelectorBaseProps {
  id?: string;
  isSelected?: boolean;
  canSelect?: boolean;
  hoverMessage?: string;
  hoverIcon?: any;
  variant?: HoverVariants;
  hideSelector?: boolean;
}

interface HoverListSelectorProps extends HoverSelectorBaseProps {
  isSelected?: boolean;
}

export const HoverSelectorBase: FC<HoverSelectorBaseProps> = ({
  className,
  children,
  handleSelect,
  handleClick,
  handleShiftSelect,
}) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    const isCommandPressed = e.metaKey || e.ctrlKey;
    const isShiftPressed = e.shiftKey;

    if (isCommandPressed) {
      handleSelect?.();
    } else if (isShiftPressed) {
      handleShiftSelect?.();
    } else {
      handleClick?.();
    }
  };

  return (
    <div
      className={clsx("relative group select-none w-full", className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const HoverListSelector: FC<HoverListSelectorProps> = ({
  children,
  isSelected = false,
  handleSelect,
  ...rest
}) => {
  const pressedKey = useAppStore((state) => state.pressedKey);

  return (
    <HoverSelectorBase handleSelect={handleSelect} {...rest}>
      {children}
      <div className="h-full absolute top-0 right-0 mr-2 transition flex items-center">
        {isCmndOrCtrlOrShiftPressed(pressedKey) && !isSelected && (
          <div
            className={`text-gray-900 p-1 rounded-full ${
              isSelected
                ? "bg-[#2286E0]"
                : "hover:bg-[#2286E0] hover:text-white"
            }`}
          >
            <CheckIcon className="h-4 w-4" />
          </div>
        )}
        <CheckMarkRadio
          className={clsx({
            block: isSelected,
            "hidden group-hover:flex":
              !isSelected && !isCmndOrCtrlOrShiftPressed(pressedKey),
            "hidden group-hover:hidden":
              !isSelected && isCmndOrCtrlOrShiftPressed(pressedKey),
          })}
          isChecked={isSelected}
          onChange={handleSelect}
        />
      </div>
    </HoverSelectorBase>
  );
};

export const HoverSelector: FC<Props> = ({
  canSelect,
  children,
  hoverMessage,
  hoverIcon,
  id = "",
  isSelected = false,
  hideSelector = false,
  variant = HoverVariants.Default,
  handleSelect,
  ...rest
}) => {
  const { t } = useTranslation();
  const pressedKey = useAppStore((state) => state.pressedKey);

  return (
    <HoverSelectorBase handleSelect={handleSelect} {...rest}>
      {!hideSelector && (
        <div
          className={clsx("absolute m-1 z-20", {
            "top-1 left-1": [
              HoverVariants.Checkbox,
              HoverVariants.RadioButton,
            ].includes(variant),
            "top-0 right-0": ![
              HoverVariants.Checkbox,
              HoverVariants.RadioButton,
            ].includes(variant),
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {[HoverVariants.Default, HoverVariants.NoBorder].includes(variant) ? (
            <>
              {canSelect && (
                <div className="flex flex-row items-center">
                  <span
                    className={clsx("text-xs font-apercu pr-2", {
                      "hidden group-hover:block": !isSelected,
                    })}
                  >
                    {t(isSelected ? "Cover Image" : "Set as Cover Image")}
                  </span>
                  {isSelected ? (
                    <PhotoIcon className="h-5 w-5 text-yellow-500" />
                  ) : (
                    <PhotoOutlineIcon className="h-5 w-5 text-gray-800 hidden group-hover:block" />
                  )}
                </div>
              )}
            </>
          ) : variant === HoverVariants.Checkbox ? (
            !isCmndOrCtrlOrShiftPressed(pressedKey) && (
              <SingleCheckbox
                id={id}
                checked={isSelected}
                className={clsx("h-5 w-5", {
                  "md:block group-hover:block": isSelected,
                  "md:hidden group-hover:block": !isSelected,
                })}
                onChange={handleSelect}
              />
            )
          ) : (
            <SingleRadioButton
              id={id}
              checked={isSelected}
              className={clsx({
                "md:hidden group-hover:block":
                  !isSelected && !isCmndOrCtrlOrShiftPressed(pressedKey),
                "md:hidden group-hover:hidden":
                  !isSelected && isCmndOrCtrlOrShiftPressed(pressedKey),
              })}
              onChange={handleSelect}
            />
          )}
        </div>
      )}
      {hoverMessage && hoverIcon && (
        <div className="absolute flex justify-center items-center w-full h-full">
          <h4 className="lg:text-md text-sm font-bold text-gray-900">
            <div className="flex">
              <IconButton
                Icon={hoverIcon}
                label={t(hoverMessage)}
                iconSize={1}
              />
            </div>
          </h4>
        </div>
      )}
      {children}
      {isCmndOrCtrlOrShiftPressed(pressedKey) && canSelect && (
        <div className="w-full h-full absolute inset-0 flex items-center justify-center">
          <div
            className={`text-white rounded-full ${
              isSelected ? "bg-[#2286E0]" : "hover:bg-[#2286E0]"
            }`}
          >
            {isSelected ? (
              <CheckIcon className="h-12 w-12 lg:h-24 lg:w-24 stroke-[3]" />
            ) : (
              <CheckCircle2 className="h-12 w-12 lg:h-24 lg:w-24 stroke-[2]" />
            )}
          </div>
        </div>
      )}
    </HoverSelectorBase>
  );
};
