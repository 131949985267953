import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  BoltIcon,
  GripVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "lucide-react";
import {
  ComponentTreeRenderer,
  ComponentsTreeView,
  ComponentsTreeViewRowProps,
} from "@amenda-components/PageBuilder";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  isDragValid,
} from "./FormBuilderDndComponents";
import { HelperMessage, IconButtonBase } from "@amenda-components/App";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  useAppStore,
  useDeleteFormComponents,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { DeleteModal } from "@amenda-components/Shared";
import { FormBuilderActionBar } from "./FormBuilderActionBar";
import { FormBuilderComponentModal } from "./FormBuilderComponentModal";
import { FormBuilderCostGroupModal } from "./FormBuilderCostGroupModal";
import { FormBuilderEditFormModal } from "./FormBuilderEditFormModal";
import { availableLayouts } from "@amenda-types";
import clsx from "clsx";
import { getComponentIdsToRemove } from "./common";
import { groupComponentsByParent } from "@amenda-utils";
import { isEmpty } from "lodash";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

const CostGroupRowBody: FC<ComponentsTreeViewRowProps> = ({
  component,
  globalData,
}) => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const handleOpen = () => {
    updateFormBuilderState("openCostGroupComponentModal", true);
    updateFormBuilderState("selectedCostGroupComponent", {
      config: component,
      global: globalData,
    });
  };

  const handleOpenDeleteModal = () => {
    updateFormBuilderState("deleteCostGroupComponent", true);
    updateFormBuilderState("selectedCostGroupComponent", {
      config: component,
      global: globalData,
    });
  };

  return (
    <div className="w-full flex justify-end items-center space-x-2 py-1">
      <IconButtonBase
        variant="clean"
        className="p-1 !bg-inherit"
        onClick={handleOpen}
      >
        <PencilIcon className="w-5 h-5" />
      </IconButtonBase>
      <IconButtonBase
        variant="clean"
        className="p-1 !bg-['none']"
        onClick={handleOpenDeleteModal}
      >
        <TrashIcon className="w-5 h-5" />
      </IconButtonBase>
    </div>
  );
};

export const FormBuilderComponentEditor: FC = () => {
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const { updateForm, loading } = useUpdateForm();

  const { deleteFormComponents, loading: isDeletingComponents } =
    useDeleteFormComponents();

  const originalForms = useProjectStore((state) => state.originalForms);
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const {
    selectedForm,
    deleteForm,
    deleteComponent,
    deleteCostGroupComponent,
    selectedFormComponent,
    selectedCostGroupComponent,
  } = formBuilderState ?? {};

  const handleDeleteForm = async () => {
    const foundForm =
      selectedForm && originalForms.find((form) => form.id === selectedForm.id);
    if (foundForm?.id) {
      await updateForm({
        isDeleted: true,
        id: foundForm.id,
        components: foundForm.components,
      });
    }
    updateFormBuilderState("selectedFormComponent", undefined);
    updateFormBuilderState("selectedForm", undefined);
    updateFormBuilderState("deleteForm", false);
  };

  const handleDeleteComponent = async () => {
    const componentId = selectedFormComponent?.config?.id;

    await deleteFormComponents({
      formId: selectedForm?.id,
      componentIds: [componentId],
    });
    updateFormBuilderState("selectedFormComponent", undefined);
    updateFormBuilderState("deleteComponent", false);
  };

  const handleDeleteCostGroupComponent = async () => {
    const componentIds = getComponentIdsToRemove(
      selectedCostGroupComponent?.config,
    );

    await deleteFormComponents({
      componentIds,
      formId: selectedForm?.id,
    });
    updateFormBuilderState("selectedCostGroupComponent", undefined);
    updateFormBuilderState("deleteCostGroupComponent", false);
  };

  const handleUpdatedComponents = (
    updatedComponentIds: string[],
    parentId?: string,
  ) => {
    const components = selectedForm?.components ?? [];
    const updatedComponents: any[] = [];
    const componentsById: Record<string, any> = {};
    const componentsByIndex: Record<string, number> = {};
    const originalComponents =
      (selectedForm &&
        originalForms.find((form) => form.id === selectedForm.id)
          ?.components) ??
      [];
    const originalComponentsById: Record<string, any> = {};

    originalComponents.forEach((c) => {
      originalComponentsById[c.componentId] = c;
    });

    components.forEach((c, i) => {
      componentsById[c.id] = c;
      componentsByIndex[c.id] = i;
    });

    updatedComponentIds.forEach((id, i) => {
      if (
        componentsById[id].order !== i ||
        componentsById[id].parentId !== parentId
      ) {
        updatedComponents.push({
          ...(originalComponentsById[id] ?? {}),
          order: i,
          parentId: parentId ?? componentsById[id].parentId,
        });
      }
    });

    return updatedComponents;
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over.data.current?.component;

    if (!activeComponent || !overComponent) return;

    updateFormBuilderState("isSavingComponentSorting", true);
    if (activeComponent.parentId === overComponent.parentId) {
      const componentIds: any[] = active.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const oldIndex = componentIds.indexOf(active.id);
      const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);
      const updatedComponents = handleUpdatedComponents(updatedComponentIds);

      await updateForm({
        id: selectedForm?.id,
        components: updatedComponents,
      });
    } else {
      const componentIds: any[] = over.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const allComponentIds = [...componentIds, active.id];
      const updatedComponentIds = arrayMove(
        allComponentIds,
        allComponentIds.length - 1,
        newIndex,
      );
      const updatedComponents = handleUpdatedComponents(
        updatedComponentIds,
        over.data.current?.component?.parentId,
      );

      await updateForm({
        id: selectedForm?.id,
        components: updatedComponents,
      });
    }
    updateFormBuilderState("isSavingComponentSorting", false);
    updateFormBuilderState("selectedCostGroupComponent", undefined);
  };

  if (!selectedForm) {
    return (
      <HelperMessage
        iconClassName="stroke-[2] h-10 w-10 text-gray-800"
        message="Select a form"
        helpText="Select a form from the sidebar to edit"
        Icon={BoltIcon}
      />
    );
  }

  const formComponentTree = groupComponentsByParent(selectedForm?.components);
  const activeComponent = selectedForm?.components?.find(
    (c) => c.id === activeId,
  );

  return (
    <div className="relative w-full h-full overflow-y-auto overscroll-contain lg:px-4 xl:px-8">
      <FormBuilderComponentModal />
      <FormBuilderCostGroupModal />
      <FormBuilderEditFormModal />
      <DeleteModal
        title="Are you sure you want to delete this field?"
        description="Note that this action is irreversible."
        loading={isDeletingComponents}
        openModal={Boolean(deleteComponent)}
        handleDelete={handleDeleteComponent}
        handleCloseModal={() =>
          updateFormBuilderState("deleteComponent", false)
        }
      />
      <DeleteModal
        title="Are you sure you want to delete this field?"
        description={
          isEmpty(selectedCostGroupComponent?.config?.components)
            ? "Note that this action is irreversible."
            : "Deleting this field will also delete all nested fields."
        }
        loading={isDeletingComponents}
        openModal={Boolean(deleteCostGroupComponent)}
        handleDelete={handleDeleteCostGroupComponent}
        handleCloseModal={() =>
          updateFormBuilderState("deleteCostGroupComponent", false)
        }
      />
      <DeleteModal
        title="Are you sure you want to delete this form?"
        description="Note that this action is irreversible."
        loading={loading}
        openModal={Boolean(deleteForm)}
        handleDelete={handleDeleteForm}
        handleCloseModal={() => updateFormBuilderState("deleteForm", false)}
      />
      <FormBuilderActionBar />
      {selectedForm?.category === AvailableForms.CostGroupsCost ? (
        <ComponentsTreeView
          readOnly={true}
          columnClassName="pt-1"
          components={formComponentTree.components as any[]}
          columns={[
            {
              id: "values",
              label: "",
            },
          ]}
          className="max-h-[80vh] mt-2"
          rowClassName="group/treeRow hover:bg-gray-100 border-b border-transparent hover:border-gray-200 mb-2"
          searchPlaceholder="Kostengruppe filtern..."
          RowBody={CostGroupRowBody}
          globalData={{
            data: {},
          }}
        />
      ) : (
        <DndContext
          sensors={sensors}
          modifiers={[restrictToFirstScrollableAncestor]}
          collisionDetection={closestCenter}
          onDragStart={(event) => {
            const { active } = event;

            setActiveId(String(active.id));
          }}
          onDragEnd={(event) => {
            const { active, over } = event;
            setActiveId(null);
            if (over && isDragValid(active, over)) {
              handleDragEnd(active, over);
            }
          }}
        >
          <ComponentTreeRenderer
            config={formComponentTree}
            readOnly={true}
            isFormBuilder={true}
            globalProps={{
              data: {},
            }}
          />
          <DragOverlay>
            {activeId && activeComponent && (
              <FormBuilderDragOverlayItem
                className={clsx(
                  "shadow-lg w-full px-1 py-2 cursor-pointer text-sm rounded-sm outline-none bg-gray-800 text-white",
                  {
                    "h-56":
                      activeComponent.layout ===
                      availableLayouts.twoColumnsFullWidth,
                  },
                )}
              >
                <div className="w-full flex items-center">
                  <div>
                    <IconButtonBase className="cursor-grab px-0.5 py-1">
                      <GripVerticalIcon className="w-5 h-5 stroke-[2]" />
                    </IconButtonBase>
                  </div>
                  <span className="ml-2 truncate">
                    {activeComponent?.properties?.label ?? activeComponent?.id}
                  </span>
                </div>
              </FormBuilderDragOverlayItem>
            )}
          </DragOverlay>
        </DndContext>
      )}
    </div>
  );
};
