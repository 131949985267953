import {
  Badges,
  DatePicker,
  DateQuarterPicker,
  Select,
  TextField,
} from "@amenda-components/FormComponents";
import { Button, ButtonWithDropdown, Modal } from "@amenda-components/App";
import { Controller, useForm } from "react-hook-form";
import { FC, useEffect } from "react";
import {
  FullColumn,
  GridWrapper,
  HalfColumn,
} from "@amenda-components/PageLayouts";
import {
  ServicePhaseOptions,
  SourcesOptions,
  getSelectCostGroupOptions,
  getSubmitBtnOptions,
} from "./common";
import {
  useCreateCostGroup,
  useDeleteCostGroups,
  useProjectStore,
  useUpdateCostGroup,
} from "@amenda-domains/mutations";

import { BKIModalButton } from "@amenda-components/Shared";
import { costGroupSchema } from "@amenda-constants";
import { isEmpty } from "lodash";
import { useGetLatestBKIDate } from "@amenda-domains/queries";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

const formId = "costGroupUpsertOrDeleteForm";

export const CostGroupModal: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const selectedCostGroupId = useProjectStore(
    (state) => state.selectedCostGroupId,
  );
  const projectCostGroupForm = useProjectStore(
    (state) => state.projectCostGroupForm,
  );
  const { control, reset, handleSubmit } = useForm<any>({
    values: projectCostGroupForm ? projectCostGroupForm : {},
    resolver: yupResolver(costGroupSchema),
  });
  const { createCostGroup, loading: isSubmitting } = useCreateCostGroup();
  const { updateCostGroup, loading: isUpdatting } = useUpdateCostGroup();
  const { getLatestBKIDate, loading } = useGetLatestBKIDate();
  const latestBKIDate = useProjectStore((state) => state.latestBKIDate);
  const projectCostGroupsByType = useProjectStore(
    (state) => state.projectCostGroupsByType,
  );
  const openProjectCostGroupModal = useProjectStore(
    (state) => state.openProjectCostGroupModal,
  );
  const setOpenProjectCostGroupModal = useProjectStore(
    (state) => state.setOpenProjectCostGroupModal,
  );
  const { deleteCostGroups, loading: isDeleting } = useDeleteCostGroups();

  const options = getSelectCostGroupOptions(projectCostGroupsByType);
  const submitOptions = getSubmitBtnOptions(
    formId,
    isEmpty(selectedCostGroupId),
  );

  const handleClose = () => {
    setOpenProjectCostGroupModal(false);
  };

  const handleDelete = async (value: string) => {
    if (value === "delete") {
      await deleteCostGroups({ ids: [selectedCostGroupId] });
      handleClose();
    }
  };

  const onSubmit = async ({
    bkiDate,
    type,
    versionDate,
    source,
    servicePhase,
    note,
    generalContractorFactor,
  }: any) => {
    const input: Record<string, any> = {
      type,
      bkiDate,
      versionDate,
      source,
      servicePhase,
      generalContractorFactor,
      note,
      resourceId: projectId || selectedProject.id,
    };

    if (selectedCostGroupId) {
      input._id = selectedCostGroupId;

      await updateCostGroup({
        input,
        options: {
          partial: false,
        },
      });
    } else {
      await createCostGroup({ input });
    }
    reset();
    handleClose();
  };

  useEffect(() => {
    getLatestBKIDate();
  }, [getLatestBKIDate]);

  return (
    <Modal
      title="Cost Entry"
      size="md"
      successLabel="Submit"
      className="lg:w-6/12"
      message="Enter parameters for the cost entry"
      loading={isSubmitting || isDeleting || isUpdatting}
      isOpen={openProjectCostGroupModal}
      onClose={handleClose}
      footerChildren={({ loading, onClose }) => {
        return (
          <>
            <div className="shrink">
              <Button type="button" onClick={onClose}>
                {t("Cancel")}
              </Button>
            </div>
            <ButtonWithDropdown
              options={submitOptions}
              loading={loading}
              onClick={handleDelete}
            />
          </>
        );
      }}
    >
      <form id={formId} className="px-2" onSubmit={handleSubmit(onSubmit)}>
        <GridWrapper>
          <HalfColumn>
            <Controller
              name="type"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    id="type"
                    label="Type"
                    selectedOption={value}
                    options={options}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <HalfColumn>
            <Controller
              name="note"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="note"
                    label="Beschreibung"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <HalfColumn>
            <Controller
              name="bkiDate"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <DateQuarterPicker
                    id="bkiDate"
                    label="Stand der Kostenermittlung"
                    value={value}
                    disabled={loading}
                    isClearable={true}
                    error={error?.message}
                    optionalLabel={<BKIModalButton />}
                    maxDate={latestBKIDate.date}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <HalfColumn>
            <Controller
              control={control}
              name="versionDate"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <DatePicker
                    id="versionDate"
                    label="Created at"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <HalfColumn>
            <Controller
              name="generalContractorFactor"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="generalContractorFactor"
                    label="GU-Aufschlag"
                    value={value}
                    type="number"
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <HalfColumn>
            <Controller
              name="source"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    id="source"
                    label="Quelle"
                    selectedOption={value}
                    options={SourcesOptions}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </HalfColumn>
          <FullColumn>
            <Controller
              name="servicePhase"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Badges
                    label="Leistungsphasen"
                    selectedOptions={value || []}
                    options={ServicePhaseOptions}
                    error={error?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </FullColumn>
        </GridWrapper>
      </form>
    </Modal>
  );
};
