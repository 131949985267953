import {
  CloudArrowDownIcon,
  Cog6ToothIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";
import {
  ColumnDef,
  Row,
  SortingState,
  Table,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ConfigureColumnsModal,
  DownloadColumnsModal,
  TableCellWrapper,
  TableGrouping,
  TablePinColumn,
  TableRowSelector,
  TableSortIndicators,
} from "./ReactTableComponents";
import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  HelperMessage,
  IconButton,
  Spinner,
  Tooltip,
} from "@amenda-components/App";
import {
  SpecialColumns,
  getCellStickyPosition,
  getDefaultPinnedColumns,
  getHeaderStickyPosition,
  getTableSize,
  isSelectColumn,
} from "./reactTableHelpers";

import { ExpandableSearch } from "./ExpandableSearch";
import { Pagination } from "@amenda-types";
import { Table2Icon } from "lucide-react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";
import { useVerticalScrollEvent } from "@amenda-utils";
import { useVirtualizer } from "@tanstack/react-virtual";

interface Props {
  label?: string;
  tableId: string;
  data: any[];
  columns: ColumnDef<any>[];
  isLoading?: boolean;
  pagination?: Pagination;
  containerClass?: string;
  showSelectorColumn?: boolean;
  showGroupingColumn?: boolean;
  canDownload?: boolean;
  isConfigurable?: boolean;
  isFullWidth?: boolean;
  maxEstimatedRowHeight?: number;
  TableRowActions?: FC<{
    row: Row<any>;
  }>;
  defaultRowSelection?: Record<number, boolean>;
  tableConfigChildren?: ReactNode;
  defaultPinnedColumns?: { left?: string[]; right?: string[] };
  groupingColumnChildren?: (row: any) => ReactNode;
  fetchNextPage?: () => Promise<void>;
  handleFilter?: () => void;
  handleSearch?: (searchText: string) => Promise<void>;
  onRowClick?: (row: Row<any>) => (e: any) => void;
  onRowSelectionChange?: (rows: Row<any>) => (e: any) => void;
  onAllRowsSelectionChange?: (rows: Table<any>) => (e: any) => void;
}

const getAvailableColumns = ({
  columns,
  showGroupingColumn,
  showSelectorColumn,
  TableRowActions,
  onRowSelectionChange,
  onAllRowsSelectionChange,
  groupingColumnChildren,
}: Pick<
  Props,
  | "showGroupingColumn"
  | "columns"
  | "showSelectorColumn"
  | "TableRowActions"
  | "onAllRowsSelectionChange"
  | "onRowSelectionChange"
  | "groupingColumnChildren"
>) => {
  let availableColumns = columns;
  if (showGroupingColumn) {
    availableColumns = [
      {
        id: SpecialColumns.GROUPING,
        header: () => <span />,
        cell: ({ row }) => (
          <TableCellWrapper>
            <TableGrouping row={row}>
              {groupingColumnChildren?.(row)}
            </TableGrouping>
          </TableCellWrapper>
        ),
      },
      ...availableColumns,
    ];
  }
  if (showSelectorColumn) {
    availableColumns = [
      {
        size: 50,
        id: SpecialColumns.SELECT,
        cell: ({ row }) => (
          <TableCellWrapper>
            <TableRowSelector
              row={row}
              onRowSelectionChange={onRowSelectionChange}
            />
          </TableCellWrapper>
        ),
        header: ({ table }) => (
          <TableRowSelector
            table={table}
            onAllRowsSelectionChange={onAllRowsSelectionChange}
          />
        ),
      },
      ...availableColumns,
    ];
  }
  if (TableRowActions) {
    availableColumns = [
      ...availableColumns,
      {
        minSize: 25,
        id: SpecialColumns.ACTIONS,
        header: () => <span />,
        cell: ({ row }) => (
          <TableCellWrapper>
            <TableRowActions row={row.original} />
          </TableCellWrapper>
        ),
      },
    ];
  }
  return availableColumns;
};

export const ReactTable: FC<Props> = memo(
  ({
    data,
    label,
    tableId,
    columns,
    isLoading,
    TableRowActions,
    isConfigurable,
    canDownload,
    defaultPinnedColumns,
    tableConfigChildren,
    pagination,
    isFullWidth = false,
    defaultRowSelection = {},
    showGroupingColumn = false,
    showSelectorColumn = false,
    maxEstimatedRowHeight = 34,
    containerClass = "max-h-96 w-fit",
    handleFilter,
    handleSearch,
    fetchNextPage,
    onRowClick,
    groupingColumnChildren,
    onRowSelectionChange,
    onAllRowsSelectionChange,
  }) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [rowSelection, setRowSelection] = useState(defaultRowSelection);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [isMounted, setIsMounted] = useState(false);
    const sortingState = useAppStore(
      (state) => state.tableColumnSorting[tableId],
    );
    const setSortingState = useAppStore((state) => state.setTableColumnSorting);
    const columnVisibilityState = useAppStore(
      (state) => state.tableColumnVisibility[tableId],
    );
    const setColumnVisibilityState = useAppStore(
      (state) => state.setTableColumnVisibility,
    );
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const pinnedColumns = useMemo(
      () =>
        getDefaultPinnedColumns({
          showGroupingColumn,
          defaultPinnedColumns,
          showSelectorColumn,
          showUserActionColumn: Boolean(TableRowActions),
        }),
      [
        showSelectorColumn,
        showGroupingColumn,
        defaultPinnedColumns,
        TableRowActions,
      ],
    );
    const [columnPinning, setColumnPinning] = useState<any>(pinnedColumns);
    const [expanded, setExpanded] = useState({});
    const memoizedColumns = useMemo(
      () =>
        getAvailableColumns({
          columns,
          showGroupingColumn,
          showSelectorColumn,
          groupingColumnChildren,
          TableRowActions,
          onRowSelectionChange,
          onAllRowsSelectionChange,
        }),
      [
        columns,
        showGroupingColumn,
        showSelectorColumn,
        groupingColumnChildren,
        TableRowActions,
        onRowSelectionChange,
        onAllRowsSelectionChange,
      ],
    );
    const memotizedData = useMemo(() => data, [data]);
    const table = useReactTable({
      data: memotizedData,
      columns: memoizedColumns,
      state: {
        expanded,
        sorting,
        rowSelection,
        columnPinning,
        columnVisibility,
      },
      onExpandedChange: setExpanded,
      onRowSelectionChange: setRowSelection,
      onColumnPinningChange: setColumnPinning,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getSubRows: (row) => row.subRows,
      onSortingChange: (updater) => {
        setSorting((prev) => {
          const value = updater instanceof Function ? updater(prev) : updater;
          setSortingState(tableId, value);
          return value;
        });
      },
      onColumnVisibilityChange: (updater) => {
        setColumnVisibility((prev) => {
          const value = updater instanceof Function ? updater(prev) : updater;
          setColumnVisibilityState(tableId, value);
          return value;
        });
      },
    });

    const { rows } = table.getRowModel();
    const currentTotalItems = rows.length;

    const virtualizer = useVirtualizer({
      overscan: 5,
      count: currentTotalItems,
      //measure dynamic row height, except in firefox because it measures table border height incorrectly
      measureElement:
        typeof window !== "undefined" &&
        navigator.userAgent.indexOf("Firefox") === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
      estimateSize: () => maxEstimatedRowHeight,
      getScrollElement: () => containerRef.current,
    });

    const virtualRows = virtualizer.getVirtualItems();
    const totalSize = virtualizer.getTotalSize();

    const handleCloseConfig = () => setOpenConfigModal(false);
    const handleOpenConfig = () => setOpenConfigModal(true);
    const handleCloseDownload = () => setOpenDownloadModal(false);
    const handleOpenDownload = () => setOpenDownloadModal(true);
    const handleVerticalScroll = useVerticalScrollEvent();

    const onSearch = (searchTerm: string) => {
      if (handleSearch) {
        setSearchTerm(searchTerm);
        handleSearch(searchTerm);
      }
    };

    const grandTotal = pagination?.docsCount ?? 0;

    const handleFetchMore = useCallback(
      async ({
        scrollHeight,
        scrollTop,
        clientHeight,
      }: HTMLElement & EventTarget) => {
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          fetchNextPage &&
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isLoading &&
          currentTotalItems < grandTotal
        ) {
          await fetchNextPage();
        }
      },
      [isLoading, currentTotalItems, grandTotal, fetchNextPage],
    );

    useEffect(() => {
      if (!isMounted) {
        setIsMounted(true);
        setSorting(sortingState ?? []);
        setColumnVisibility(columnVisibilityState ?? {});
      }
    }, [isMounted, sortingState, columnVisibilityState]);

    useEffect(() => {
      if (!isEmpty(defaultRowSelection)) {
        setRowSelection(defaultRowSelection);
      }
    }, [defaultRowSelection, setRowSelection]);

    return (
      <div className="relative w-full">
        <ConfigureColumnsModal
          isOpen={openConfigModal}
          columns={table.getAllLeafColumns()}
          onClose={handleCloseConfig}
        />
        <DownloadColumnsModal
          data={data}
          isOpen={openDownloadModal}
          columns={table.getAllLeafColumns()}
          onClose={handleCloseDownload}
        />
        <div className="flex justify-between pt-2 pb-2">
          <div className="flex items-center space-x-2">
            {label && (
              <span className="px-2 text-xl font-sans font-gray-700 font-bold">
                {t(label)}
              </span>
            )}
            {!!handleSearch && (
              <ExpandableSearch
                searchTerm={searchTerm}
                handleSearch={onSearch}
              />
            )}
          </div>
          <div className="flex space-x-2">
            {handleFilter && (
              <Tooltip id="filterIcon" message="Filter">
                <IconButton
                  label="Filter"
                  Icon={FunnelIcon}
                  iconSize={1.3}
                  onClick={handleFilter}
                />
              </Tooltip>
            )}
            {canDownload && (
              <Tooltip
                id="downloadIcon"
                message="Table download as csv or excel"
              >
                <IconButton
                  label="Table download as csv or excel"
                  Icon={CloudArrowDownIcon}
                  iconSize={1.3}
                  onClick={handleOpenDownload}
                />
              </Tooltip>
            )}
            {isConfigurable && (
              <Tooltip id="settingsIcon" message="Configure table columns">
                <IconButton
                  label="Configure table columns"
                  Icon={Cog6ToothIcon}
                  iconSize={1.3}
                  onClick={handleOpenConfig}
                />
              </Tooltip>
            )}
            {tableConfigChildren}
          </div>
        </div>
        {isLoading && (
          <div className="absolute flex justify-center z-50 items-center w-full min-h-80 h-full transition bg-gray-300/40">
            <Spinner variant="secondary" spinnerSize="md" />
          </div>
        )}
        <div
          ref={containerRef}
          className={clsx("overflow-auto overscroll-contain", containerClass)}
          onScroll={handleVerticalScroll(handleFetchMore)}
        >
          <table
            className="grid border-separate border-spacing-0"
            style={{
              ...getTableSize(table, isFullWidth),
            }}
          >
            <thead className="grid sticky top-0 z-20 bg-white">
              {table.getHeaderGroups().map(({ id: rowId, headers }) => (
                <tr key={rowId} className="flex w-full">
                  {headers.map((header, i) => {
                    return (
                      <th
                        className={clsx(
                          "amenda-table-heading border-b-2 border-gray-400 sticky top-0 bg-white group/th overflow-hidden truncate",
                          {
                            "z-30": header.column.getIsPinned(),
                            "z-20": !header.column.getIsPinned(),
                          },
                        )}
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: "100%",
                          minWidth: header.getSize(),
                          ...getHeaderStickyPosition(headers, i),
                        }}
                      >
                        {!Boolean(header.isPlaceholder) && (
                          <div className="flex items-center font-apercu font-medium text-gray-600 w-full relative">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            <TableSortIndicators column={header.column} />
                            <TablePinColumn
                              column={header.column}
                              hidePin={isSelectColumn(header.id)}
                            />
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody
              className="bg-white grid relative"
              style={{
                height: `${totalSize}px`,
              }}
            >
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<any>;
                const cells = row.getVisibleCells();

                return (
                  <tr
                    key={row.id}
                    className="pl-2 pt-2 pb-2 group/tr flex absolute w-full"
                    style={{
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    {cells.map((cell, i) => {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            width: "100%",
                            minWidth: cell.column.getSize(),
                            ...getCellStickyPosition(cells, i),
                          }}
                          onClick={
                            ![
                              SpecialColumns.SELECT,
                              SpecialColumns.ACTIONS,
                              SpecialColumns.GROUPING,
                            ].includes(cell.column.id as SpecialColumns) &&
                            onRowClick
                              ? onRowClick(cell.row.original)
                              : undefined
                          }
                          className={clsx(
                            "flex font-apercu whitespace-nowrap py-2.5 pl-4 pr-3 text-sm bg-white group-hover/tr:bg-gray-100 overflow-hidden truncate",
                            {
                              "z-20 sticky": cell.column.getIsPinned(),
                              "cursor-pointer":
                                Boolean(onRowClick) &&
                                ![
                                  SpecialColumns.SELECT,
                                  SpecialColumns.ACTIONS,
                                  SpecialColumns.GROUPING,
                                ].includes(cell.column.id as SpecialColumns),
                            },
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <HelperMessage
              className={clsx("h-96", {
                hidden: !isEmpty(data),
              })}
              Icon={Table2Icon}
              message={searchTerm ? "No results found" : "No data found"}
              helpText={
                searchTerm
                  ? "There are no results for this term"
                  : "There is no data available for this table"
              }
            />
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(nextProps.data)) {
      return false;
    } else if (
      JSON.stringify(prevProps.columns) !== JSON.stringify(nextProps.columns)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.defaultRowSelection) !==
      JSON.stringify(nextProps.defaultRowSelection)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.defaultPinnedColumns) !==
      JSON.stringify(nextProps.defaultPinnedColumns)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.pagination) !==
      JSON.stringify(nextProps.pagination)
    ) {
      return false;
    } else if (prevProps.isLoading !== nextProps.isLoading) {
      return false;
    }
    return true;
  },
);
