import { FC, useEffect, useState } from "react";
import { useAttachmentStore, useProjectStore } from "@amenda-domains/mutations";

import { Image } from "@amenda-components/App";
import { SliderView } from "@amenda-components/FileUploads";
import { getSliderAttachments } from "@amenda-components/Gallery/common";
import { isEmpty } from "lodash";
import { useSearchAttachments } from "@amenda-domains/queries";

export const ProjectAttachmentSliderView: FC = () => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [attachments, setAttachments] = useState<any[]>([]);
  const { searchAttachments } = useSearchAttachments();
  const setGalleryAttachments = useAttachmentStore(
    (state) => state.setAttachments,
  );
  const setIsGalleryModalOpen = useAttachmentStore(
    (state) => state.setIsGalleryModalOpen,
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );

  const handleClick = (file: any) => {
    setGalleryAttachments({ attachments });
    setIsGalleryModalOpen(true);
    setSelectedAttachment(file);
  };

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (selectedProject?.id) {
        await searchAttachments({
          filters: {
            "formValues.project": selectedProject.id,
          },
          callback: setAttachments,
        });
      }
    };

    searchAttachmentAsync();

    return () => {
      setAttachments([]);
    };
  }, [searchAttachments, selectedProject?.id]);

  if (isEmpty(attachments)) {
    return (
      <Image
        url=""
        size="pb-16/17"
        enableHighlighting={false}
        magicSize="galleryCropped"
      />
    );
  }
  return (
    <SliderView
      files={getSliderAttachments({
        attachments,
        selectedAttachments: [],
        galleryUrl: selectedProject?.galleryUrl,
      })}
      onClick={handleClick}
    />
  );
};
