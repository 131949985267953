import { FC, ReactNode } from "react";

import { ErrorMessage } from "./ErrorMessage";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface TextAreaProps {
  error?: string;
  id: string;
  label: string;
  placeholder?: string;
  optional?: ReactNode;
  rows?: number;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
}

export const TextArea: FC<TextAreaProps> = ({
  error,
  id,
  label,
  placeholder,
  optional,
  value,
  disabled,
  readOnly,
  rows = 4,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-2 group/textArea">
      <div className="flex justify-between">
        <label htmlFor={id} className="amenda-component-label">
          {t(label)}
        </label>
        {optional}
      </div>
      <div>
        <textarea
          id={id}
          rows={rows}
          value={value}
          readOnly={readOnly}
          className={clsx("amenda-component", {
            "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
              !!error,
            "focus:ring-indigo-500 focus:border-indigo-700 border-gray-300":
              !error,
            "bg-gray-100": disabled || readOnly,
            "cursor-default": readOnly,
          })}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          disabled={disabled}
          onBlur={onBlur}
          autoComplete="off"
        />
      </div>
      <ErrorMessage id={id} error={error} />
    </div>
  );
};
