import {
  getComponentsFromForms,
  getValidationSchema,
  groupComponentsByParent,
} from "@amenda-utils";

import { AvailableForms } from "@amenda-constants";
import { Button } from "@amenda-components/App";
import { FC } from "react";
import { UserProfileForm } from "@amenda-components/Users";
import { transformUsersToForm } from "@amenda-components/Contacts/common";
import { useForm } from "react-hook-form";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface MyAccountInput {
  email: string;
  photoURL: string;
  userDetails: {
    phone: string;
    language: string;
    title: string;
    department: string;
    location: string;
    firstName: string;
    lastName: string;
  };
}

export const CurrentUserProfileForm: FC<{
  currentUserInfo: any;
  loading: boolean;
  onSubmit: (form: any) => void;
}> = ({ currentUserInfo, loading, onSubmit }) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms) || {};
  const components = getComponentsFromForms(forms[AvailableForms.CurrentUser]);
  const formComponentTree = groupComponentsByParent(components);
  const validationSchema = getValidationSchema(components);

  const { handleSubmit, control } = useForm<MyAccountInput>({
    values: transformUsersToForm(currentUserInfo),
    resolver: yupResolver(validationSchema),
    resetOptions: {
      keepIsSubmitted: true,
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  return (
    <div>
      <div className="mb-4 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Your Profile")}</h1>
          <p className="amenda-settings-subline">
            {t("This is where you can maintain your Profile Information.")}
          </p>
        </div>
      </div>
      <form id="user-profile-form" onSubmit={handleSubmit(onSubmit)}>
        <UserProfileForm
          control={control}
          currentUserInfo={currentUserInfo}
          formComponentTree={formComponentTree}
        />
      </form>
      <div className="w-full mt-4">
        <Button
          form="user-profile-form"
          type="submit"
          size="md"
          variant="primary"
          loading={loading}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};
