import {
  PageComponentProps,
  similaritySearchConfigColumns,
} from "@amenda-types";

import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { Control } from "react-hook-form";
import { FC } from "react";
import { SimilaritySearchConfigRow } from "./SimilaritySearchConfigRow";
import { groupComponents } from "@amenda-utils";

interface Props {
  control: Control<any>;
  globalData: Record<string, any>;
  components: PageComponentProps[];
}

export const SimilaritySearchConfigForm: FC<Props> = ({
  control,
  components,
  globalData,
}) => {
  const groupedComponents = groupComponents(components);

  return (
    <div className="w-full">
      <ComponentsTreeView
        label=""
        readOnly={false}
        control={control}
        globalData={globalData}
        components={groupedComponents}
        columns={similaritySearchConfigColumns}
        searchPlaceholder="Filter..."
        className="max-h-full h-[80rem]"
        RowBody={SimilaritySearchConfigRow}
      />
    </div>
  );
};
