import { createContext, useContext } from "react";

import { Control } from "react-hook-form";
import { NestedPageComponentProps } from "@amenda-types";
import isEmpty from "lodash/isEmpty";

interface NestedFormProviderProps {
  control?: Control<any>;
  id?: string;
  data?: any;
  readOnly?: boolean;
}

export const NestedFormContext = createContext<NestedFormProviderProps>({});

export const transformWithNestedFormContext = ({
  context,
  config,
  isFormBuilder,
  globalProps: global,
}: {
  config: NestedPageComponentProps;
  globalProps?: Record<string, any>;
  context?: NestedFormProviderProps;
  isFormBuilder?: boolean;
}) => {
  if (!isEmpty(context) && !isFormBuilder) {
    const id = context?.id ? `${context.id}.${config.id}` : config.id;
    const control = context?.control ?? global?.control;
    const data = context?.data ?? global?.data;
    const nestedFormReadOnly = Boolean(context?.readOnly);
    const validation = config?.validation
      ? {
          ...config.validation,
          id,
        }
      : config?.validation;

    return {
      config: {
        ...config,
        id,
        validation,
      },
      global: {
        ...global,
        data,
        control,
        nestedFormReadOnly,
      },
    };
  }
  return {
    config,
    global,
  };
};

export const useNestedFormContext = () => {
  const context = useContext(NestedFormContext);

  if (!context) {
    return;
  }
  return context;
};
