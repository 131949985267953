import { FC } from "react";
import { PdfLayout } from "@amenda-components/PdfBuilder";
import { ProjectTemplatePrintView } from "@amenda-components/Projects";

export const PrintProjectTemplate: FC = () => {
  return (
    <PdfLayout>
      <ProjectTemplatePrintView />
    </PdfLayout>
  );
};
