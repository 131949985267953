/*
GitHub Copilot: 
The code you've selected is a common pattern used in JavaScript and TypeScript to delay the execution 
of a function until the current call stack has cleared. This is done using the `setTimeout` function 
with a delay of `0` milliseconds.In this specific case, the function being delayed is `e.target.focus()`. 
This is a method that sets focus on a specific DOM element, `e.target`, where `e` is the event object passed 
to an event handler function.The reason for using `setTimeout` here is to ensure that the `focus()` method is 
called after any other pending JavaScript operations have completed. This can be necessary in certain situations where, 
for example, the element you want to focus is being rendered or modified by another JavaScript operation. 
By delaying the `focus()` call, you ensure that the element is in the correct state when the method is called.
This pattern is commonly used in React and other JavaScript libraries to handle timing issues related to the 
rendering and modification of DOM elements.
*/

export const handleWheelChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();
  // Prevent the page/container scrolling
  e.stopPropagation();
  // Refocus immediately
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const getMailOrPhoneRef = (type: "email" | "phone", value: string) => {
  if (type === "email") {
    return `mailto:${value}`;
  }

  return `tel:${value}`;
};
