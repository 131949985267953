import { FC, ReactNode, createContext, useContext } from "react";
import {
  FormAutoSaveBase,
  FormAutoSaveBaseChildProps,
  FormAutoSaveResourceProps,
  FormAutoSaveSubmitProps,
} from "./FormAutoSaveWrapperBase";

import { ObjectSchema } from "yup";

type FormAutoSaveContextProps = FormAutoSaveBaseChildProps;

const FormAutoSaveContext = createContext<FormAutoSaveContextProps>({});

export const useFormAutoSave = (): FormAutoSaveContextProps => {
  const context = useContext(FormAutoSaveContext);

  if (!context) {
    throw new Error(
      "useFormAutoSave must be used within a FormAutoSaveProvider",
    );
  }
  return context;
};

interface Props extends FormAutoSaveResourceProps {
  children: ReactNode;
  ignoreResourceIdAlways?: boolean;
  values: Record<string, any>;
  inputSchema: ObjectSchema<any>;
  onSubmit: (props: FormAutoSaveSubmitProps) => Promise<void>;
}

export const FormAutoSaveProvider: FC<Props> = ({ children, ...rest }) => {
  return (
    <FormAutoSaveBase {...rest}>
      {({
        reset,
        resourceId,
        control,
        setValue,
        submitButtonRef,
        handleFormSubmit,
        handleSubmitManually,
      }) => (
        <FormAutoSaveContext.Provider
          value={{
            reset,
            control,
            setValue,
            resourceId,
            submitButtonRef,
            handleSubmitManually,
            handleFormSubmit,
          }}
        >
          <>{children}</>
        </FormAutoSaveContext.Provider>
      )}
    </FormAutoSaveBase>
  );
};
