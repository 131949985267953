import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  Control,
  useController,
  useFieldArray,
  useFormState,
} from "react-hook-form";
import { FC, ReactNode, useRef } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";

import AsyncSelect from "react-select/async";
import { DebounceTimes } from "@amenda-constants";
import { ErrorMessage } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { components } from "react-select";
import { debounce } from "lodash";
import { getErrorFromFieldError } from "./common";
import { useSearchSystemRoles } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface ChildProps {
  fields: any[];
  remove: (index: number) => void;
  update: (index: number, value: any) => void;
}

interface Props {
  id: string;
  label?: string;
  isMulti?: boolean;
  className?: string;
  optional?: ReactNode;
  control: Control<any>;
  disabled?: boolean;
  isClearable?: boolean;
  controlShouldRenderValue?: boolean;
  children?: (props: ChildProps) => ReactNode;
}

interface SelectWrapperProps extends Omit<Props, "children" | "control"> {
  value?: any;
  error?: string;
  children?: ReactNode;
  onChange?: (value: any) => void;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.ClearIndicator>
  );
};

const SelectWrapper: FC<SelectWrapperProps> = ({
  id,
  label,
  value,
  error,
  optional,
  children,
  disabled,
  isMulti = true,
  isClearable = false,
  controlShouldRenderValue = true,
  className = "mb-2",
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { searchSystemRoles, loading } = useSearchSystemRoles();
  const debouncedSearch = useRef(
    debounce(async (searchTerm: string, resolve: (users: any[]) => void) => {
      await searchSystemRoles(searchTerm, resolve);
    }, DebounceTimes.Search),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        await debouncedSearch(inputValue, resolve);
      }
    });

  return (
    <div ref={ref} className={className}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <AsyncSelect
        cacheOptions
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        menuPlacement="auto"
        isClearable={isClearable}
        controlShouldRenderValue={controlShouldRenderValue}
        placeholder={t("Search contacts")}
        className={customStyling.select.containerClass}
        isLoading={loading}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        loadOptions={loadOptions}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? t("No results found") : t("Start typing")
        }
        components={{
          ClearIndicator,
          DropdownIndicator,
        }}
        styles={{
          ...customStyling.select.styleOverride,
          ...selectMenuOverflow(true, ref),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        {...selectMenuOverflowProps(true)}
      />
      <ErrorMessage id={id} error={error} />
      {children}
    </div>
  );
};

const MultiSelect: FC<Props> = ({ children, control, id, ...props }) => {
  const { fields, update, remove, replace } = useFieldArray({
    control,
    name: id,
  });
  const { errors } = useFormState({
    control,
    name: id,
    exact: true,
  });
  const fieldError = errors[id];
  const error = Array.isArray(fieldError)
    ? getErrorFromFieldError(fieldError)
    : (fieldError?.message as string);

  const handleChange = (value: any[] | null) => {
    replace(value ?? []);
  };

  return (
    <SelectWrapper
      id={id}
      value={fields}
      error={error}
      onChange={handleChange}
      {...props}
    >
      <div>
        {children?.({
          fields,
          remove,
          update,
        })}
      </div>
    </SelectWrapper>
  );
};

const SingleSelect: FC<Props> = ({ control, id, children, ...props }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });

  return (
    <SelectWrapper
      id={id}
      isMulti={false}
      value={value}
      error={error?.message}
      onChange={onChange}
      {...props}
    />
  );
};

export const SystemRolesSelect: FC<Props> = ({
  children,
  isMulti,
  ...props
}) => {
  if (isMulti) {
    return <MultiSelect {...props}>{children}</MultiSelect>;
  }
  return <SingleSelect {...props} />;
};
