import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  BookmarkIcon,
  BookmarkSlashIcon,
} from "@heroicons/react/20/solid";
import { Button, ButtonWithDropdown, Modal } from "@amenda-components/App";
import { Column, Row, Table } from "@tanstack/react-table";
import { FC, HTMLProps, ReactNode, useEffect, useRef, useState } from "react";
import {
  SpecialColumns,
  downloadCsvOrExcel,
  getDefaultVisibleColumns,
  tableDownloadOptions,
} from "./reactTableHelpers";

import { ChevronRightIcon } from "lucide-react";
import Fuse from "fuse.js";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { MiniSwitch } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface TableSortIndicatorsProps {
  column: Column<any, any>;
}

interface TableCellWrapperProps {
  children: ReactNode;
  className?: string;
}

export const TableCellWrapper: FC<TableCellWrapperProps> = ({
  children,
  className = "flex items-center",
}) => {
  return <div className={clsx("h-full", className)}>{children}</div>;
};

export const TableSortIndicators: FC<TableSortIndicatorsProps> = ({
  column,
}) => {
  const isSorted = column.getIsSorted();
  const canSort = column.getCanSort();

  if (!canSort) return null;
  return (
    <button
      className={clsx("pl-3", {
        "text-gray-500": isSorted,
        "group-hover/th:text-gray-500 text-transparent": !isSorted,
      })}
      onClick={column.getToggleSortingHandler()}
    >
      {!isSorted ? (
        <ArrowsUpDownIcon className="h-4 w-4" />
      ) : isSorted === "desc" ? (
        <ArrowDownIcon className="h-4 w-4" />
      ) : (
        <ArrowUpIcon className="h-4 w-4" />
      )}
    </button>
  );
};

type IndetermintateCheckboxProps = HTMLProps<HTMLInputElement> & {
  indeterminate?: boolean;
};

const IndeterminateCheckbox: FC<IndetermintateCheckboxProps> = ({
  indeterminate,
  className = "",
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={clsx(
        "amenda-component text-black h-5 w-5 cursor-pointer",
        className,
      )}
      {...rest}
    />
  );
};

interface TableRowSelectorProps {
  table?: Table<any>;
  row?: Row<any>;
  children?: ReactNode;
  onRowSelectionChange?: (rows: Row<any>) => (e: any) => void;
  onAllRowsSelectionChange?: (rows: Table<any>) => (e: any) => void;
}

export const TableGrouping: FC<TableRowSelectorProps> = ({ row, children }) => {
  if (row?.getCanExpand()) {
    return (
      <div className="flex items-center">
        <button
          className="cursor-pointer p-1 bg-gray-800 text-white hover:bg-gray-600"
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: "pointer" },
          }}
        >
          <ChevronRightIcon
            className={clsx("h-4 w-4", {
              "rotate-90 transform": row.getIsExpanded(),
            })}
          />
        </button>
        {children}
      </div>
    );
  }
  return null;
};

export const TableRowSelector: FC<TableRowSelectorProps> = ({
  table,
  row,
  onAllRowsSelectionChange,
  onRowSelectionChange,
}) => {
  if (table) {
    const {
      getIsAllRowsSelected,
      getIsSomeRowsSelected,
      getToggleAllRowsSelectedHandler,
    } = table;
    const onChange = onAllRowsSelectionChange
      ? onAllRowsSelectionChange(table)
      : getToggleAllRowsSelectedHandler();

    return (
      <IndeterminateCheckbox
        checked={getIsAllRowsSelected()}
        indeterminate={getIsSomeRowsSelected()}
        onChange={onChange}
      />
    );
  } else if (row) {
    const {
      getIsSelected,
      getCanSelect,
      getIsSomeSelected,
      getToggleSelectedHandler,
    } = row;

    const onChange = onRowSelectionChange
      ? onRowSelectionChange(row)
      : getToggleSelectedHandler();

    return (
      <IndeterminateCheckbox
        checked={getIsSelected()}
        disabled={!getCanSelect()}
        indeterminate={getIsSomeSelected()}
        onChange={onChange}
      />
    );
  }
  return null;
};

interface TablePinColumnProps {
  hidePin?: boolean;
  column: Column<any, any>;
}

export const TablePinColumn: FC<TablePinColumnProps> = ({
  column,
  hidePin = false,
}) => {
  const onClick = () => {
    if (column.getIsPinned()) {
      column.pin(false);
    } else {
      column.pin("left");
    }
  };

  if (!column.getCanPin() || hidePin) return null;
  return (
    <button
      className={clsx("absolute right-0 pl-3", {
        "text-gray-500": column.getIsPinned(),
        "group-hover/th:text-gray-500 text-transparent": !column.getIsPinned(),
      })}
      onClick={onClick}
    >
      {column.getIsPinned() ? (
        <BookmarkSlashIcon className="h-4 w-4" />
      ) : (
        <BookmarkIcon className="h-4 w-4" />
      )}
    </button>
  );
};

interface ConfigureColumnsModalProps {
  columns: Column<any, any>[];
  isOpen: boolean;
  onClose: () => void;
}

interface TableColumnSelectorWrapperProps {
  columns: Column<any, any>[];
  children: (args: {
    columns: Column<any, any>[];
    Selector: FC<{
      label: string;
      value: boolean;
      onChange: (value: boolean) => void;
    }>;
  }) => ReactNode;
}

const TableColumnSelectorWrapper: FC<TableColumnSelectorWrapperProps> = ({
  children,
  columns,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredColumns, setFilteredColumns] = useState(columns);

  const handleSearch = (value: string) => {
    let filteredColumns = [...columns];

    if (value) {
      const fuse = new Fuse(columns, {
        includeScore: true,
        keys: [
          { name: "id", getFn: (column: Column<any, any>) => column.id },
          {
            name: "label",
            getFn: (column: Column<any, any>) =>
              (column.columnDef?.meta as any)?.label || "",
          },
        ],
      });
      const results = fuse.search(value);
      filteredColumns = results.map((res) => res.item);
    }
    setSearchTerm(value);
    setFilteredColumns(filteredColumns);
  };

  return (
    <div className="w-full py-3 px-3">
      <MiniSearchField
        value={searchTerm}
        className="border boder-gray-900"
        placeholder={t("Search for column") + "..."}
        onChange={handleSearch}
      />
      <div className="w-full h-60 pt-3 overflow-y-auto flex flex-col space-y-2">
        {children({
          columns: filteredColumns,
          Selector: ({ label, value, onChange }) => {
            return (
              <div className="flex items-start space-x-4">
                <MiniSwitch
                  label={t(label)}
                  value={value}
                  onChange={onChange}
                />
                <label>{t(label)}</label>
              </div>
            );
          },
        })}
      </div>
    </div>
  );
};

export const ConfigureColumnsModal: FC<ConfigureColumnsModalProps> = ({
  columns,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const availableColumns = columns.filter(
    (column) =>
      ![
        SpecialColumns.ACTIONS,
        SpecialColumns.SELECT,
        SpecialColumns.GROUPING,
      ].includes(column.id as SpecialColumns),
  );

  return (
    <Modal
      size="md"
      className="lg:w-5/12"
      cancelLabel="Close"
      title="Configure table"
      message="Toggle column visibility"
      isOpen={isOpen}
      closeModalFromTitle={true}
      onClose={onClose}
    >
      <TableColumnSelectorWrapper columns={availableColumns}>
        {({ columns, Selector }) => {
          return (
            <>
              {columns.map(
                ({
                  id,
                  columnDef,
                  getIsVisible,
                  getToggleVisibilityHandler,
                }) => {
                  const metaLabel = (columnDef?.meta as any)?.label;

                  const handleChange = (value: boolean) => {
                    getToggleVisibilityHandler()({
                      target: {
                        id,
                        checked: value,
                      },
                    });
                  };

                  return (
                    <Selector
                      key={id}
                      label={t(metaLabel || id)}
                      value={getIsVisible()}
                      onChange={handleChange}
                    />
                  );
                },
              )}
            </>
          );
        }}
      </TableColumnSelectorWrapper>
    </Modal>
  );
};

interface DownloadColumnsModalProps {
  data: any[];
  columns: Column<any, any>[];
  isOpen: boolean;
  onClose: () => void;
}

export const DownloadColumnsModal: FC<DownloadColumnsModalProps> = ({
  data,
  columns,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const availableColumns = columns.filter(
    (column) =>
      ![
        SpecialColumns.ACTIONS,
        SpecialColumns.SELECT,
        SpecialColumns.GROUPING,
      ].includes(column.id as SpecialColumns),
  );
  const { defaultVisibleColumns } = getDefaultVisibleColumns(availableColumns);
  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);

  const handleChange = (id: string) => (value: boolean) => {
    setVisibleColumns({
      ...visibleColumns,
      [id]: value,
    });
  };

  const handleDownload = async (value: any) => {
    setLoading(true);
    await downloadCsvOrExcel({
      data,
      value,
      visibleColumns,
      availableColumns,
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      size="md"
      className="lg:w-5/12"
      title="Download table"
      message="Download table as excel or CSV"
      isOpen={isOpen}
      loading={loading}
      onClose={onClose}
      closeModalFromTitle={true}
      footerChildren={({ loading, onClose }) => {
        return (
          <>
            <div className="shrink">
              <Button type="button" onClick={onClose}>
                {t("Cancel")}
              </Button>
            </div>
            <ButtonWithDropdown
              loading={loading}
              options={tableDownloadOptions}
              onClick={handleDownload}
            />
          </>
        );
      }}
    >
      <TableColumnSelectorWrapper columns={availableColumns}>
        {({ columns, Selector }) => {
          return (
            <>
              {columns.map(({ id, columnDef }) => {
                const metaLabel = (columnDef?.meta as any)?.label;

                return (
                  <Selector
                    key={id}
                    label={t(metaLabel || id)}
                    value={visibleColumns[id]}
                    onChange={handleChange(id)}
                  />
                );
              })}
            </>
          );
        }}
      </TableColumnSelectorWrapper>
    </Modal>
  );
};
