import { FC, useEffect, useMemo, useState } from "react";
import { Skeleton, Tooltip } from "@amenda-components/App";
import { useDashboardStore, useProjectStore } from "@amenda-domains/mutations";
import {
  useGetLatestBKIDate,
  useGetProjectCostAggregation,
} from "@amenda-domains/queries";

import { AllowedCostGroupTypes } from "@amenda-constants";
import { ComponentPicker } from "./ComponentPicker";
import { CostGroupTypes } from "@amenda-components/CostGroup/common";
import { DateQuarterPicker } from "./DateQuarterPicker";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { formatNumbers } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

type WidgetProps = {
  id: string;
  label: string;
  isEditing?: boolean;
  componentId: string;
  adornment: string;
  date?: string;
  costGroupType?: AllowedCostGroupTypes;
  costGroupCode?: string;
  referenceQuantity?: string;
};

const costGroupOptions = CostGroupTypes.map((type) => ({
  value: type,
  label: type,
}));

const Widget: FC<WidgetProps> = ({
  id,
  isEditing,
  adornment,
  componentId,
  costGroupCode,
  costGroupType,
  referenceQuantity,
  date = "",
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const projects = useProjectStore((state) => state.projects);
  const { getLatestBKIDate, loading } = useGetLatestBKIDate();
  const latestBKIDate = useProjectStore((state) => state.latestBKIDate);
  const updateWidgetProps = useDashboardStore(
    (state) => state.updateWidgetProps,
  );
  const { getProjectCostAggregation, loading: projectCostAggregationLoader } =
    useGetProjectCostAggregation();
  const resourceIds = useMemo(() => projects.map((p) => p.id), [projects]);

  const hasAllData = [
    data.averageCost,
    data.minimumCost,
    data.maximumCost,
  ].every((no) => Number.isFinite(no));

  const handleDate = (date: string) => {
    updateWidgetProps(id, { date });
  };

  const handleCostGroup = ({ value }: any) =>
    updateWidgetProps(id, {
      costGroupType: value,
    });

  useEffect(() => {
    getLatestBKIDate();
  }, [getLatestBKIDate]);

  useEffect(() => {
    if (
      date &&
      costGroupType &&
      costGroupCode &&
      !isEmpty(resourceIds) &&
      referenceQuantity
    ) {
      getProjectCostAggregation({
        resourceIds,
        referenceQuantity,
        type: costGroupType,
        considerationDate: date,
        considerationCostGroup: Number(costGroupCode),
        context: {
          requestPolicy: "network-only",
        },
        callback: setData,
      });
    }
  }, [
    date,
    costGroupType,
    costGroupCode,
    resourceIds,
    referenceQuantity,
    getProjectCostAggregation,
  ]);

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="h-[95%]">
        {projectCostAggregationLoader ? (
          <Skeleton height={6} />
        ) : !hasAllData ? (
          <p className="w-full text-center font-apercu text-xs py-3">
            {t("No data available for this widget")}
          </p>
        ) : (
          <div>
            <p>
              {data.averageCost && formatNumbers(data.averageCost, adornment)}
            </p>
            {data.maximumCost && data.minimumCost && (
              <p className="text-sm pt-1">
                {formatNumbers(data.minimumCost)}
                <span className="mx-1">&ndash;</span>
                {formatNumbers(data.maximumCost, adornment)}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between">
        <Tooltip
          id={`${id}_componentPicker`}
          message={!isEditing ? "Click edit to modify widget" : undefined}
        >
          <ComponentPicker
            widgetId={id}
            componentId={componentId}
            disabled={!isEditing}
          />
        </Tooltip>
        <Tooltip
          id={`${id}_DateQuarterPicker`}
          message={!isEditing ? "Click edit to modify widget" : undefined}
        >
          <DateQuarterPicker
            value={date}
            disabled={!isEditing || loading}
            maxDate={latestBKIDate.date}
            onChange={handleDate}
          />
        </Tooltip>
        <Tooltip
          id={`${id}_operationsMenu`}
          message={!isEditing ? "Click edit to modify widget" : undefined}
        >
          <OperationsMenu
            disabled={!isEditing}
            options={costGroupOptions}
            label="Cost Group Versions"
            closeOnHover={true}
            selectedOption={costGroupType}
            onChange={handleCostGroup}
          />
        </Tooltip>
      </div>
    </div>
  );
};

interface Props {
  isEditingWidgets?: boolean;
  componentCodeById: Record<
    string,
    {
      code: string;
      referenceQuantity: string;
    }
  >;
  widget: {
    id: string;
    properties: any;
  };
}

export const CostGroupWidget: FC<Props> = ({
  widget,
  isEditingWidgets,
  componentCodeById,
}) => {
  const { code, referenceQuantity } =
    componentCodeById[widget.properties?.componentId] || {};

  return (
    <Widget
      id={widget.id}
      isEditing={isEditingWidgets}
      costGroupCode={code}
      referenceQuantity={referenceQuantity}
      {...widget.properties}
    />
  );
};
