import { gql } from "urql";

export const IMAGE_DESCRIPTOR_FRAGMENT = gql`
  fragment ImageDescriptorFragment on ImageDescriptor {
    id: _id
    label
    type
    sourceURL
    descriptor
  }
`;
