import { ErrorMessage } from "./ErrorMessage";
import { FC } from "react";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { onChangeOptions } from "@amenda-components/PageBuilder/common";
import { useTranslation } from "react-i18next";

interface CheckBoxProps {
  className?: string;
  error?: string;
  id: string;
  label?: string;
  options: Option[];
  selectedOptions?: string[];
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
}

export const CheckBox: FC<CheckBoxProps> = ({
  error,
  label,
  options,
  disabled,
  className = "mb-2 mt-6",
  selectedOptions = [],
  onBlur,
  onChange,
}) => {
  const { t } = useTranslation();
  const handleChange = (option: Option) => {
    onChangeOptions({
      onChange,
      option,
      selectedOptions,
    });
  };
  return (
    <div className={className}>
      {label && <legend className="amenda-component-label">{t(label)}</legend>}
      <div className="mt-1 space-y-1">
        {options.map((option) => {
          return (
            <div className="flex items-start" key={option.value}>
              <div className="flex items-center h-5">
                <input
                  id={option.value}
                  name={option.label}
                  type="checkbox"
                  className={clsx("amenda-component h-5 w-5", {
                    "text-gray-900": !disabled,
                    "text-gray-500 cursor-not-allowed": disabled,
                  })}
                  checked={selectedOptions.includes(option.value)}
                  disabled={disabled}
                  onChange={() => handleChange(option)}
                  onBlur={onBlur}
                />
              </div>
              <div className="font-apercu ml-3 text-sm">
                <label
                  htmlFor={option.value}
                  className="font-medium text-gray-900 font-sm"
                >
                  {t(option.label)}
                  {Number.isFinite(Number(option?.count)) && (
                    <span className="font-medium text-gray-500 ml-2">
                      ({option?.count})
                    </span>
                  )}
                </label>
                {option.description && (
                  <p className="font-apercu text-gray-500">
                    {t(option.description)}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};
