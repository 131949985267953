import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { FC, ReactNode } from "react";

import { ChevronRightIcon } from "lucide-react";
import clsx from "clsx";

interface Props {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  panelClassName?: string;
  titleClassName?: string;
  defaultOpen?: boolean;
  hideBorder?: boolean;
}

export const Accordion: FC<Props> = ({
  title,
  children,
  className,
  panelClassName = "px-2",
  titleClassName = "",
  hideBorder = false,
  defaultOpen = false,
}) => {
  return (
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <DisclosureButton
            as="button"
            type="button"
            className={clsx(
              "flex w-full justify-between text-left text-sm font-medium cursor-pointer py-2",
              className,
              {
                "border-b border-gray-200": !open && !hideBorder,
              },
            )}
          >
            <div className={clsx(titleClassName, "font-apercu font-medium")}>
              {title}
            </div>
            <div className="flex flex-row">
              <ChevronRightIcon
                className={clsx("h-6 w-6 text-gray-600 mr-2", {
                  "rotate-90 transform": open,
                })}
              />
            </div>
          </DisclosureButton>
          <DisclosurePanel
            className={clsx(panelClassName, {
              "border-b border-gray-200": !hideBorder,
            })}
          >
            {children}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
