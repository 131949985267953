import {
  CollectionProps,
  getArgsFromKeywords,
  updateCollectionShare,
} from "./common";
import { FC, useState } from "react";
import { isEmpty, uniq } from "lodash";
import { useAppStore, useCreateCollection } from "@amenda-domains/mutations";

import { AvailableNotificationTypes } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { CollectionsModalWrapper } from "./CollectionsModalWrapper";
import { sanitizeData } from "@amenda-utils";
import { useGetAllUsers } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props extends CollectionProps {
  isOpen: boolean;
  collectionRoute: string;
  handleClose: () => void;
}

export const CreateCollectionModal: FC<Props> = ({
  isOpen,
  collectionType,
  collectionRoute,
  resourceIds = [],
  handleClose,
  getResourceIds,
  clearSelectedResources,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { createCollection } = useCreateCollection();
  const { getAllUsers } = useGetAllUsers();
  const showNotification = useAppStore((state) => state.showNotification);

  const getUsersFromKeyword = async (keywords: any[]) => {
    if (isEmpty(keywords)) return [];

    const args = getArgsFromKeywords(keywords);
    const users = await getAllUsers({
      userDetails: args,
      skipStorage: true,
    });
    return users?.map((user: any) => user.id) || [];
  };

  const goToCollection = (collectionId: string) => {
    if (collectionId) {
      navigate(`${collectionRoute}/${collectionId}`);
    }
  };

  const getResourceIdsAsync = async (args: any) => {
    let resourceIds: string[] = [];
    if (!isEmpty(args)) {
      resourceIds = await getResourceIds(args);
    }
    return resourceIds || [];
  };

  const handleSubmit = async (
    {
      description,
      expirationDate,
      tags = [],
      shareWithRoles = [],
      shareWithContacts = [],
      shareWithUserTags = [],
      ...rest
    }: any,
    reset: () => void,
  ) => {
    setLoading(true);

    const args = getArgsFromKeywords(tags);
    const tagResourceIds = await getResourceIdsAsync(args);
    const keywordUserIds = await getUsersFromKeyword(shareWithUserTags);

    let selectedResourceIds = resourceIds.concat(tagResourceIds);
    selectedResourceIds = uniq(selectedResourceIds);
    const shares = updateCollectionShare({
      keywordUserIds,
      shareWithRoles,
      shareWithContacts,
      expirationDate,
    });
    let input = {
      ...rest,
      shares,
      description,
      collectionType,
      resourceIds: selectedResourceIds,
      keywords: tags?.map(({ value }: any) => value),
    };
    input = sanitizeData(input);

    try {
      if (isEmpty(input.resourceIds)) {
        throw new Error(
          t("CollectionCreationError", {
            resource: t(collectionType),
          }),
        );
      }

      const collection = await createCollection({ input });
      setLoading(false);
      reset();
      clearSelectedResources();
      handleClose();
      goToCollection(collection.id);
    } catch (err: any) {
      setLoading(false);
      showNotification(AvailableNotificationTypes.Error, err.message);
    }
  };

  return (
    <CollectionsModalWrapper
      title="Create new collection"
      isOpen={isOpen}
      isLoading={loading}
      collectionType={collectionType}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      footerChildren={(formId, loading) => {
        return (
          <div className="w-full">
            <Button
              form={formId}
              className="w-full flex justify-center"
              type="submit"
              loading={loading}
              variant="primary"
            >
              {t("Create collection")}
            </Button>
          </div>
        );
      }}
    />
  );
};
