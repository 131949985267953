import { FC, useEffect } from "react";

import { AllowedCollectionType } from "@amenda-types";
import { ConstructionDetailsPageWrapper } from "./ConstructionDetailsPageWrapper";
import { useAttachmentStore } from "@amenda-domains/mutations";

export const ConstructionDetailsPage: FC = () => {
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );

  useEffect(() => {
    return () => {
      clearSelectedAttachments();
    };
  }, [clearSelectedAttachments]);

  return (
    <ConstructionDetailsPageWrapper
      collectionType={AllowedCollectionType.ConstructionDetails}
    />
  );
};
