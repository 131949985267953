import { ActionBar, Tooltip } from "@amenda-components/App";
import {
  ArrowDownTrayIcon,
  CheckIcon,
  CloudArrowUpIcon,
  FolderIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  AvailableNotificationTypes,
  GeneralPermissionTypes,
} from "@amenda-types";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import {
  useAppStore,
  useAttachmentStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { DeleteModal } from "@amenda-components/Shared";
import { GeneralAccessTypes } from "@amenda-constants";
import { Share2Icon } from "lucide-react";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import clsx from "clsx";
import { copyToClickBoard } from "@amenda-utils";
import { useFaceAPI } from "@amenda-context";

export const AttachmentActionBar: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [hasCopiedLink, setHasCopiedLink] = useState(false);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const setIsGalleryModalOpen = useAttachmentStore(
    (state) => state.setIsGalleryModalOpen,
  );
  const setIsEditingAttachment = useAttachmentStore(
    (state) => state.setIsEditingAttachmentDetails,
  );
  const isEditing = useAttachmentStore(
    (state) => state.isEditingAttachmentDetails,
  );
  const isSavingAttachment = useAttachmentStore(
    (state) => state.isSavingAttachment,
  );
  const showNotification = useAppStore((state) => state.showNotification);
  const toggleMoveAttachmentsModal = useAttachmentStore(
    (state) => state.toggleMoveAttachmentsModal,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { updateAttachment, loading } = useUpdateAttachment();
  const { cleanUpFaceDetection } = useFaceAPI();

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );

  const closeFullScreenModal = () => {
    cleanUpFaceDetection();
    setIsEditingAttachment(false);
    setIsGalleryModalOpen(false);
  };

  const handleCopyToClickboard = () => {
    copyToClickBoard({
      text: selectedAttachment?.url || "",
      onSuccess: () => {
        showNotification(
          AvailableNotificationTypes.Success,
          "Link copied to clipboard",
        );
        setHasCopiedLink(true);
      },
    });
  };

  const download = () => {
    if (selectedAttachment?.url) {
      //append d parameter to magic URL to initiate download
      return window.location.replace(`${selectedAttachment?.url}=d`);
    }
  };

  const deleteAttachment = async () => {
    await updateAttachment({
      input: {
        _id: selectedAttachment.id,
        isDeleted: true,
      },
    });
    setOpenModal(false);
    closeFullScreenModal();
  };

  const handleCloseShare = () => setOpenShareModal(false);
  const toggleEditing = () => setIsEditingAttachment(!isEditing);

  const handleMoveToFolder = () => {
    toggleSelectedAttachment(selectedAttachment.id);
    setIsGalleryModalOpen(false);
    toggleMoveAttachmentsModal(true);
  };

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    const share = {
      type,
      users: users.map((u) => ({
        id: u._id,
        role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };

    await updateAttachment({
      input: {
        share,
        _id: selectedAttachment.id,
      },
    });
    setOpenShareModal(false);
  };

  return (
    <>
      <DeleteModal
        title="Delete Attachment"
        description="Are you sure you want to delete this attachment?"
        loading={loading}
        openModal={openModal}
        handleDelete={deleteAttachment}
        handleCloseModal={() => setOpenModal(false)}
      />
      <ShareModal
        title="Share attachment with"
        loading={loading}
        openModal={openShareModal}
        ownerId={selectedAttachment?.ownerId}
        shareType={selectedAttachment?.share?.type}
        shareWith={selectedAttachment?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <ActionBar>
        {({ IconButton }) => (
          <>
            {isEditing ? (
              <Tooltip id="autoSave" message="Your Data is automatically saved">
                <IconButton
                  Icon={CloudArrowUpIcon}
                  label="Autosave"
                  className={clsx({
                    "animate-pulse text-green-600": isSavingAttachment,
                  })}
                  variant="base"
                />
              </Tooltip>
            ) : (
              <>
                {mediaGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="copyLink" message="Copy Link">
                    <IconButton
                      Icon={LinkIcon}
                      label="Copy Link"
                      onClick={handleCopyToClickboard}
                      className={clsx({
                        "animate-pulse-alert": hasCopiedLink,
                      })}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message="Share">
                    <IconButton
                      Icon={Share2Icon}
                      label="Share"
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Download] && (
                  <Tooltip id="downloadImage" message="Download Image">
                    <IconButton
                      Icon={ArrowDownTrayIcon}
                      label="Download Image"
                      onClick={download}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Move] && (
                  <Tooltip id="moveToFolder" message="Move to Folder">
                    <IconButton
                      Icon={FolderIcon}
                      label="Move to Folder"
                      onClick={handleMoveToFolder}
                      disabled={isEditing}
                    />
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Delete] && (
                  <Tooltip id="deleteImage" message="Delete Image">
                    <IconButton
                      Icon={TrashIcon}
                      label="Delete Image"
                      onClick={() => setOpenModal(true)}
                      disabled={loading}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {mediaGeneralPermissions[GeneralPermissionTypes.Edit] && (
              <Tooltip
                id="toggleEdit"
                message={isEditing ? "Go back" : "Edit Attachment"}
              >
                <IconButton
                  Icon={isEditing ? CheckIcon : PencilIcon}
                  label={isEditing ? "Go back" : "Edit Attachment"}
                  onClick={toggleEditing}
                />
              </Tooltip>
            )}
            <IconButton
              Icon={XMarkIcon}
              label="Close Modal"
              onClick={closeFullScreenModal}
            />
          </>
        )}
      </ActionBar>
    </>
  );
};
