import jwt from "jsonwebtoken";

export type DecodedToken = jwt.JwtPayload & {
  uid: string;
  tenantId: string;
  email: string;
  customToken: string;
};

export const decodeToken = (value: string, secret?: string) => {
  try {
    const signingSecret = secret || process.env.REACT_APP_TOKEN_SECRET || "";
    let decoded = jwt.verify(value, signingSecret, {
      ignoreExpiration: true,
    });
    decoded = typeof decoded === "string" ? { decoded } : decoded;
    return decoded as DecodedToken;
  } catch (e) {
    return {} as DecodedToken;
  }
};

export const encodeWithSecret = (value: any) => {
  try {
    const encoded = jwt.sign(value, process.env.REACT_APP_TOKEN_SECRET || "");
    return encoded;
  } catch (e) {
    return value;
  }
};
