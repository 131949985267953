import { AllowedAttachmentType, AllowedCollectionType } from "@amenda-types";
import { DebounceTimes, PaginationLimit } from "@amenda-constants";
import { FC, useCallback, useRef } from "react";
import {
  isSearchFilterEmpty,
  transformFilters,
} from "@amenda-components/Shared/common";
import {
  useAttachmentStore,
  useFormStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import {
  useGetAllAttachments,
  useSearchAttachments,
} from "@amenda-domains/queries";

import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { MediaGallery } from "@amenda-components/Gallery";
import { abortRequest } from "@amenda-domains/abortExchange";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  isCollection?: boolean;
}

export const AttachmentsPageWrapper: FC<Props> = ({ isCollection = false }) => {
  const { t } = useTranslation();
  const { getAllAttachments } = useGetAllAttachments();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const attachments = useAttachmentStore((state) => state.attachments);
  const pagination = useAttachmentStore((state) => state.pagination);
  const setIsSearching = useAttachmentStore((state) => state.setIsSearching);
  const { searchAttachments } = useSearchAttachments();
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();
  const { clearAttachments } = useAttachmentStore();

  const selectedCollection =
    selectedCollectionByType[AllowedCollectionType.Attachments];

  const debounceSearch = useRef(
    debounce(
      async ({
        searchTerm,
        filters,
        isCollection,
        resourceIds,
      }: {
        filters: Record<string, any>;
        searchTerm: string;
        isCollection: boolean;
        resourceIds: string[];
      }) => {
        setIsSearching(true);
        abortRequest("searchAttachments");
        if (!isEmpty(searchTerm) || !isSearchFilterEmpty(filters)) {
          await searchAttachments({
            isCollection,
            resourceIds,
            searchTerm,
            filters: {
              ...filters,
              type: AllowedAttachmentType.image,
            },
          });
        } else {
          const args: any = {
            attachmentType: AllowedAttachmentType.image,
            limit: PaginationLimit.attachments,
          };
          if (isCollection) {
            args.ids = resourceIds;
          }

          await getAllAttachments(args);
        }
        setIsSearching(false);
      },
      DebounceTimes.Search,
    ),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    await debounceSearch({
      searchTerm,
      isCollection,
      filters: transformFilters(sidebarFilters),
      resourceIds: selectedCollection?.resourceIds,
    });
  };

  const fetchAttachments = async (formValues: any) => {
    const attachments = await getAllAttachments({
      formValues,
      attachmentType: AllowedAttachmentType.image,
      callback: (data: any) => {},
    });

    return attachments;
  };

  const fetchNextPage = useCallback(async () => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      pagination?.hasNext &&
      pagination?.docsCount &&
      attachments.length < pagination.docsCount
    ) {
      const args: any = {
        next: pagination?.next,
        limit: PaginationLimit.attachments,
        attachmentType: AllowedAttachmentType.image,
      };
      if (isCollection) {
        args.ids = selectedCollection?.resourceIds;
      }

      await getAllAttachments(args);
    }
  }, [
    searchTerm,
    isCollection,
    sidebarFilters,
    attachments.length,
    pagination?.next,
    pagination?.hasNext,
    pagination?.docsCount,
    selectedCollection?.resourceIds,
    getAllAttachments,
  ]);

  useRunOnMountOnly(
    async () => {
      clearAttachments();
      if (!isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)) {
        await searchAttachments({
          isCollection,
          searchTerm,
          resourceIds: selectedCollection?.resourceIds,
          filters: {
            ...sidebarFilters,
            type: AllowedAttachmentType.image,
          },
        });
      } else {
        const args: any = {
          isCollection,
          attachmentType: AllowedAttachmentType.image,
          limit: PaginationLimit.attachments,
        };
        if (isCollection) {
          args.ids = selectedCollection?.resourceIds;
        }

        await getAllAttachments(args);
      }
    },
    JSON.stringify([isCollection, selectedCollection?.resourceIds]),
  );

  return (
    <MaxWidthLayout>
      <Helmet title={t(isCollection ? "Media collection" : "Media Overview")} />
      <MediaGallery
        canUpload={true}
        canAssignAttachments={true}
        canCreateCollection={true}
        attachments={attachments}
        pagination={pagination}
        fetchPaginatedAttachments={fetchNextPage}
        fetchAttachments={fetchAttachments}
        searchAttachments={handleSearch}
      />
    </MaxWidthLayout>
  );
};
