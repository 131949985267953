import backend from "i18next-http-backend";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ["navigator"],
    },
    ns: ["translation", "form"],
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    debug: false,
    fallbackLng: "de", // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
