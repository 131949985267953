import { FC, ReactNode, useState } from "react";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { IconButton } from "@amenda-components/App";

interface Props {
  disabled?: boolean;
  withLock?: boolean;
  initialScale: number;
  minimumScale: number;
  maximumScale: number;
  contentClass?: string;
  contentStyle?: any;
  centerZoomedOut?: boolean;
  centerOnInit?: boolean;
  limitToBounds?: boolean;
  wrapperClass?: string;
  wrapperStyle?: any;
  children: ReactNode;
}

export const PanPinchZoom: FC<Props> = ({
  children,
  initialScale,
  minimumScale,
  maximumScale,
  disabled = false,
  withLock = false,
  centerZoomedOut = false,
  centerOnInit = false,
  limitToBounds = false,
  ...rest
}) => {
  const [lockPanPinch, setLockPanPinch] = useState(disabled);

  const handleLockPanPinch = () => setLockPanPinch(!lockPanPinch);

  return (
    <div className="w-full h-full flex justify-center overflow-y-auto">
      <TransformWrapper
        disabled={lockPanPinch}
        initialScale={initialScale}
        minScale={minimumScale}
        maxScale={maximumScale}
        limitToBounds={limitToBounds}
        centerZoomedOut={centerZoomedOut}
        centerOnInit={centerOnInit}
        wheel={{
          excluded: ["amenda-disable-wheel", "amenda-disable-pan-pinch"],
        }}
        panning={{
          excluded: ["amenda-disable-panning"],
        }}
        doubleClick={{
          excluded: ["amenda-disable-double-click", "amenda-disable-pan-pinch"],
        }}
        pinch={{
          excluded: ["amenda-disable-pinch", "amenda-disable-pan-pinch"],
        }}
      >
        {withLock && (
          <IconButton
            variant="primary"
            className="z-30 m-2"
            Icon={lockPanPinch ? LockClosedIcon : LockOpenIcon}
            label="Edit Photo"
            onClick={handleLockPanPinch}
            disabled={disabled}
          />
        )}
        <TransformComponent {...rest}>{children}</TransformComponent>
      </TransformWrapper>
    </div>
  );
};
