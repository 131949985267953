import { FC, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { HeadlessTabs } from "@amenda-components/App";
import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { TabItem } from "@amenda-types";
import { adminSettingsNavigation } from "@amenda-constants";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  children: ReactNode;
}

export const AdminSettingsLayout: FC<Props> = ({ children, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const foundIndex = adminSettingsNavigation.findIndex(
    (tab) => tab.value === pathname,
  );
  const index = foundIndex >= 0 ? foundIndex : 0;

  const handleChange = (tab: TabItem) => {
    navigate(tab.value);
  };

  return (
    <MaxWidthLayout className={className}>
      <Helmet title={t("Admin Settings")} />
      <div className="flex justify-center">
        <div className="grid grid-cols-1 w-full">
          <div className="bg-white overflow-hidden pb-5 rounded sm:rounded-lg">
            <div className="flex justify-between items-center">
              <h3 className="amenda-page-title">{t("Admin Settings")}</h3>
            </div>
            <div className="px-0">
              <HeadlessTabs
                defaultTabIndex={index}
                tabListClassName="border-0"
                tabs={adminSettingsNavigation}
                tabClassName={(isSelected) =>
                  clsx(
                    "px-3 py-2 font-apercu text-sm flex space-x-1 items-center whitespace-nowrap focus:outline-none",
                    {
                      "bg-gray-900 text-white": isSelected,
                      "text-gray-900 hover:text-white hover:bg-gray-900":
                        !isSelected,
                    },
                  )
                }
                onChange={handleChange}
              >
                {() => <div className="mt-5">{children}</div>}
              </HeadlessTabs>
            </div>
          </div>
        </div>
      </div>
    </MaxWidthLayout>
  );
};
