import {
  ComponentTreeRenderer,
  FormAutoSaveBaseChildProps,
  FormAutoSaveSubmitProps,
  FormWrapper,
} from "@amenda-components/PageBuilder";
import { FC, ReactNode } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  GeneralPermissionTypes,
  NestedPageComponentProps,
} from "@amenda-types";
import {
  getComponentsFromForms,
  getValidationWithPermissions,
  groupComponentsByParent,
} from "@amenda-utils";
import {
  transformAttachmentToForm,
  transformFormToAttachment,
} from "@amenda-components/Gallery/common";
import {
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { Button } from "@amenda-components/App";
import { TrashIcon } from "lucide-react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  readOnly?: boolean;
  handleClose: () => void;
}

interface EditConstructionDetailsProps {
  components: any[];
  componentTree: NestedPageComponentProps;
  globalProps?: Record<string, any>;
  formValues?: Record<string, any>;
  resourceId?: string;
  children?: (
    props: Omit<
      FormAutoSaveBaseChildProps,
      "submitButtonRef" | "handleFormSubmit"
    >,
  ) => ReactNode;
  handleSave: (args: FormAutoSaveSubmitProps) => Promise<void>;
}

interface ReadOnlyConstructionDetailsProps {
  componentTree: NestedPageComponentProps;
  formValues?: Record<string, any>;
}

export const EditConstructionDetails: FC<EditConstructionDetailsProps> = ({
  components,
  componentTree,
  resourceId,
  globalProps = {},
  formValues = {},
  children,
  handleSave,
}) => {
  const inputSchema = getValidationWithPermissions(
    components,
    (components) => components,
  );
  const values = transformAttachmentToForm(formValues || {});
  const projectId = formValues?.project;

  if (!children) return null;
  return (
    <FormWrapper
      className="py-0"
      resourceId={resourceId}
      inputSchema={inputSchema}
      formComponentTree={componentTree}
      globalProps={{
        ...globalProps,
        keywordResourceId: projectId,
      }}
      values={values}
      onSubmit={handleSave}
    >
      {children}
    </FormWrapper>
  );
};

const ReadOnlyConstructionDetails: FC<ReadOnlyConstructionDetailsProps> = ({
  componentTree,
  formValues,
}) => {
  return (
    <ComponentTreeRenderer
      config={componentTree}
      globalProps={{
        data: formValues,
      }}
      readOnly={true}
    />
  );
};

export const ConstructionDetailsForm: FC<Props> = ({
  className,
  readOnly = false,
  handleClose,
}) => {
  const { t } = useTranslation();
  const forms = useProjectStore((state) => state.forms);
  const { updateAttachment, loading } = useUpdateAttachment();
  const setIsSavingAttachment = useAttachmentStore(
    (state) => state.setIsSavingAttachment,
  );
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const setOpenDeleteModal = useAttachmentStore(
    (state) => state.setOpenDeleteModal,
  );

  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );

  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const componentTree = groupComponentsByParent(components);

  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const onSubmit = async ({
    resourceId,
    dirtyData,
  }: FormAutoSaveSubmitProps) => {
    const input = transformFormToAttachment({
      form: dirtyData,
      resourceId,
    });

    if (isEmpty(dirtyData)) {
      return;
    }

    setIsSavingAttachment(true);
    await updateAttachment({
      input,
    });
    setIsSavingAttachment(false);
    handleClose();
  };

  return (
    <div className={clsx("w-full", className)}>
      {readOnly ? (
        <ReadOnlyConstructionDetails
          componentTree={componentTree}
          formValues={selectedAttachment?.formValues}
        />
      ) : (
        <EditConstructionDetails
          components={components}
          componentTree={componentTree}
          resourceId={selectedAttachment?.id}
          formValues={selectedAttachment?.formValues}
          globalProps={{
            loading,
          }}
          handleSave={onSubmit}
        >
          {() => (
            <div className="w-full flex flex-col space-y-2">
              <Button
                className="w-full justify-center"
                type="submit"
                variant="primary"
                loading={loading}
              >
                {t("Save Changes")}
              </Button>
              {mediaGeneralPermissions[GeneralPermissionTypes.Delete] && (
                <Button
                  className="w-full items-center justify-center space-x-1 bg-white text-red-600"
                  onClick={handleDelete}
                >
                  <span>{t("Delete")}</span>
                  <TrashIcon className="w-4 h-4" />
                </Button>
              )}
            </div>
          )}
        </EditConstructionDetails>
      )}
    </div>
  );
};
