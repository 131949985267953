import * as Sentry from "@sentry/react";

import { AllMaterials, AllMaterialsCollection } from "@amenda-pages/Materials";
import {
  AllProjectsCollectionPage,
  AllProjectsPage,
  CreateOrEditProjectPage,
  NotFoundPage,
  PrintProjectPage,
  PrintProjectTemplate,
  ProjectPage,
} from "@amenda-pages/Projects";
import {
  AttachmentsCollectionPage,
  AttachmentsPage,
} from "@amenda-pages/Gallery";
import {
  ConstructionDetailsCollectionPage,
  ConstructionDetailsPage,
  NewConstructionDetailsPage,
  ViewConstructionDetailsPage,
} from "@amenda-pages/Construction";
import {
  ContactsCollectionPage,
  ContactsPage,
  UsersCollectionPage,
  UsersPage,
} from "@amenda-pages/Contacts";
import {
  CreateOrEditRole,
  FormBuilderPage,
  ManageUsers,
  MyAccount,
  RegionalFactors,
  Roles,
  SecurityPage,
  SimilaritySearch,
  Subscription,
  Tenant,
} from "@amenda-pages/Settings";
import {
  DefaultLoginPage,
  LoginWithTokenPage,
  Logout,
  ProfileUpdatedSuccessPage,
  RegisterPage,
  RegistrationSuccessPage,
  ResetPassword,
  UpdateProfilePage,
} from "@amenda-pages/Auth";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import {
  PrivateRouteWrapper,
  PublicRouteWrapper,
} from "@amenda-components/Auth";

import { DashboardPage } from "@amenda-pages/Dashboard";
import { NotFoundRoute } from "@amenda-constants";
import PrimaryProviders from "./PrimaryProviders";
import { ShowDemoComponentsOrRedirect } from "@amenda-components/Demo";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const appRouter = sentryCreateBrowserRouter([
  {
    path: "/login/by-token",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <LoginWithTokenPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/login",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <DefaultLoginPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/signup",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <RegisterPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/signup/*",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <Outlet />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "complete-registration/success",
        element: <ProfileUpdatedSuccessPage />,
      },
      {
        path: "complete-registration",
        element: <UpdateProfilePage />,
      },
      {
        path: "success",
        element: <RegistrationSuccessPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/planungs_details",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ConstructionDetailsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/planungs_details/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "neu",
        element: <NewConstructionDetailsPage />,
      },
      {
        path: ":constructionDetailId/bearbeiten",
        element: <ViewConstructionDetailsPage />,
      },
      {
        path: ":constructionDetailId",
        element: <ViewConstructionDetailsPage />,
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <ConstructionDetailsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <ConstructionDetailsCollectionPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/einstellungen",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <MyAccount />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/einstellungen/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "security",
        element: <SecurityPage />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ManageUsers />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/admin/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "rollen/neu",
        element: <CreateOrEditRole />,
      },
      {
        path: "rollen/:roleId",
        element: <CreateOrEditRole />,
      },
      {
        path: "rollen",
        element: <Roles />,
      },
      {
        path: "konto",
        element: <Tenant />,
      },
      {
        path: "regional-factors",
        element: <RegionalFactors />,
      },
      {
        path: "similarity-search",
        element: <SimilaritySearch />,
      },
      {
        path: "form-builder",
        element: <FormBuilderPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/kollegen",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <UsersPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/kollegen/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <UsersCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <UsersCollectionPage />,
      },
      {
        path: ":userId",
        element: <UsersPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/kontaktverzeichnis",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ContactsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/kontaktverzeichnis/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <ContactsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <ContactsCollectionPage />,
      },
      {
        path: ":userId",
        element: <ContactsPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/einheitspreise",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AllMaterials />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/einheitspreise/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <AllMaterialsCollection />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <AllMaterialsCollection />,
      },
      {
        path: "neu",
        element: <AllMaterials />,
      },
      {
        path: ":materialId",
        element: <AllMaterials />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/medien",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AttachmentsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/medien/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <AttachmentsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <AttachmentsCollectionPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/projekte/:projectId/template/:templateId/templateData/:templateDataId/generate-pdf",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <PrintProjectTemplate />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte/:projectId/generate-pdf",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <PrintProjectPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AllProjectsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <AllProjectsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <AllProjectsCollectionPage />,
      },
      {
        path: "neu/allgemein",
        element: <CreateOrEditProjectPage />,
      },
      {
        path: ":projectId/bearbeiten",
        element: <CreateOrEditProjectPage />,
      },
      {
        path: ":projectId",
        element: <ProjectPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/demo-components",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ShowDemoComponentsOrRedirect />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/auth/reset-password",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <ResetPassword />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/logout",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Logout />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <DashboardPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: NotFoundRoute,
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <NotFoundPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "*",
    element: <Navigate to={NotFoundRoute} />,
  },
]);
