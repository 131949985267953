import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import { Select, TextField } from "@amenda-components/FormComponents";
import { getChangedValues, getFullName } from "@amenda-utils";
import { getUserStatus, getUserStatuses, processUserStatus } from "./common";
import { useUpdateUser, useUsersStore } from "@amenda-domains/mutations";

import { Modal } from "@amenda-components/App";
import { SystemRolesSelect } from "@amenda-components/Shared";
import { isEmpty } from "lodash";
import { useAssignSystemRole } from "@amenda-domains/mutations/settings";
import { useGetSystemRole } from "@amenda-domains/queries";
import { userInputSchema } from "@amenda-constants";
import { yupResolver } from "@hookform/resolvers/yup";

interface EditOfficeUserModalInput {
  email: string;
  status?: string;
  systemRole?: any;
  firstName: string;
  lastName: string;
}

export const EditOfficeUserModal: FC = () => {
  const { updateUser, loading } = useUpdateUser();
  const { assignSystemRole, loading: assignSystemRoleLoader } =
    useAssignSystemRole();
  const user = useUsersStore((state) => state.selectedUser);
  const modals = useUsersStore((state) => state.modals);
  const setOfficeUserModal = useUsersStore((state) => state.setOfficeUserModal);
  const [defaultRole, setDefaultRole] = useState<Record<string, any>>();
  const { getSystemRole } = useGetSystemRole();

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm<EditOfficeUserModalInput>({
    resolver: yupResolver(userInputSchema),
    values: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      status: getUserStatus(user),
      systemRole: defaultRole,
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const closeModal = () => {
    reset();
    setOfficeUserModal(false);
  };

  const handleAssignRole = async (roleId?: string) => {
    if (roleId) {
      await assignSystemRole({
        input: {
          roleId,
          userId: user.id,
        },
      });
    }
  };

  const handleUpdateUser = async (data: EditOfficeUserModalInput) => {
    if (isEmpty(data)) {
      return;
    }

    const { status, ...rest } = data;
    const input: Record<string, any> = {
      _id: user.id,
      uid: user.uid,
      fullName: getFullName(rest?.firstName, rest?.lastName),
      ...rest,
      ...processUserStatus(status),
    };

    await updateUser({
      input,
    });
  };

  const onSubmit: SubmitHandler<EditOfficeUserModalInput> = async (data) => {
    const { systemRole, ...rest } = getChangedValues<EditOfficeUserModalInput>(
      data,
      dirtyFields,
    );

    await handleUpdateUser(rest as any);
    await handleAssignRole(systemRole?.id);
    closeModal();
  };

  useEffect(() => {
    if (user?.id) {
      getSystemRole({
        usersIds: [user?.id],
        callback: setDefaultRole,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [user?.id, getSystemRole]);

  return (
    <Modal
      isOpen={modals.openEditOfficeUserModal}
      onClose={closeModal}
      onSuccess={handleSubmit(onSubmit)}
      title="Update User"
      successLabel="Update"
      withSuccess={true}
      size="md"
      loading={loading || assignSystemRoleLoader}
    >
      <div className="px-4 py-1 isolate -space-y-px rounded-md">
        <form id="edit-user">
          <div>
            <div className="grid grid-cols-1 gap-x-4">
              <div className="sm:col-span-3">
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="email"
                        type="email"
                        label="E-Mail Address"
                        onChange={onChange}
                        error={error?.message}
                        value={value}
                        readOnly
                      />
                    );
                  }}
                />
              </div>
              <div className="sm:col-span-3 grid grid-cols-2 gap-x-2">
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="firstName"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="firstname"
                          label="First Name"
                          onChange={onChange}
                          error={error?.message}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="lastName"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="lastName"
                          label="Last Name"
                          onChange={onChange}
                          error={error?.message}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="sm:col-span-3 grid grid-cols">
                <Controller
                  control={control}
                  name="status"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Select
                        id="status"
                        label="Status"
                        selectedOption={value}
                        error={error?.message}
                        options={getUserStatuses(user)}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
              <div className="sm:col-span-3 grid grid-cols">
                <SystemRolesSelect
                  id="systemRole"
                  label="Role"
                  control={control}
                  isClearable={true}
                  isMulti={false}
                  disabled={user?.isTenantAdmin}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
