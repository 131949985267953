import { gql, useMutation } from "urql";

import { AvailableNotificationTypes } from "@amenda-types";
import { TENANT_FRAGMENT } from "@amenda-domains/fragments/tenant";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useAppStore } from "@amenda-domains/mutations/app";
import { useNavigate } from "react-router-dom";

type State = {
  primaryTenant: Record<string, any>;
};
type Actions = {
  setPrimaryTenant: (tenant: Record<string, any>) => void;
};

export const useTenantStore = create(
  immer<State & Actions>((set, get) => ({
    primaryTenant: {},
    setPrimaryTenant: (tenant) =>
      set((state) => {
        state.primaryTenant = tenant;
      }),
  })),
);

export const CREATE_TENANT = gql`
  ${TENANT_FRAGMENT}
  mutation CreateTenant($input: NewTenantInput!) {
    createTenant(input: $input) {
      ...TenantFragment
    }
  }
`;

const UPDATE_TENANT = gql`
  ${TENANT_FRAGMENT}
  mutation UpdateTenant($input: UpdatedTenantRootInput!) {
    updateTenant(input: $input) {
      ...TenantFragment
    }
  }
`;

export const useCreateTenant = () => {
  const navigate = useNavigate();
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callCreateTenant] = useMutation(CREATE_TENANT);

  const createTenant = (variables: Record<string, any>) => {
    return callCreateTenant(variables)
      .then(({ data }) => {
        if (data?.createTenant) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Registration successful!",
          );
          navigate("/signup/success?onboarding=true");
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    createTenant,
    loading: result.fetching,
  };
};

export const useUpdateTenant = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const setPrimaryTenant = useTenantStore((state) => state.setPrimaryTenant);

  const [result, callUpdateTenant] = useMutation(UPDATE_TENANT);

  const updateTenant = (variables: Record<string, any>) => {
    return callUpdateTenant(variables)
      .then(({ data }) => {
        if (data?.updateTenant) {
          setPrimaryTenant(data?.updateTenant);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    updateTenant,
    updateTenantLoader: result.fetching,
  };
};
