import { FC, useEffect, useState } from "react";

import { CostEstimateExistingProjectModal } from "./CostEstimateExistingProjectModal";
import { CostEstimateGenerateProjectModal } from "./CostEstimateGenerateProjectModal";
import { CostEstimationModalContent } from "./CostEstimationModalContent";
import { FullScreenModal } from "@amenda-components/App";
import { useGetLatestBKIDate } from "@amenda-domains/queries";
import { useProjectStore } from "@amenda-domains/mutations";

export const CostEstimationModal: FC = () => {
  const openCostEstimateModal = useProjectStore(
    (state) => state.openCostEstimateModal,
  );
  const setCostEstimateModal = useProjectStore(
    (state) => state.setCostEstimateModal,
  );
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);
  const [isOpenExistingModal, setIsExistingOpenModal] = useState(false);
  const clearCostEstimateValues = useProjectStore(
    (state) => state.clearCostEstimateValues,
  );
  const costEstimationModalValues = useProjectStore(
    (state) => state.costEstimationModalValues,
  );
  const { getLatestBKIDate } = useGetLatestBKIDate();

  const handleOpenNewModal = () => setIsOpenNewModal(true);
  const handleOpenExistingModal = () => setIsExistingOpenModal(true);
  const closeCostEstimationModal = () => setCostEstimateModal(false);

  const cleanUpAndCloseCostEstimationModal = () => {
    setIsOpenNewModal(false);
    setIsExistingOpenModal(false);
    closeCostEstimationModal();
    clearCostEstimateValues();
  };

  useEffect(() => {
    getLatestBKIDate();
  }, [getLatestBKIDate]);

  return (
    <>
      <CostEstimateGenerateProjectModal
        isOpen={isOpenNewModal}
        costEstimateInput={costEstimationModalValues}
        closeModal={cleanUpAndCloseCostEstimationModal}
      />
      <CostEstimateExistingProjectModal
        isOpen={isOpenExistingModal}
        costEstimateInput={costEstimationModalValues}
        closeModal={cleanUpAndCloseCostEstimationModal}
      />
      <FullScreenModal
        isOpen={openCostEstimateModal}
        onClose={cleanUpAndCloseCostEstimationModal}
      >
        {openCostEstimateModal && (
          <CostEstimationModalContent
            handleOpenNewModal={handleOpenNewModal}
            handleOpenExistingModal={handleOpenExistingModal}
            closeCostEstimationModal={closeCostEstimationModal}
            cleanUpAndCloseCostEstimationModal={
              cleanUpAndCloseCostEstimationModal
            }
          />
        )}
      </FullScreenModal>
    </>
  );
};
