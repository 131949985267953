import { FC, ReactNode } from "react";

import clsx from "clsx";

interface Props {
  children: ReactNode;
  className?: string;
  tabIndex?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const SidebarContainer: FC<Props> = ({
  children,
  className = "w-96 border-l",
}) => {
  return (
    <div className="hidden lg:flex lg:shrink-0">
      <div
        className={clsx(
          "flex flex-col h-full border-gray-200 overflow-hidden",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const MainContainer: FC<Props> = ({
  children,
  className = "justify-between lg:px-5 lg:py-4 px-2",
  ...rest
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col min-w-0 flex-1 lg:overflow-hidden overflow-auto pt-4",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
