import { AvailableForms, PermissionComponentKey } from "@amenda-constants";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapper,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  getComponentsFromForms,
  getValidationWithPermissions,
  groupComponentsByParent,
} from "@amenda-utils";
import { transformAttachmentToForm, transformFormToAttachment } from "./common";
import {
  useAttachmentStore,
  useCreateAttachment,
  useProjectStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { isEmpty } from "lodash";
import { processFormPermissions } from "@amenda-components/Shared/common";

export const AttachmentDetailsForm = () => {
  const { createAttachment } = useCreateAttachment();
  const { updateAttachment } = useUpdateAttachment();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment
  );
  const setIsSavingAttachment = useAttachmentStore(
    (state) => state.setIsSavingAttachment
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {}
  );

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const formComponentTree = groupComponentsByParent(
    components,
    processFormPermissions(permissions)
  );
  const inputSchema = getValidationWithPermissions(
    components,
    processFormPermissions(permissions)
  );
  const values = transformAttachmentToForm(
    selectedAttachment?.formValues || {}
  );
  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media
  );
  const defaultShareType =
    mediaGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];
  const projectId = selectedAttachment?.formValues?.project;

  const onSubmit = async ({
    resourceId,
    dirtyData,
  }: FormAutoSaveSubmitProps) => {
    const handleSave = resourceId ? updateAttachment : createAttachment;
    const input = transformFormToAttachment({
      defaultShareType,
      form: dirtyData,
      resourceId,
    });

    if (isEmpty(dirtyData)) {
      return;
    }

    setIsSavingAttachment(true);
    await handleSave({
      input,
    });
    setIsSavingAttachment(false);
  };

  return (
    <FormAutoSaveWrapper
      className="py-4"
      resourceId={selectedAttachment?.id}
      inputSchema={inputSchema}
      formComponentTree={formComponentTree}
      globalProps={{
        keywordResourceId: projectId,
      }}
      values={values}
      onSubmit={onSubmit}
    />
  );
};
