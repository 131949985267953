import { FC } from "react";

interface Props {
  text: string;
  searchTerm?: string;
  className?: string;
}

export const TextHighlighter: FC<Props> = ({ text, searchTerm, className }) => {
  if (!searchTerm) {
    return <span className={className}>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <span className={className}>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="bg-yellow-300">
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
};
