import { QuickActions, RecentProjects } from "@amenda-components/Dashboard";

import { FC } from "react";
import { FrigadeBanner } from "@frigade/react";
import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations/users";

export const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const currentUser = useUsersStore((state) => state.currentUser);

  return (
    <MaxWidthLayout>
      <Helmet title={t("Dashboard")} />
      <div className="grid items-start grid-cols-1 md:grid-cols-4 h-full">
        <div className="grid grid-cols-1 md:col-span-3 md:border-r md:h-screen">
          <section className="flex flex-col justify-center text-gray-600">
            <div className="w-full mx-auto sm:px-2 h-full">
              <h1 className="amenda-page-title py-1 lg:py-6 px-2 ">
                Hallo, {currentUser?.firstName}!
              </h1>
              <div className="flex flex-col col-span-full xl:col-span-8 bg-white pt-2">
                <FrigadeBanner flowId="flow_iVss7YKuIMfjRHvb" />
                <QuickActions />
              </div>
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 h-full">
          <RecentProjects />
        </div>
      </div>
    </MaxWidthLayout>
  );
};
