import { BcfReader, Topic } from "@parametricos/bcf-js";

import { ErrorCode } from "react-dropzone";
import { FC } from "react";
import { FileUploaderDropZone } from "@amenda-components/FileUploads";
import { FileUploaderDropZoneValidator } from "@amenda-types";
import { FolderIcon } from "@heroicons/react/24/outline";
import { HelperMessage } from "@amenda-components/App";
import { devConsole } from "@amenda-utils";

interface Props {
  loading: boolean;
  openModal: boolean;
  rejectionReason: string | null;
  setOpenModal: (openModal: boolean) => void;
  setRejectionReason: (reason: string | null) => void;
  setLoading: (loading: boolean) => void;
  setBcfTopics: (bcfTopics: Topic[]) => void;
}

export const UploadBCFModal: FC<Props> = ({
  loading,
  openModal,
  rejectionReason,
  setOpenModal,
  setRejectionReason,
  setLoading,
  setBcfTopics,
}) => {
  const handleClose = () => {
    setRejectionReason(null);
    setOpenModal(false);
  };

  const onDrop = async (acceptedFiles: any[]) => {
    setLoading(true);
    setRejectionReason(null);
    const reader = new BcfReader();
    const file = acceptedFiles[0];
    await reader.read(file);
    await reader.read(file);
    setBcfTopics(reader.topics);
    setLoading(false);
    handleClose();
  };

  const onDropRejected = (rejectedFiles: any[]) => {
    devConsole?.warn(
      "amenda:FileUploaderDropZone, files rejected, ",
      rejectedFiles
    );
    setRejectionReason(
      rejectedFiles.length > 1
        ? "You can only upload 1 BCF File"
        : rejectedFiles[0]?.errors?.[0]?.message
    );
  };

  const validator = (file: File): FileUploaderDropZoneValidator => {
    const fileExt = file.name.split(".").pop();
    if (fileExt && !["bcf"].includes(fileExt)) {
      return {
        code: ErrorCode.FileInvalidType,
        message: `File is not a BCF file type`,
      };
    }

    return null;
  };

  return (
    <FileUploaderDropZone
      acceptedFileTypes={{}}
      noKeyboard={true}
      onDrop={onDrop}
      validator={validator}
      onDropRejected={onDropRejected}
      maxFiles={1}
    >
      <HelperMessage
        message="BCF Datei einlesen"
        helpText="Drag 'n' drop a BCF here, or click to select"
        Icon={FolderIcon}
      />
      {rejectionReason && (
        <p className="mb-2 text-sm text-red-600 text-center">
          {rejectionReason}
        </p>
      )}
    </FileUploaderDropZone>
  );
};
