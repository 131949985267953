import { FC, useEffect, useState } from "react";

import clsx from "clsx";
import { getIndicatorColor } from "./common";

interface Props {
  progress: number;
  showDefaultColor?: boolean;
  className?: string;
}

export const ProgressIndicator: FC<Props> = ({
  progress,
  showDefaultColor = false,
}) => {
  const [currProgress, setCurrProgress] = useState<number>(0);

  useEffect(() => {
    let value = currProgress;

    const timeout = setTimeout(() => {
      value += 5;

      if (value < progress) {
        setCurrProgress(value);
      } else {
        setCurrProgress(progress);
      }
    }, 50);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [progress, currProgress]);

  return (
    <div
      className={clsx(
        "flex items-center justify-center h-8 w-8 xxl:h-8 xxl:w-8 rounded-full",
        !showDefaultColor && getIndicatorColor(currProgress),
        {
          "text-gray-300 transition ease-in duration-300": showDefaultColor,
        },
      )}
      style={{
        background: `radial-gradient(closest-side, white 79%,transparent 80% 100%), conic-gradient(currentColor ${currProgress}%, #F0F0F0 0)`,
      }}
      role="status"
    >
      <span
        className={clsx(
          "font-apercu text-xxs md:text-xs lg:text-sm ease-in duration-300 pt-[0.5]",
          {
            "text-gray-300": showDefaultColor,
            "text-gray-700": !showDefaultColor,
          },
        )}
      >
        {progress}
      </span>
    </div>
  );
};
