import { AggregationOperations, AvailableWidgetTypes } from "@amenda-types";
import { AllowedCostGroupTypes, UID_SIZES } from "@amenda-constants";

import { AggregateOption } from "@amenda-components/Dashboard/common";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { uid } from "uid";

type WidgetProperty = {
  label: string;
  adornment?: string;
  isCostGroup?: boolean;
  type: AvailableWidgetTypes;
  componentId: string;
  operation: string;
  date?: string;
  costGroupType?: AllowedCostGroupTypes;
};

type WidgetType = {
  id: string;
  layout: any;
  properties: WidgetProperty;
};

type State = {
  widget: Record<string, any>;
  showAggregations: boolean;
  availableWidgets: any[];
  aggregationData: Record<string, any>;
};

type Actions = {
  setWidget: (widget: Record<string, any>) => void;
  setAggregationData: (props: { projects: any[] }) => void;
  setShowAggregations: (showAggregations: boolean) => void;
  removeWidget: (widget: WidgetType) => void;
  addWidget: (widget: AggregateOption) => void;
  updateWidgetLayout: (id: string, layout: any) => void;
  updateWidgetProps: (id: string, properties: Partial<WidgetProperty>) => void;
  updateWidgetsLayout: (layouts: any[]) => void;
};

const updateWidget = (
  widgets: WidgetType[],
  updater: (widget: WidgetType) => any,
) => {
  return widgets.map((widget) => {
    return {
      ...widget,
      ...updater(widget),
    };
  });
};

const processAggregationData = (projects: any[] = []) => {
  const data: Record<string, any[]> = {};
  const formValues = projects.map((p) => p.formValues);

  formValues.forEach((values) => {
    Object.entries(values).forEach(([key, value]) => {
      if (data[key]) {
        data[key].push(value);
      } else {
        data[key] = [value];
      }
    });
  });
  return data;
};

export const useDashboardStore = create(
  immer<State & Actions>((set) => ({
    showAggregations: false,
    availableWidgets: [],
    aggregationData: {},
    widget: {},
    setWidget: (widget) =>
      set((state) => {
        state.widget = widget;
        state.availableWidgets = widget.widgets || [];
      }),
    setShowAggregations: (showAggregations) =>
      set((state) => {
        state.showAggregations = showAggregations;
      }),
    setAggregationData: ({ projects }) =>
      set((state) => {
        state.aggregationData = processAggregationData(projects);
      }),
    removeWidget: (widget) =>
      set((state) => {
        state.availableWidgets = state.availableWidgets.filter(
          (w) => w.id !== widget.id,
        );
      }),
    updateWidgetsLayout: (layouts) =>
      set((state) => {
        state.availableWidgets = state.availableWidgets.map((widget) => {
          const layout =
            layouts.find((layout) => layout.i === widget.layout.i) ||
            widget.layout;
          return {
            ...widget,
            layout,
          };
        });
      }),
    addWidget: ({ value, ...rest }) =>
      set((state) => {
        const id = uid(UID_SIZES.sm);
        const properties: any = rest;
        properties.componentId = value;
        properties.type = AvailableWidgetTypes.Formulae;

        if (!rest.isCostGroup) {
          properties.operation = rest.operation || AggregationOperations.SUM;
        }

        state.availableWidgets = [
          ...state.availableWidgets,
          {
            id,
            properties,
            layout: {
              x: 0,
              y: 0,
              w: 2,
              h: 1,
              i: id,
            },
          },
        ];
      }),
    updateWidgetLayout: (widgetId, layout) =>
      set((state) => {
        state.availableWidgets = updateWidget(state.availableWidgets, (w) => {
          if (w.id === widgetId) {
            return {
              ...w,
              layout: {
                ...w.layout,
                ...layout,
              },
            };
          }
          return w;
        });
      }),
    updateWidgetProps: (widgetId, properties) =>
      set((state) => {
        state.availableWidgets = updateWidget(state.availableWidgets, (w) => {
          if (w.id === widgetId) {
            return {
              ...w,
              properties: {
                ...w.properties,
                ...properties,
              },
            };
          }
          return w;
        });
      }),
  })),
);
