import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { FC, useEffect } from "react";
import {
  firebaseApp,
  useAppStore,
  useTenantStore,
  useUpdateUser,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AvailableNotificationTypes } from "@amenda-types";
import { Button } from "@amenda-components/App";
import { FullColumn } from "@amenda-components/PageLayouts";
import { SecuritySchema } from "@amenda-constants";
import { SettingsProfileLayout } from "@amenda-components/Settings";
import { TextField } from "@amenda-components/FormComponents";
import { devConsole } from "@amenda-utils";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface SecurityInput {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const SecurityPage: FC = () => {
  const { t } = useTranslation();
  const { updateUser, loading } = useUpdateUser();
  const currentUser = useUsersStore((state) => state.currentUser);
  const showNotification = useAppStore((state) => state.showNotification);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm<SecurityInput>({
    resolver: yupResolver(SecuritySchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<SecurityInput> = async ({
    currentPassword,
    newPassword,
  }) => {
    try {
      const auth = getAuth(firebaseApp);
      auth.tenantId = primaryTenant?.tenantId;
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword,
      );

      if (!auth.currentUser) return;
      await reauthenticateWithCredential(auth.currentUser, credential).catch(
        (error) => {
          devConsole?.error(error);
          throw Error(t("Invalid password"));
        },
      );

      await updateUser({
        input: {
          _id: currentUser.id,
          uid: currentUser.uid,
          password: newPassword,
        },
      });
      showNotification(
        AvailableNotificationTypes.Success,
        "Password Successfully Updated",
      );
    } catch (error: any) {
      showNotification(AvailableNotificationTypes.Error, error.message);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <SettingsProfileLayout>
      <FullColumn>
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="amenda-settings-heading">{t("Password")}</h1>
              <p className="amenda-settings-subline">
                {t("Please enter your current Password to set a New one")}
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
        </div>
        <form id="security-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-row gap-1 mt-6 ">
            <div className=" sm:mt-0 sm:col-span-2">
              <div className="max-w-lg">
                <Controller
                  control={control}
                  name="currentPassword"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="current-password"
                        type="password"
                        onChange={onChange}
                        label="Current Password"
                        error={error?.message}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className=" sm:mt-0 sm:col-span-2">
              <div className="max-w-lg">
                <Controller
                  control={control}
                  name="newPassword"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="new-password"
                        type="password"
                        autoComplete="new-password"
                        onChange={onChange}
                        label="New Password"
                        error={error?.message}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="sm:mt-0 sm:col-span-2">
              <div className="max-w-lg">
                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="confirm-password"
                        type="password"
                        autoComplete="new-password"
                        onChange={onChange}
                        label="Repeat New Password"
                        error={error?.message}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="w-full mt-4">
          <Button
            type="submit"
            size="md"
            variant="primary"
            form="security-form"
            loading={loading}
          >
            {t("Save")}
          </Button>
        </div>
      </FullColumn>
    </SettingsProfileLayout>
  );
};
