import {
  Button,
  HelperMessage,
  LoaderWrapper,
  Modal,
  ResourceSelector,
} from "@amenda-components/App";
import { FC, useRef, useState } from "react";
import {
  MiniSearchField,
  ProjectCard,
} from "@amenda-components/SearchComponents";
import { debounce, isEmpty } from "lodash";
import {
  useAttachmentStore,
  useUpdateMultipleAttachments,
} from "@amenda-domains/mutations";

import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { DebounceTimes } from "@amenda-constants";
import { HoverVariants } from "@amenda-types";
import { transformFormToAttachmentBase } from "./common";
import { useSearchProjects } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

export const MoveAttachmentModal: FC = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState<any[]>([]);
  const isOpen = useAttachmentStore(
    (state) => state.modals.openMoveAttachmentsModal
  );
  const toggleMoveAttachmentsModal = useAttachmentStore(
    (state) => state.toggleMoveAttachmentsModal
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments
  );
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments
  );
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const { searchProjects, loading } = useSearchProjects();
  const { updateMultipleAttachments, loading: isSubmitting } =
    useUpdateMultipleAttachments();

  const handleClose = () => toggleMoveAttachmentsModal(false);

  const debounceSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (!isEmpty(searchTerm)) {
        await searchProjects({
          searchTerm,
          callback: (projects) => setProjects(projects ?? []),
        });
      }
    }, DebounceTimes.Search)
  ).current;

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await debounceSearch(searchTerm);
  };

  const handleSubmit = async () => {
    if (selectedProjectId) {
      const input = {
        ...transformFormToAttachmentBase({
          project: selectedProjectId,
        }),
        _ids: selectedAttachments,
      };
      await updateMultipleAttachments({
        input,
      });
    }

    setSearchTerm("");
    setProjects([]);
    clearSelectedAttachments();
    handleClose();
  };

  return (
    <Modal
      size="md"
      successLabel="Submit"
      className="lg:w-4/12 md:w-1/2 w-11/12"
      title="Move Attachments"
      isOpen={isOpen}
      loading={isSubmitting}
      closeModalFromTitle={true}
      onClose={handleClose}
      footerClassName="py-2 px-4 w-full flex justify-end border-t border-gray-300"
      footerChildren={({ loading, onClose }) => {
        return (
          <div className="flex items-center space-x-1">
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              type="button"
              variant="primary"
              disabled={isEmpty(selectedProjectId)}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          </div>
        );
      }}
    >
      <div className="w-full px-4 pb-10">
        <MiniSearchField
          className="bg-gray-100"
          placeholder="Search projects"
          value={searchTerm}
          onChange={handleSearch}
        />
        {loading ? (
          <LoaderWrapper className="h-32" spinnerSize="sm" />
        ) : isEmpty(projects) ? (
          <HelperMessage
            className="h-32"
            iconClassName="h-8 w-8 text-gray-800"
            Icon={BuildingOffice2Icon}
            message="No projects found"
          />
        ) : (
          <div className="flex flex-col space-y-2 pt-4 overflow-y-auto max-h-[70vh]">
            {projects.map((project) => (
              <ResourceSelector
                key={project.id}
                id={project.id}
                isSelected={selectedProjectId === project.id}
                onClick={() => setSelectedProjectId(project.id)}
                selectorType={HoverVariants.RadioButton}
              >
                <ProjectCard key={project.id} project={project} />
              </ResourceSelector>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
