import { Avatar, Link, Modal, Skeleton } from "@amenda-components/App";
import { FC, ReactNode, useEffect, useState } from "react";
import { StaticColumnProps, flattenUserDetails } from "./common";
import { getComponentsById, isRestricted } from "@amenda-utils";
import {
  useGetAllContacts,
  useGetContact,
  useGetUserProjects,
} from "@amenda-domains/queries";
import {
  useProjectStore,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { MiniCard } from "@amenda-components/Shared";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

type LinkedDataItem = (props: { value: any; onClick: () => void }) => ReactNode;

interface LinkedDataViewProps {
  values: any[];
  children: LinkedDataItem;
  onClick: (value: any) => void;
}

interface LinkedDataProps {
  user: any;
}

const LinkedDataView: FC<LinkedDataViewProps> = ({
  values,
  children,
  onClick,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  if (isEmpty(values)) {
    return null;
  }

  const selectedValues = values.slice(0, 2);
  selectedValues.push({
    label: "...",
  });

  return (
    <div className="w-full">
      <Link className="w-full text-left" variant="primary" onClick={handleOpen}>
        <span>{selectedValues.map((v) => v.label).join(", ")}</span>
      </Link>
      <Modal
        isOpen={openModal}
        isElevated={true}
        closeModalFromTitle={true}
        withCancel={false}
        size="md"
        className="lg:w-4/12 md:w-1/2 w-11/12"
        title="View Details"
        onClose={handleClose}
      >
        <div className="flex flex-wrap">
          {values.map(({ label, ...value }) => (
            <div key={label} className="pb-1 px-1 w-full">
              {children({
                value,
                onClick: () => onClick(value),
              })}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

const LinkedPersons: FC<LinkedDataProps> = ({ user }) => {
  const { ref, inView } = useInView();
  const [contacts, setContacts] = useState<any[]>([]);
  const navigate = useNavigate();
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );
  const { getAllContacts, loading } = useGetAllContacts();

  const companyId = user?.type === AllowedContactType.company && user?.id;

  const handleClick = (user: any) => {
    setSelectedUserId(user);
    setOpenUsersTableView(false);
    navigate(`/kontaktverzeichnis`);
  };

  useEffect(() => {
    if (companyId && inView) {
      getAllContacts({
        isDeleted: false,
        type: AllowedContactType.person,
        contactDetails: {
          company: [companyId],
        },
        callback: ({ users = [] }: any) => setContacts(users),
      });
    }

    return () => {
      setContacts([]);
    };
  }, [companyId, inView, getAllContacts]);

  if (loading) {
    return <Skeleton />;
  }
  return (
    <div ref={ref}>
      <LinkedDataView
        values={contacts.map((user) => ({
          ...user,
          label: [user?.lastName, user?.firstName].filter(Boolean).join(", "),
        }))}
        onClick={handleClick}
      >
        {({ value, onClick }) => (
          <div
            onClick={onClick}
            className="w-full flex cursor-pointer bg-white border border-gray-300 px-4 py-2 space-x-2 hover:bg-gray-50"
          >
            <div className="px-2">
              <Avatar
                className="h-8 w-8 text-base"
                name={`${value?.firstName} ${value?.lastName}`}
              />
            </div>
            <div>
              <p className="text-base font-medium text-gray-900">
                {`${value.firstName ?? ""} ${value.lastName ?? ""}`}
              </p>
              <p className="font-apercu text-sm text-left text-gray-500">
                {value?.address?.name ?? ""}
              </p>
            </div>
          </div>
        )}
      </LinkedDataView>
    </div>
  );
};

const getContactLabels = (contacts: any[]) => {
  return contacts.map((c) => {
    const fUser = flattenUserDetails(c);

    return {
      ...fUser,
      label: fUser?.companyName,
    };
  });
};

const LinkedCompany: FC<LinkedDataProps> = ({ user }) => {
  const { inView, ref } = useInView();
  const [contacts, setContacts] = useState<any[]>([]);
  const { getContact, loading } = useGetContact();
  const navigate = useNavigate();
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );

  const companyId = user?.company;

  const handleClick = (user: any) => {
    setSelectedUserId(user);
    setOpenUsersTableView(false);
    navigate(`/kontaktverzeichnis/unternehmen`);
  };

  useEffect(() => {
    if (companyId && inView) {
      getContact({
        id: companyId,
        callback: (c) => setContacts([c]),
      });
    }

    return () => {
      setContacts([]);
    };
  }, [inView, companyId, getContact]);

  if (loading) {
    return <Skeleton />;
  }
  return (
    <div ref={ref}>
      <LinkedDataView values={getContactLabels(contacts)} onClick={handleClick}>
        {({ value, onClick }) => (
          <div
            onClick={onClick}
            className="w-full flex cursor-pointer bg-white border border-gray-300 px-4 py-2 space-x-2 hover:bg-gray-50"
          >
            <div className="px-2">
              <Avatar className="h-8 w-8 text-base" name={value?.companyName} />
            </div>
            <div>
              <p className="text-base text-left font-medium text-gray-900 hover:text-gray-600">
                {value?.companyName}
              </p>
              <p className="font-apercu text-sm text-left text-gray-500">
                {value?.address?.name ?? ""}
              </p>
            </div>
          </div>
        )}
      </LinkedDataView>
    </div>
  );
};

const LinkedProjects: FC<LinkedDataProps> = ({ user }) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const { getUserProjects, loading } = useGetUserProjects();
  const [projects, setProjects] = useState<any[]>([]);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const components = useProjectStore((state) => state.projectFormComponents);

  const componentsById = getComponentsById(components);
  const projectName = componentsById["name"];
  const projectAddress = componentsById["address"];

  const handleClick = (project: any) => {
    navigate(`/projekte/${project.id}`);
  };

  useEffect(() => {
    if (user?.id && inView) {
      getUserProjects({
        limit: 5,
        isDeleted: false,
        userId: user.id,
        context: {},
        callback: setProjects,
      });
    }

    return () => {
      setProjects([]);
    };
  }, [user?.id, inView, getUserProjects]);

  if (loading) {
    return <Skeleton />;
  } else if (isRestricted(projectName, permissions)) {
    return null;
  }
  return (
    <div ref={ref}>
      <LinkedDataView
        values={projects.map((project) => ({
          ...project,
          label: project?.name,
        }))}
        onClick={handleClick}
      >
        {({ value, onClick }) => (
          <div className="w-full cursor-pointer" onClick={onClick}>
            <MiniCard
              image={value.galleryUrl}
              projectName={
                !isRestricted(projectName, permissions) && value?.name
              }
              projectDescription={
                !isRestricted(projectAddress, permissions) &&
                value?.address?.name
              }
              enableHighlighting={true}
            />
          </div>
        )}
      </LinkedDataView>
    </div>
  );
};

export const getLinkedContactDataColumns = (
  t: (label: string) => string,
): StaticColumnProps[] => [
  {
    id: "linkedProjects",
    label: t("Projects list"),
    getCell: (row: any) => {
      return <LinkedProjects user={row} />;
    },
  },
  {
    id: "linkedPersons",
    label: t("Related Persons"),
    contactType: AllowedContactType.company,
    getCell: (row: any) => {
      return <LinkedPersons user={row} />;
    },
  },
  {
    id: "linkedCompany",
    label: t("Related Company"),
    contactType: AllowedContactType.person,
    getCell: (row: any) => {
      return <LinkedCompany user={row} />;
    },
  },
];
