import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Button,
  IconButtonBase,
  Image,
  Modal,
  Tooltip,
} from "@amenda-components/App";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "@amenda-components/Settings/FormBuilderDndComponents";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
  restrictToFirstScrollableAncestor,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
  useAttachmentStore,
  useProjectStore,
  useSetProjectGalleryImage,
  useUpdateAttachment,
} from "@amenda-domains/mutations";

import { AvailableImageVariants } from "@amenda-types";
import { PinIcon } from "lucide-react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { getSliderAttachments } from "./common";
import { runAllAsync } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  attachments: any[];
}

type MediaSliderProps = {
  selectedProject: any;
  sortedAttachments: any[];
  setSortedAttachments: Dispatch<SetStateAction<any[]>>;
};

const getUpdatedAttachments = (
  attachments: any[],
  sortedAttachmentIds: string[],
) => {
  let sortedAttachments: any[] = [];

  sortedAttachmentIds.forEach((id) => {
    const attachment = attachments.find((a) => a.id === id);
    if (attachment) {
      sortedAttachments.push(attachment);
    }
  });
  return sortedAttachments;
};

const MediaSlider: FC<MediaSliderProps> = ({
  sortedAttachments,
  selectedProject,
  setSortedAttachments,
}) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { setProjectGallery, projectGalleryLoader } =
    useSetProjectGalleryImage();
  const { updateAttachment, loading } = useUpdateAttachment();

  const activeAttachment = sortedAttachments.find((a) => a.id === activeId);

  const showLoader = (attachment: any) => {
    return attachment.id === selectedId && (projectGalleryLoader || loading);
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const attachmentIds = active.data.current?.sortable?.items ?? [];
    const newIndex = attachmentIds.indexOf(over.id);
    const oldIndex = attachmentIds.indexOf(active.id);
    const updatedAttachmentIds: string[] = arrayMove(
      attachmentIds,
      oldIndex,
      newIndex,
    );

    setSortedAttachments(
      getUpdatedAttachments(sortedAttachments, updatedAttachmentIds),
    );
  };

  const handleRemoveAttachment = async (attachment: any) => {
    setSelectedId(attachment.id);
    await updateAttachment({
      input: {
        _id: attachment.id,
        formValues: {
          slider: { show: false },
        },
      },
    });
    setSortedAttachments((prev) => prev.filter((a) => a.id !== attachment.id));
    setSelectedId(null);
  };

  const handleSetDefaultPhoto = async (attachment: any) => {
    setSelectedId(attachment.id);
    if (selectedProject?.id) {
      await setProjectGallery({
        input: {
          projectId: selectedProject?.id,
          galleryUrl: attachment.url,
        },
      });
    }
    setSelectedId(null);
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToFirstScrollableAncestor]}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          const { active } = event;
          setActiveId(String(active.id));
        }}
        onDragEnd={(event) => {
          const { active, over } = event;
          setActiveId(null);
          if (over && isDragValid(active, over)) {
            handleDragEnd(active, over);
          }
        }}
      >
        <SortableContext
          id="mediaSlider"
          items={sortedAttachments.map((a) => a.id)}
          strategy={rectSortingStrategy}
        >
          <FormBuilderSortDroppable
            getClassName={(isOver) =>
              clsx("w-full h-full py-6 px-2 border border-dashed", {
                "border-gray-300": isOver,
                "border-transparent": !isOver,
              })
            }
          >
            <div className="grid grid-cols-4 gap-1">
              {sortedAttachments.map((a) => (
                <FormBuilderSortableItem id={a.id} key={a.id} data={{ a }}>
                  {(isDragging) => (
                    <div
                      className={clsx("h-full w-full relative group", {
                        invisible: isDragging,
                      })}
                    >
                      <div className="h-full w-full cursor-grab">
                        <Image
                          url={a.url}
                          isFullWidth={true}
                          size="pb-10/12"
                          magicSize="gallery"
                          background={clsx("bg-inherit", {
                            "animate-pulse": showLoader(a),
                          })}
                          isMasked={showLoader(a)}
                          enableHighlighting={false}
                          variant={AvailableImageVariants.cover}
                        />
                      </div>
                      <Tooltip
                        id="removeSliderItem"
                        position="top"
                        message="Remove photo"
                        positionStrategy="fixed"
                        className="absolute z-10 top-1 right-1 "
                      >
                        <IconButtonBase
                          variant="dangerAlt"
                          className="cursor-pointer invisible group-hover:visible"
                          onClick={() => handleRemoveAttachment(a)}
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </IconButtonBase>
                      </Tooltip>
                      <Tooltip
                        id={a.id + "pinSliderItem"}
                        position="top"
                        positionStrategy="fixed"
                        message={
                          selectedProject?.galleryUrl === a.url
                            ? "Default project photo"
                            : "Set as default project photo"
                        }
                        className="absolute -top-2 left-2"
                      >
                        <IconButtonBase
                          className={clsx(
                            "cursor-pointer group-hover:visible",
                            {
                              "visible text-blue-600":
                                selectedProject?.galleryUrl === a.url,
                              "invisible text-gray-800":
                                selectedProject?.galleryUrl !== a.url,
                            },
                          )}
                          onClick={() => handleSetDefaultPhoto(a)}
                        >
                          <PinIcon className="-rotate-45 w-5 h-5 z-10 stroke-[2]" />
                        </IconButtonBase>
                      </Tooltip>
                    </div>
                  )}
                </FormBuilderSortableItem>
              ))}
            </div>
          </FormBuilderSortDroppable>
        </SortableContext>
        <>
          {createPortal(
            <DragOverlay modifiers={[restrictToParentElement]}>
              {activeAttachment && (
                <FormBuilderDragOverlayItem className="shadow-sm">
                  <div className="h-full w-full relative group">
                    <div className="h-full w-full cursor-grab">
                      <Image
                        url={activeAttachment.url}
                        isFullWidth={true}
                        size="pb-10/12"
                        magicSize="gallery"
                        background="bg-white"
                        variant={AvailableImageVariants.cover}
                      />
                    </div>
                  </div>
                </FormBuilderDragOverlayItem>
              )}
            </DragOverlay>,
            document.body,
          )}
        </>
      </DndContext>
    </div>
  );
};

export const MediaSliderModal: FC<Props> = ({ attachments }) => {
  const { t } = useTranslation();
  const hasRunOnce = useRef(false);
  const [loading, setLoading] = useState(false);
  const openMediaSlider = useAttachmentStore(
    (state) => state.modals.openMediaSlider,
  );
  const setOpenMediaSlider = useAttachmentStore(
    (state) => state.setOpenMediaSlider,
  );
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const [sortedAttachments, setSortedAttachments] = useState<any[]>([]);
  const { updateAttachment } = useUpdateAttachment();

  const handleClose = () => {
    setOpenMediaSlider(false);
    clearSelectedAttachments();
    hasRunOnce.current = false;
  };

  const handleSave = async () => {
    const updatedSortedAttachments = sortedAttachments
      .map((a, i) => {
        return {
          id: a.id,
          order: i,
          slider: a?.formValues?.slider,
        };
      })
      .filter((a) => !a?.slider || a.slider.order !== a.order)
      .map((a) => ({ id: a.id, order: a.order }));

    setLoading(true);
    await runAllAsync(updatedSortedAttachments, async (a) =>
      updateAttachment({
        input: {
          _id: a.id,
          formValues: {
            slider: {
              order: a.order,
              show: true,
            },
          },
        },
      }),
    );
    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    if (!hasRunOnce.current && openMediaSlider) {
      setSortedAttachments(
        getSliderAttachments({
          attachments,
          selectedAttachments,
          galleryUrl: selectedProject?.galleryUrl,
        }),
      );
      hasRunOnce.current = true;
    }
  }, [
    attachments,
    openMediaSlider,
    selectedAttachments,
    selectedProject?.galleryUrl,
  ]);

  return (
    <Modal
      isOpen={openMediaSlider}
      onClose={handleClose}
      size="md"
      className="xl:w-5/12 lg:8/12 md:w-full"
      title="Media Slider"
      message="Click on any of the images to set the default project photo"
      closeModalFromTitle={true}
      loading={loading}
      footerClassName="py-2 px-4 w-full flex justify-end border-t border-gray-300"
      footerChildren={({ loading }) => (
        <div className="flex items-center space-x-1">
          <Button size="lg" withGroup onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button
            size="lg"
            withGroup
            variant="primary"
            loading={loading}
            onClick={handleSave}
          >
            {t("Save")}
          </Button>
        </div>
      )}
    >
      <div className="w-full h-full pb-12">
        <MediaSlider
          selectedProject={selectedProject}
          sortedAttachments={sortedAttachments}
          setSortedAttachments={setSortedAttachments}
        />
      </div>
    </Modal>
  );
};
