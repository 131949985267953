import { FC, useState } from "react";
import {
  useDeleteContacts,
  useFormStore,
  useUpdateCollection,
  useUsersStore,
} from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { ContactDirectoryProps } from "./types";
import { ContactsCollectionMenu } from "./ContactsCollectionMenu";
import { DeleteModal } from "@amenda-components/Shared";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = ContactDirectoryProps & {
  isCollection: boolean;
};

export const ContactsActionBar: FC<Props> = ({
  isCollection,
  canDeleteUsers,
  collectionType,
  getAllContacts,
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const selectedUsers = useUsersStore((state) => state.selectedUsers);
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);
  const selectedCollection = useFormStore(
    (state) => state.selectedCollectionByType[collectionType],
  );
  const { deleteContacts, loading } = useDeleteContacts();

  const handleCloseModal = () => setOpenModal(false);

  const handleArchiveUsers = async () => {
    if (selectedUsers.length > 0) {
      await deleteContacts({
        ids: selectedUsers,
      });
      handleCloseModal();
    }
  };

  const handleDeleteFromCollection = async () => {
    if (selectedCollection?.id) {
      await updateCollection({
        input: {
          _id: selectedCollection.id,
          aggregation: {
            $pull: {
              resourceIds: {
                $in: selectedUsers,
              },
            },
          },
        },
      });
      clearSelectedUsers();
    }
  };

  const handleDeleteUsers = async () => {
    if (isCollection) {
      await handleDeleteFromCollection();
    } else {
      setOpenModal(true);
    }
  };

  const handleClearSelectedUsers = () => {
    clearSelectedUsers();
  };

  const handleGetResourceIds = async (args: any) => {
    const users = await getAllContacts({
      userDetails: args,
      skipStorage: true,
    });
    return users?.map((user: any) => user.id);
  };

  return (
    <>
      <DeleteModal
        title="Delete contacts"
        description="Are you sure you want to delete these contacts?"
        loading={loading}
        openModal={openModal}
        handleDelete={handleArchiveUsers}
        handleCloseModal={handleCloseModal}
      />
      <div className="flex items-center justify-end">
        <div
          className={clsx("flex items-center", {
            "space-x-1": selectedUsers.length <= 0,
          })}
        >
          {selectedUsers.length > 0 && (
            <>
              <Button
                className="text-red-600"
                size="xs"
                onClick={handleClearSelectedUsers}
              >
                {t("Cancel")}
              </Button>
              <ContactsCollectionMenu
                isCollection={isCollection}
                canDeleteUsers={canDeleteUsers}
                isDeleting={updateCollectionLoader}
                resourceIds={selectedUsers}
                collectionType={collectionType}
                collectionRoute="/kontaktverzeichnis/sammlungen"
                getResourceIds={handleGetResourceIds}
                handleDeleteUsers={handleDeleteUsers}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
