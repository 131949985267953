import { AllowedCollectionType, AppRoutes } from "@amenda-types";
import {
  useGetAllContacts,
  useGetContact,
  useSearchContacts,
} from "@amenda-domains/queries";

import { BlockedRoutes } from "@amenda-constants";
import { ContactDirectoryWrapper } from "./ContactDirectoryWrapper";
import { FC } from "react";
import { useParams } from "react-router-dom";

export const ContactsPage: FC = () => {
  const { userId } = useParams<{ userId?: string }>();
  const { getAllContacts } = useGetAllContacts();
  const { getContact } = useGetContact();
  const { searchContacts } = useSearchContacts();

  return (
    <ContactDirectoryWrapper
      autoSelect={!userId}
      canFilterByType={true}
      canDeleteUsers={true}
      canCreateUser={true}
      singularTitle="Contact"
      pluralTitle="Contacts"
      collectionType={AllowedCollectionType.Contacts}
      rootRoute={AppRoutes.Contacts}
      collectionRoute={BlockedRoutes.ContactsCollection}
      getContact={getContact}
      getAllContacts={getAllContacts}
      searchContacts={searchContacts}
    />
  );
};
