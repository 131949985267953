import {
  FormBuilderReadOnlyHeader,
  HeaderSelects,
  HeaderText,
} from "./ReadOnlyHeaderComponents";
import {
  enrichHeaderComponentDefaults,
  getHeaderLayoutClasses,
} from "./common";
import {
  getComponentsById,
  getHeaderComponents,
  isRestricted,
  resolveHeaderComponentValues,
} from "@amenda-utils";

import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { PageComponentProps } from "@amenda-types";
import { ReadOnlyKeywordHeaders } from "@amenda-components/Shared/ReadOnlyKeywords";
import { ReadOnlyRegionalSelect } from "@amenda-components/Shared";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

interface Props {
  className?: string;
  components: any[];
  formValues?: Record<string, any>;
  permissions?: Record<string, any>;
  isFormBuilder?: boolean;
}

interface ReadonlyHeaderComponentProps {
  isFormBuilder: boolean;
  component: PageComponentProps;
  value?: any;
}

const ReadOnlyHeaderComponent: FC<ReadonlyHeaderComponentProps> = ({
  isFormBuilder,
  component,
  value,
}) => {
  const componentType = component?.component as FormComponentTypes;

  switch (true) {
    case isFormBuilder:
      return <FormBuilderReadOnlyHeader component={component} />;
    case [FormComponentTypes.Select, FormComponentTypes.MultiSelect].includes(
      componentType,
    ):
      value = value || [];
      return (
        <HeaderSelects
          component={component}
          values={Array.isArray(value) ? value : [value]}
        />
      );
    case FormComponentTypes.Keyword === componentType:
      return <ReadOnlyKeywordHeaders keywords={value} />;
    case FormComponentTypes.RegionalSelect === componentType:
      return <ReadOnlyRegionalSelect regionId={value} />;
    case FormComponentTypes.AddressSearch === componentType:
      return <HeaderText component={component} value={value?.name} />;
    default:
      return <HeaderText component={component} value={value} />;
  }
};

export const ReadOnlyHeaders: FC<Props> = ({
  components,
  formValues,
  permissions = {},
  className = "w-full",
  isFormBuilder = false,
}) => {
  const headerComponents = getHeaderComponents(components);
  const sortedHeaderComponents = enrichHeaderComponentDefaults(
    sortBy(headerComponents, "headerProperties.order"),
    isFormBuilder,
  );
  const allowedComponents = isFormBuilder
    ? sortedHeaderComponents
    : sortedHeaderComponents.filter(
        (component) => !isRestricted(component, permissions),
      );
  const componentsById = getComponentsById(allowedComponents);
  const resolvedValues = resolveHeaderComponentValues(
    componentsById,
    formValues ?? {},
  );

  return (
    <div className={clsx("grid grid-cols-6 pt-2", className)}>
      {allowedComponents.map((component) => (
        <div className={getHeaderLayoutClasses(component)} key={component.id}>
          <ReadOnlyHeaderComponent
            isFormBuilder={isFormBuilder}
            component={component}
            value={resolvedValues[component.id]}
          />
        </div>
      ))}
    </div>
  );
};
