import { FC, useEffect } from "react";

import { AttachmentsPageWrapper } from "./AttachmentsPageWrapper";
import { useAttachmentStore } from "@amenda-domains/mutations";

export const AttachmentsPage: FC = () => {
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );

  useEffect(() => {
    return () => {
      clearSelectedAttachments();
    };
  }, [clearSelectedAttachments]);

  return <AttachmentsPageWrapper />;
};
