import {
  AvailablePermissions,
  ComponentDisplayCondition,
  Conditions,
  FormTab,
  NestedPageComponentProps,
  PageComponentProps,
} from "@amenda-types";
import { Children, ReactNode } from "react";
import {
  CostEstimateValues,
  SetCostEstimateProps,
  TransformedCostEstimateValues,
} from "@amenda-domains/mutations";
import {
  FormComponentTypes,
  PDF_TOKEN,
  SidebarFilters,
  UID_SIZES,
} from "@amenda-constants";
import {
  cloneDeep,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isString,
  isUndefined,
  set,
  snakeCase,
  sortBy,
} from "lodash";

import { decodeToken } from "./auth";
import { flattenUserDetails } from "@amenda-components/Contacts/common";
import { getValuesByComponent } from "@amenda-components/SearchComponents";
import { isBgfComponent } from "@amenda-components/Projects/common";
import isObject from "lodash/isObject";
import merge from "lodash/merge";
import { uid } from "uid";

export interface ComponentProp {
  id: string;
  parentId: null | string;
  order: number;
  components?: any[];
}

const getComponentIds = (components: PageComponentProps[]) => {
  const componentIds: Record<string, number> = {};
  let parentComponentIndx = -1;
  components.forEach((component, indx: number) => {
    if (!component.parentId) {
      parentComponentIndx = indx;
    }
    componentIds[component.id] = indx;
  });
  return {
    componentIds,
    parentComponentIndx,
  };
};

const sortChildComponents = (
  components: PageComponentProps[],
  componentIds: Record<string, number>,
) => {
  components?.forEach((component: { parentId?: any }) => {
    const parentComponentIndx = componentIds[component?.parentId];
    if (isNil(parentComponentIndx)) {
      return;
    }
    const parentComponent = components[parentComponentIndx];
    const sortedComponents: any[] = sortBy(
      [...(parentComponent.components || []), component],
      "order",
    );
    parentComponent.components = sortedComponents;
  });
};

export const groupComponentsByParent = (
  components: PageComponentProps[] = [],
  processFormPermissions: (
    components: PageComponentProps[],
  ) => PageComponentProps[] = (components) => components,
) => {
  const filteredComponents = processFormPermissions(components);
  const clonedComponents = cloneDeep(filteredComponents);
  const { parentComponentIndx, componentIds } =
    getComponentIds(clonedComponents);
  let componentTree =
    parentComponentIndx >= 0 ? clonedComponents[parentComponentIndx] : {};
  sortChildComponents(clonedComponents, componentIds);
  return componentTree as NestedPageComponentProps;
};

const filterTitleAndSaveBtn = (components: PageComponentProps[]) => {
  return components.filter((component) => {
    if (isNil(component.component)) {
      return true;
    }
    return ![FormComponentTypes.Title, FormComponentTypes.Save].includes(
      component.component as FormComponentTypes,
    );
  });
};

export const generateReadableId = (name?: string) => {
  let readableId = uid(UID_SIZES.lg);

  if (name) {
    readableId = snakeCase(name) + "_" + uid(UID_SIZES.sm);
  }
  return readableId;
};

export const groupComponents = (components: PageComponentProps[]) => {
  const filteredComponents = filterTitleAndSaveBtn(components);
  const clonedComponents = cloneDeep(filteredComponents);
  const { componentIds } = getComponentIds(clonedComponents);
  const groupedComponents: NestedPageComponentProps[] = [];

  sortChildComponents(clonedComponents, componentIds);

  clonedComponents.forEach((component) => {
    if (!Boolean(component?.parentId)) {
      groupedComponents.push(component);
    }
  });
  return groupedComponents;
};

const getComponentsByParentId = ({
  parentId,
  components,
  foundComponents,
}: {
  parentId: string;
  components: PageComponentProps[];
  foundComponents: any[];
}) => {
  components.forEach((component) => {
    if (component.parentId === parentId) {
      foundComponents.push(component);
      getComponentsByParentId({
        components,
        foundComponents,
        parentId: component.id,
      });
    }
  });
};

export const getComponentsByParent = (
  parentId: string,
  components: PageComponentProps[],
) => {
  const foundComponents: any[] = [];
  getComponentsByParentId({
    parentId,
    components,
    foundComponents,
  });
  return foundComponents;
};

export const safeParse = (value: string, defaultValue = {}) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};

const isEmptyObject = (value: any) => {
  return isObject(value) && !isArray(value) && isEmpty(value);
};

export const sanitizeData = (values: any) => {
  const clonedValues = cloneDeep(values);
  Object.keys(clonedValues).forEach((key) => {
    const value = clonedValues[key];
    if (isUndefined(value) || isEmptyObject(value)) {
      delete clonedValues[key];
    }
  });
  return clonedValues;
};

export const sanitizeNestedData = (values: any) => {
  const clonedValues = cloneDeep(values);
  Object.keys(clonedValues).forEach((key) => {
    const value = clonedValues[key];
    if (isObject(value) && !isArray(value)) {
      clonedValues[key] = sanitizeNestedData(value);
    }
  });
  return sanitizeData(clonedValues);
};

export const transformFormComponents = (
  components: any[],
  formId: string,
): PageComponentProps[] => {
  return components.map(
    ({ componentId, id, type, validation, headerProperties, ...rest }) => {
      let component: any = {
        id: componentId,
        formId,
        ...rest,
      };

      if (!!type) {
        component["component"] = type;
      }
      if (!!validation) {
        const { componentId, ...rest } = validation;
        component.validation = {
          ...rest,
          id: componentId,
        };
      }
      if (!!headerProperties) {
        component.headerProperties = {
          ...headerProperties,
          component: headerProperties.type,
        };
      }
      return component;
    },
  );
};

export const getComponentsById = (components: any[]) => {
  let componentsById: Record<string, any> = {};
  components?.forEach((component) => {
    componentsById = {
      ...componentsById,
      [component.id]: component,
    };
  });
  return componentsById;
};

export const getContactComponents = (
  components: any[],
  permissions: Record<string, any>,
) => {
  const componentsById = getComponentsById(components);
  let contactComponents: Record<string, any> = {};
  Object.keys(componentsById).forEach((key) => {
    const component = componentsById[key];
    const parentComponent = componentsById[component?.parentId];

    if (
      component.properties?.contactType &&
      !isRestricted(component, permissions)
    ) {
      contactComponents = {
        ...contactComponents,
        [key]: {
          ...component,
          parentLabel: parentComponent?.properties?.label,
        },
      };
    }
  });
  return contactComponents;
};

export const groupComponentsByParentID = (
  contactComponents: Record<string, any>,
) => {
  let componentsByParentID: Record<string, any> = {};
  Object.keys(contactComponents).forEach((key) => {
    const component = contactComponents[key];
    const parentLabel = component?.parentLabel || component.parentId;
    componentsByParentID = {
      ...componentsByParentID,
      [parentLabel]: {
        ...(componentsByParentID[parentLabel] || {}),
        [key]: component,
      },
    };
  });
  return componentsByParentID;
};

const isInputComponent = (component: PageComponentProps) => {
  return !isNil(component.validation);
};

const showFirst4 = (indx: number) => {
  return indx < 4;
};

export const getDragAndDropValuesFromForm = (
  components: PageComponentProps[],
) => {
  let inputComponents = components.filter((component) =>
    isInputComponent(component),
  );

  return inputComponents.map(({ id, properties }, index) => ({
    value: id,
    label: properties?.label,
    id: index,
    show: showFirst4(index),
  }));
};

const isSearchableComponent = (component: PageComponentProps) =>
  !!component.isSearchable;

const isContactComponent = (component: PageComponentProps) =>
  !!component.isContact;

export const getSearchComponents = (components: PageComponentProps[]) => {
  return components.filter((component) => isSearchableComponent(component));
};

export const getSelectSearchComponents = (components: PageComponentProps[]) => {
  const selectComponents = [
    FormComponentTypes.Select,
    FormComponentTypes.MultiSelect,
    FormComponentTypes.Keyword,
    FormComponentTypes.Badges,
    FormComponentTypes.Checkbox,
    FormComponentTypes.RadioButton,
    FormComponentTypes.ColoredSelect,
    FormComponentTypes.SearchAndSelect,
    FormComponentTypes.SearchAndSelectProjects,
    FormComponentTypes.RegionalSelect,
  ];

  return components.filter(
    (component) =>
      isSearchableComponent(component) &&
      selectComponents.includes(component.component as FormComponentTypes),
  );
};

export const getContactComponentsId = (
  formComponents: Record<string, PageComponentProps[]>,
): any[] => {
  let contactComponents: PageComponentProps[] = [];
  Object.values(formComponents).forEach((components) => {
    contactComponents = contactComponents.concat(
      components.filter((component) => isContactComponent(component)),
    );
  });
  return contactComponents.map(({ id }) => id);
};

export const getKeywordComponentIds = (forms: any[]) => {
  let keywordComponentIds: any[] = [];
  forms?.forEach((form) => {
    form.components.forEach((component: any) => {
      if (component.component === FormComponentTypes.Keyword) {
        keywordComponentIds.push(component?.id);
      }
    });
  });
  return keywordComponentIds;
};

export const getKeywordsCount = ({
  componentId,
  formValues,
  keywordId,
}: {
  keywordId?: string;
  formValues: any[];
  componentId: string;
}) => {
  let usedKeywords: any[] = [];

  formValues?.forEach((values) => {
    usedKeywords.push(...(values?.[componentId] || []));
  });

  return usedKeywords.filter((kId) => kId === keywordId).length;
};

export const getCheckboxCount = ({
  componentId,
  formValues,
  value,
}: {
  value?: string;
  formValues: any[];
  componentId: string;
}) => {
  let usedOptions: any[] = getValuesByComponent(formValues, componentId);

  return usedOptions.filter((optionId) => optionId === value).length;
};

export const getHeaderComponents = (components: PageComponentProps[]) => {
  return components.filter((c) => c.headerProperties);
};

export const getNonHeaderComponents = (components: PageComponentProps[]) => {
  return components.filter((component) => isNil(component.headerProperties));
};

export const getAvailableRangeFromState = (state: any) => {
  const range: {
    path: string;
    start: string;
    end: string;
  }[] = [];
  Object.keys(state).forEach((key) => {
    if (!isEmpty(state[key]?.to) && !isEmpty(state[key]?.from)) {
      range.push({
        path: key,
        start: state[key].from,
        end: state[key].to,
      });
    }
  });
  return range;
};

export const arrayToObject = (value: any[], keyBy?: string) => {
  return value.reduce((acc, v, i) => {
    return {
      ...acc,
      [keyBy ? v[keyBy] : i]: v,
    };
  }, {});
};

export const objectToArray = (value: Record<string, any>) => {
  return Object.keys(value).map((key) => value[key]);
};

export const mergeJson = (
  source: Record<string, any> | any[],
  destination: Record<string, any>,
  keyBy?: string,
) => {
  if (isArray(source)) {
    let updatedSource = arrayToObject(source, keyBy);
    updatedSource = merge(updatedSource, destination);
    return objectToArray(updatedSource);
  } else {
    return merge(source, destination);
  }
};

export const getDefaultURL = () => {
  const url = new URL(window.location.origin);
  return url.hostname === "localhost" ? process.env.REACT_APP_URL : url.origin;
};

export const getIdTokenInUrl = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const decodedToken = decodeToken(token || "");

  if (decodedToken?.customToken) {
    return decodedToken;
  }
};

export const getPdfToken = async () => {
  const token = await localStorage.getItem(PDF_TOKEN);
  if (token) {
    return token;
  }
  return;
};

export const runAllAsync = async <T>(
  data: T[] = [],
  asyncCallback: (item: T) => Promise<any>,
) => await Promise.allSettled(data.map((item) => asyncCallback(item)));

export const getFormComponentsById = (components: any[]) => {
  return components.map((component) => {
    return {
      [component.id]: component,
    };
  });
};

export const getProjectStatus = (
  componentsById: Record<string, any>,
  status: string,
) => {
  const options = componentsById?.status?.properties?.options;
  if (options) {
    const foundOption = options.find((option: any) => {
      return option.value === status;
    });
    return foundOption?.label;
  }
  return status;
};

const resolveValuesFromSelects = (component: any, values: any) => {
  const options: any[] = component?.properties?.options;
  if (Array.isArray(values)) {
    return values?.map((value) => {
      return options?.find((option) => option.value === value) || value;
    });
  }
  return options?.find((option) => option.value === values);
};

const resolvedSelectAndMultiSelect = (component: any, value: any) => {
  if (
    [FormComponentTypes.Select, FormComponentTypes.MultiSelect].includes(
      component?.component,
    )
  ) {
    return {
      [component.id]: resolveValuesFromSelects(component, value),
    };
  }
  return {};
};

const resolveOthers = (component: any, value: any) => {
  if (
    [FormComponentTypes.Select, FormComponentTypes.MultiSelect].includes(
      component?.component,
    )
  ) {
    return {};
  }
  return {
    [component.id]: value,
  };
};

export const resolveHeaderComponentValues = (
  componentsById: Record<string, any>,
  formValues: Record<string, any>,
) => {
  let resolvedValues: Record<string, any> = {};

  Object.keys(componentsById).forEach((key) => {
    const component = componentsById[key];
    const value = formValues?.[key];

    resolvedValues = {
      ...resolvedValues,
      ...resolveOthers(component, value),
      ...resolvedSelectAndMultiSelect(component, value),
    };
  });

  return resolvedValues;
};

export const devConsole =
  process.env.NODE_ENV !== "production" ? console : undefined;

const groupSearchFilters = (searchFilters: Record<string, SidebarFilters>) => {
  let groupedSearchFilters: Record<string, any> = {};
  Object.keys(searchFilters).forEach((key) => {
    const { filterType, value } = searchFilters[key];
    groupedSearchFilters = {
      ...groupedSearchFilters,
      [filterType]: {
        ...groupedSearchFilters?.[filterType],
        [key]: value,
      },
    };
  });
  return groupedSearchFilters;
};

const transformArrayFilters = (key: string, sidebarFilters: SidebarFilters) => {
  const { value, filterType } = sidebarFilters;

  if (isArray(value) && !isEmpty(value)) {
    return {
      [key]: {
        value,
        filterType,
      },
    };
  }
  return {};
};

const transformStringFilters = (
  key: string,
  sidebarFilters: SidebarFilters,
) => {
  const { value, filterType } = sidebarFilters;

  if (isString(value) && value) {
    return {
      [key]: {
        value,
        filterType,
      },
    };
  }
  return {};
};

const transformAddressFilters = (
  key: string,
  sidebarFilters: SidebarFilters,
) => {
  const { value, filterType, componentType } = sidebarFilters;

  if (componentType === FormComponentTypes.AddressSearch && value) {
    return {
      [key]: {
        value: value.name,
        filterType,
      },
    };
  }
  return {};
};

const addToPath = (object: Record<string, any>, path: string, value: any) => {
  if (value) {
    set(object, path, value);
  }
};

const transformRangeFilters = (key: string, sidebarFilters: SidebarFilters) => {
  const { value, filterType, componentType, isNumberInputField } =
    sidebarFilters;

  if (value.from || value.to) {
    let filterValue: Record<string, any> = {};

    if (isNumberInputField) {
      addToPath(filterValue, "$gte", value.from && Number(value.from));
      addToPath(filterValue, "$lte", value.to && Number(value.to));
    } else if (componentType === FormComponentTypes.DatePickerRange) {
      addToPath(filterValue, "from.$gte", value.from);
      addToPath(filterValue, "to.$lte", value.to);
    } else if (componentType === FormComponentTypes.Hidden) {
      addToPath(filterValue, "height.$lte", value.from && Number(value.from));
      addToPath(filterValue, "width.$lte", value.to && Number(value.to));
    }

    return {
      [key]: {
        filterType,
        value: filterValue,
      },
    };
  }
  return {};
};

export const transformSearchFilters = (
  sidebarFilters?: Record<string, SidebarFilters>,
) => {
  let transformedSidebarFilter: Record<string, any> = {};

  if (isEmpty(sidebarFilters)) return transformedSidebarFilter;
  Object.keys(sidebarFilters).forEach((key) => {
    transformedSidebarFilter = {
      ...transformedSidebarFilter,
      ...transformArrayFilters(key, sidebarFilters[key]),
      ...transformAddressFilters(key, sidebarFilters[key]),
      ...transformStringFilters(key, sidebarFilters[key]),
      ...transformRangeFilters(key, sidebarFilters[key]),
    };
  });

  return groupSearchFilters(transformedSidebarFilter);
};

export const getPrintSectionComponents = (components: any[]) => {
  return components?.filter((component) => !component.headerProperties);
};

export const parseComponentHeightAndWidth = (style: any) => {
  const width = parseInt(style.width.slice(0, -2));
  const height = parseInt(style.height.slice(0, -2));
  return { width, height };
};

export const batchHandler = (items: any[], callback: (item: any) => void) => {
  const batchSize = 10;
  let start = 0;
  let end = batchSize;

  while (start < items.length) {
    const batch = items.slice(start, end);
    batch.forEach((item) => {
      callback(item);
    });
    start = end;
    end = end + batchSize;
  }
};

export const getComponentsFromForms = (forms?: FormTab[]) => {
  let components: any[] = [];
  forms?.forEach((form) => {
    if (form.components) {
      components = components.concat(form.components);
    }
  });
  return components;
};

// rewrite getChangeValues with generics for values
export const getChangedValues = <T>(
  values: Record<string, any>,
  dirtyFields: Record<string, boolean>,
) => {
  const changedValues: Record<string, any> = {};

  Object.keys(dirtyFields).forEach((key) => {
    changedValues[key] = values[key];
  });

  return changedValues as T;
};

export const isRestricted = (
  component: any,
  permissions: Record<string, Record<string, AvailablePermissions>>,
) => {
  return (
    permissions?.[component?.formId]?.[component.id] ===
    AvailablePermissions.Restricted
  );
};

export const getSelectableSearchComponents = (
  searchableComponents: any[],
  prefix = "",
) => {
  let values: any = {};
  searchableComponents
    .filter((component: any) =>
      [
        FormComponentTypes.Keyword,
        FormComponentTypes.Select,
        FormComponentTypes.MultiSelect,
      ].includes(component?.component),
    )
    .forEach((component: any) => {
      const key = [prefix, component.id].filter(Boolean).join(".");
      values = { ...values, [key]: 1 };
    });
  return values;
};

export const isUrl = (value: string) => {
  const WebRegex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/i;

  return WebRegex.test(value);
};

export const processConditionBase = (
  { value: expectedValue, condition }: ComponentDisplayCondition,
  currentValue: any,
) => {
  let passes = false;

  switch (condition) {
    case Conditions.IsEqual:
      passes = expectedValue === currentValue;
      break;
    case Conditions.Includes:
      passes = Array.isArray(expectedValue)
        ? expectedValue.includes(String(currentValue))
        : String(expectedValue).includes(String(currentValue));
      break;
    case Conditions.GreaterThan:
      passes = Number(currentValue) > Number(expectedValue);
      break;
    case Conditions.LessThan:
      passes = Number(currentValue) < Number(expectedValue);
      break;
  }
  return passes;
};

const processConditions = (
  condition: ComponentDisplayCondition,
  values: Record<string, any>,
) => {
  const { when } = condition;
  return processConditionBase(condition, values[when]);
};

export const isConditionTrue = (
  conditions: ComponentDisplayCondition | ComponentDisplayCondition[],
  values: Record<string, any>,
) => {
  if (!Array.isArray(conditions)) {
    return processConditions(conditions, values);
  }
  const conditionValues: boolean[] = [];
  let foundAndCondition = false;
  conditions.forEach((condition, i) => {
    const currentValue = processConditions(condition, values);
    if (Boolean(condition?.and) && i > 0) {
      const previousValue = conditionValues[i - 1];
      conditionValues.push(previousValue && currentValue);
      foundAndCondition = true;
    } else {
      conditionValues.push(currentValue);
    }
  });
  return foundAndCondition
    ? conditionValues.every((v) => Boolean(v))
    : conditionValues.some(Boolean);
};

export const getComponentsWithProperty = (
  key: string,
  components: PageComponentProps[] = [],
) => {
  return components.filter((c) => {
    if (!c?.component) {
      return true;
    }
    return Boolean(c[key as keyof PageComponentProps]);
  });
};

export const getComponentsWithCommonValues = (
  components: PageComponentProps[] = [],
) => {
  return components.filter(
    (c) =>
      c?.component &&
      [
        FormComponentTypes.Badges,
        FormComponentTypes.Checkbox,
        FormComponentTypes.RadioButton,
        FormComponentTypes.Select,
        FormComponentTypes.Keyword,
        FormComponentTypes.MultiSelect,
        FormComponentTypes.RegionalSelect,
        FormComponentTypes.SearchAndSelect,
      ].includes(c.component as FormComponentTypes),
  );
};

export const transformCommonComponentValues = (
  components: PageComponentProps[],
  values: any,
) => {
  let transformedValues: Record<string, any> = {};
  const data = values ?? {};

  Object.keys(data).forEach((key) => {
    if (isNil(data[key])) return;
    const component = components.find((c) => c.id === key);
    if (!Boolean(component?.component)) return;
    if (
      [
        FormComponentTypes.Select,
        FormComponentTypes.RadioButton,
        FormComponentTypes.RegionalSelect,
      ].includes(component?.component as FormComponentTypes)
    ) {
      transformedValues[key] = Array.isArray(data[key])
        ? data[key][0]
        : data[key];
    } else if (
      component?.component === FormComponentTypes.SearchAndSelect &&
      !Boolean(component?.properties?.isMulti)
    ) {
      transformedValues[key] = Array.isArray(data[key])
        ? data[key][0]
        : data[key];
    } else if (
      component?.component === FormComponentTypes.Badges &&
      component?.properties?.isMulti === false
    ) {
      transformedValues[key] = Array.isArray(data[key])
        ? data[key][0]
        : data[key];
    } else {
      transformedValues[key] = data[key];
    }
  });

  return transformedValues;
};

export const isFormTabDisabled = (
  shouldDisplay: boolean,
  otherChecks?: boolean,
) => {
  if (shouldDisplay) {
    return otherChecks;
  }
  return true;
};

export const processFormTabsDisplay = (forms: FormTab[], values: any) => {
  return forms.map(({ id, name, order, ...rest }) => {
    const conditions = rest?.properties?.display;

    return {
      order,
      value: id,
      label: name,
      shouldDisplay:
        isNil(conditions) || isEmpty(conditions)
          ? true
          : isConditionTrue(rest?.properties?.display, values),
    };
  });
};

export const processDisplayComponents = (
  values: any,
  components: PageComponentProps[] = [],
) => {
  const availableComponents: PageComponentProps[] = [];

  components.forEach((c) => {
    if (
      isEmpty(c?.display) ||
      (c?.display && isConditionTrue(c?.display, values))
    ) {
      availableComponents.push(c);
    }
  });

  return availableComponents;
};

export const getFormDisplayComponentIds = (
  components: PageComponentProps[] = [],
) => {
  const displayComponentIds: string[] = [];

  components
    .filter((c) => !isEmpty(c?.display))
    .forEach((c) => {
      c.display?.forEach((condition) => {
        if (!displayComponentIds.includes(condition.when)) {
          displayComponentIds.push(condition.when);
        }
      });
    });

  return { displayComponentIds };
};

export const getValuesFromWatcher = (
  componentIds: string[],
  watcher: any[] = [],
) => {
  const values: Record<string, any> = {};

  componentIds.forEach((id, i) => {
    values[id] = watcher[i];
  });

  return values;
};

const appendToPath = (field: "EMAIL" | "TEL", values: any[]) => {
  let path = "";

  values.forEach((v) => {
    path += `${field};TYPE=${v.label?.toUpperCase?.() || "OTHER"}:${v.value}\n`;
  });

  return path;
};

const generateVCard = (data: any) => {
  const fullName = [data.firstName, data.lastName].filter(Boolean).join(" ");
  const phone = appendToPath("TEL", data.phone);
  const email = appendToPath("EMAIL", data.email);
  const org = data.company?.companyName ?? "";

  return `BEGIN:VCARD
VERSION:3.0
FN:${fullName}
ORG:${org}
${phone}
${email}
NOTE:${data.note || ""}
END:VCARD`;
};

export const downloadVCard = (data: any, name = "contact") => {
  const vCardData = generateVCard(data);
  const filename = `${name}.vcf`;

  const blob = new Blob([vCardData], { type: "text/vcard" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const getGroupableFromForms = (forms: FormTab[]) => {
  const components = getComponentsFromForms(forms);
  return components.filter((component) => Boolean(component.isGroupable));
};

const addMaterialToGroup = (existingMaterials: any[] = [], material?: any) => {
  const materials = [...existingMaterials];
  const hasMaterial = existingMaterials.some((u) => u._id === material?._id);

  if (material && !hasMaterial) {
    materials.push({
      ...material,
      id: material._id,
    });
  }

  return materials;
};

const getGroupedMaterials = (materials: any[]) => {
  const groupedMaterials: Record<string, any> = {};

  batchHandler(materials, (m) => {
    const groupIds = m._id;

    Object.keys(groupIds).forEach((key) => {
      const ids = groupIds[key];

      if (ids && Array.isArray(ids)) {
        ids.forEach((id) => {
          const materials = addMaterialToGroup(
            groupedMaterials[id]?.materials,
            m?.materials,
          );

          groupedMaterials[id] = {
            id: JSON.stringify(id),
            ...groupedMaterials[id],
            materials,
            componentId: key,
            componentValue: id,
            count: materials.length,
          };
        });
      } else if (isString(ids)) {
        const materials = addMaterialToGroup(
          groupedMaterials[ids]?.materials,
          m?.materials,
        );

        groupedMaterials[ids] = {
          id: JSON.stringify(ids),
          ...groupedMaterials[ids],
          materials,
          componentId: key,
          componentValue: ids,
          count: materials.length,
        };
      }
    });
  });

  return groupedMaterials;
};

export const handleGroupedMaterials = (materials: any[]) => {
  const groupedMaterials = getGroupedMaterials(materials);

  return Object.values(groupedMaterials).reduce((acc, value) => {
    acc.push(value);
    return acc;
  }, []);
};

const addUserToGroup = (existingUsers: any[] = [], userGroup?: any) => {
  const users = [...existingUsers];
  const groupUsers = userGroup?.users ?? userGroup?.contacts;
  const hasUser = existingUsers.some((u) => u._id === groupUsers?._id);

  if (groupUsers && !hasUser) {
    users.push(
      flattenUserDetails({
        ...groupUsers,
        id: groupUsers._id,
      }),
    );
  }

  return users;
};

const getGroupedUsers = (users: any[], componentFilters: any = {}) => {
  const groupedUsers: Record<string, any> = {};

  batchHandler(users, (u) => {
    const groupIds = u._id;

    Object.keys(groupIds).forEach((key) => {
      const ids = groupIds[key];
      const filterValues = componentFilters[key]?.value;

      if (ids && Array.isArray(ids)) {
        ids
          .filter((id) => {
            if (isEmpty(filterValues)) {
              return true;
            }
            return filterValues.includes(id);
          })
          .forEach((id) => {
            const users = addUserToGroup(groupedUsers[id]?.users, u);

            groupedUsers[id] = {
              id: JSON.stringify(id),
              ...groupedUsers[id],
              users,
              componentId: key,
              componentValue: id,
              count: users.length,
            };
          });
      } else if (isString(ids)) {
        const users = addUserToGroup(groupedUsers[ids]?.users, u);

        if (filterValues && filterValues !== ids) {
          return true;
        }

        groupedUsers[ids] = {
          id: JSON.stringify(ids),
          ...groupedUsers[ids],
          users,
          componentId: key,
          componentValue: ids,
          count: users.length,
        };
      }
    });
  });

  return groupedUsers;
};

export const handleGroupedUsers = (users: any[], componentFilters?: any) => {
  const groupedUsers = getGroupedUsers(users, componentFilters);
  return Object.values(groupedUsers).reduce((acc, value) => {
    acc.push(value);
    return acc;
  }, []);
};

export const handleTableGroupedUsers = (
  users: any[],
  componentFilters?: any,
) => {
  const groupedUsers = getGroupedUsers(users, componentFilters);

  return Object.keys(groupedUsers).reduce((acc: any[], key) => {
    acc.push({
      ...groupedUsers[key],
      subRows: groupedUsers[key].users,
    });
    return acc;
  }, []);
};

export const getFullName = (firstName?: string, lastName?: string) => {
  const name = [firstName, lastName]
    .filter(Boolean)
    .map((n) => n?.trim())
    .join(" ");
  return name.length > 0 ? name : undefined;
};

export const readFileAsync = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => reject("File reading was aborted");
    reader.onerror = () => reject("File reading has failed");
    reader.onload = () => resolve(reader.result);

    reader.readAsText(file);
  });
};

export const isChildrenEmpty = (children: ReactNode) => {
  let childrenIsEmpty = false;

  Children.forEach(children, (child, i) => {
    if (i === 0 || childrenIsEmpty) {
      return;
    }
    childrenIsEmpty = isEmpty(child);
  });
  return childrenIsEmpty;
};

export const isSafeCollection = (
  isCollection?: boolean,
  resourceIds?: string[],
) => {
  if (Boolean(isCollection)) {
    return !isEmpty(resourceIds);
  }
  return true;
};

export const isMobile = (window?: Window) => {
  const viewPortWidth = window?.innerWidth;
  const minWidthTablet = 640;
  return viewPortWidth && viewPortWidth < minWidthTablet;
};

export const getSearchAggregation = (
  autoComplete: any,
  match: Record<string, any>,
) => [
  {
    $search: {
      index: "autocomplete",
      compound: {
        should: [...autoComplete],
      },
    },
  },
  match,
  {
    $addFields: {
      score: {
        $meta: "searchScore",
      },
    },
  },
  {
    $setWindowFields: {
      output: {
        maxScore: {
          $max: "$score",
        },
      },
    },
  },
  {
    $addFields: {
      normalizedScore: {
        $divide: ["$score", "$maxScore"],
      },
    },
  },
];

export const getSelectedNavigationFilters = (filters?: Record<string, any>) => {
  return Object.entries(filters ?? {}).flatMap(([key, data]) => {
    if (!data?.display?.options) {
      return [];
    }
    if (Array.isArray(data.display.options)) {
      return data.display.options.map((option: any) => ({
        id: key,
        value: option.value,
        label: option.label,
      }));
    }
    return {
      id: key,
      value: data.value,
      label: data.display.options.label,
    };
  });
};

type CostEstimateByKey = Omit<TransformedCostEstimateValues, "percentageCost">;

const getConsiderationValue = ({
  estimationMode,
  costGroupKey,
  bgfComponentId = "",
  componentByCode,
  considerationValues,
}: {
  costGroupKey: number;
  estimationMode?: boolean;
  bgfComponentId?: string;
  componentByCode: Record<string, any>;
  considerationValues: Record<string, number>;
}) => {
  if (!Boolean(estimationMode)) {
    return considerationValues[bgfComponentId] ?? 1;
  }
  const component = componentByCode[String(costGroupKey)];
  const referenceQuantity = component?.properties?.referenceQuantity;

  return considerationValues[referenceQuantity] ?? 1;
};

type AddInferredParentCostEstimatesProps = Pick<
  SetCostEstimateProps,
  "componentsByCode" | "inferredCalcCostGroups"
> &
  ProcessCostEstimateResultsResponse;

const addInferredParentCostEstimates = ({
  costEstimatesByKey,
  componentsByCode,
  inferredCalcCostGroups,
  parentCostEstimatesByKey: parentCostEstimates,
}: AddInferredParentCostEstimatesProps) => {
  const parentCostEstimatesByKey = { ...parentCostEstimates };
  const costGroups = inferredCalcCostGroups.filter((key) => {
    const parentKey = componentsByCode[String(key)];
    return Boolean(parentKey);
  });

  costGroups.forEach((key) => {
    const childEstimates = parentCostEstimatesByKey[String(key)];
    const parentKey = componentsByCode[String(key)];
    const parentEstimates = parentCostEstimatesByKey[String(parentKey)];

    if (parentKey) {
      const costGroupTotal =
        (parentEstimates?.costGroupTotal ?? 0) +
        (childEstimates?.costGroupTotal ?? 0);
      const costGroupCount = Math.min(
        ...[
          parentEstimates?.costGroupCount,
          childEstimates?.costGroupCount,
        ].filter(isNumber),
      );

      parentCostEstimatesByKey[String(parentKey)] = {
        costGroupCount,
        costGroupTotal,
      };
    }
  });

  return {
    costEstimatesByKey,
    parentCostEstimatesByKey,
  };
};

type ProcessParentCostEstimates = SetCostEstimateProps &
  ProcessCostEstimateResultsResponse;

const processParentCostEstimates = ({
  estimationMode,
  componentByCode,
  costEstimatesByKey,
  considerationValues,
  costGroupQuantityComponents,
  parentCostEstimatesByKey: parentCostEstimates,
}: ProcessParentCostEstimates) => {
  const parentCostEstimatesByKey = { ...parentCostEstimates };
  const bgfComponent = costGroupQuantityComponents?.find(isBgfComponent);

  Object.keys(parentCostEstimatesByKey).forEach((key) => {
    const considerationValue = getConsiderationValue({
      estimationMode,
      componentByCode,
      considerationValues,
      costGroupKey: Number(key),
      bgfComponentId: bgfComponent?.id,
    });

    parentCostEstimatesByKey[key].costGroupAverage =
      (parentCostEstimatesByKey[key].costGroupTotal ?? 0) / considerationValue;
  });

  return {
    costEstimatesByKey,
    parentCostEstimatesByKey,
  };
};

type ProcessCostEstimateResultsResponse = {
  costEstimatesByKey: Record<string, CostEstimateByKey>;
  parentCostEstimatesByKey: Record<string, Partial<CostEstimateValues>>;
};

const processCostEstimateResults = ({
  estimationMode,
  totalProjects,
  costEstimateValues,
  componentByCode,
  componentsByCode,
  considerationValues,
  costGroupQuantityComponents,
}: SetCostEstimateProps) => {
  const costEstimatesByKey: Record<string, CostEstimateByKey> = {};
  const parentCostEstimatesByKey: Record<
    string,
    Partial<CostEstimateValues>
  > = {};
  const bgfComponent = costGroupQuantityComponents?.find(isBgfComponent);

  costEstimateValues.forEach((estimate) => {
    const { costGroupKey, costGroupCount, costGroupAverage } = estimate;
    const parentCostGroup = componentsByCode[String(costGroupKey)];
    const considerationValue = getConsiderationValue({
      estimationMode,
      componentByCode,
      costGroupKey,
      considerationValues,
      bgfComponentId: bgfComponent?.id,
    });
    const costGroupTotal = Number(costGroupAverage * considerationValue);

    if (parentCostGroup) {
      const parentEstimates = parentCostEstimatesByKey[String(parentCostGroup)];
      const estimatedParentCostGroupTotal =
        (parentEstimates?.costGroupTotal ?? 0) + costGroupTotal;
      const estimatedParentCostCount = Math.min(
        costGroupCount,
        parentEstimates?.costGroupCount ?? costGroupCount,
      );

      parentCostEstimatesByKey[String(parentCostGroup)] = {
        costGroupCount: estimatedParentCostCount,
        costGroupTotal: estimatedParentCostGroupTotal,
      };
    }

    costEstimatesByKey[costGroupKey] = {
      costGroupCount,
      totalProjects,
      costGroupAverage,
      costGroupTotal,
    };
  });

  return {
    costEstimatesByKey,
    parentCostEstimatesByKey,
  };
};

type AddInferredCostGroups = Pick<
  SetCostEstimateProps,
  "totalProjects" | "componentsByCode" | "inferredCalcCostGroups"
> &
  ProcessCostEstimateResultsResponse & {
    costEstimates: Record<string, TransformedCostEstimateValues>;
  };

const addInferredCostGroups = ({
  totalProjects,
  componentsByCode,
  inferredCalcCostGroups,
  parentCostEstimatesByKey,
  costEstimates: costEstimateRes,
}: AddInferredCostGroups) => {
  const costEstimates = {
    ...costEstimateRes,
  };

  inferredCalcCostGroups.forEach((costGroupKey) => {
    const parentKey = componentsByCode[costGroupKey];
    const costGroupTotal =
      parentCostEstimatesByKey[costGroupKey]?.costGroupTotal ?? 0;
    const costGroupCount =
      parentCostEstimatesByKey[costGroupKey]?.costGroupCount ?? 0;
    const costGroupAverage =
      parentCostEstimatesByKey[costGroupKey]?.costGroupAverage ?? 0;
    const parentCostGroupTotal =
      parentCostEstimatesByKey[String(parentKey)]?.costGroupTotal ?? 0;

    costEstimates[costGroupKey] = {
      totalProjects,
      costGroupCount,
      costGroupAverage,
      costGroupTotal,
      percentageCost: isNil(parentKey)
        ? costGroupTotal
          ? 100
          : NaN
        : Math.round((costGroupTotal / parentCostGroupTotal) * 100),
    };
  });

  return costEstimates;
};

type CalculatePercentageCost = SetCostEstimateProps &
  ProcessCostEstimateResultsResponse;

const calculatePercentageCost = ({
  componentsByCode,
  costEstimatesByKey,
  parentCostEstimatesByKey,
}: CalculatePercentageCost) => {
  const costEstimates: Record<string, TransformedCostEstimateValues> = {};

  Object.keys(costEstimatesByKey).forEach((key) => {
    const { costGroupTotal } = costEstimatesByKey[key];
    const parentKey = componentsByCode[key];
    const parentTotal =
      parentCostEstimatesByKey[String(parentKey)]?.costGroupTotal ?? 0;

    costEstimates[key] = {
      ...costEstimatesByKey[key],
      percentageCost: isNil(parentKey)
        ? 100
        : Math.round((costGroupTotal / parentTotal) * 100),
    };
  });

  return costEstimates;
};

const addInferredPercentageCost = (
  componentsByCode: Record<string, number | null>,
  estimateValues: Record<string, TransformedCostEstimateValues>,
) => {
  const inferredCostEstimates: Record<string, TransformedCostEstimateValues> = {
    ...estimateValues,
  };
  let totalCostGroupTotal = 0;

  Object.keys(estimateValues).forEach((key) => {
    if (!componentsByCode[key]) {
      totalCostGroupTotal += estimateValues[key].costGroupTotal ?? 0;
    }
  });

  Object.keys(estimateValues).forEach((key) => {
    const { costGroupTotal } = inferredCostEstimates[key];

    if (!componentsByCode[key] && costGroupTotal) {
      inferredCostEstimates[key] = {
        ...inferredCostEstimates[key],
        percentageCost: Math.round(
          (costGroupTotal / totalCostGroupTotal) * 100,
        ),
      };
    }
  });

  return inferredCostEstimates;
};

export const processCostGroupEstimates = (args: SetCostEstimateProps) => {
  if (isEmpty(args.costEstimateValues)) {
    return {};
  }

  const processedEstimateResults = processCostEstimateResults(args);
  const inferredEstimateResults = addInferredParentCostEstimates({
    ...args,
    ...processedEstimateResults,
  });
  const costEstimatesRes = processParentCostEstimates({
    ...args,
    ...inferredEstimateResults,
  });
  const percentageCostEstimates = calculatePercentageCost({
    ...args,
    ...costEstimatesRes,
  });
  const inferredCostEstimates = addInferredCostGroups({
    ...args,
    ...costEstimatesRes,
    costEstimates: percentageCostEstimates,
  });

  return addInferredPercentageCost(
    args.componentsByCode,
    inferredCostEstimates,
  );
};
