import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

import { ReactNode } from "react";
import { TabItem } from "@amenda-types";
import clsx from "clsx";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";

type TabChildren<T> = (tab: T) => ReactNode;

interface Props<T> {
  tabs: T[];
  defaultTabIndex?: number;
  tabListClassName?: string;
  panelsClassName?: string;
  children: ReactNode | TabChildren<T>;
  tabClassName?: (isSelected: boolean) => string;
  onChange?: (tab: T) => void;
}

const getDefaultTabClassName = (isSelected?: boolean) =>
  clsx("amenda-tab", {
    "border-gray-900 text-gray-900": isSelected,
    "border-transparent  hover:border-gray-600 hover:text-gray-700":
      !isSelected,
  });

export const HeadlessTabs = <T extends TabItem>({
  tabs,
  children,
  tabListClassName,
  defaultTabIndex = 0,
  panelsClassName = "mt-2",
  tabClassName = getDefaultTabClassName,
  onChange,
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <TabGroup
      className="w-full"
      defaultIndex={defaultTabIndex}
      onChange={(index) => onChange?.(tabs[index])}
    >
      <TabList
        className={clsx(
          "amenda-tab-list pt-4 bg-white z-40 sticky top-0 w-full overscroll-y-contain overflow-y-auto",
          tabListClassName,
        )}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab?.value}
            className={({ selected }) =>
              clsx(tabClassName(selected), {
                hidden: tab?.disabled,
              })
            }
            disabled={tab?.disabled}
          >
            {t(tab?.label)}
          </Tab>
        ))}
      </TabList>
      <TabPanels className={clsx("font-apercu w-full", panelsClassName)}>
        {tabs.map((tab) => (
          <TabPanel key={tab.value}>
            {isFunction(children) ? children(tab) : children}
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};
