import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { LayoutProps } from "./common";
import { useTranslation } from "react-i18next";

export const ReadOnlyTwoColumnsFullWidth: FC<LayoutProps> = ({
  config,
  children,
}) => {
  const { t } = useTranslation();
  const { label, description } = config?.properties ?? {};

  return (
    <div data-testid="readOnlyTwoColumnsFullWidthLayout" className="space-y-6">
      <div className="amenda-form-layout">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            {label && <h3 className="amenda-form-heading">{t(label)}</h3>}
            {description && (
              <p className="mt-1 text-sm text-gray-600">{t(description)}</p>
            )}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <GridWrapper>{children}</GridWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
