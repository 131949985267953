import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import { FC, ReactNode, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  ReadOnlyProjectFormWrapper,
  ReadOnlyProjectMedia,
} from "@amenda-components/Projects";
import {
  addQueryToPath,
  getStaticProjectTabs,
  staticProjectLabels,
} from "./common";
import { isEmpty, isNil } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { HeadlessTabs } from "@amenda-components/App";
import { ReadOnlyProjectConstructionDetails } from "./ReadOnlyProjectConstructionDetails";
import { ReadOnlyProjectCostGroups } from "@amenda-components/CostGroup/ReadOnlyProjectCostGroups";
import { ReadOnlyProjectTemplateDesigner } from "@amenda-components/Projects";
import { Timeline } from "@amenda-components/Timeline";
import clsx from "clsx";
import { getQueryParams } from "@amenda-utils";

const showCurrentTab = ({
  tab,
  projectId,
  projectForms,
}: {
  tab: TabItem;
  projectForms: FormTab[];
  projectId: string;
}) => {
  const tabComponents: Record<string, ReactNode> = {
    [staticProjectLabels.constructionDetails.value]: (
      <ReadOnlyProjectConstructionDetails projectId={projectId} />
    ),
    [staticProjectLabels.attachments.value]: (
      <ReadOnlyProjectMedia projectId={projectId} />
    ),
    [staticProjectLabels.designs.value]: (
      <ReadOnlyProjectTemplateDesigner projectId={projectId} />
    ),
    [staticProjectLabels.costGroups.value]: (
      <ReadOnlyProjectCostGroups projectId={projectId} />
    ),
    [staticProjectLabels.activity.value]: (
      <Timeline type={AllowedTimelineTypes.Project} resourceId={projectId} />
    ),
  };

  projectForms?.forEach((form) => {
    tabComponents[form.id] = (
      <ReadOnlyProjectFormWrapper components={form.components} />
    );
  });
  return tabComponents[tab.value];
};

export const ReadOnlyProjectTabs: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const projectForms = useProjectStore((state) => state.projectForms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const projectTabs = useMemo(() => {
    const tabs: TabItem[] =
      availableForms?.map((form) => ({
        label: form.name,
        value: form.id,
      })) || [];

    tabs.push(...getStaticProjectTabs(projectsGeneralPermissions));

    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const foundIndex = projectTabs.findIndex(
    (tab) => tab.value === getQueryParams(search)?.tab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  const handleChange = (tab: TabItem) => {
    navigate(addQueryToPath(pathname, { tab: tab.value }), { replace: true });
  };

  if (isEmpty(availableForms) || isNil(projectId)) {
    return null;
  }
  return (
    <HeadlessTabs
      panelsClassName="pt-4"
      tabListClassName="border-0"
      tabs={projectTabs}
      defaultTabIndex={defaultTabIndex}
      onChange={handleChange}
      tabClassName={(isSelected) =>
        clsx(
          "px-3 py-2 font-apercu text-sm flex space-x-1 items-center whitespace-nowrap focus:outline-none",
          {
            "bg-gray-900 text-white": isSelected,
            "text-gray-900 hover:text-white hover:bg-gray-900": !isSelected,
          },
        )
      }
    >
      {(tab) => <>{showCurrentTab({ tab, projectId, projectForms })}</>}
    </HeadlessTabs>
  );
};
