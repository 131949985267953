import { AllowedContactType, FormComponentProps, FormTab } from "@amenda-types";
import { Button, HeadlessTabs, SlideOver } from "@amenda-components/App";
import { FC, RefObject, useRef } from "react";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { SubmitHandler, useForm } from "react-hook-form";
import { getValidationSchema, groupComponentsByParent } from "@amenda-utils";
import {
  useCreateContact,
  useSettingsStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { PermissionComponentKey } from "@amenda-constants";
import { transformFormToUsers } from "./common";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  isOpen: boolean;
  customComponents?: Record<string, FC<FormComponentProps>>;
  title: string;
  description: string;
  type: AllowedContactType;
  forms: FormTab[];
  handleClose: () => void;
}

interface ComponentFormProps {
  components: any[];
  submitBtnRef: RefObject<HTMLButtonElement>;
  customComponents: Record<string, FC<FormComponentProps>>;
  onSubmit: SubmitHandler<any>;
}

const ComponentForm: FC<ComponentFormProps> = ({
  components,
  submitBtnRef,
  customComponents,
  onSubmit,
}) => {
  const formComponentTree = groupComponentsByParent(components);
  const validationSchema = getValidationSchema(components);

  const { control, handleSubmit } = useForm({
    shouldUnregister: true,
    values: {},
    resolver: yupResolver(validationSchema),
    resetOptions: {
      keepIsSubmitted: true,
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  return (
    <form className="px-8 py-4" onSubmit={handleSubmit(onSubmit)}>
      <ComponentTreeRenderer
        config={formComponentTree}
        customComponents={customComponents}
        readOnly={false}
        globalProps={{
          control,
        }}
      />
      <button ref={submitBtnRef} type="submit" className="hidden" />
    </form>
  );
};

export const UserSliderOverBase: FC<Props> = ({
  type,
  title,
  isOpen,
  description,
  forms,
  customComponents = {},
  handleClose,
}) => {
  const { t } = useTranslation();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const { createContact, loading } = useCreateContact();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const callback = useUsersStore((state) => state.addUserSlideOverCb);

  const tabs = forms.map((form) => ({
    label: form.name,
    value: form.id,
    components: form.components,
  }));
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );
  const defaultShareType =
    contactsGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];

  const onSubmit: SubmitHandler<any> = async (form) => {
    const input = transformFormToUsers({ type, form, defaultShareType });

    await createContact({
      input,
      callback,
    });
    handleClose();
  };

  const handleSubmit = () => submitBtnRef.current?.click();

  return (
    <SlideOver
      title={title}
      description={description}
      show={isOpen}
      className="md:w-1/2 w-full"
      Icon={BuildingOfficeIcon}
      onClose={handleClose}
      Footer={
        <div className="shrink-0 px-4 py-4 flex justify-end">
          <Button type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>

          <Button
            type="button"
            variant="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            {t("Save")}
          </Button>
        </div>
      }
    >
      <div className="w-full z-40">
        <HeadlessTabs
          tabs={tabs}
          tabListClassName="mx-auto w-full px-4 sm:px-6 lg:px-8"
        >
          {(tab) => (
            <ComponentForm
              key={tab.value}
              submitBtnRef={submitBtnRef}
              components={tab.components}
              customComponents={customComponents}
              onSubmit={onSubmit}
            />
          )}
        </HeadlessTabs>
      </div>
    </SlideOver>
  );
};
