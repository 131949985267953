import {
  BuildingOfficeIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { FC, useMemo, useRef, useState } from "react";
import { debounce, isEmpty } from "lodash";

import { AllowedContactType } from "@amenda-types";
import { DebounceTimes } from "@amenda-constants";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { TextHighlighter } from "./TextHighlighter";
import { getUserName } from "@amenda-components/Contacts/common";
import { useInView } from "react-intersection-observer";
import { useSearchUsersAndContacts } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  searchTerm: string;
  menuIsOpen: boolean;
  selectContacts: (name: string) => void;
  setSearchTerm: (searchTerm: string) => void;
}

const Icon: FC<{ type?: AllowedContactType }> = ({ type }) => {
  if (type === AllowedContactType.company) {
    return (
      <BuildingOfficeIcon className="min-w-[1.25rem] min-h-[1.25rem] w-5 h-5 text-gray-800" />
    );
  }
  return (
    <UserCircleIcon className="min-w-[1.25rem] min-h-[1.25rem] w-5 h-5 text-gray-800" />
  );
};

export const TimelineInlineContactSearch: FC<Props> = ({
  searchTerm,
  menuIsOpen,
  setSearchTerm,
  selectContacts,
}) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const scrollingRef = useRef<HTMLDivElement>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { searchUsersAndContacts, loading } = useSearchUsersAndContacts();

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        await searchUsersAndContacts({
          searchTerm,
          autoSelect: true,
          callback: (users) => setUsers(users),
        });
      }, DebounceTimes.Search),
    [searchUsersAndContacts],
  );

  const handleInputChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (searchTerm.length > 0) {
      await debouncedSearch(searchTerm);
    }
  };

  const scrollToAndFocusItem = (
    index: number,
    scrollPosition: "down" | "up",
  ) => {
    if (scrollingRef.current && scrollingRef.current.children[index]) {
      const item = scrollingRef.current.children[index];
      const height = scrollingRef.current?.getBoundingClientRect()?.height;
      const scrollTop = scrollingRef?.current?.scrollTop;

      if (scrollTop < Number(height / 2) || scrollPosition === "up") {
        item.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleArrowDown = () => {
    const index = !Number.isFinite(selectedIndex)
      ? 0
      : Number(selectedIndex) + 1;

    if (index < users.length) {
      setSelectedIndex(index);
      scrollToAndFocusItem(index, "down");
    }
  };

  const handleArrowUp = () => {
    if (Number.isFinite(selectedIndex) && Number(selectedIndex) > 0) {
      const index = Number(selectedIndex) - 1;

      setSelectedIndex(index);
      scrollToAndFocusItem(index, "up");
    }
  };

  const handleKeyDown = (event: any) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        handleArrowUp();
        break;
      case "ArrowDown":
        event.preventDefault();
        handleArrowDown();
        break;
      case "Enter":
        if (event.keyCode === 229) {
          // ignore the keydown event from an Input Method Editor(IME)
          // ref. https://www.w3.org/TR/uievents/#determine-keydown-keyup-keyCode
          break;
        }
        if (menuIsOpen) {
          event.preventDefault();

          const user = users[Number(selectedIndex)];
          selectContacts(getUserName(user));
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="relative z-40 font-apercu text-gray-700 text-sm"
      onKeyDown={handleKeyDown}
    >
      <div ref={scrollingRef} className="py-1 h-48 overflow-y-auto">
        {isEmpty(users) && searchTerm.length > 3 && (
          <p className="px-2 py-1 text-center break-words">
            {t("No office contact or company found matching the search term")}
          </p>
        )}
        {loading && !inView && (
          <div className="flex items-center space-x-2 px-4 pt-1 pb-3">
            <span>{t("Loading")}</span>
            <span className="text-green-600 text-lg animate-pulse">...</span>
          </div>
        )}
        <div className="divide-y">
          {users.map((user, index) => (
            <div key={user.id}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectContacts(getUserName(user));
                }}
                className="flex items-center space-x-2 px-4 py-1 cursor-pointer hover:bg-gray-100 hover:text-gray-900"
              >
                <Icon type={user?.type} />
                <TextHighlighter
                  className="truncate"
                  text={getUserName(user)}
                  searchTerm={searchTerm}
                />
              </div>
            </div>
          ))}
        </div>
        <div ref={ref} />
        {loading && inView && (
          <div className="flex items-center space-x-2 px-4 pt-1 pb-3">
            <span>{t("Loading")}</span>
            <span className="text-green-600 text-lg animate-pulse">...</span>
          </div>
        )}
      </div>
      <MiniSearchField
        className="border-t border-gray-300"
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};
