import {
  Control,
  Controller,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import {
  DateQuarterPicker,
  ErrorMessage,
  Select,
  SingleCheckbox,
  TextField,
} from "@amenda-components/FormComponents";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { devConsole, sanitizeData } from "@amenda-utils";
import { useSettingsStore, useUsersStore } from "@amenda-domains/mutations";

import { CheckIcon } from "@heroicons/react/24/outline";
import { GeneralPermissionTypes } from "@amenda-types";
import { IconButton } from "@amenda-components/App";
import { MaterialReadOnlyUnitPriceCard } from "./MaterialReadOnlyUnitPrices";
import { TrashIcon } from "@heroicons/react/24/solid";
import { materialSourcesOptions } from "./common";
import { materialUnitPriceSchema } from "@amenda-constants";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface UnitPricesFormProps {
  control: Control<any>;
  getNestedId?: (id: string) => string;
}

const UnitPricesForm: FC<UnitPricesFormProps> = ({
  control,
  getNestedId = (id) => id,
}) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );

  return (
    <div className="grid grid-cols-2 gap-1 items-end">
      <div className="col-span-2 md:col-span-1">
        <Controller
          name={getNestedId("total_purchase_quantity")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                id={getNestedId("total_purchase_quantity")}
                type="number"
                value={value}
                label="Gesamt Abnahmemenge"
                error={error?.message}
                onChange={onChange}
              />
            );
          }}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name={getNestedId("total_price")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                id={getNestedId("total_price")}
                type="number"
                value={value}
                label="Einheitspreis"
                error={error?.message}
                endAddOnText="€"
                onChange={onChange}
              />
            );
          }}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name={getNestedId("indication_year")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <DateQuarterPicker
                id={getNestedId("indication_year")}
                label="Quartal der Ermittlung"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            );
          }}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Controller
          name={getNestedId("source")}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                id={getNestedId("source")}
                label="Quelle"
                selectedOption={value}
                error={error?.message}
                options={materialSourcesOptions}
                onChange={onChange}
              />
            );
          }}
        />
      </div>
      {unitPriceGeneralPermissions?.[GeneralPermissionTypes.Archive] && (
        <div className="col-span-2 md:col-span-1">
          <Controller
            name={getNestedId("status")}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <SingleCheckbox
                  id={getNestedId("status")}
                  label="Status:archiviert"
                  checked={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

interface CreateUnitPriceProps {
  append: UseFieldArrayAppend<any>;
}

const CreateUnitPrice: FC<CreateUnitPriceProps> = ({ append }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<any>({
    values: {},
    resolver: yupResolver(materialUnitPriceSchema),
  });
  const currentUser = useUsersStore((state) => state.currentUser);

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  const onSubmit = (data: any) => {
    const sanitizedData = sanitizeData(data);

    append({
      ...sanitizedData,
      ownerId: currentUser?.id,
    });
    reset();
  };

  return (
    <div className="flex flex-col w-full group/labelledInput mb-4">
      <div className="bg-white border min-h-16 shrink-0 px-6 py-4 group-focus-within/labelledInput:border-gray-700">
        <UnitPricesForm control={control} />
        <div className="col-span-2 flex justify-end">
          <div>
            <button
              type="button"
              className="amenda-button"
              onClick={handleSubmit(onSubmit, onError)}
            >
              {t("Add entry")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditUnitPriceFormProps {
  index?: number;
  control: Control<any>;
  remove: UseFieldArrayRemove;
  setEditing: (isEditing: boolean) => void;
  getNestedId: (name: string) => string;
}

const EditUnitPriceForm: FC<EditUnitPriceFormProps> = ({
  index,
  control,
  remove,
  setEditing,
  getNestedId,
}) => {
  const handleDelete = () => {
    remove?.(index);
  };

  const handleClose = () => {
    setEditing(false);
  };

  return (
    <div className="flex flex-col w-full group/labelledInput mb-4">
      <div className="bg-white border min-h-16 shrink-0 px-6 py-4 group-focus-within/labelledInput:border-gray-700">
        <div className="flex space-x-1 items-center justify-end">
          <IconButton
            label="Delete item"
            iconSize={1}
            className="flex items-center justify-center text-gray-900 bg-white hover:bg-gray-900 hover:text-white"
            Icon={TrashIcon}
            onClick={handleDelete}
          />
          <IconButton
            label="Save and Close"
            className="flex items-center justify-center text-gray-900 bg-white hover:bg-gray-900 hover:text-white"
            iconSize={1}
            Icon={CheckIcon}
            onClick={handleClose}
          />
        </div>
        <UnitPricesForm control={control} getNestedId={getNestedId} />
      </div>
    </div>
  );
};

interface MaterialReadOnlyUnitPriceCardWrapperProps {
  index: number;
  control: Control<any>;
  remove: UseFieldArrayRemove;
  setEditing: (isEditing: boolean) => void;
  getNestedId: () => string;
}

const MaterialReadOnlyUnitPriceCardWrapper: FC<
  MaterialReadOnlyUnitPriceCardWrapperProps
> = ({ control, index, remove, setEditing, getNestedId }) => {
  const watch = useWatch({
    control,
    name: getNestedId(),
  });

  return (
    <MaterialReadOnlyUnitPriceCard
      showActions={true}
      index={index}
      remove={remove}
      setEditing={setEditing}
      {...watch}
    />
  );
};

interface CreateOrEditUnitPricesProps {
  id: string;
  control: Control<any>;
}

const CreateOrEditUnitPrices: FC<CreateOrEditUnitPricesProps> = ({
  id,
  control,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: id,
  });
  const [editField, setEditField] = useState<Record<string, boolean>>({});

  const setEditing = (id: string) => (isEditing: boolean) => {
    setEditField({
      ...editField,
      [id]: isEditing,
    });
  };

  const getNestedId = (index: number) => (name?: string) => {
    if (name) {
      return `${id}.${index}.${name}`;
    }
    return `${id}.${index}`;
  };

  return (
    <div className="w-full flex flex-col">
      <CreateUnitPrice append={append} />
      <div className="flex flex-col-reverse">
        {fields.map((field, index) => {
          if (editField[String(index)]) {
            return (
              <EditUnitPriceForm
                key={field.id}
                index={index}
                control={control}
                remove={remove}
                setEditing={setEditing(String(index))}
                getNestedId={getNestedId(index)}
              />
            );
          }
          return (
            <MaterialReadOnlyUnitPriceCardWrapper
              key={field.id}
              index={index}
              control={control}
              getNestedId={getNestedId(index)}
              remove={remove}
              setEditing={setEditing(String(index))}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ErrorMessageWrapperProps {
  id: string;
  control: Control<any>;
}

const ErrorMessageWrapper: FC<ErrorMessageWrapperProps> = ({ id, control }) => {
  const { errors } = useFormState({
    control,
    name: id,
  });

  const error = errors[id];

  return (
    <ErrorMessage id={id} error={error?.message as any} className="text-xs" />
  );
};

interface Props {
  id: string;
  control?: Control<any>;
}

export const CreateOrEditMaterialUnitPrices: FC<Props> = ({ id, control }) => {
  return (
    <div className="w-full mt-1">
      {control && <CreateOrEditUnitPrices id={id} control={control} />}
      {control && <ErrorMessageWrapper id={id} control={control} />}
    </div>
  );
};
