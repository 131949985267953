import {
  AllowedCostGroupTypes,
  AvailableForms,
  costEstimateProjectSchema,
} from "@amenda-constants";
import { Button, Modal } from "@amenda-components/App";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FC, useState } from "react";
import {
  convertEstimatesToCostGroupData,
  getConsiderationDate,
} from "./common";
import {
  useCreateCostGroup,
  useCreateProject,
  useProjectStore,
} from "@amenda-domains/mutations";

import { InferType } from "yup";
import { TextField } from "@amenda-components/FormComponents";
import { getComponentByCode } from "@amenda-components/CostGroup/common";
import { getComponentsFromForms } from "@amenda-utils";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  isOpen: boolean;
  costEstimateInput: Record<string, any>;
  closeModal: () => void;
}

const formId = "costEstimateGenerateProjectModal";

type CostEstimateType = InferType<typeof costEstimateProjectSchema>;

export const CostEstimateGenerateProjectModal: FC<Props> = ({
  isOpen,
  costEstimateInput,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const costEstimateValues = useProjectStore(
    (state) => state.costEstimateValues,
  );
  const { createProject } = useCreateProject();
  const { createCostGroup } = useCreateCostGroup();
  const forms = useProjectStore((state) => state.forms) || {};
  const { handleSubmit, control } = useForm<CostEstimateType>({
    resolver: yupResolver(costEstimateProjectSchema),
  });
  const latestBKIDate = useProjectStore((state) => state.latestBKIDate);

  const costGroupComponents = getComponentsFromForms(
    forms[AvailableForms.CostGroupsCost] || [],
  );
  const { componentByCode } = getComponentByCode(costGroupComponents);

  const handleUpsertProject: SubmitHandler<any> = async (values) => {
    setIsLoading(true);
    const date = new Date().toISOString();
    const { referenceQuantity, considerationValue, considerationDate } =
      costEstimateInput;

    const data = convertEstimatesToCostGroupData({
      componentByCode,
      costEstimateValues,
      considerationValue,
      referenceQuantity,
    });

    const project = await createProject({
      input: {
        ...values,
        formValues: {
          ...values,
        },
      },
    });
    if (project) {
      await createCostGroup({
        input: {
          ...data,
          versionDate: date,
          resourceId: project.id,
          type: AllowedCostGroupTypes.Rollover,
          bkiDate: getConsiderationDate(
            considerationDate,
            latestBKIDate.dateIso,
          ),
        },
      });
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      title="New Project"
      withSuccess={true}
      size="md"
      successLabel="Continue"
      loading={isLoading}
      onClose={closeModal}
      footerChildren={({ loading, onClose }) => {
        return (
          <>
            <div className="shrink">
              <Button type="button" onClick={onClose}>
                {t("Cancel")}
              </Button>
            </div>
            <Button
              variant="primary"
              form={formId}
              loading={loading}
              type="submit"
            >
              {t("Save")}
            </Button>
          </>
        );
      }}
    >
      <form
        id={formId}
        className="flex flex-col w-120 pb-10 px-8"
        onSubmit={handleSubmit(handleUpsertProject)}
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="name"
                label="Project Name"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name="number"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id="number"
                label="Project Number"
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </form>
    </Modal>
  );
};
