import { FC, useState } from "react";
import { IconButtonBase, Tooltip } from "@amenda-components/App";
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { LayoutProps } from "./common";
import { PlusIcon } from "lucide-react";
import { availableLayouts } from "@amenda-types";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { useDndMonitor } from "@dnd-kit/core";

interface SortItemProps extends LayoutProps {
  className?: string;
  showBorder?: boolean;
}

interface AddComponentButtonProps {
  config?: any;
  isFormBuilder?: boolean;
  className?: string;
}

const FormBuilderSortItem: FC<SortItemProps> = ({
  config,
  children,
  className,
  showBorder = false,
}) => {
  const {
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
    isOver,
    setNodeRef,
  } = useSortable({
    id: config?.id ?? "",
    data: {
      component: config,
    },
  });
  const isSavingComponentSorting = useAppStore(
    (state) => state.formBuilderState?.isSavingComponentSorting,
  );

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={clsx(
        className,
        "border-dashed border border-transparent hover:border-gray-300 p-2 cursor-default w-full h-full",
        {
          "!border-red-300": isOver,
          invisible: isDragging,
          "!border-gray-300": showBorder && !isOver,
          "flex items-center": ![
            availableLayouts.twoColumnsFullWidth,
            availableLayouts.fullColumnGrid,
            availableLayouts.nestedForm,
          ].includes(config?.layout as availableLayouts),
          "animate-pulse": Boolean(isSavingComponentSorting),
        },
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export const SortItem: FC<SortItemProps> = ({
  isFormBuilder = false,
  children,
  className,
  ...rest
}) => {
  if (!isFormBuilder) {
    return <div className={className}>{children}</div>;
  }
  return (
    <FormBuilderSortItem className={className} {...rest}>
      {children}
    </FormBuilderSortItem>
  );
};

const FormBuilderSortDroppable: FC<SortItemProps> = ({
  children,
  className,
  config,
}) => {
  const [isOver, setOver] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useDndMonitor({
    onDragStart() {
      setOver(true);
    },
    onDragMove(event) {
      const { active } = event;
      const sortableId = active.data.current?.sortable?.containerId;
      const layout = config?.layout;
      const disabled = !sortableId || !layout || !sortableId.startsWith(layout);

      setDisabled(disabled);
    },
    onDragEnd() {
      setOver(false);
      setDisabled(false);
    },
    onDragCancel() {
      setOver(false);
      setDisabled(false);
    },
  });

  return (
    <SortableContext
      id={`${config?.layout}.${config?.id}`}
      items={config?.components?.map((component) => component.id) ?? []}
      strategy={rectSortingStrategy}
      disabled={disabled}
    >
      <div
        className={clsx("border border-dashed", className, {
          "bg-gray-50 border-gray-300": isOver && !disabled,
          "bg-red-50 border-red-300": isOver && disabled,
          "border-transparent": !isOver,
        })}
      >
        {children}
      </div>
    </SortableContext>
  );
};

export const SortDroppable: FC<SortItemProps> = ({
  children,
  className,
  isFormBuilder = false,
  ...rest
}) => {
  if (!isFormBuilder) {
    return <div className={className}>{children}</div>;
  }
  return (
    <FormBuilderSortDroppable className={className} {...rest}>
      {children}
    </FormBuilderSortDroppable>
  );
};

export const AddComponentButton: FC<AddComponentButtonProps> = ({
  isFormBuilder,
  className,
  config,
}) => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const handleClick = () => {
    if (!config) return;

    let component: Record<string, any> = {
      parentId: config.id,
      order: config.components?.length ?? 0,
    };

    updateFormBuilderState("selectedFormComponent", {
      config: component,
    });
    updateFormBuilderState("openCreateComponentModal", true);
  };

  if (!isFormBuilder) return null;

  return (
    <div className={clsx("w-full flex py-1", className)}>
      <Tooltip
        id={config?.id ?? "add_component_button"}
        message="Add component"
      >
        <IconButtonBase
          onClick={handleClick}
          className="!text-white !bg-gray-800"
          variant="round"
        >
          <PlusIcon className="w-6 h-6" />
        </IconButtonBase>
      </Tooltip>
    </div>
  );
};
