import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { GeneralPermissionTypes, Pagination } from "@amenda-types";
import {
  useAttachmentStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { FC } from "react";
import { HelperMessage } from "@amenda-components/App";
import { MediaGalleryProps } from "./common";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";

const hasPaginatedValues = (pagination?: Pagination) => {
  return pagination?.docsCount && pagination.docsCount > 0;
};

type Props = Pick<MediaGalleryProps, "pagination" | "canUpload">;

export const EmptyAttachments: FC<Props> = ({ pagination, canUpload }) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const showMediaGeneralPermissions =
    mediaGeneralPermissions[GeneralPermissionTypes.Create];
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal,
  );
  const { searchTerm } = useSidebarFiltersWithPath();

  const handleOpenUploadModal = () => {
    toggleBulkEditorModal(true);
  };

  if (
    Boolean(canUpload) &&
    showMediaGeneralPermissions &&
    !hasPaginatedValues(pagination) &&
    isEmpty(searchTerm)
  ) {
    return (
      <HelperMessage
        message="Drop images to get started"
        helpText="Enjoy your unlimited Space"
        Icon={PhotoIcon}
        actionText="Upload"
        onClick={handleOpenUploadModal}
      />
    );
  }
  return (
    <HelperMessage
      message="No attachments found"
      helpText={
        !isEmpty(searchTerm)
          ? "Try changing your search filters"
          : "Contact your administrator to add attachments"
      }
      Icon={PhotoIcon}
    />
  );
};
