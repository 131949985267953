import {
  AggregationOperations,
  AvailablePermissions,
  AvailableWidgetTypes,
  ChartVariations,
} from "@amenda-types";
import { ComponentValidationType, Option } from "@amenda-types";
import {
  CostGroupComponentStaticId,
  CostGroupPermissionPath,
} from "@amenda-constants";

import getPath from "lodash/get";
import { processFormPermissions } from "@amenda-components/Shared/common";

export const operationOptions = [
  { value: AggregationOperations.SUM, label: AggregationOperations.SUM },
  {
    value: AggregationOperations.AVERAGE,
    label: AggregationOperations.AVERAGE,
  },
  {
    value: AggregationOperations.MINIMUM,
    label: AggregationOperations.MINIMUM,
  },
  {
    value: AggregationOperations.MAXIMUM,
    label: AggregationOperations.MAXIMUM,
  },
];

export const chartOptions = [
  { value: ChartVariations.Doughnut, label: ChartVariations.Doughnut },
  { value: ChartVariations.Bar, label: ChartVariations.Bar },
  { value: ChartVariations.Line, label: ChartVariations.Line },
  { value: ChartVariations.Pie, label: ChartVariations.Pie },
];

export interface AggregateOption extends Option {
  type: AvailableWidgetTypes;
  operation?: AggregationOperations;
  adornment?: string;
  isCostGroup?: boolean;
}

export const calculateSum = (values: number[]) => {
  let sum = 0;
  values.forEach((value) => {
    sum = sum + value;
  });
  return sum;
};

export const calculateAvg = (values: number[]) => {
  const total = calculateSum(values);
  return total / values.length;
};

export const findMin = (values: number[]) => {
  let min = values[0];
  values.forEach((value) => {
    if (value < min) {
      min = value;
    }
  });
  return min;
};

export const findMax = (values: number[]) => {
  let max = 0;
  values.forEach((value) => {
    if (value > max) {
      max = value;
    }
  });
  return max;
};

export const handleChartData = (data: number[], labels: string[]) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 0,
    },
  ],
});

export const chartConfig = {
  maintainAspectRatio: false, // Set to false to make the chart full width
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "right" as const,
      maxWidth: 120,
      labels: {
        usePointStyle: true,
        boxWidth: 12,
      },
    },
    layout: {
      margin: 2,
    },
    tooltip: {
      enabled: true,
      cornerRadius: 0,
      displayColors: false,
      font: { size: 5 },
    },
  },
};

export const getWidgetLayouts = (widgets: { layout: any }[]) => {
  let layouts: any = { lg: [] };
  widgets.forEach(({ layout }) => {
    layouts.lg.push(layout);
  });
  return layouts;
};

export const getNumericComponents = (components: any[]) => {
  return components.filter(
    (component) =>
      component?.validation?.type === ComponentValidationType.Number,
  );
};

export const getNumericOptions = (
  components: any[],
  permissions: Record<string, any>,
) => {
  const allowedComponents = processFormPermissions(permissions)(components);

  return allowedComponents
    .filter(
      (component) =>
        component?.validation?.type === ComponentValidationType.Number,
    )
    .map((component) => ({
      value: component.id,
      label: component?.properties?.label || component.id,
      adornment:
        component?.properties?.endAddOnText ||
        component?.properties?.startAddOnText,
      type: AvailableWidgetTypes.Formulae,
    }));
};

export const getCostGroupOptions = (permissions: Record<string, any>) => {
  const costGroupPermission = getPath(permissions, CostGroupPermissionPath);
  const isRestricted = costGroupPermission === AvailablePermissions.Restricted;

  const costGroupOptions: AggregateOption[] = [];

  if (!isRestricted) {
    costGroupOptions.push({
      adornment: "€",
      label: "Kosten",
      isCostGroup: true,
      type: AvailableWidgetTypes.Formulae,
      value: CostGroupComponentStaticId,
    });
  }
  return costGroupOptions;
};

export const getValuesFromWidgets = (
  availableWidgets: {
    id: string;
    properties: Record<string, any>;
    layout: Record<string, any>;
  }[],
) => {
  const values: Record<string, any> = {};

  availableWidgets.forEach((widget) => {
    if (widget.properties.isCostGroup) return;

    values[widget.properties.componentId] = 1;
  });

  return values;
};
