import { useProjectStore, useUsersStore } from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { FC } from "react";
import { UserSliderOverBase } from "./UserSlideOverBase";

export const CompanySlideOver: FC = () => {
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType
  );
  const openCompanySlideOver = useUsersStore(
    (state) => state.openCompanySlideOver
  );
  const setOpenCompanySlideOver = useUsersStore(
    (state) => state.setOpenCompanySlideOver
  );

  const forms = formsByContactType[AllowedContactType.company];

  return (
    <UserSliderOverBase
      isOpen={openCompanySlideOver}
      handleClose={() => setOpenCompanySlideOver(false)}
      type={AllowedContactType.company}
      title="New Company"
      description="Please enter the Company Information"
      forms={forms}
    />
  );
};
