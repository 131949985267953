import { AvailableForms, FormCategories } from "@amenda-constants";
import { AvailablePermissions, FormTab } from "@amenda-types";
import {
  useAppStore,
  useProjectStore,
  useSettingsStore,
  useUpsertSystemRole,
} from "@amenda-domains/mutations";
import { useEffect, useMemo, useState } from "react";

export const useFormBuilderModifySystemRole = () => {
  const { upsertSystemRole, loading } = useUpsertSystemRole();
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole
  );

  const updatedSystemRole = {
    _id: currentUserSystemRole?.id,
    permissions: {
      ...(currentUserSystemRole?.permissions ?? {}),
    },
  };

  const addComponentToPermissions = async (form: any, component: any) => {
    updatedSystemRole.permissions[form.id] = {
      ...(updatedSystemRole.permissions[form.id] ?? {}),
      [component.componentId]: AvailablePermissions.Edit,
    };

    await upsertSystemRole({ input: updatedSystemRole, skipCallback: true });
  };

  const addComponentsToPermissions = async (form: any, components: any[]) => {
    updatedSystemRole.permissions[form.id] = components.reduce(
      (acc: Record<string, any>, c: any) => {
        acc[c.componentId] = AvailablePermissions.Edit;
        return acc;
      },
      {}
    );

    await upsertSystemRole({ input: updatedSystemRole, skipCallback: true });
  };

  const addFormToPermissions = async (form: any) => {
    updatedSystemRole.permissions[form.id] = form.components.reduce(
      (acc: Record<string, any>, c: any) => {
        acc[c.componentId] = AvailablePermissions.Edit;
        return acc;
      },
      {}
    );

    await upsertSystemRole({ input: updatedSystemRole, skipCallback: true });
  };

  return {
    loading,
    addComponentToPermissions,
    addComponentsToPermissions,
    addFormToPermissions,
  };
};

const getForms = (forms: Record<string, FormTab[]>, formKeys: string[]) => {
  const availableForms: FormTab[] = [];

  formKeys.forEach((key) => {
    availableForms.push(...(forms[key] ?? []));
  });

  return availableForms;
};

export const useGetFormsByCategory = () => {
  const storeForms = useProjectStore((state) => state.forms);

  const forms = useMemo(() => storeForms ?? {}, [storeForms]);
  const [availableForms, setAvailableForms] = useState<any[]>([]);
  const formBuilderState = useAppStore((state) => state.formBuilderState);

  const { selectedCategory } = formBuilderState ?? {};

  useEffect(() => {
    let formKeys = Object.keys(forms).filter(
      (key) => selectedCategory && key.startsWith(selectedCategory)
    );
    if (selectedCategory === FormCategories.Contacts) {
      formKeys = [AvailableForms.ContactsCreation, AvailableForms.CurrentUser];
    }

    setAvailableForms(getForms(forms, formKeys));
  }, [forms, selectedCategory]);

  return { availableForms, setAvailableForms };
};
