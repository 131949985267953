import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { FC } from "react";
import { ShareableContactsSearch } from "./ShareableContactsSearch";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  onBlur?: () => void;
}

export const CollectionContacts: FC<Props> = ({ id, control, setValue }) => {
  const { t } = useTranslation();
  const isShared = useWatch({
    control,
    exact: true,
    name: "shareable",
  });

  return (
    <div className="w-full">
      {isShared && (
        <label htmlFor={id} className="amenda-component-label">
          {t("Share with Contacts")}
        </label>
      )}
      <ShareableContactsSearch
        id={id}
        control={control}
        isHidden={!isShared}
        ignoreStylesOverride={true}
        setValue={setValue}
      />
    </div>
  );
};
