import { FC } from "react";
import { Image } from "@amenda-components/App";
import { TitleAndDescription } from "@amenda-components/PdfBuilder";

export const ProjectCard: FC<{
  project: any;
}> = ({ project }) => {
  return (
    <div className="flex flex-row items-center rounded p-2 w-full">
      <div className="w-24">
        <Image url={project.galleryUrl} />
      </div>
      <TitleAndDescription
        title={project.name}
        container="column"
        className="ml-4 text-left"
        description={
          <div className="text-xs text-gray-500 w-full">
            {project?.number && (
              <p className="font-semibold uppercase pb-2">{project.number}</p>
            )}
            {project?.address?.name && (
              <p className="truncate">{project.address.name}</p>
            )}
          </div>
        }
      />
    </div>
  );
};
