import { AllowedCollectionType, AppRoutes } from "@amenda-types";
import { FC, useEffect, useRef } from "react";
import {
  FormAutoSaveProvider,
  FormAutoSaveSubmitProps,
} from "@amenda-components/PageBuilder";
import {
  LargerScreensSidebar,
  SidebarFilters,
  SmallerScreensSidebar,
} from "@amenda-components/Shared";
import {
  isAttachmentOrProjectCollection,
  isSearchFilterEmpty,
  transformFilters,
} from "./common";
import {
  useAppStore,
  useAttachmentStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import { useLocation, useParams } from "react-router-dom";
import {
  useSearchAttachments,
  useSearchProjects,
} from "@amenda-domains/queries";

import { ProjectTabRoutes } from "@amenda-components/Projects/common";
import { getQueryParams } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";
import { sidebarFiltersSchema } from "@amenda-constants";

const FiltersWrapper: FC = () => {
  const { pathname, search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const { searchProjects } = useSearchProjects();
  const { searchAttachments } = useSearchAttachments();
  const setIsSearching = useProjectStore((state) => state.setIsSearching);
  const setIsSearchingAttachments = useAttachmentStore(
    (state) => state.setIsSearching,
  );
  const { sidebarFilters, searchTerm, setSidebarFilters, clearSidebarFilters } =
    useSidebarFiltersWithPath();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );

  const activeProjectTab = getQueryParams(search)?.tab;
  const selectedCollection =
    selectedCollectionByType?.[
      pathname.includes(AppRoutes.Attachments)
        ? AllowedCollectionType.Attachments
        : AllowedCollectionType.Projects
    ];
  const isCollection = isAttachmentOrProjectCollection(pathname);
  const isProject =
    pathname.includes(AppRoutes.Projects) &&
    ProjectTabRoutes.Attachments !== activeProjectTab;

  const searchInProjects = async (filters: any) => {
    setIsSearching(true);
    await searchProjects({
      isCollection,
      searchTerm,
      filters,
      resourceIds: selectedCollection?.resourceIds,
    });
    setIsSearching(false);
  };

  const searchInAttachments = async (searchFilters: any) => {
    let filters = { ...searchFilters };
    if (activeProjectTab === ProjectTabRoutes.Attachments && projectId) {
      filters = {
        ...filters,
        "formValues.project": projectId,
      };
    }

    setIsSearchingAttachments(true);
    await searchAttachments({
      filters,
      isCollection,
      searchTerm,
      resourceIds: selectedCollection?.resourceIds,
    });
    setIsSearchingAttachments(false);
  };

  const handleSearch = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const runSearch = isProject ? searchInProjects : searchInAttachments;

    setSidebarFilters(sanitizedData);
    if (isEmpty(searchTerm) && isSearchFilterEmpty(sanitizedData)) return;

    await runSearch(transformFilters(sanitizedData));
  };

  return (
    <FormAutoSaveProvider
      ignoreResourceIdAlways={true}
      values={sidebarFilters}
      inputSchema={sidebarFiltersSchema}
      onSubmit={handleSearch}
    >
      <div className="w-full relative">
        <SidebarFilters
          isProject={isProject}
          clearSidebarFilters={clearSidebarFilters}
        />
      </div>
    </FormAutoSaveProvider>
  );
};

export const SidebarFiltersWrapper: FC = () => {
  const ref = useRef<string>();
  const { pathname, search } = useLocation();
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const { sidebarFilters, setSidebarFilters } = useSidebarFiltersWithPath();

  const hasActiveFilters = !isSearchFilterEmpty(sidebarFilters);

  // orchestrate clearing and closing of the filter sidebar
  useEffect(() => {
    const processProjectRoutes = () => {
      const activeProjectTab = getQueryParams(search)?.tab;

      if (pathname.startsWith(AppRoutes.Projects)) {
        if (ref.current === AppRoutes.Attachments) {
          setSidebarFilters({});
          setOpenSidebar(false);
        } else if (
          pathname !== AppRoutes.Projects &&
          !pathname.startsWith(AppRoutes.ProjectsCollection) &&
          ProjectTabRoutes.Attachments !== activeProjectTab
        ) {
          setOpenSidebar(false);
        } else if (hasActiveFilters) {
          setOpenSidebar(true);
        }
        ref.current = AppRoutes.Projects;
      }
    };

    processProjectRoutes();
  }, [search, pathname, hasActiveFilters, setSidebarFilters, setOpenSidebar]);

  useEffect(() => {
    const processAttachmentsRoute = () => {
      if (pathname.startsWith(AppRoutes.Attachments)) {
        if (ref.current === AppRoutes.Projects) {
          setSidebarFilters({});
          setOpenSidebar(false);
        } else if (
          pathname !== AppRoutes.Attachments &&
          !pathname.startsWith(AppRoutes.AttachmentsCollection)
        ) {
          setOpenSidebar(false);
        } else if (hasActiveFilters) {
          setOpenSidebar(true);
        }
        ref.current = AppRoutes.Attachments;
      }
    };

    processAttachmentsRoute();
  }, [pathname, setOpenSidebar, hasActiveFilters, setSidebarFilters]);

  useEffect(() => {
    const closeSidebarForOtherRoutes = () => {
      if (
        !pathname.startsWith(AppRoutes.Projects) &&
        !pathname.startsWith(AppRoutes.Attachments)
      ) {
        setOpenSidebar(false);
      }
    };

    closeSidebarForOtherRoutes();
  }, [pathname, setOpenSidebar]);

  return (
    <>
      <LargerScreensSidebar>
        <FiltersWrapper />
      </LargerScreensSidebar>
      <SmallerScreensSidebar>
        <FiltersWrapper />
      </SmallerScreensSidebar>
    </>
  );
};
