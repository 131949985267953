import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { FC, ReactNode } from "react";
import { LogicalOperators, Option } from "@amenda-types";

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { FormComponentTypes } from "@amenda-constants";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { OperationsMenu } from "@amenda-components/Dashboard";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  component: any;
  title: string;
  options?: Option[];
  selectedOption?: string;
  defaultOpen?: boolean;
  hideBorder?: boolean;
  showOperationsMenu: boolean;
  children: ReactNode;
  handleSelectedOption: (e: any) => void;
}

export const Accordion: FC<Props> = ({
  title,
  children,
  component,
  defaultOpen,
  options = [],
  hideBorder = false,
  handleSelectedOption,
  selectedOption,
  showOperationsMenu,
}) => {
  const { t } = useTranslation();

  const hasOperationsMenu = [
    FormComponentTypes.Keyword,
    FormComponentTypes.MultiSelect,
    FormComponentTypes.Select,
  ].includes(component.component);
  const foundOption = selectedOption
    ? options.find((option) => option.value === selectedOption)
    : options.find((option) => option.value === LogicalOperators.AND);

  return (
    <div className="w-full mb-2">
      <Disclosure as="div" defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton
              as="div"
              className={clsx(
                "flex w-full justify-between px-2 text-left text-sm font-medium cursor-pointer",
                {
                  "border-b border-gray-200 py-2": !open,
                  "pt-2": open,
                },
              )}
            >
              <span className="font-apercu">{t(title)}</span>
              <div className="flex flex-row">
                {open && hasOperationsMenu && showOperationsMenu && (
                  <OperationsMenu
                    withIcon
                    Icon={FunnelIcon}
                    options={options}
                    selectedOption={foundOption?.value}
                    onChange={handleSelectedOption}
                  />
                )}
                <ChevronRightIcon
                  className={clsx("h-5 w-5 text-gray-600", {
                    "rotate-90 transform": open,
                    "ml-3": hasOperationsMenu,
                  })}
                />
              </div>
            </DisclosureButton>
            <DisclosurePanel
              className={clsx("px-2", {
                "border-b border-gray-200": !hideBorder,
              })}
            >
              {children}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
