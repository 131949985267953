import { FC, ReactNode } from "react";
import { ReactSelect, ReactSelectProps } from "./ReactSelect";

import { Option } from "@amenda-types";

interface Props extends ReactSelectProps {
  id: string;
  optional?: ReactNode;
  value?: string;
  className?: string;
  onChange: (value: any) => void;
  onBlur?: () => void;
}

export const SingleSelect: FC<Props> = ({
  options,
  value,
  onChange,
  ...rest
}) => {
  const getValue = (option: Option) => {
    return rest?.getOptionValue?.(option) ?? option?.value ?? "";
  };

  const resolvedValue = options.find((option) => value === getValue(option));

  const handleChange = (option: Option) => {
    onChange(getValue(option));
  };

  return (
    <ReactSelect
      isMulti={false}
      options={options}
      value={resolvedValue ?? null}
      onChange={handleChange}
      {...rest}
    />
  );
};
