import "mapbox-gl/dist/mapbox-gl.css";

import { FC, ReactNode, useMemo } from "react";
import { generateBoundsCoordinates, isCoordinateValid } from "./common";

import ReactMapboxGl from "react-mapbox-gl";
import mapboxgl from "mapbox-gl";

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const defaultLngLat = [13.454293, 52.515816];

interface Props {
  disableDragging?: boolean;
  children?: ReactNode;
  centerCoordinate?: any;
  doubleClickZoom?: boolean;
}

export const getCenterCoordinates: any = (
  centerCoordinate?: any,
  altCenterCoordinate?: any,
) => {
  if (isCoordinateValid(centerCoordinate)) {
    return generateBoundsCoordinates(centerCoordinate);
  }
  if (isCoordinateValid(altCenterCoordinate)) {
    return generateBoundsCoordinates(altCenterCoordinate);
  }
  return generateBoundsCoordinates(defaultLngLat);
};

export const Map: FC<Props> = ({
  children,
  disableDragging,
  doubleClickZoom = true,
  centerCoordinate = generateBoundsCoordinates(defaultLngLat),
}) => {
  const MapboxGlMap = useMemo(
    () =>
      ReactMapboxGl({
        doubleClickZoom,
        accessToken: process.env.REACT_APP_MAPBOX_CLIENT_ID as string,
        dragRotate: false,
        dragPan: !disableDragging,
        scrollZoom: !disableDragging,
        touchZoomRotate: false,
      }),
    [disableDragging, doubleClickZoom],
  );

  return (
    <MapboxGlMap
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/azimio/cla19ya75000t15nxs8y151d8"
      fitBounds={centerCoordinate}
      fitBoundsOptions={{
        maxZoom: 12,
      }}
      movingMethod="jumpTo"
      containerStyle={{
        height: "100%",
        width: "100%",
      }}
      onRender={(event: any) => {
        event.resize();
      }}
    >
      <>{children}</>
    </MapboxGlMap>
  );
};
