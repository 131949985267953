import { FC } from "react";
import { GripIcon } from "lucide-react";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import { SortItem } from "./DragAndDropWrapper";

export const OneThirdColumn: FC<LayoutProps> = ({
  config,
  children,
  isFormBuilder,
}) => {
  return (
    <SortItem
      className="col-span-4 md:col-span-2 group/one-third-col"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <IconButtonBase
          className="cursor-grab px-0.5 py-1 invisible group-hover/one-third-col:visible"
          variant="clean"
        >
          <GripIcon className="w-5 h-5 stroke-[2]" />
        </IconButtonBase>
      )}
      {children}
    </SortItem>
  );
};
