import * as faceapi from "face-api.js";

import { AppRoutes, ImageDescriptorType } from "@amenda-types";
import { CANVAS_ID, CDN_MODEL_URLS } from "./constants";
import React, { ReactNode, createContext, useEffect, useState } from "react";
import {
  convertImageToBase64,
  convertImgElementSrcToBase64,
  injectHtMLElements,
} from "./utils";

import { devConsole } from "@amenda-utils";
import { useGetAllImageDecorators } from "@amenda-domains/queries";
import { useImageRecognitionStore } from "@amenda-domains/mutations";
import { useLocation } from "react-router-dom";

interface FaceAPIContextType {
  isModelLoaded: boolean;
  processing: boolean;
  detectedFaces: any[];
  cleanUpFaceDetection: () => void;
  processImageForFaceRecognition: (imgElementId: string) => void;
}

export const FaceAPIContext = createContext<FaceAPIContextType | undefined>(
  undefined,
);

interface Props {
  children: ReactNode;
}

export const FaceAPIProvider = ({ children }: Props) => {
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [faceMatcher, setFaceMatcher] = useState<any>(null);
  const { getAllImageDescriptors } = useGetAllImageDecorators();
  const faceImageDescriptors = useImageRecognitionStore(
    (state) => state.faceImageDescriptors,
  );
  const detectedFaces = useImageRecognitionStore(
    (state) => state.detectedFaces,
  );
  const setDetectedFaces = useImageRecognitionStore(
    (state) => state.setDetectedFaces,
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isModelLoaded && pathname === AppRoutes.Attachments) {
      Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri(
          CDN_MODEL_URLS.Mobilenetv1Model,
        ),
        faceapi.nets.faceLandmark68Net.loadFromUri(
          CDN_MODEL_URLS.FaceLandmarkModel,
        ),
        faceapi.nets.faceRecognitionNet.loadFromUri(
          CDN_MODEL_URLS.FaceRecognitionModel,
        ),
      ])
        .then(() => {
          setIsModelLoaded(true);
        })
        .catch((error) => {
          devConsole?.error("Error loading models:", error);
        });
    }
  }, [isModelLoaded, pathname]);

  useEffect(() => {
    getAllImageDescriptors({
      type: ImageDescriptorType.face,
      selectImageDescriptorFields: {
        type: 1,
        label: 1,
        sourceURL: 1,
        descriptor: 1,
      },
    });
  }, [getAllImageDescriptors]);

  useEffect(() => {
    const loadFaceDescriptorsIntoFaceMatcher = async () => {
      if (faceImageDescriptors.length < 1 || !isModelLoaded) return;

      const labeledFaceDescriptors = await Promise.all(
        faceImageDescriptors.map(async (imageDescriptor: any) => {
          const descriptors: any[] = [];

          descriptors.push(new Float32Array(imageDescriptor.descriptor));
          return new faceapi.LabeledFaceDescriptors(
            imageDescriptor.id + "[]" + imageDescriptor?.label,
            descriptors,
          );
        }),
      );

      setFaceMatcher(new faceapi.FaceMatcher(labeledFaceDescriptors, 0.4));
    };
    loadFaceDescriptorsIntoFaceMatcher();
  }, [faceImageDescriptors, isModelLoaded]);

  const processImageForFaceRecognition = async (imgElementId: string) => {
    setProcessing(true);
    // remove canvas if exists
    if (!isModelLoaded) {
      setProcessing(false);
      return;
    }

    convertImgElementSrcToBase64(imgElementId, async (imgEl) => {
      injectHtMLElements(imgEl);

      const results = await faceapi
        .detectAllFaces(
          imgEl,
          new faceapi.SsdMobilenetv1Options({ minConfidence: 0.2 }),
        )
        .withFaceLandmarks()
        .withFaceDescriptors();

      drawFaceRecognitionResults(
        results,
        imgEl,
        async (recognitionResults: any) => {
          setProcessing(false);
          await createDetectedFacesList(recognitionResults, imgEl);
        },
      );
    });
  };

  const createDetectedFacesList = async (
    recognitionResults: any,
    imgEl: any,
  ) => {
    let faces = [];

    for (const {
      detection,
      id,
      label,
      descriptor,
      dimensions,
    } of recognitionResults) {
      const sourceURL = await getFaceImageFromDetections(detection, imgEl);

      faces.push({
        id,
        type: "face",
        label,
        sourceURL,
        dimensions,
        descriptor: Array.from(descriptor),
      });
    }

    setDetectedFaces(faces);
  };

  const drawFaceRecognitionResults = (
    results: any,
    imgEl: any,
    callback: (descriptors: any) => void,
  ) => {
    const canvas: any = document.getElementById(CANVAS_ID);
    if (!canvas || !imgEl) return;

    const height: number = (imgEl as HTMLImageElement).clientHeight;
    const width: number = (imgEl as HTMLImageElement).clientWidth;

    faceapi.matchDimensions(canvas, {
      width,
      height,
    });
    const resizedResults = faceapi.resizeResults(results, {
      width,
      height,
    });
    let recognitionResults: any[] = [];

    resizedResults.forEach(({ detection, descriptor, landmarks }: any) => {
      const mid = faceMatcher?.findBestMatch(descriptor).toString().split("[]");
      const faceLabel = mid?.[1];
      const faceId = mid?.[0].trim();

      const options = {
        boxColor: "transparent",
      };
      const drawBox: any = new faceapi.draw.DrawBox(detection.box, options);

      recognitionResults.push({
        id: faceId,
        label: faceLabel,
        detection,
        descriptor,
        dimensions: {
          top: landmarks.shift.y,
          left: landmarks.shift.x,
          width: landmarks.imageWidth,
          height: landmarks.imageHeight,
        },
      });

      drawBox.draw(canvas);
    });
    callback(recognitionResults);
  };

  const getFaceImageFromDetections = async (detection: any, imgEl: any) => {
    const imgElement = document.createElement("img");
    imgElement.src = imgEl.src;

    const faceImages = await faceapi.extractFaces(imgElement, [detection]);

    const extractedFace = faceImages[0];
    // Convert the canvas content to base64
    const base64String = convertImageToBase64(extractedFace);
    return base64String;
  };

  const cleanUpFaceDetection = () => {
    setDetectedFaces([]);
  };

  const value = {
    isModelLoaded,
    processing,
    detectedFaces,
    cleanUpFaceDetection,
    processImageForFaceRecognition,
  };

  return (
    <FaceAPIContext.Provider value={value}>{children}</FaceAPIContext.Provider>
  );
};

export const useFaceAPI = (): FaceAPIContextType => {
  const context = React.useContext(FaceAPIContext);
  if (!context) {
    throw new Error("useFaceAPI must be used within a FaceAPIProvider");
  }
  return context;
};
