import { AvailableForms, getCostGroupInputSchema } from "@amenda-constants";
import { FC, useEffect } from "react";
import {
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapperBase,
} from "@amenda-components/PageBuilder";
import {
  getComponentCodeById,
  transformCostGroupData,
  transformCostGroupForm,
} from "./common";
import { useProjectStore, useUpdateCostGroup } from "@amenda-domains/mutations";

import { CostGroupFormWrapper } from "./CostGroupFormWrapper";
import { getComponentsFromForms } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useGetAllCostGroups } from "@amenda-domains/queries";

export const CreateOrEditProjectCostGroups: FC<{ projectId?: string }> = ({
  projectId,
}) => {
  const costGroupValues = useProjectStore(
    (state) => state.projectCostGroupForm
  );
  const selectedCostGroupId = useProjectStore(
    (state) => state.selectedCostGroupId
  );
  const setIsSavingProject = useProjectStore(
    (state) => state.setIsSavingProject
  );
  const forms = useProjectStore((state) => state.forms);
  const { updateCostGroup } = useUpdateCostGroup();
  const { getAllCostGroups } = useGetAllCostGroups();

  const costGroupCostForms = forms?.[AvailableForms.CostGroupsCost] ?? [];
  const costGroupQuantityForms =
    forms?.[AvailableForms.CostGroupsQuantity] ?? [];
  const components = getComponentsFromForms([
    ...costGroupCostForms,
    ...costGroupQuantityForms,
  ]);
  const { componentCodeById } = getComponentCodeById(components);
  const costGroupInputSchema = getCostGroupInputSchema(components);
  const values = transformCostGroupData(costGroupValues);

  useEffect(() => {
    if (projectId) {
      getAllCostGroups({
        resourceId: projectId,
        isDeleted: false,
      });
    }
  }, [getAllCostGroups, projectId]);

  const onSubmit = async ({
    dirtyData,
    resourceId,
  }: FormAutoSaveSubmitProps) => {
    setIsSavingProject(true);
    if (!isEmpty(dirtyData) && resourceId) {
      const data = transformCostGroupForm(componentCodeById, dirtyData);
      const input = {
        _id: resourceId,
        ...data,
      };

      await updateCostGroup({
        input,
        options: {
          partial: true,
        },
      });
    }
    setIsSavingProject(false);
  };

  return (
    <FormAutoSaveWrapperBase
      key={selectedCostGroupId}
      className="pb-6 pt-2"
      inputSchema={costGroupInputSchema}
      resourceId={selectedCostGroupId}
      values={values}
      onSubmit={onSubmit}
    >
      {({ control, setValue, getValues }) => (
        <>
          {control && setValue && getValues && (
            <CostGroupFormWrapper
              control={control}
              setValue={setValue}
              getValues={getValues}
              isDisabled={isEmpty(selectedCostGroupId)}
            />
          )}
        </>
      )}
    </FormAutoSaveWrapperBase>
  );
};
