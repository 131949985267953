import { AddressSearch, TextField } from "@amenda-components/FormComponents";

import { Controller } from "react-hook-form";
import { FC } from "react";
import { FullColumn } from "@amenda-components/PageLayouts";
import { SingleFileUploader } from "@amenda-components/App";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const TenantForm: FC<{
  control: any;
  tenant: any;
}> = ({ control, tenant }) => {
  const { t } = useTranslation();
  const setSelectedImageFile = useAttachmentStore(
    (state) => state.setSelectedImageFile
  );

  return (
    <FullColumn>
      <div className="max-w-[500px]">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="amenda-settings-heading">
              {t("Office Information")}
            </h1>
            <p className="amenda-settings-subline "></p>
          </div>
        </div>
      </div>
      <div className="mt-2 mb-8 group">
        <SingleFileUploader
          title="Logo"
          isDisabled={Boolean(tenant?.logoUrl)}
          onFileChange={setSelectedImageFile}
        >
          {({ getFileSrc, openFileUploader }) => (
            <>
              {getFileSrc(tenant?.logoUrl) && (
                <button
                  type="button"
                  className="max-w-[200px] hover:opacity-60 transition-opacity duration-300 ease-in-out"
                  onClick={openFileUploader}
                >
                  <img alt="Office Logo" src={getFileSrc(tenant?.logoUrl)} />
                </button>
              )}
            </>
          )}
        </SingleFileUploader>
      </div>
      <div className="mt-2">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name="displayName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="displayName"
                    label="Organization Display Name"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 mb-8">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name={"officeAddress"}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <AddressSearch
                    id="address"
                    label="Address"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name="technicalContactPerson"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="technicalContactPerson"
                    label="Contact Person"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 hidden">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name="responsibleContactPerson"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="responsibleContactPerson"
                    label="Responsible Contact Person"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name="billingEmailAddress"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="billingEmailAddress"
                    label="Billing E-mail Address"
                    type="email"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg">
            <Controller
              control={control}
              name="taxId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="taxId"
                    label="Tax ID"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </FullColumn>
  );
};
