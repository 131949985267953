import { Resolver, ResolverConfig, Variables } from "@urql/exchange-graphcache";
import { isArray, uniq } from "lodash";

import { stringifyVariables } from "@urql/core";

enum Pagination {
  NEXT = "next",
  PREVIOUS = "previous",
  LIMIT = "limit",
}

const paginationKeys = [
  Pagination.NEXT,
  Pagination.PREVIOUS,
  Pagination.LIMIT,
] as string[];

const hasSimilarArguments = (
  parentArgs: Variables,
  fieldArgs: Variables | null,
) => {
  let matches = true;

  if (!fieldArgs) {
    return !matches;
  }

  const parentKeys = Object.keys(parentArgs).filter(
    (key) => !paginationKeys.includes(key),
  );
  const parentKeysMatched: string[] = [];

  Object.keys(fieldArgs).forEach((key) => {
    if (paginationKeys.includes(key) || !matches) {
      return;
    }
    parentKeysMatched.push(key);
    if (isArray(fieldArgs[key]) || typeof fieldArgs[key] === "object") {
      matches =
        stringifyVariables(fieldArgs[key]) ===
        stringifyVariables(parentArgs[key]);
    } else {
      matches = fieldArgs[key] === parentArgs[key];
    }
  });
  matches = matches && parentKeys.length === parentKeysMatched.length;
  return matches;
};

const simplePagination = (property: string, typeName: string): Resolver => {
  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info;

    const allFields = cache.inspectFields(entityKey);
    const matchingFields = allFields.filter(
      (info) => info.fieldName === fieldName,
    );
    const size = matchingFields.length;

    if (size === 0) {
      return undefined;
    }

    const fieldKey = `${fieldName}(${stringifyVariables(fieldArgs)})`;
    const isInCache = cache.resolve(
      cache.resolve(entityKey, fieldKey) as string,
      property,
    );

    const results: string[] = [];
    matchingFields
      .filter((field) => hasSimilarArguments(fieldArgs, field.arguments))
      .forEach((field) => {
        const { fieldKey } = field;

        const data = cache.resolve(
          cache.resolve(entityKey, fieldKey) as string,
          property,
        ) as any[];

        if (data) {
          results.push(...data);
        }
      });

    info.partial = !isInCache;

    return {
      [property]: uniq(results),
      __typename: typeName,
    };
  };
};

export const resolvers: ResolverConfig = {
  Query: {
    getAllUsers: simplePagination("users", "PaginatedUser"),
    getAllContacts: simplePagination("contacts", "PaginatedContact"),
    getAllProjects: simplePagination("projects", "PaginatedProject"),
    getAllMaterials: simplePagination("materials", "PaginatedMaterial"),
    getAllSystemRoles: simplePagination("roles", "PaginatedSystemRole"),
    getAllAttachments: simplePagination("attachments", "PaginatedAttachment"),
    getAllRegionalFactors: simplePagination(
      "regionalFactors",
      "PaginatedRegionalFactor",
    ),
  },
};
