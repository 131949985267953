import { Button, Modal } from "@amenda-components/App";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  useCreateImageDescriptor,
  useImageRecognitionStore,
  useUpdateImageDescriptor,
} from "@amenda-domains/mutations";

import { FC } from "react";
import { TextField } from "@amenda-components/FormComponents";
import { detectedFaceSchema } from "@amenda-constants";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormProps {
  label: string;
}

export const DetectedFaceModal: FC = () => {
  const { t } = useTranslation();
  const { createImageDescriptor, loading } = useCreateImageDescriptor();
  const { upateImageDescriptor, loading: updateImageDescriptorLoader } =
    useUpdateImageDescriptor();
  const { isOpen, selectedFace } = useImageRecognitionStore(
    (state) => state.detectedFaceModal
  );
  const setDetectedFaceModal = useImageRecognitionStore(
    (state) => state.setDetectedFaceModal
  );

  const { originalLabel, hasDescriptor, dimensions, id, ...rest } =
    selectedFace;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<FormProps>({
    values: {
      label: originalLabel,
    },
    resolver: yupResolver(detectedFaceSchema),
  });

  const onClose = () => {
    reset();
    setDetectedFaceModal(false);
  };

  const upsertImageDescriptor = async (data: any) => {
    const input = hasDescriptor
      ? {
          ...data,
          _id: id,
        }
      : data;
    const handleSave = hasDescriptor
      ? upateImageDescriptor
      : createImageDescriptor;

    await handleSave({ input });
    onClose();
  };

  const onSubmit: SubmitHandler<any> = async (values) => {
    const data = {
      ...rest,
      ...values,
    };

    await upsertImageDescriptor(data);
  };

  return (
    <Modal
      isElevated={true}
      isOpen={isOpen}
      size="md"
      title="Assign Label"
      withCancel={false}
      closeModalFromTitle={true}
      onClose={onClose}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <form
        className="flex flex-col space-y-2 w-120 pb-10 px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="label"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="label"
                label="Label"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            );
          }}
        />
        <Button
          withGroup={true}
          loading={loading || updateImageDescriptorLoader}
          type="submit"
          size="lg"
          variant="primary"
          disabled={!isDirty}
        >
          <span className="w-full text-center">{t("Save")}</span>
        </Button>
      </form>
    </Modal>
  );
};
