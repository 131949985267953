import { FC } from "react";
import { MiniCard } from "@amenda-components/Shared";
import { isRestricted } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  componentsById: Record<string, any>;
  permissions: Record<string, any>;
  project: any;
}

export const ProjectMiniCard: FC<Props> = ({
  componentsById,
  permissions,
  project,
}) => {
  const navigate = useNavigate();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );

  const projectName = componentsById["name"];
  const projectDescription = componentsById["description"];

  const handleClick = () => {
    setSelectedProject({});
    navigate(`/projekte/${project?.id}`);
  };

  return (
    <div className="cursor-pointer w-full" onClick={handleClick}>
      <MiniCard
        image={project.galleryUrl}
        projectName={!isRestricted(projectName, permissions) && project?.name}
        projectDescription={
          !isRestricted(projectDescription, permissions) && project.description
        }
        enableHighlighting={true}
      />
    </div>
  );
};
