import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  BuildingOfficeIcon,
  CubeIcon,
  CurrencyEuroIcon,
  PhotoIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Button, IconButtonBase } from "@amenda-components/App";
import { ConstructionIcon, GripVerticalIcon } from "lucide-react";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "./FormBuilderDndComponents";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useAppStore, useUpdateMultipleForms } from "@amenda-domains/mutations";

import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { FormBuilderCreateFormButton } from "./FormBuilderCreateFormButton";
import { FormCategories } from "@amenda-constants";
import { FormTab } from "@amenda-types";
import clsx from "clsx";
import { getCategorySidebar } from "./common";
import isEmpty from "lodash/isEmpty";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useGetFormsByCategory } from "./hooks";
import { useTranslation } from "react-i18next";

const Icons: FC<{ value?: FormCategories }> = ({ value }) => {
  switch (value) {
    case FormCategories.Projects:
      return <BuildingOfficeIcon className="w-5 h-5" />;
    case FormCategories.Media:
      return <PhotoIcon className="w-5 h-5" />;
    case FormCategories.ConstructionDetails:
      return <ConstructionIcon className="w-5 h-5" />;
    case FormCategories.UnitPrice:
      return <CubeIcon className="w-5 h-5" />;
    case FormCategories.CostGroups:
      return <CurrencyEuroIcon className="w-5 h-5" />;
    default:
      return <UsersIcon className="w-5 h-5" />;
  }
};

export const FormBuilderSectionsSidebar: FC = () => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const { updateMultipleForms, loading } = useUpdateMultipleForms();
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { availableForms, setAvailableForms } = useGetFormsByCategory();

  const { selectedCategory, selectedForm } = formBuilderState ?? {};
  const label =
    getCategorySidebar(true).find(
      (category) => category.value === selectedCategory,
    )?.label ?? "";
  const availableFormIds = availableForms.map((f) => f.id);
  const activeForm = availableForms.find((f) => f.id === activeId);

  const handleGoBack = () => {
    updateFormBuilderState("selectedCategory", undefined);
  };

  const handleSelectForm = (form: FormTab) => () => {
    updateFormBuilderState("selectedForm", form);
  };

  const handleUpdatedForms = (updatedFormIds: string[]) => {
    const allForms: any[] = [];
    const updatedForms: any[] = [];
    const formsById: Record<string, any> = {};

    availableForms.forEach((form) => {
      formsById[form.id] = form;
    });

    updatedFormIds.forEach((id, i) => {
      const form = {
        ...formsById[id],
        order: i,
      };

      if (formsById[id].order !== i) {
        updatedForms.push(form);
      }
      allForms.push(form);
    });

    return {
      allForms,
      updatedForms,
    };
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const oldIndex = availableFormIds.indexOf(active.id);
    const newIndex = availableFormIds.indexOf(over.id);
    const updatedFormIds = arrayMove(availableFormIds, oldIndex, newIndex);
    const { allForms, updatedForms } = handleUpdatedForms(updatedFormIds);

    if (!isEmpty(updatedForms)) {
      await updateMultipleForms({
        forms: updatedForms.map((form) => ({
          _id: form.id,
          order: form.order,
        })),
      });
    }
    setAvailableForms(allForms);
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveId(String(active.id));
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <SortableContext
        id="formBuilderSidebar"
        items={availableFormIds}
        strategy={verticalListSortingStrategy}
      >
        <FormBuilderSortDroppable className="w-full h-full px-2">
          <div className="flex items-center pb-4">
            <Icons value={selectedCategory} />
            <span className="ml-2">{t(label)}</span>
            <div className="justify-self-end ml-auto">
              <Button onClick={handleGoBack}>
                <div className="flex items-center">
                  <ArrowLongLeftIcon className="w-5 h-5" />
                  <span className="ml-1">{t("Go back")}</span>
                </div>
              </Button>
            </div>
          </div>
          <div className="w-full space-y-2 flex flex-col">
            {availableForms.map((form) => (
              <FormBuilderSortableItem key={form.id} id={form.id}>
                {(isDragging) => (
                  <div
                    role="button"
                    className={clsx(
                      "w-full px-1 py-2 cursor-pointer text-sm text-left rounded-sm outline-none",
                      {
                        "bg-gray-800 text-white": selectedForm?.id === form.id,
                        "text-gray-800 bg-white hover:bg-gray-800 hover:text-white":
                          selectedForm?.id !== form.id,
                        "animate-pulse": loading,
                        invisible: isDragging,
                      },
                    )}
                    onClick={handleSelectForm(form)}
                  >
                    <div className="w-full flex items-center">
                      <div>
                        <IconButtonBase className="cursor-grab px-0.5 py-1">
                          <GripVerticalIcon className="w-5 h-5 stroke-[2]" />
                        </IconButtonBase>
                      </div>
                      <span className="ml-2 truncate">{form.name}</span>
                    </div>
                  </div>
                )}
              </FormBuilderSortableItem>
            ))}
            <FormBuilderCreateFormButton />
          </div>
        </FormBuilderSortDroppable>
      </SortableContext>
      <DragOverlay modifiers={[restrictToVerticalAxis]}>
        {activeId && activeForm && (
          <FormBuilderDragOverlayItem>
            <div className="w-full flex items-center">
              <div>
                <IconButtonBase className="cursor-grab px-0.5 py-1">
                  <GripVerticalIcon className="w-5 h-5 stroke-[2]" />
                </IconButtonBase>
              </div>
              <span className="ml-2 truncate">{activeForm.name}</span>
            </div>
          </FormBuilderDragOverlayItem>
        )}
      </DragOverlay>
    </DndContext>
  );
};
