import { FC, useEffect } from "react";

import { Button } from "@amenda-components/App";
import { CompanySchema } from "@amenda-constants";
import { InferType } from "yup";
import { TenantForm } from "@amenda-components/Tenant";
import { sanitizeData } from "@amenda-utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

type TenantInput = InferType<typeof CompanySchema>;

export const TenantFormWrapper: FC<{
  tenant: any;
  loading: boolean;
  onFormSubmit: (args: any) => void;
}> = ({ tenant, loading, onFormSubmit }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<TenantInput>({
    resolver: yupResolver(CompanySchema),
  });

  const onSubmit = async (data: TenantInput) => {
    const dataForm = sanitizeData(data);
    await onFormSubmit(dataForm);
  };

  useEffect(() => {
    const getValues = (tenant: any = {}) => {
      let officeAddress: any = {};
      if (tenant?.officeAddress) {
        const { __typename, ...rest } = tenant?.officeAddress;
        officeAddress = rest;
      }
      return {
        officeAddress,
        displayName: tenant?.displayName || "",
        technicalContactPerson: tenant?.technicalContactPerson || "",
        responsibleContactPerson: tenant?.responsibleContactPerson || "",
        billingEmailAddress: tenant?.billingEmailAddress || "",
        taxId: tenant?.taxId || "",
      };
    };
    if (tenant) {
      reset(getValues(tenant));
    }
  }, [tenant, reset]);

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <TenantForm tenant={tenant} control={control} />
      <div className="flex justify-start">
        <Button type="submit" variant="secondary" size="md" loading={loading}>
          {t("Save")}
        </Button>
      </div>
    </form>
  );
};
