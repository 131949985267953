import { MainContainer, SidebarContainer } from "@amenda-components/Shared";

import { BulkEditorAttachmentsForm } from "@amenda-components/Gallery";
import { FC } from "react";
import { Image } from "@amenda-components/App";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const MediaBulkEditorAttachmentsView: FC = () => {
  const { t } = useTranslation();
  const attachments = useAttachmentStore(
    (state) => state.bulkEditorState.attachments
  );

  return (
    <>
      <MainContainer className="lg:px-5 lg:py-4 px-2">
        <div className="w-full h-full overflow-y-auto overscroll-y-contain">
          <div className="w-full pb-4 sticky top-0 bg-white z-30">
            <span className="amenda-component-label">
              {t("BulkEditCounter", {
                totalAttachments: attachments.length,
              })}
            </span>
          </div>
          <div className="w-full grid grid-cols-5 gap-2">
            {attachments.map((attachment) => (
              <div key={attachment.id}>
                <Image
                  url={attachment.url}
                  magicSize="gallery"
                  background="bg-gray-100"
                />
                <div className="w-full truncate">
                  <div className="text-sm text-gray-800 truncate pt-1">
                    {attachment?.name ??
                      attachment?.originalFilename ??
                      attachment?.filename}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </MainContainer>
      <SidebarContainer>
        <BulkEditorAttachmentsForm />
      </SidebarContainer>
    </>
  );
};
