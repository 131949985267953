export const SSD_MOBILENETV1 = "ssd_mobilenetv1";
export const CANVAS_ID = "amendaCanvasOverlay";
export const MODEL_URLS = {
  Mobilenetv1Model:
    "https://raw.githubusercontent.com/ml5js/ml5-data-and-models/main/models/faceapi/ssd_mobilenetv1_model-weights_manifest.json",
  FaceLandmarkModel:
    "https://raw.githubusercontent.com/ml5js/ml5-data-and-models/main/models/faceapi/face_landmark_68_model-weights_manifest.json",
  FaceRecognitionModel:
    "https://raw.githubusercontent.com/ml5js/ml5-data-and-models/main/models/faceapi/face_recognition_model-weights_manifest.json",
};

export const CDN_MODEL_URLS = {
  Mobilenetv1Model:
    "https://cdn.jsdelivr.net/npm/@vladmandic/face-api@1.6.1/model/ssd_mobilenetv1_model-weights_manifest.json",
  FaceLandmarkModel:
    "https://cdn.jsdelivr.net/npm/@vladmandic/face-api@1.6.1/model/face_landmark_68_model-weights_manifest.json",
  FaceRecognitionModel:
    "https://cdn.jsdelivr.net/npm/@vladmandic/face-api@1.6.1/model/face_recognition_model-weights_manifest.json",
};
