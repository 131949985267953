import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { FC, ForwardRefExoticComponent, ReactNode } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  title?: string;
  description?: string;
  className?: string;
  Icon?: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  children: ReactNode;
  Footer?: ReactNode;
  onClose: () => void;
}

export const SlideOver: FC<Props> = ({
  children,
  description,
  title,
  show,
  Icon,
  Footer,
  className = "w-full md:w-96",
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Transition show={show}>
      <Dialog
        as="div"
        className="relative inset-0 overflow-hidden"
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden w-full">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-50 fixed inset-0 transition bg-black/10 backdrop-blur-sm" />
          </TransitionChild>
          <div
            className={clsx("fixed inset-y-0 right-0 flex z-[70]", className)}
          >
            <TransitionChild
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl w-full">
                {title && (
                  <div className="lg:py-6 px-6 py-2 lg:px-4 bg-gray-900">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="font-medium text-white">
                        <div className="flex flex-row">
                          {Icon && <Icon className="h-6 w-6 mr-2" />}
                          {t(title)}
                        </div>
                      </DialogTitle>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={onClose}
                        >
                          <span className="sr-only">{t("Close panel")}</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    {description && (
                      <div className="mt-1">
                        <p className="text-sm font-apercu text-indigo-300">
                          {t(description)}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <div className="w-full overflow-y-auto overscroll-y-contain h-full">
                  {children}
                </div>
                {Footer}
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
