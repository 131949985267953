import { Control, useWatch } from "react-hook-form";

import { FC } from "react";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { Option } from "@amenda-types";
import { SystemRolesSelect } from "./SystemRolesSelect";
import { isEmpty } from "lodash";
import { sharePermissionsOptions } from "./common";

interface RoleProfileCardProps {
  systemRole: any;
  handleChangePermission: (option: Option) => void;
}

const RoleProfileCard: FC<RoleProfileCardProps> = ({
  systemRole,
  handleChangePermission,
}) => {
  return (
    <div className="flex justify-between items-center w-full border border-gray-300 p-2">
      <div className="flex flex-row gap-4 items-center">
        <div className="flex flex-col space-y-1 items-start">
          <div className="text-sm font-apercu font-bold text-gray-800">
            {systemRole.name}
          </div>
          <div className="text-xs font-apercu text-gray-400 max-w-64">
            {systemRole.description}
          </div>
        </div>
      </div>
      <OperationsMenu
        indicatorIconClassName="h-3 w-3"
        labelClassName="text-xs font-medium text-gray-500"
        menuItemsClassName="right-0"
        options={sharePermissionsOptions}
        selectedOption={systemRole?.permission}
        onChange={handleChangePermission}
      />
    </div>
  );
};

interface Props {
  id: string;
  control: Control<any>;
}

export const CollectionRoles: FC<Props> = ({ id, control }) => {
  const isShared = useWatch({
    control,
    exact: true,
    name: "shareable",
  });

  const handleChangePermission =
    ({
      value,
      fieldIndex,
      update,
    }: {
      value: any;
      fieldIndex: number;
      update: (index: number, value: any) => void;
    }) =>
    (option: Option) => {
      update(fieldIndex, { ...value, permission: option.value });
    };

  if (!isShared) {
    return null;
  }
  return (
    <div className="w-100 block mt-2">
      <SystemRolesSelect
        id={id}
        control={control}
        isMulti={true}
        label="Share with Roles"
      >
        {({ fields, update }) => (
          <>
            {!isEmpty(fields) && (
              <ul className="flex flex-col space-y-2">
                {fields.map((field, i) => {
                  return (
                    <li key={field.id}>
                      <RoleProfileCard
                        systemRole={field}
                        handleChangePermission={handleChangePermission({
                          value: field,
                          fieldIndex: i,
                          update,
                        })}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </SystemRolesSelect>
    </div>
  );
};
