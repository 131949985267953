import { FC } from "react";
import clsx from "clsx";

interface Props {
  starClassName?: string;
  sparkleClassName?: string;
  totalElements?: number; // Number of stars & sparkles
}

const random = (min: number, max: number) => {
  return Math.floor(Math.random() * max + min);
};

export const Sparkle: FC<Props> = ({
  totalElements = 40,
  sparkleClassName = "bg-white",
  starClassName = "before:bg-white after:bg-white",
}) => {
  const shapes = ["amenda-sparkle", "amenda-star"];
  const sizes = ["", "medium", "small"];
  const animations = [
    "amenda-pulse1",
    "amenda-pulse2",
    "amenda-pulse3",
    "amenda-pulse4",
  ];

  const elements = Array.from(
    { length: totalElements },
    (_, index) => index + 1,
  );

  return (
    <div className="w-full h-full">
      {elements.map((element) => {
        const shape = shapes[random(0, shapes.length)];

        return (
          <div
            key={String(element) + "-amenda-sparkles"}
            className={clsx(
              shape,
              sizes[random(0, sizes.length)],
              animations[random(0, animations.length)],
              {
                [starClassName]: shape === "amenda-star",
                [sparkleClassName]: shape === "amenda-sparkle",
              },
            )}
            style={{
              top: random(0, 100) + "%",
              left: random(0, 100) + "%",
            }}
          />
        );
      })}
    </div>
  );
};
