import {
  ActionMeta,
  FormatOptionLabelMeta,
  StylesConfig,
  components,
} from "react-select";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { FC, KeyboardEventHandler, ReactNode, useRef } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";

import { ErrorMessage } from "./ErrorMessage";
import { Option } from "@amenda-types";
import Select from "react-select/creatable";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  backspaceRemovesValue?: boolean;
  classNames?: Record<string, any>;
  components?: Record<string, any>;
  controlShouldRenderValue?: boolean;
  formatCreateLabel?: (inputValue: string) => ReactNode | string;
  formatGroupLabel?: (group: any) => any;
  formatOptionLabel?: (option: any, meta: FormatOptionLabelMeta<Option>) => any;
  id?: string;
  inputValue?: string;
  options: any[];
  label?: string;
  error?: string;
  optional?: ReactNode;
  value?: Option[] | Option;
  placeholder?: string;
  disabled?: boolean;
  isMulti?: boolean;
  className?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  menuIsOpen?: boolean;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  styles?: Partial<StylesConfig<any, boolean, any>>;
  onChange: (value: any, meta: ActionMeta<any>) => void;
  onBlur?: () => void;
  onInputChange?: (inputValue: string) => void;
  onCreate?: (inputValue: string) => void;
  onKeyDown?: KeyboardEventHandler;
}

export const createOption = (label: string, value: string): Option => ({
  label,
  value,
});

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.ClearIndicator>
  );
};

export const ReactSelectCreatable: FC<Props> = ({
  className = "mb-2",
  disabled,
  error,
  id = "",
  isMulti = false,
  isClearable = true,
  hasMenuOverflow = false,
  hideErrorMessage = false,
  label,
  onCreate,
  optional,
  placeholder,
  styles = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className={clsx("group/reactSelectCreatable", className)}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional}
      </div>
      <Select
        className={customStyling.select.containerClass}
        placeholder={placeholder && t(placeholder)}
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable={isClearable}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? t("No results found") : t("Start typing")
        }
        styles={{
          ...customStyling.select.styleOverride,
          ...selectMenuOverflow(hasMenuOverflow, ref),
          ...styles,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        components={{
          ClearIndicator,
          DropdownIndicator,
        }}
        onCreateOption={onCreate}
        {...selectMenuOverflowProps(hasMenuOverflow)}
        {...rest}
      />
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
