import { FC, ReactNode } from "react";

import { AllowedContactType } from "@amenda-types";
import { ContactProfileCardBase } from "@amenda-components/Contacts";
import { HeadlessContacts } from "@amenda-components/Contacts";
import { IconButton } from "@amenda-components/App";
import { TrashIcon } from "lucide-react";

interface Props {
  id: string;
  control: any;
  label: string;
  isMulti?: boolean;
  contactType: AllowedContactType;
  optional?: ReactNode;
}

export const SearchContact: FC<Props> = (props) => {
  return (
    <HeadlessContacts {...props}>
      {({ contact, remove }) => {
        return (
          <div className="w-full flex flex-row justify-between items-center">
            <ContactProfileCardBase contact={contact} />
            <div className="items-center justify-between h-7 ">
              {remove && (
                <IconButton
                  label="Delete item"
                  iconSize={1}
                  className="text-gray-600 mr-4 md:mr-0"
                  Icon={TrashIcon}
                  onClick={remove}
                />
              )}
            </div>
          </div>
        );
      }}
    </HeadlessContacts>
  );
};
