import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import {
  EditProjectMedia,
  ProjectFormsWrapper,
  ProjectTemplateDesigner,
} from "@amenda-components/Projects";
import { FC, ReactNode } from "react";
import {
  addQueryToPath,
  staticProjectLabels,
} from "@amenda-components/Projects/common";
import { useLocation, useNavigate } from "react-router-dom";

import { CreateOrEditProjectCostGroups } from "@amenda-components/CostGroup/CreateOrEditProjectCostGroups";
import { EditProjectConstructionDetails } from "./EditProjectConstructionDetails";
import { HeadlessTabs } from "@amenda-components/App";
import { Timeline } from "@amenda-components/Timeline";
import clsx from "clsx";
import { isEmpty } from "lodash";

interface Props {
  defaultTab: string;
  projectId?: string;
  projectForms: FormTab[];
  projectFormTabs: TabItem[];
}

const showCurrentTab = ({
  tab,
  projectId,
  projectForms,
}: {
  tab: TabItem;
  projectForms: FormTab[];
  projectId?: string;
}) => {
  const tabComponents: Record<string, ReactNode> = {
    [staticProjectLabels.constructionDetails.value]: (
      <EditProjectConstructionDetails projectId={projectId} />
    ),
    [staticProjectLabels.attachments.value]: (
      <div className="amenda-form pt-5">
        <EditProjectMedia projectId={projectId} />
      </div>
    ),
    [staticProjectLabels.designs.value]: (
      <ProjectTemplateDesigner projectId={projectId} />
    ),
    [staticProjectLabels.costGroups.value]: (
      <CreateOrEditProjectCostGroups projectId={projectId} />
    ),
    [staticProjectLabels.activity.value]: (
      <Timeline type={AllowedTimelineTypes.Project} resourceId={projectId} />
    ),
  };
  projectForms.forEach((form) => {
    tabComponents[form.id] = <ProjectFormsWrapper form={form} />;
  });

  return tabComponents[tab.value];
};

export const ProjectFormTabs: FC<Props> = ({
  projectId,
  defaultTab,
  projectForms,
  projectFormTabs,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const foundIndex = projectFormTabs.findIndex(
    (tab) => tab.value === defaultTab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  const handleChange = (tab: TabItem) => {
    navigate(
      addQueryToPath(pathname, {
        tab: tab.value,
      }),
      { replace: true },
    );
  };

  if (isEmpty(projectForms)) {
    return null;
  }
  return (
    <HeadlessTabs
      panelsClassName="pt-4"
      tabListClassName="border-0"
      tabs={projectFormTabs}
      defaultTabIndex={defaultTabIndex}
      onChange={handleChange}
      tabClassName={(isSelected) =>
        clsx(
          "px-3 py-2 font-apercu text-sm flex space-x-1 items-center whitespace-nowrap focus:outline-none",
          {
            "bg-gray-900 text-white": isSelected,
            "text-gray-900 hover:text-white hover:bg-gray-900": !isSelected,
          },
        )
      }
    >
      {(tab) => <>{showCurrentTab({ tab, projectId, projectForms })}</>}
    </HeadlessTabs>
  );
};
