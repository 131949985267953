import { AppRoutes } from "@amenda-types";
import { isEmpty } from "lodash";
import { matchPath } from "react-router-dom";

export const convertExtractedImages = (images?: string[]) => {
  return (images ?? []).map((image, i) => {
    return {
      id: String(i),
      url: image,
    };
  });
};

export const isEditingConstructionDetails = (pathname: string) => {
  const matchesPath = matchPath(
    {
      path: AppRoutes.ConstructionDetailsEdit,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );

  return !isEmpty(matchesPath);
};

export const isConstructionView = (pathname: string) => {
  const matchesPath = matchPath(
    {
      path: AppRoutes.ConstructionDetailsView,
      caseSensitive: false,
      end: false,
    },
    pathname,
  );

  return pathname !== AppRoutes.ConstructionDetailsNew && !isEmpty(matchesPath);
};

export const isCreateNewConstructionDetail = (pathname: string) => {
  const matchesPath = matchPath(
    {
      path: AppRoutes.ConstructionDetailsNew,
      caseSensitive: false,
      end: true,
    },
    pathname,
  );

  return !isEmpty(matchesPath);
};
