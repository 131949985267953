import { FC, ReactNode } from "react";

import { Transition } from "@headlessui/react";
import { useAppStore } from "@amenda-domains/mutations";

interface Props {
  children: ReactNode;
}

export const LargerScreensSidebar: FC<Props> = ({ children }) => {
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);

  if (!sidebarOpen) return null;
  return (
    <Transition
      as="div"
      appear={true}
      show={sidebarOpen}
      enter="transition-opacity duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-400"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="hidden right-0 bg-white lg:flex lg:shrink-0 z-50">
        <div className="flex flex-col w-80 h-screen border-l border-gray-200 pt-4 pb-10">
          <div className="w-full h-full overflow-y-auto pb-4">{children}</div>
        </div>
      </div>
    </Transition>
  );
};

export const SmallerScreensSidebar: FC<Props> = ({ children }) => {
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);

  if (!sidebarOpen) return null;
  return (
    <Transition
      appear={true}
      show={sidebarOpen}
      enter="transition-opacity duration-400"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-400"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="lg:hidden bg-white z-50 w-full h-screen pt-20 border-l">
        <div className="pb-2 h-full overflow-y-auto overscroll-y-contain">
          {children}
        </div>
      </div>
    </Transition>
  );
};
