import { AllowedAttachmentType, AppRoutes } from "@amenda-types";
import { AvailableForms, PaginationLimit } from "@amenda-constants";
import { CollectionDropdown, SharedLayout } from "@amenda-components/Shared";
import {
  ConstructionDetailsCard,
  ConstructionDetailsListCard,
} from "./ConstructionDetailsCard";
import { getComponentsFromForms, isSafeCollection } from "@amenda-utils";
import {
  useAttachmentStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { ConstructionDetailsActionBar } from "./ConstructionDetailsActionBar";
import { ConstructionDetailsProps } from "./types";
import { ConstructionIcon } from "lucide-react";
import { FC } from "react";
import { FormAutoSaveSubmitProps } from "@amenda-components/PageBuilder";
import isEmpty from "lodash/isEmpty";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ConstructionDetails: FC<ConstructionDetailsProps> = ({
  attachments,
  canUpload,
  canCreateCollection,
  pagination,
  isCollection,
  collectionType,
  label = "Construction Details",
  searchAttachments,
  fetchAttachments,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const layoutView = useAttachmentStore((state) => state.layoutView);
  const {
    searchTerm,
    sidebarFilters,
    setSearchTerm,
    setSidebarFilters,
    clearSidebarFilters,
  } = useSidebarFiltersWithPath();

  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const selectAllAttachments = useAttachmentStore(
    (state) => state.setSelectAllAttachments,
  );

  const selectedCollection = selectedCollectionByType?.[collectionType];

  const handleCreateNew = () => {
    navigate(AppRoutes.ConstructionDetailsNew);
  };

  const handleSearch = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const { searchTerm, ...rest } = sanitizedData;
    if (!searchAttachments) return;
    setSearchTerm(searchTerm);
    setSidebarFilters(rest);
    await searchAttachments(searchTerm, rest);
  };

  const handleClearSearch = async () => {
    if (!searchAttachments) return;
    clearSidebarFilters();
    await searchAttachments("", {});
  };

  const handleResourceIds = async (args: any) => {
    let attachmentIds: string[] = [];

    if (fetchAttachments) {
      const attachments = await fetchAttachments({
        formValues: args,
        attachmentType: AllowedAttachmentType.pdf,
        callback: (data: any) => {},
      });

      attachmentIds =
        attachments?.map((attachment: any) => attachment.id) ?? [];
    }
    return attachmentIds;
  };

  const handleGetNextPage = async () => {
    const args: any = {
      next: pagination?.next,
      limit: PaginationLimit.attachments,
      attachmentType: AllowedAttachmentType.image,
    };
    if (isCollection) {
      args.ids = selectedCollection?.resourceIds;
    }

    await fetchAttachments?.(args);
  };

  return (
    <>
      <SharedLayout
        view={layoutView}
        isFetching={isFetching}
        isSearching={isSearching}
        hasNavigationSearch={true}
        components={components}
        hasSearchFilters={
          !isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)
        }
        searchTerm={searchTerm}
        sidebarFilters={sidebarFilters}
        HelperIcon={ConstructionIcon}
        searchPlaceholder="Search construction details"
        helperMessage="No Construction details found"
        helperDescription="Please add construction details to view them here"
        helperDescriptionSearch="Try changing your search filters"
        helperActionText={canUpload ? "Add new detail" : undefined}
        helperOnClick={canUpload ? handleCreateNew : undefined}
        resources={attachments}
        totalSelectedResources={selectedAttachments.length}
        selectAllResources={selectAllAttachments}
        clearSelectedResources={clearSelectedAttachments}
        hasNextPage={
          isSearchFilterEmpty(sidebarFilters) &&
          isEmpty(searchTerm) &&
          isSafeCollection(
            Boolean(isCollection),
            selectedCollection?.resourceIds,
          ) &&
          Boolean(
            pagination?.hasNext &&
              pagination?.docsCount &&
              attachments.length < pagination.docsCount,
          )
        }
        hasNoPages={
          !isEmpty(pagination) &&
          !Boolean(pagination?.hasNext) &&
          !Boolean(pagination?.hasPrevious)
        }
        getNextPage={handleGetNextPage}
        collectionDropdownChildren={
          Boolean(canCreateCollection) ? (
            <CollectionDropdown
              label={label}
              goBackLabel="All construction details"
              resourceIds={selectedAttachments}
              collectionType={collectionType}
              routes={{
                goBack: AppRoutes.ConstructionDetails,
                collection: AppRoutes.ConstructionDetailsCollection,
              }}
              getResourceIds={handleResourceIds}
              clearSelectedResources={clearSelectedAttachments}
            />
          ) : (
            <h1 className="truncate text-lg py-4 text-gray-900">{t(label)}</h1>
          )
        }
        handleNavigationSearch={handleSearch}
        handleClearNavigationSearch={handleClearSearch}
        navigationChildren={
          <ConstructionDetailsActionBar
            canUpload={canUpload}
            collectionType={collectionType}
          />
        }
        gridChildren={(attachment) => (
          <ConstructionDetailsCard attachment={attachment} />
        )}
        listChildren={(attachment) => (
          <ConstructionDetailsListCard attachment={attachment} />
        )}
      />
    </>
  );
};
