import { FC, useEffect, useState } from "react";
import {
  processTemplateData,
  useGetAllForms,
  useGetProject,
  useGetProjectTemplateData,
  useGetTemplate,
} from "@amenda-domains/queries";
import { useProjectStore, useTemplatesStore } from "@amenda-domains/mutations";

import { LoaderWrapper } from "@amenda-components/App";
import { PdfGenerator } from "@amenda-components/PdfBuilder";
import { devConsole } from "@amenda-utils";
import { useParams } from "react-router-dom";

export const ProjectTemplatePrintView: FC = () => {
  const { projectId, templateDataId, templateId } = useParams<{
    projectId?: string;
    templateId?: string;
    templateDataId?: string;
  }>();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const { getProjectTemplateData, loading: getProjectTemplateDataLoader } =
    useGetProjectTemplateData();
  const [template, setTemplate] = useState<any>([]);
  const { getTemplate, loading: getTemplateLoader } = useGetTemplate();
  const selectedTemplate = useTemplatesStore((state) => state.selectedTemplate);
  const selectedTemplateData = useTemplatesStore(
    (state) => state.selectedTemplateData
  );
  const { getProject, loading: getProjectLoader } = useGetProject();
  const projectForms = useProjectStore((state) => state.projectForms);
  const { getAllForms, loading: getFormsLoader } = useGetAllForms();

  const isLoading =
    getProjectLoader ||
    getProjectTemplateDataLoader ||
    getTemplateLoader ||
    getFormsLoader;

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    if (templateDataId) {
      getProjectTemplateData({
        projectId,
        id: templateDataId,
      });
    }
  }, [templateDataId, projectId, getProjectTemplateData]);

  useEffect(() => {
    if (templateId) {
      getTemplate({
        id: templateId,
      });
    }
  }, [getTemplate, templateId]);

  useEffect(() => {
    devConsole?.info("amenda:FormsEarlyLoader: fetching forms");
    getAllForms();
  }, [getAllForms]);

  useEffect(() => {
    const processTemplateDataAsync = async () => {
      const processedTemplate = await processTemplateData({
        projectForms,
        selectedProject,
        templateValues: selectedTemplateData?.values,
        templateStructure: selectedTemplate?.structure,
      });
      setTemplate(processedTemplate);
    };

    processTemplateDataAsync();
  }, [
    projectForms,
    selectedProject,
    selectedTemplate?.structure,
    selectedTemplateData?.values,
  ]);

  if (isLoading) {
    return <LoaderWrapper />;
  }
  return <PdfGenerator components={template} />;
};
