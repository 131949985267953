import { Alert } from "@amenda-components/App";
import { FC } from "react";
import { useBlocker } from "react-router-dom";

interface Props {
  blockChange: boolean;
  message: string;
}

export const DetectRouteChange: FC<Props> = ({ blockChange, message }) => {
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      blockChange && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <Alert
      btnLabelSuccess="Continue"
      isOpen={blocker.state === "blocked"}
      title="Warning"
      message={message}
      onCancel={() => blocker.reset?.()}
      onSubmit={() => blocker.proceed?.()}
    />
  );
};
