import { FC, ReactNode } from "react";

import { Spinner } from ".";
import { buttonTheme } from "@amenda-styles/theme";

interface ButtonProps {
  className?: string;
  form?: string;
  withGroup?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?:
    | "outline"
    | "default"
    | "danger"
    | "primary"
    | "secondary"
    | "warning"
    | "dangerAlt";
  ref?: any;
  disabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  className,
  children,
  withGroup,
  size = "md",
  loading = false,
  variant = "default",
  type = "button",
  ...props
}) => {
  return (
    <button
      {...props}
      className={buttonTheme({
        size,
        variant,
        className,
        withGroup: Boolean(withGroup),
        disabled: Boolean(props.disabled),
      })}
      type={type}
    >
      {children}
      {loading && <Spinner spinnerSize="xs" variant={variant} />}
    </button>
  );
};
