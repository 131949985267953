import { Control, Controller, useController } from "react-hook-form";
import {
  SingleSelect,
  Switch,
  TextField,
} from "@amenda-components/FormComponents";

import { FC } from "react";
import { getLayoutOptions } from "./common";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  component?: any;
}

export const FormBuilderComponentHeaderConfig: FC<Props> = ({ control }) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
  } = useController({
    name: "hasHeaderProperties",
    control,
    defaultValue: false,
  });

  return (
    <div className="w-full flex flex-col space-y-1">
      <Switch
        label="Show in readOnly header?"
        checked={value}
        onChange={onChange}
        showErrorMessage={false}
      />
      {value && (
        <>
          <Controller
            name="headerLayout"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SingleSelect
                id="headerLayout"
                label="Layout"
                isClearable={false}
                error={error?.message}
                value={value}
                getOptionLabel={(option) => t(option.label)}
                hasMenuOverflow={true}
                onChange={onChange}
                options={getLayoutOptions()}
              />
            )}
          />
          <Controller
            name="titleClassName"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id="titleClassName"
                label="Label CSS tailwind classes"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="descriptionClassName"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id="descriptionClassName"
                label="Value CSS tailwind classes"
                error={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="withTitle"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Switch
                label="Show title?"
                checked={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </>
      )}
    </div>
  );
};
